import config from '../config';
import AbstractClient from './abstractClient';

class MachineInstallationFileClient extends AbstractClient {
  getPathBase() {
    return `${config.api.version}/machine_installation_files`;
  }
}

export default new MachineInstallationFileClient();
