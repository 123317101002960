import { fromJS, List } from 'immutable';
import history from '../history';
import projectContactApi from '../api/projectContact';
import projectApi from '../api/project';
import { shortId, entityShortId } from '../idTools';

function dispatchError(err, dispatch) {
  dispatch({
    type: 'ALERT',
    style: 'danger',
    message: JSON.parse(err.response.text)['hydra:description'],
    dismissAfter: 5000,
  });
}

// this method is used only in ProjectContact form component
export function findProjectContact(id) {
  return (dispatch) =>
    projectContactApi
      .find(id, {
        fields: '@id,projectId,projectContactList,name,email,phone,job,comment',
      })
      .then((projectContact) => {
        dispatch({
          type: 'RECEIVE_CURRENT_PROJECT_CONTACT',
          projectContact,
        });
      });
}

function createProjectContact(values, dispatch) {
  return new Promise((resolve, reject) => {
    projectContactApi
      .create(values)
      .then((projectContact) => {
        const tmpList = values.projectContactList || List();
        return projectApi.update(values.projectId, {
          projectContactList: tmpList.push(projectContact.get('@id')),
        });
      })
      .then((project) => {
        dispatch({
          type: 'RECEIVE_CURRENT_PROJECT',
          project: fromJS(project),
        });

        history.push(`/projects/${shortId(values.projectId)}/`);
        resolve();
      })
      .catch((err) => {
        dispatchError(err, dispatch);
        reject();
      });
  });
}

function updateProjectContact(values, dispatch) {
  return new Promise((resolve, reject) => {
    projectContactApi
      .update(values['@id'], values)
      .then((projectContact) => {
        dispatch({
          type: 'UPDATE_PROJECT_CONTACT',
          projectContact,
        });
        history.push(`/projects/${shortId(values.projectId)}/`);
        resolve();
      })
      .catch((err) => {
        dispatchError(err, dispatch);
        reject();
      });
  });
}

export function createUpdateProjectContact(values) {
  return (dispatch) => {
    if (values['@id']) {
      return updateProjectContact(values, dispatch);
    }

    return createProjectContact(values, dispatch);
  };
}

export function removeCurrentProjectContact() {
  return {
    type: 'REMOVE_CURRENT_PROJECT_CONTACT',
  };
}

export function removeContactFromProject(project, projectContact) {
  return (dispatch) => {
    const projectContactList = project.get('projectContactList');

    const toDel = projectContactList.findIndex(
      (tmp) => projectContact.get('@id') === tmp.get('@id')
    );

    if (toDel > -1) {
      const newProjectContactList = projectContactList.delete(toDel);
      const newProject = project.set(
        'projectContactList',
        newProjectContactList
      );

      projectContactApi
        .update(newProject.get('@id'), newProject.toJS())
        .then((updatedProject) => {
          dispatch({
            type: 'RECEIVE_CURRENT_PROJECT',
            project: updatedProject,
          });
        });
    }
  };
}

export function addContactIdToProject(project, projectContactId) {
  return (dispatch) => {
    const projectContactList = project.get('projectContactList');

    const newProjectContactList = projectContactList.push(projectContactId);
    const newProject = project.set('projectContactList', newProjectContactList);

    projectContactApi
      .update(newProject.get('@id'), {
        projectContactList: newProject.projectContactList.toJS(),
      })
      .then((updatedProject) => {
        dispatch({
          type: 'RECEIVE_CURRENT_PROJECT',
          project: updatedProject,
        });
        history.push(`/projects/${entityShortId(project)}/`);
      })
      .catch((err) => {
        dispatchError(err, dispatch);
      });
  };
}
