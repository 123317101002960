import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Map } from 'immutable';
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import Task from '../../entity/Task';
import { entityShortId } from '../../idTools';
import { MdRemoveRedEye, MdPlace } from 'react-icons/md';

function SiteAddress({ site }) {
  if (!site) {
    return null;
  }
  return (
    <Fragment>
      {site.get('formattedAddress')}

      <a
        href={`https://maps.google.fr/?q=${encodeURIComponent(
          site.get('formattedAddress')
        )}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <MdPlace />
      </a>
    </Fragment>
  );
}

SiteAddress.propTypes = {
  site: ImmutablePropTypes.map.isRequired,
};

function TaskRow({ task, onToggleMachine }) {
  const siteIds = task.getSiteIds();

  return (
    <tr>
      <td>
        {task.getIn(['project', 'name'])}
        {siteIds && ` – ${siteIds}`}
      </td>
      <td>{task.startDate.format('DD MMMM YYYY')}</td>
      <td>{task.getNbMachinesToTransfer()}</td>
      <td>
        {task.machineList
          .groupBy((m) => m.get('machineModel'))
          .entrySeq()
          .map((entry) => (
            <div key={entry}>
              {entry[0]} : {entry[1].size} machines
            </div>
          ))}
      </td>
      <td>
        {task.userList
          .map((user) => `${user.firstname} ${user.lastname}`)
          .join(', ')}
      </td>
      <td>
        <SiteAddress site={task.mainSite} />
      </td>
      <td>
        <Link to={`/tasks/${entityShortId(task)}`} title="Voir la tâche">
          <MdRemoveRedEye title="Voir la tâche" />
        </Link>
      </td>
      <td>
        <input
          type="checkbox"
          name={task.get('@id')}
          onChange={onToggleMachine}
        />
      </td>
    </tr>
  );
}

TaskRow.propTypes = {
  onToggleMachine: PropTypes.func.isRequired,
  task: PropTypes.instanceOf(Task),
};

class TaskTable extends PureComponent {
  constructor(props) {
    super(props);

    this.handleToggleMachine = this.handleToggleMachine.bind(this);

    this.state = {
      selectedTaskList: {},
    };
  }

  handleToggleMachine(event) {
    const eventName = event.target.name;
    const eventChecked = event.target.checked;

    this.setState((prevState) => ({
      selectedTaskList: {
        ...prevState.selectedTaskList,
        [eventName]: eventChecked,
      },
    }));
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedTaskList } = this.state;
    if (selectedTaskList !== prevState.selectedTaskList) {
      const out = Map(selectedTaskList)
        .filter((v) => v)
        .keySeq()
        .toArray();

      this.props.onChange(out);
    }
  }

  render() {
    const { taskList } = this.props;

    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Projet</th>
            <th>Date</th>
            <th>Nb machines</th>
            <th>Machines</th>
            <th>Techiciens</th>
            <th>Adresse de destination</th>
            <th>Tâche</th>
            <th>Sél.</th>
          </tr>
        </thead>
        <tbody>
          {taskList.map((task) => (
            <TaskRow
              key={task.get('@id')}
              task={task}
              onToggleMachine={this.handleToggleMachine}
            />
          ))}
        </tbody>
      </Table>
    );
  }
}

TaskTable.propTypes = {
  taskList: ImmutablePropTypes.listOf(PropTypes.instanceOf(Task)),
  onChange: PropTypes.func.isRequired,
};

export default TaskTable;
