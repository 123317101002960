import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Machine from '../../../entity/Machine';

function MachineCell({ machine }) {
  return (
    <Fragment>
      <div>{machine.get('machineModel')}</div>
      <div>
        {machine.get('machinePN')}
        {' - '}
        {machine.get('machineSerialNumber')}
      </div>
    </Fragment>
  );
}

MachineCell.propTypes = {
  machine: PropTypes.instanceOf(Machine).isRequired,
};

export default MachineCell;
