import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { LinkContainer } from 'react-router-bootstrap';
import { Alert, Button, ButtonGroup } from 'react-bootstrap';
import { entityShortId } from '../../idTools';
import Task from '../../entity/Task';
import { MdCheck, MdEdit, MdEventNote, MdRemoveRedEye } from 'react-icons/md';

function hasMultipleContacts(task) {
  const machineList = task.get('machineList');

  const groupedMachines = machineList.groupBy((machine) =>
    List.of(
      machine.get('siteContactFirstname'),
      machine.get('siteContactName'),
      machine.get('siteContactPhone'),
      machine.get('siteContactEmail')
    )
  );

  return groupedMachines.size > 1;
}

const userFullName = (user) => `${user.firstname} ${user.lastname}`;

function AppointmentRow({ task, handleAppointmentTaken }) {
  const machine = task.machineList && task.machineList.first();

  const siteIds = task.getSiteIds();
  const taskShortId = entityShortId(task);

  return (
    <tr>
      <td>
        {task.getIn(['project', 'name'])}
        {siteIds && ` – ${siteIds}`}
      </td>
      <td>
        {task.machineList
          .groupBy((m) => m.get('machineModel'))
          .entrySeq()
          .map((entry) => (
            <div key={entry}>
              {entry[0]} : {entry[1].size} machines
            </div>
          ))}
      </td>
      <td>
        {task.startDate.isSame(task.endDate, 'day')
          ? `Le ${task.startDate.format('DD/MM/YYYY')}`
          : `Du ${task.startDate.format('DD/MM')} au ${task.endDate.format(
              'DD/MM/YY'
            )}`}
      </td>
      <td>
        {machine && (
          <div>
            {hasMultipleContacts(task) && (
              <Alert variant="warning">
                Attention ! Plusieurs contact, seul un est affiché
              </Alert>
            )}
            <div>
              {machine.get('siteContactFirstname')}{' '}
              {machine.get('siteContactName')}
            </div>
            <div>{machine.get('siteContactPhone')}</div>
            <div>{machine.get('siteContactEmail')}</div>
          </div>
        )}
      </td>
      <td>{task.getIn(['mainSite', 'formattedAddress'])}</td>
      <td>{task.userList.map(userFullName).join(', ')}</td>
      <td>{task.get('comment')}</td>
      <td>{task.get('planningComment')}</td>
      <td className="Appointment__ActionList">
        <ButtonGroup>
          <Button
            type="button"
            variant="success"
            onClick={() => handleAppointmentTaken(task)}
            title="Noter comme validé"
          >
            <MdCheck />
          </Button>
          <LinkContainer to={`/tasks/${taskShortId}/edit`}>
            <Button variant="secondary" title="modifier la tache">
              <MdEdit />
            </Button>
          </LinkContainer>
          <LinkContainer to={`/tasks/${taskShortId}/logs/create`}>
            <Button variant="secondary" title="Ajouter / voir les une notes">
              <MdEventNote />
            </Button>
          </LinkContainer>
          <LinkContainer
            to={`/tasks/${taskShortId}`}
            title="Détail de la tâche"
          >
            <Button variant="primary">
              <MdRemoveRedEye />
            </Button>
          </LinkContainer>
        </ButtonGroup>
      </td>
    </tr>
  );
}
AppointmentRow.propTypes = {
  task: PropTypes.instanceOf(Task).isRequired,
  handleAppointmentTaken: PropTypes.func.isRequired,
};

export default AppointmentRow;
