import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { List } from 'immutable';
import { Link } from 'react-router-dom';
import { ButtonGroup, Table } from 'react-bootstrap';
import { entityShortId } from '../../idTools';
import { Machine as MachineCell } from './Cells';
import * as machineActions from '../../actions/machine';
import { currentUserIsAdminSelector } from '../../selector';
import MachineInstallDates from './MachineInstallDates';
import machineApi from '../../api/machine';
import {
  MdEdit,
  MdEmail,
  MdFileDownload,
  MdRemoveRedEye,
  MdPhone,
  MdPlace,
  MdWarning,
} from 'react-icons/md';

function InputFilter(props) {
  return (
    <input
      size="0"
      style={{ width: '100%' }}
      onChange={(event) => props.handleFilterChange(props.filterKey, event)}
    />
  );
}
InputFilter.propTypes = {
  filterKey: PropTypes.string.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
};

class MachineTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  componentWillUnmount() {
    this.props.clearMachineTableFilter();
  }

  getProjectName(machine) {
    return (
      machine && machine.get('project') && machine.get('project').get('name')
    );
  }

  hasComment() {
    const { machineFilters, currentMachines } = this.props;

    return (
      (machineFilters && machineFilters.get('comment')) ||
      currentMachines.some((machine) => machine.get('comment'))
    );
  }

  hasTakeback() {
    return this.props.currentMachines.some(
      (machine) => machine.get('takebackType') || machine.get('takebackSN')
    );
  }

  isColumnVisible(column) {
    return !(
      this.props.hideColumns && this.props.hideColumns.indexOf(column) > -1
    );
  }

  handleFilterChange(key, event) {
    const filter = { columnKey: key, filterTerm: event.target.value };
    this.props.addMachineTableFilter(filter);
  }

  render() {
    const {
      currentMachines,
      machineFilters,
      isAdmin,
      handleMachineStatusChange,
      handleMachinePreparationStatusChange,
    } = this.props;

    const hasTakeback = this.hasTakeback();
    const hasComment = this.hasComment();

    return (
      <form>
        <Table striped bordered hover>
          <thead>
            <tr>
              {this.isColumnVisible('project') && <th>Projet</th>}
              <th>Status</th>
              {this.isColumnVisible('preparationStatus') && (
                <th>Préparation machine</th>
              )}
              {this.isColumnVisible('installDates') && (
                <th>Installation requise</th>
              )}
              <th>Planification</th>
              {this.isColumnVisible('machine') && <th>Équipement</th>}
              {this.isColumnVisible('takeback') && hasTakeback && (
                <th>Reprise</th>
              )}
              {this.isColumnVisible('address') && <th>Adresse</th>}
              {this.isColumnVisible('contact') && <th>Contact</th>}
              {hasComment && <th>Commentaires</th>}
              <th />
            </tr>

            <tr>
              {this.isColumnVisible('project') && <td />}
              <td>
                <select
                  defaultValue={machineFilters && machineFilters.get('status')}
                  onChange={(event) => this.handleFilterChange('status', event)}
                >
                  <option value="">Tous</option>
                  <option value="to_plan">À planifier</option>
                  <option value="planned">Planifiées</option>
                  <option value="done">Terminées</option>
                  <option value="waiting">En attente</option>
                </select>
              </td>
              {this.isColumnVisible('preparationStatus') && <td />}
              {this.isColumnVisible('installDates') && <td />}
              <td />
              {this.isColumnVisible('machine') && (
                <td>
                  <InputFilter
                    handleFilterChange={this.handleFilterChange}
                    filterKey="stuff"
                  />
                </td>
              )}
              {this.isColumnVisible('takeback') && hasTakeback && (
                <td>
                  <InputFilter
                    handleFilterChange={this.handleFilterChange}
                    filterKey="takeback"
                  />
                </td>
              )}
              {this.isColumnVisible('address') && (
                <td>
                  <InputFilter
                    handleFilterChange={this.handleFilterChange}
                    filterKey="site_formattedAddress"
                  />
                </td>
              )}
              {this.isColumnVisible('contact') && (
                <td>
                  <InputFilter
                    handleFilterChange={this.handleFilterChange}
                    filterKey="contact"
                  />
                </td>
              )}
              {hasComment && (
                <td>
                  <InputFilter
                    handleFilterChange={this.handleFilterChange}
                    filterKey="comment"
                  />
                </td>
              )}
              <td />
            </tr>
          </thead>
          <tbody>
            {currentMachines.map((machine) => (
              <tr key={machine.get('@id')}>
                {this.isColumnVisible('project') && (
                  <td>{this.getProjectName(machine)}</td>
                )}
                <td>
                  <select
                    itemID={machine.get('@id')}
                    onChange={(event) =>
                      handleMachineStatusChange(machine, event.target.value)
                    }
                    defaultValue={machine.get('status')}
                  >
                    <option value="to_plan">À planifier</option>
                    <option value="planned">Planifiée</option>
                    <option value="done">Terminée</option>
                    <option value="waiting">En attente</option>
                  </select>
                </td>
                {this.isColumnVisible('preparationStatus') && (
                  <td>
                    <select
                      itemID={machine.get('@id')}
                      onChange={(event) =>
                        handleMachinePreparationStatusChange(
                          machine,
                          event.target.value
                        )
                      }
                      defaultValue={machine.get('preparationStatus')}
                    >
                      <option value="waiting_info">
                        {"En attente d'informations"}
                      </option>
                      <option value="to_prepare">A préparer</option>
                      <option value="ready">Prête</option>
                      <option value="on_site">Sur site</option>
                      <option value="not_applicable">Non concernée</option>
                    </select>
                  </td>
                )}
                {this.isColumnVisible('installDates') && (
                  <td>
                    <MachineInstallDates machine={machine} />
                  </td>
                )}
                <td>
                  {machine.get('task') && (
                    <div>
                      <div>
                        {machine.getIn(['task', 'status']) ===
                          'needs_appointment' && (
                          <MdWarning title="RDV on validé" />
                        )}{' '}
                        <a
                          href={`/tasks/${machine.task
                            .get('@id')
                            .replace('/v1/tasks/', '')}`}
                        >
                          {machine.getIn(['task', 'startDate']) &&
                            machine
                              .getIn(['task', 'startDate'])
                              .format('DD/MM/YYYY')}
                        </a>
                      </div>
                      <div>
                        {machine.task.userList
                          .map((user) => user.fullname)
                          .join(', ')}
                      </div>
                    </div>
                  )}
                </td>
                {this.isColumnVisible('machine') && (
                  <td>
                    <MachineCell machine={machine} />
                  </td>
                )}
                {this.isColumnVisible('takeback') && hasTakeback && (
                  <td>
                    <div>
                      {machine.get('takebackType')
                        ? `Type: ${machine.get('takebackType')}`
                        : ''}
                    </div>
                    <div>
                      {machine.get('takebackSN')
                        ? `SN: ${machine.get('takebackSN')}`
                        : ''}
                    </div>
                  </td>
                )}
                {this.isColumnVisible('address') && (
                  <td>
                    {machine.get('site') ? (
                      <div>
                        {machine.get('site').get('formattedAddress')}{' '}
                        <a
                          href={`https://maps.google.com/?q=${machine.getIn([
                            'site',
                            'formattedAddress',
                          ])}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <MdPlace />
                        </a>
                      </div>
                    ) : (
                      ''
                    )}
                    <div>
                      {machine.get('serviceName')}
                      {machine.get('serviceBuilding') &&
                        `, ${machine.get('serviceBuilding')}`}
                      {machine.get('serviceFloor') &&
                        `, ${machine.get('serviceFloor')}`}
                      {machine.get('serviceOffice') &&
                        `, ${machine.get('serviceOffice')}`}
                    </div>
                  </td>
                )}
                {this.isColumnVisible('contact') && (
                  <td>
                    <div>
                      {machine.get('siteContactFirstname')}{' '}
                      {machine.get('siteContactName')}
                    </div>
                    {machine.get('siteContactPhone') && (
                      <div>
                        {machine.get('siteContactPhone')}{' '}
                        <a href={`tel: ${machine.get('siteContactPhone')}`}>
                          <MdPhone />
                        </a>
                      </div>
                    )}

                    {machine.get('siteContactEmail') && (
                      <div>
                        {machine.get('siteContactEmail')}{' '}
                        <a href={`mailto: ${machine.get('siteContactEmail')}`}>
                          <MdEmail />
                        </a>
                      </div>
                    )}
                  </td>
                )}
                {hasComment && <td>{machine.get('comment')}</td>}
                <td className="text-nowrap">
                  <ButtonGroup>
                    {isAdmin && (
                      <Link
                        to={`/machines/${entityShortId(machine)}/edit`}
                        className="btn btn-link btn-sm"
                        title="Modifier la machine"
                      >
                        <MdEdit />
                      </Link>
                    )}
                    {machine.isPVAvailable && (
                      <a
                        className="btn btn-link btn-sm"
                        href="#download-pv"
                        title="Télécharger le PV d'intervention"
                        onClick={(e) => {
                          e.preventDefault();
                          machineApi.openPV(machine);
                        }}
                      >
                        <MdFileDownload />
                      </a>
                    )}
                    {/* <input type='checkbox' onChange={() => this.props.selectMachine(machine)} /> */}

                    <Link
                      to={`/machines/${entityShortId(machine)}`}
                      className="btn btn-link btn-sm"
                      title="Voir le détail"
                    >
                      <MdRemoveRedEye />
                    </Link>
                  </ButtonGroup>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </form>
    );
  }
}

MachineTable.propTypes = {
  currentMachines: ImmutablePropTypes.list.isRequired,
  hideColumns: PropTypes.array,
  addMachineTableFilter: PropTypes.func.isRequired,
  clearMachineTableFilter: PropTypes.func.isRequired,
  handleMachineStatusChange: PropTypes.func.isRequired,
  handleMachinePreparationStatusChange: PropTypes.func.isRequired,
  machineFilters: PropTypes.object,
  isAdmin: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    currentMachines: state.app.get('currentMachines')
      ? state.app.getIn(['currentMachines', 'hydra:member'])
      : List(),
    isAdmin: currentUserIsAdminSelector(state),
  };
}

export default connect(mapStateToProps, machineActions)(MachineTable);

export { MachineTable };
