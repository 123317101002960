import { fromJS, List } from 'immutable';
import history from '../history';
import teamApi from '../api/team';

function dispatchError(err, dispatch) {
  dispatch({
    type: 'ALERT',
    style: 'danger',
    message: JSON.parse(err.response.text)['hydra:description'],
    dismissAfter: 5000,
  });
}

function createTeam(values, dispatch) {
  return new Promise((resolve, reject) => {
    teamApi
      .create(values)
      .then((team) => {
        dispatch({
          type: 'RECEIVE_NEW_TEAM',
          team: fromJS(team),
        });

        history.push('/teams/');
        resolve();
      })
      .catch((err) => {
        dispatchError(err, dispatch);
        reject();
      });
  });
}

function updateTeam(values, dispatch) {
  return new Promise((resolve, reject) => {
    teamApi
      .update(values['@id'], values)
      .then((team) => {
        dispatch({
          type: 'UPDATE_TEAM',
          team: fromJS(team),
        });
        history.push('/teams/');
        resolve();
      })
      .catch((err) => {
        dispatchError(err, dispatch);
        reject();
      });
  });
}

export function findTeam(id) {
  return (dispatch) =>
    teamApi.find(id).then((team) => {
      dispatch({
        type: 'RECEIVE_CURRENT_TEAM',
        team: fromJS(team),
      });
    });
}

export function removeCurrentTeam() {
  return {
    type: 'REMOVE_CURRENT_TEAM',
  };
}

export function findAllTeams() {
  return (dispatch) =>
    teamApi.findBy({ fields: '@id,name,agency' }).then((teamList) => {
      dispatch({
        type: 'RECEIVE_ALL_TEAM',
        teamList: fromJS(teamList),
      });
    });
}

export function createUpdateTeam(values) {
  return (dispatch) => {
    if (values['@id']) {
      return updateTeam(values, dispatch);
    }

    return createTeam(values, dispatch);
  };
}

export function deleteTeam(team) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      teamApi
        .del(team.get('@id'))
        .then(() => {
          dispatch({
            type: 'DELETE_TEAM',
            team,
          });

          history.push('/teams/');
          resolve();
        })
        .catch((err) => {
          dispatchError(err, dispatch);
          reject();
        });
    });
}

export function filterTeamList(values) {
  return {
    type: 'SET_TEAM_LIST_FILTER',
    teamList: List(values.teamListFilter),
  };
}
