import PropTypes from 'prop-types';
import React from 'react';
import { parse } from 'query-string';
import { Map } from 'immutable';
import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';
import {
  Alert,
  FormControl,
  FormLabel,
  FormGroup,
  Button,
  ButtonToolbar,
} from 'react-bootstrap';
import columnChoices, { getAllValues } from '../projects/machines/Column';
import {
  removeCurrentMachine,
  findMachine,
  deleteMachine,
  createUpdateMachine,
} from '../../actions/machine';
import { formControlFields } from './utils';
import ProjectSelect from './ProjectSelect';
import Machine from '../../entity/Machine';

function validateMachineForm(values) {
  const errors = {};

  if (!values.project) {
    errors.project = 'Le champ project est obligatoire';
  }

  if (!(values.site && values.site.city)) {
    errors.site = { city: 'Le champ ville est obligatoire' };
  }

  return errors;
}

export class MachineForm extends React.Component {
  constructor(props) {
    super(props);

    this.fetchMachine = this.fetchMachine.bind(this);
    this.onDeleteCurrentMachine = this.onDeleteCurrentMachine.bind(this);
  }

  componentDidMount() {
    this.fetchMachine();
  }

  componentDidUpdate(prevProps) {
    const oldMachine = prevProps.match.params.machineId;
    const newMachine = this.props.match.params.machineId;

    if (oldMachine !== newMachine) {
      this.fetchMachine();
    }
  }

  componentWillUnmount() {
    this.props.removeCurrentMachine();
  }

  onDeleteCurrentMachine() {
    const machine = this.props.currentMachine;

    if (window.confirm('Supprimer cette machine ?')) {
      this.props.deleteMachine(machine);
    }
  }

  fetchMachine() {
    if (this.props.match.params.machineId) {
      this.props.findMachine(this.props.match.params.machineId);
    }
  }

  render() {
    const { createUpdateMachine, initialValues } = this.props;

    return (
      <Form
        onSubmit={createUpdateMachine}
        initialValues={initialValues}
        validate={validateMachineForm}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <h1>{this.props.isEdition ? 'Édition' : 'Création'} machine</h1>

            <Field name="project">
              {({ input, meta }) => (
                <>
                  <FormLabel>Projet</FormLabel>
                  <ProjectSelect {...formControlFields(input)} />

                  {meta.touched && meta.error && (
                    <Alert variant="danger">{meta.error}</Alert>
                  )}
                </>
              )}
            </Field>

            <Field name="preparationStatus">
              {({ input, meta }) => (
                <>
                  <FormLabel>Preparation atelier</FormLabel>
                  <FormControl as="select" {...formControlFields(input)}>
                    <option value="waiting_info">
                      {"En attente d'informations"}
                    </option>
                    <option value="to_prepare">A préparer</option>
                    <option value="ready">Prête</option>
                    <option value="on_site">Sur site</option>
                    <option value="not_applicable">Non concernée</option>
                  </FormControl>

                  {meta.touched && meta.error && (
                    <Alert variant="danger">{meta.error}</Alert>
                  )}
                </>
              )}
            </Field>

            <Field name="transferStatus">
              {({ input, meta }) => (
                <>
                  <FormLabel>Statut de transfert</FormLabel>
                  <FormControl as="select" {...formControlFields(input)}>
                    <option value="waiting_info">
                      {"En attente d'informations"}
                    </option>
                    <option value="to_transfer">A transférer</option>
                    <option value="transfered">Transférée</option>
                    <option value="not_applicable">Non concernée</option>
                  </FormControl>

                  {meta.touched && meta.error && (
                    <Alert variant="danger">{meta.error}</Alert>
                  )}
                </>
              )}
            </Field>

            <h2>Site</h2>
            <Field
              name="site.city"
              subscription={{ error: true, touched: true }}
            >
              {({ meta: { touched, error } }) =>
                touched && error ? (
                  <Alert variant="danger">{error}</Alert>
                ) : null
              }
            </Field>

            {columnChoices
              .get('group')
              .get('Emplacement')
              .filter((column) => column.get('value').substr(0, 5) === 'site_')
              .map(
                (column) =>
                  column.get('text') && (
                    <Field
                      name={`site.${column.get('value').substr(5)}`}
                      key={`site.${column.get('value').substr(5)}`}
                    >
                      {({ input }) => (
                        <FormGroup key={column.get('text')}>
                          {column.get('type') !== 'hidden' && (
                            <FormLabel>{column.get('text')}</FormLabel>
                          )}
                          <FormControl
                            key={`input_${column.get('value')}`}
                            type={column.get('type') || 'text'}
                            {...formControlFields(input)}
                          />
                        </FormGroup>
                      )}
                    </Field>
                  )
              )
              .toArray()}

            {columnChoices
              .get('group')
              .map((list, key) => {
                return (
                  <div key={`group_${key}`}>
                    <h2>{key}</h2>
                    {list
                      .filter(
                        (column) => column.get('value').substr(0, 5) !== 'site_'
                      )
                      .map(
                        (column) =>
                          column.get('text') && (
                            <Field
                              name={column.get('value')}
                              key={column.get('value')}
                            >
                              {({ input }) => (
                                <FormGroup key={column.get('text')}>
                                  {column.get('type') !== 'hidden' && (
                                    <FormLabel>{column.get('text')}</FormLabel>
                                  )}
                                  <FormControl
                                    key={`input_${column.get('value')}`}
                                    type={column.get('type') || 'text'}
                                    {...formControlFields(input)}
                                  />
                                </FormGroup>
                              )}
                            </Field>
                          )
                      )}
                  </div>
                );
              })
              .toArray()}

            <ButtonToolbar>
              <Button type="submit" variant="primary">
                {this.props.isEdition ? 'Modifier' : 'Créer'}
              </Button>

              {this.props.isEdition && (
                <Button variant="danger" onClick={this.onDeleteCurrentMachine}>
                  Supprimer la machine
                </Button>
              )}
            </ButtonToolbar>
          </form>
        )}
      </Form>
    );
  }
}
MachineForm.defaultTypes = {
  currentMachine: null,
};
MachineForm.propTypes = {
  '@id': PropTypes.string,
  machineSerialNumber: PropTypes.string,
  site: PropTypes.object,
  initialValues: PropTypes.object.isRequired,
  removeCurrentMachine: PropTypes.func.isRequired,
  findMachine: PropTypes.func.isRequired,
  deleteMachine: PropTypes.func.isRequired,
  createUpdateMachine: PropTypes.func.isRequired,
  isEdition: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  currentMachine: PropTypes.instanceOf(Machine),
};

function mapStateToProps(state, ownProps) {
  const currentMachine = state.app.get('currentMachine');
  const formKeys = getAllValues(true).concat([
    '@id',
    'project',
    'preparationStatus',
    'transferStatus',
  ]);

  let initialValues = Map();

  if (currentMachine) {
    formKeys.forEach((key) => {
      const splittedKey = key.split('.');
      initialValues = initialValues.setIn(
        splittedKey,
        currentMachine.getIn(splittedKey)
      );
    });
  } else {
    const project = parse(ownProps.location.search).project;

    if (project) {
      initialValues = Map({ project });
    }
  }

  return {
    isEdition: !!currentMachine,
    currentMachine,
    initialValues: initialValues.toJS(),
  };
}

export const MachineFormContainer = connect(mapStateToProps, {
  removeCurrentMachine,
  findMachine,
  deleteMachine,
  createUpdateMachine,
})(MachineForm);
