import { fromJS } from 'immutable';
import history from '../history';
import localforage from 'localforage';
import userApi from '../api/user';
import {
  USER_DEFAULT_FIELDS,
  USER_DEFAULT_FIELDS_WITHOUT_TEAM,
} from '../entity/User';

function changeActiveState(currentUser, isActive) {
  return (dispatch) =>
    userApi
      .update(currentUser.get('@id'), { isActive })
      .then((user) => {
        dispatch({
          type: 'UPDATE_USER',
          user: fromJS(user),
        });
      })
      .catch((err) => {
        dispatch({
          type: 'ALERT',
          style: 'danger',
          message: JSON.parse(err.response.text)['hydra:description'],
          dismissAfter: 5000,
        });
      });
}

function dispatchError(err, dispatch) {
  dispatch({
    type: 'ALERT',
    style: 'danger',
    message: JSON.parse(err.response.text)['hydra:description'],
    dismissAfter: 5000,
  });
}

function createUser(values, dispatch) {
  return new Promise((resolve, reject) => {
    userApi
      .create(values)
      .then((user) => {
        dispatch({
          type: 'RECEIVE_NEW_USER',
          user: fromJS(user),
        });

        history.push('/users/');
        resolve();
      })
      .catch((err) => {
        dispatchError(err, dispatch);
        reject();
      });
  });
}

function updateUser(data, dispatch) {
  const values = data;
  if (values.team && values.team['@id']) {
    values.team = values.team['@id'];
  }

  return new Promise((resolve, reject) => {
    userApi
      .update(values['@id'], values)
      .then((user) => {
        dispatch({
          type: 'UPDATE_USER',
          user: fromJS(user),
        });
        history.push('/users/');
        resolve();
      })
      .catch((err) => {
        dispatchError(err, dispatch);
        reject();
      });
  });
}

export function findUser(id) {
  return (dispatch) =>
    userApi.find(id).then((user) => {
      dispatch({
        type: 'RECEIVE_CURRENT_USER',
        user: fromJS(user),
      });
    });
}

export function removeCurrentUser() {
  return {
    type: 'REMOVE_CURRENT_USER',
  };
}

export function findAllUsers() {
  return (dispatch) =>
    userApi
      .findBy({
        'order[position]': 'ASC',
        fields: `${USER_DEFAULT_FIELDS_WITHOUT_TEAM},team{@id,name}`,
      })
      .then((userList) => {
        dispatch({
          type: 'RECEIVE_ALL_USER',
          userList: fromJS(userList),
        });
      });
}

export function findHiddenUsers() {
  return (dispatch) => {
    userApi
      .findBy({ showInTaskList: 'never', fields: USER_DEFAULT_FIELDS })
      .then((userList) => {
        dispatch({
          type: 'RECEIVE_HIDDEN_USERS',
          userList,
        });
      });
  };
}

export function deactivateUser(user) {
  return changeActiveState(user, false);
}

export function activateUser(user) {
  return changeActiveState(user, true);
}

export function createUpdateUser(values) {
  return (dispatch) => {
    if (values['@id']) {
      return updateUser(values, dispatch);
    }

    return createUser(values, dispatch);
  };
}

export function changePassword(data) {
  return (dispatch) => {
    const values = data;

    return new Promise((resolve, reject) => {
      userApi
        .changePassword(values)
        .then((me) => {
          localforage.setItem(
            'onedrive.auth.token',
            me.get('azureToken').access_token
          );
          dispatch({
            type: 'RECEIVE_ME',
            me,
          });
          dispatch({
            type: 'ALERT',
            style: 'success',
            message: 'Mot de passe changé avec succès',
            dismissAfter: 5000,
          });
          history.push('/');
          resolve();
        })
        .catch((err) => {
          dispatchError(err, dispatch);
          reject();
        });
    });
  };
}

export function moveUserUp(user) {
  return (dispatch) => {
    createUpdateUser({
      '@id': user.get('@id'),
      position: user.get('position') - 1,
    })(dispatch);
  };
}

export function moveUserDown(user) {
  return (dispatch) => {
    createUpdateUser({
      '@id': user.get('@id'),
      position: user.get('position') + 1,
    })(dispatch);
  };
}

export function findMe() {
  return (dispatch) => {
    userApi.findMe().then((me) => {
      dispatch({
        type: 'RECEIVE_ME',
        me,
      });
    });
  };
}

export function setAllUserFilter(checked) {
  return {
    type: 'SET_ALL_USER_FILTER',
    checked,
  };
}
