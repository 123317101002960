import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { entityShortId } from '../../idTools';
import Project from '../../entity/Project';

export default function ProjectButton({ project }) {
  return (
    <LinkContainer
      to={`/projects/${entityShortId(project)}/`}
      key={project.get('@id')}
    >
      <Button block variant="secondary">
        <div>{project.get('name')}</div>

        {project.get('brandList') && project.get('brandList').size > 0 && (
          <p className="small">
            (
            {project
              .get('brandList')
              .map((brand, key) => (key > 0 ? ', ' : '') + brand.get('name'))}
            )
          </p>
        )}
      </Button>
    </LinkContainer>
  );
}

ProjectButton.propTypes = {
  project: PropTypes.instanceOf(Project).isRequired,
};
