import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { parse } from 'query-string';
import { List } from 'immutable';
import Loader from 'react-loader';
import moment from 'moment';
import 'moment/locale/fr';
import { connect } from 'react-redux';
import BigCalendar from 'react-big-calendar';
import history from '../../history';
import RouterPropTypes from '../../prop-types/router';
import { selectTask } from '../../actions/task';
import TaskInfo from './taskInfo';
import taskApi from '../../api/task';
import userApi from '../../api/user';
import PlanningLegend from './planningLegend';
import { dayPropGetter } from './utils';
import { PLANNING_FIELDS } from './globalPlanning';
import User from '../../entity/User';

moment.locale('fr');
BigCalendar.momentLocalizer(moment);

const startAccessor = (task) => task.startDate && task.startDate.toDate();
const endAccessor = (task) => task.endDate && task.endDate.toDate();

class UserPlanning extends React.PureComponent {
  constructor(props) {
    super(props);
    this.fetchCurrentUser = this.fetchCurrentUser.bind(this);
    this.fetchPlanning = this.fetchPlanning.bind(this);
    this.handleNavigate = this.handleNavigate.bind(this);
    this.renderTask = this.renderTask.bind(this);
    this.renderTitle = this.renderTitle.bind(this);
  }

  componentDidMount() {
    this.fetchCurrentUser();
  }

  componentDidUpdate(prevProps) {
    const { currentUser, currentDate, match } = this.props;

    if (
      currentUser &&
      (!prevProps.currentUser ||
        currentUser.get('@id') !== prevProps.currentUser.get('@id'))
    ) {
      this.fetchPlanning();
    }

    if (match.params.userId !== prevProps.match.params.userId) {
      this.fetchCurrentUser();
    }

    if (currentDate && !currentDate.isSame(prevProps.currentDate, 'day')) {
      this.fetchPlanning();
    }
  }

  fetchCurrentUser() {
    if (this.props.match.params.userId) {
      this.props.findUser(this.props.match.params.userId);
    }
  }

  fetchPlanning() {
    if (this.props.currentUser) {
      this.props.fetchPlanningForUserByMonth(
        this.props.currentUser,
        this.props.currentDate.year(),
        this.props.currentDate.format('MM')
      );
    }
  }

  handleNavigate(newDate) {
    return this.props.userChangeDate(
      this.props.match.params.userId,
      moment(newDate)
    );
  }

  renderTaskTitle(task) {
    const machineGroup = task.machineList.groupBy((m) => m.get('machineModel'));
    return machineGroup
      .map((list, model) => `${model} : ${list.size} machines`)
      .toList()
      .reduce((prev, curr) => `${prev}\n${curr}`);
  }

  renderTask(event) {
    const task = event.event;
    return <TaskInfo task={task} selectTask={this.props.selectTask} />;
  }

  renderTitle() {
    if (!this.props.currentUser || !this.props.currentDate) {
      return <div />;
    }

    return (
      <h1 className="text-center">
        {this.props.currentUser.get('firstname')}{' '}
        {this.props.currentUser.get('lastname')}
      </h1>
    );
  }

  render() {
    const taskList = this.props.taskList;

    return (
      <div>
        {this.renderTitle()}

        <Loader
          loaded={this.props.isLoaded}
          parentClassName="loader loader--inline"
        >
          <BigCalendar
            className="userPlanning"
            culture={'fr'}
            events={taskList.toArray()}
            allDayAccessor={() => true}
            startAccessor={startAccessor}
            endAccessor={endAccessor}
            tooltipAccessor={this.renderTaskTitle}
            components={{ event: this.renderTask }}
            defaultDate={this.props.currentDate.toDate()}
            defaultView={'month'}
            views={['month', 'agenda']}
            onNavigate={this.handleNavigate}
            dayPropGetter={dayPropGetter}
            popup
          />
        </Loader>

        <h2>Légende</h2>
        <PlanningLegend />
      </div>
    );
  }
}
UserPlanning.propTypes = {
  fetchPlanningForUserByMonth: PropTypes.func.isRequired,
  selectTask: PropTypes.func.isRequired,
  findUser: PropTypes.func.isRequired,
  userChangeDate: PropTypes.func.isRequired,
  match: RouterPropTypes.params({
    userId: PropTypes.string.isRequired,
  }).isRequired,
  isLoaded: PropTypes.bool.isRequired,
  taskList: PropTypes.object.isRequired,
  currentUser: PropTypes.instanceOf(User),
  currentDate: PropTypes.instanceOf(moment).isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    currentDate: parse(ownProps.location.search).currentDate
      ? moment(parse(ownProps.location.search).currentDate)
      : moment(),
  };
}

function fetchPlanningForUserByMonth(user, year, month) {
  return (dispatch) => {
    // state is not redux's `getState` as we are on hooks here, not on redux
    dispatch({
      type: 'REQUEST_TASK_LIST',
    });

    taskApi
      .findForUserByMonth(user, year, month, PLANNING_FIELDS)
      .then((taskList) => {
        dispatch({
          type: 'RECEIVE_TASKS',
          taskList,
        });
      });
  };
}

export function findUser(id) {
  return (dispatch) =>
    userApi.find(id, { fields: '@id,firstname,lastname' }).then((user) => {
      dispatch({
        type: 'RECEIVE_CURRENT_USER',
        user,
      });
    });
}

function initState() {
  return { taskList: List(), taskListLoaded: false, currentUser: null };
}

function reducer(state, action) {
  switch (action.type) {
    case 'REQUEST_TASK_LIST':
      return {
        ...state,
        taskListLoaded: false,
        taskList: List(),
      };

    case 'RECEIVE_TASKS':
      return {
        ...state,
        taskListLoaded: true,
        taskList: action.taskList.getMembers(),
      };

    case 'RECEIVE_CURRENT_USER':
      return {
        ...state,
        currentUser: action.user,
      };

    default:
      throw new Error(`Unknown action ${action.type}`);
  }
}

function userChangeDate(userId, newDate) {
  const now = moment();
  const parameters = !now.isSame(newDate, 'month')
    ? `?currentDate=${newDate.startOf('month').format('YYYY-MM-DD')}`
    : '';

  history.push(`/planning/${userId}${parameters}`);
}

function UserPlanningLocalFetch({ ...props }) {
  const [state, dispatch] = useReducer(reducer, null, initState);

  return (
    <UserPlanning
      fetchPlanningForUserByMonth={(user, year, week) =>
        fetchPlanningForUserByMonth(user, year, week)(dispatch, state)
      }
      taskList={state.taskList}
      isLoaded={state.taskListLoaded}
      findUser={(id) => findUser(id)(dispatch, state)}
      currentUser={state.currentUser}
      {...props}
    />
  );
}

export default connect(mapStateToProps, { selectTask, userChangeDate })(
  UserPlanningLocalFetch
);
