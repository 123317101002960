import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { is, Map } from 'immutable';
import { connect } from 'react-redux';
import Loader from 'react-loader';
import { Button, ButtonToolbar, Card, Pagination } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import MachineTable from './MachineTable';
import PagedCollection from '../../entity/PagedCollection';
import * as machineActions from '../../actions/machine';

export class GlobalMachine extends React.PureComponent {
  constructor(props) {
    super(props);

    this.fetchMachines = this.fetchMachines.bind(this);
    this.getNbPage = this.getNbPage.bind(this);
    this.goToPage = this.goToPage.bind(this);
  }

  componentDidMount() {
    this.fetchMachines();
  }

  componentDidUpdate(prevProps) {
    const oldPage = prevProps.currentPage;
    const newPage = this.props.currentPage;

    if (newPage && oldPage !== newPage) {
      this.fetchMachines();
    }

    if (!is(prevProps.machineFilters, this.props.machineFilters)) {
      this.fetchMachines(this.props.machineFilters.toJS());
    }
  }

  getNbPage() {
    return this.props.currentMachines.getNbPages();
  }

  fetchMachines() {
    const params = Object.assign(
      { page: this.props.currentPage },
      this.props.machineFilters.toJS()
    );

    this.props.findMachines(params);
  }

  goToPage(eventKey) {
    this.props.machineGoToPage(eventKey);
  }

  render() {
    return (
      <div>
        <h1>Machines</h1>

        <Card body>
          <ButtonToolbar>
            <LinkContainer to="/machines/export">
              <Button variant="secondary">Exporter les machines (XLS)</Button>
            </LinkContainer>

            <LinkContainer to="/machines/create">
              <Button variant="secondary">+ Ajouter une machine</Button>
            </LinkContainer>
          </ButtonToolbar>
        </Card>

        <Loader loaded={!!this.props.currentMachines}>
          <MachineTable
            machineFilters={this.props.machineFilters}
            hideColumns={['contact', 'preparationStatus']}
          />
          {this.props.currentMachines && (
            <Pagination onSelect={this.goToPage}>
              {Array(this.getNbPage())
                .fill(null)
                .map((_, k) => (
                  <Pagination.Item
                    key={k}
                    active={k + 1 === this.props.currentPage}
                  >
                    {k + 1}
                  </Pagination.Item>
                ))}
            </Pagination>
          )}
        </Loader>
      </div>
    );
  }
}

GlobalMachine.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      page: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  currentPage: PropTypes.number.isRequired,
  machineFilters: ImmutablePropTypes.map.isRequired,
  currentMachines: PropTypes.instanceOf(PagedCollection),
  findMachines: PropTypes.func.isRequired,
  machineGoToPage: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    currentMachines: state.app.get('currentMachines'),
    currentPage: parseInt(ownProps.match.params.page, 10) || 1,
    machineFilters:
      state.app.getIn(['filters', 'machineTable']) ||
      Map({ status: 'to_plan' }),
  };
}

export const GlobalMachineContainer = connect(
  mapStateToProps,
  machineActions
)(GlobalMachine);
