import { fromJS } from 'immutable';
import history from '../history';
import materialResourceApi from '../api/materialResource';

function dispatchError(err, dispatch) {
  dispatch({
    type: 'ALERT',
    style: 'danger',
    message: JSON.parse(err.response.text)['hydra:description'],
    dismissAfter: 5000,
  });
}

function createMaterialResource(values, dispatch) {
  return new Promise((resolve, reject) => {
    materialResourceApi
      .create(values, { fields: '@id' })
      .then(() => {
        history.push('/material-resources/');
        resolve();
      })
      .catch((err) => {
        dispatchError(err, dispatch);
        reject();
      });
  });
}

function updateMaterialResource(values, dispatch) {
  return new Promise((resolve, reject) => {
    materialResourceApi
      .update(values['@id'], values, { fields: '@id' })
      .then(() => {
        history.push('/material-resources/');
        resolve();
      })
      .catch((err) => {
        dispatchError(err, dispatch);
        reject();
      });
  });
}

export function findAllMaterialResources() {
  return (dispatch) =>
    materialResourceApi
      .findBy({
        'order[team.name]': 'asc',
        'order[name]': 'asc',
        fields:
          '@id,backgroundColor,foregroundColor,description,name,symbol,type,team{@id,agency,name}',
      })
      .then((materialResourceList) => {
        dispatch({
          type: 'RECEIVE_ALL_MATERIAL_RESOURCE',
          materialResourceList: fromJS(materialResourceList),
        });
      });
}

export function createUpdateMaterialResource(values) {
  return (dispatch) => {
    if (values['@id']) {
      return updateMaterialResource(values, dispatch);
    }

    return createMaterialResource(values, dispatch);
  };
}
