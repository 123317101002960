import PagedCollection from './PagedCollection';

export default class TaskPagedCollection extends PagedCollection {
  getHavingUser() {
    return this.getMembers().filter(
      (task) => task.userList && task.userList.size > 0
    );
  }

  getUnassigned() {
    return this.getMembers().filter(
      (task) => !task.userList || task.userList.size === 0
    );
  }
}
