import PropTypes from 'prop-types';
import React from 'react';
import { Navbar as BsNavBar, Nav, Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import MainLink from '../navigation/MainLink';

class NavBar extends React.PureComponent {
  getProjectPath(append) {
    return `/projects/${this.props.projectId}/${append ? append : ''}`;
  }

  render() {
    return (
      <BsNavBar variant="dark" bg="success" className="mb-2">
        <Container>
          <LinkContainer to={this.getProjectPath()} exact>
            <BsNavBar.Brand>{this.props.name}</BsNavBar.Brand>
          </LinkContainer>

          <Nav className="mr-auto">
            <MainLink to={this.getProjectPath('machines/')}>Machines</MainLink>
            {this.props.isAdmin && (
              <MainLink to={this.getProjectPath('plan/')}>
                Planification
              </MainLink>
            )}
            <MainLink to={this.getProjectPath('stocks/')}>Stocks</MainLink>
            {this.props.isAdmin && (
              <MainLink to={this.getProjectPath('installation/')}>
                Configuration PV
              </MainLink>
            )}
          </Nav>
        </Container>
      </BsNavBar>
    );
  }
}

NavBar.propTypes = {
  projectId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export default NavBar;
