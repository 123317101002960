import config from '../config';
import AbstractClient from './abstractClient';

class TaskFileClient extends AbstractClient {
  getPathBase() {
    return `${config.api.version}/task_files`;
  }
}

export default new TaskFileClient();
