import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import machineApi from '../../../api/machine';
import machineInstallationFileApi from '../../../api/machineInstallationFile';
import { shortId } from '../../../idTools';
import config from '../../../config';
import { MdClear } from 'react-icons/md';

const DROPZONE_STYLES = {
  width: '100%',
  height: '200px',
  borderWidth: '2px',
  borderColor: 'rgb(102, 102, 102)',
  borderStyle: 'dashed',
  borderRadius: '5px',
};

function MachineImportInstallationFile({
  machineId,
  onChange,
  onUploadStart,
  onUploadEnd,
}) {
  const [
    machineInstallationFileList,
    setMachineInstallationFileList,
  ] = useState(null);

  const [uploadingFileList, setUploadingFileList] = useState([]);

  const fetchMachineInstallationFiles = useCallback(() => {
    machineInstallationFileApi
      .findBy({ machine: machineId, fields: '@id,uploadedName' })
      .then((res) => {
        setMachineInstallationFileList(res.getMembers());
      });
  }, [machineId]);

  useEffect(() => {
    onChange(machineInstallationFileList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machineInstallationFileList]);

  useEffect(() => {
    fetchMachineInstallationFiles();
  }, [fetchMachineInstallationFiles]);

  if (machineInstallationFileList === null) {
    return null;
  }

  function handleDrop(files) {
    onUploadStart();
    setUploadingFileList(files);

    const promiseList = files.map((file) =>
      machineApi.importInstallationFile(shortId(machineId), file)
    );

    Promise.all(promiseList)
      .then(() => {
        onUploadEnd();
        fetchMachineInstallationFiles();
        setUploadingFileList([]);
      })
      .catch(() => {
        onUploadEnd();
        alert("Oops ! Une erreur est survenue lors de l'import du fichier");
      });
  }

  return (
    <div>
      <h2>Pages de configuration</h2>

      {machineInstallationFileList.size > 0 ? (
        <ListGroup>
          {machineInstallationFileList.map((file) => (
            <ListGroupItem
              key={file.get('@id')}
              className="d-flex justify-content-between"
            >
              <a href={`${config.api.url}${file.get('@id')}/download`}>
                {file.get('uploadedName')}
              </a>

              <a
                href="#deleteFile"
                onClick={() => {
                  machineInstallationFileApi.del(file.get('@id'));
                  setMachineInstallationFileList((prev) =>
                    prev.filter((item) => item !== file)
                  );
                }}
              >
                <MdClear />
              </a>
            </ListGroupItem>
          ))}

          {uploadingFileList.map((file) => (
            <ListGroupItem disabled key={file.name}>
              {file.name}
            </ListGroupItem>
          ))}
        </ListGroup>
      ) : (
        <div className="text-muted">Aucun fichier attaché</div>
      )}

      <Dropzone accept="image/jpeg" onDrop={handleDrop} style={DROPZONE_STYLES}>
        <div>Scanner des pages de configuration de machines.</div>
      </Dropzone>
    </div>
  );
}

MachineImportInstallationFile.propTypes = {
  machineId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onUploadStart: PropTypes.func.isRequired,
  onUploadEnd: PropTypes.func.isRequired,
};

export default MachineImportInstallationFile;
