import { Map, List, Record, fromJS } from 'immutable';
import * as Sentry from '@sentry/browser';
import { FORM_RECEIVE_PROJECT_LIST } from './actions/form';

function setState(state, newState) {
  return state.merge(newState);
}

function receiveAllBrand(state, brandList) {
  return state.merge({ brandList });
}

function formReceiveAllProject(state, projectList) {
  return state
    .setIn(['form', 'select', 'projectList'], projectList)
    .setIn(['form', 'select', 'projectList-fetch-status'], 'SUCCEEDED');
}

function receiveAllProjectGroup(state, projectGroupList) {
  return state.merge({ projectGroupList });
}

function receiveProjectGroup(state, projectGroup) {
  return state.set('currentProjectGroup', projectGroup);
}

function deleteProjectGroup(state, projectGroup) {
  const projectGroupList = state.getIn(['projectGroupList', 'hydra:member']);
  const toDel = projectGroupList.findIndex(
    (tmp) => projectGroup.get('@id') === tmp.get('@id')
  );

  if (toDel > -1) {
    return state.setIn(
      ['projectGroupList', 'hydra:member'],
      projectGroupList.delete(toDel)
    );
  }

  return state;
}

// Users
function receiveAllUser(state, userList) {
  return state.set('userList', userList);
}

function receiveHiddenUsers(state, userList) {
  return state.set('hiddenUserList', userList);
}

function receiveCurrentUser(state, user) {
  return state.set('currentUser', user);
}

function loginAttempt(state) {
  return state.mergeIn(['login-fetch-status'], {
    status: 'IN_PROGRESS',
    error: null,
  });
}

function loginFailed(state) {
  const error = "Nom d'utilisateur ou mot de passe incorrect";

  return state.mergeIn(['login-fetch-status'], {
    status: 'ERROR',
    error,
  });
}

function loginSucceeded(state, me) {
  let newState = state.mergeIn(['login-fetch-status'], {
    status: 'SUCCEEDED',
    error: null,
  });

  return receiveMe(newState, me);
}

function receiveMe(state, me) {
  if (me) {
    Sentry.configureScope((scope) => {
      scope.setUser({
        email: me.email,
        id: me.get('@id'),
        username: me.fullname,
      });
    });
  }
  return state.set('me', me);
}

function logout(state) {
  Sentry.configureScope((scope) => {
    scope.setUser(null);
  });
  return state.delete('me');
}

function updateUser(state, user) {
  const userList = state.getIn(['userList', 'hydra:member']);
  if (!userList) {
    return state;
  }

  const toUpdate = userList.findIndex(
    (tmp) => user.get('@id') === tmp.get('@id')
  );

  if (toUpdate > -1) {
    return state.setIn(
      ['userList', 'hydra:member'],
      userList.set(toUpdate, user)
    );
  }

  return state;
}

function removeCurrentUser(state) {
  return state.remove('currentUser');
}

// Teams
function receiveAllTeam(state, teamList) {
  return state.set('teamList', teamList);
}

function receiveCurrentTeam(state, team) {
  return state.set('currentTeam', team);
}

function updateTeam(state, team) {
  const teamList = state.getIn(['teamList', 'hydra:member']);
  const toUpdate =
    teamList && teamList.findIndex((tmp) => team.get('@id') === tmp.get('@id'));

  if (toUpdate > -1) {
    return state.setIn(
      ['teamList', 'hydra:member'],
      teamList.set(toUpdate, team)
    );
  }

  return state;
}

function removeCurrentTeam(state) {
  return state.remove('currentTeam');
}

function deleteTeam(state, team) {
  const teamList = state.getIn(['teamList', 'hydra:member']);
  const toDel = teamList.findIndex((tmp) => team.get('@id') === tmp.get('@id'));

  if (toDel > -1) {
    return state.setIn(['teamList', 'hydra:member'], teamList.delete(toDel));
  }

  return state;
}

// Material Resource
function receiveAllMaterialResource(state, materialResourceList) {
  return state.set('materialResourceList', materialResourceList);
}

function deleteMachine(state, machine) {
  const projectList = state
    .getIn(['currentMachines', 'hydra:member'])
    .filterNot((m) => m.get('@id') === machine.get('@id'));

  return state.setIn(['currentMachines', 'hydra:member'], projectList);
}

function receiveCurrentProject(state, project) {
  return state.merge({ currentProject: project });
}

function removeCurrentProject(state) {
  return state.remove('currentProject');
}

function setCurrentSites(state, currentSites, currentProject) {
  const currentSitesWithMachineToPlan = currentSites.filter(
    (site) =>
      site &&
      ((currentProject &&
        site.getIn(['nbMachineToPlan', currentProject.get('@id')]) > 0) ||
        (!currentProject && site.get('nbMachineToPlan').size > 0))
  );

  const markers = currentSitesWithMachineToPlan
    .groupBy((site) => `${site.get('latitude')}|${site.get('longitude')}`)
    .map((siteList) => {
      const machines = state
        .getIn(['currentMachines', 'hydra:member'])
        .filter((machine) =>
          siteList.some(
            (site) => machine.getIn(['site', '@id']) === site.get('@id')
          )
        );

      const machineInfo = machines
        .map((machine) => machine.get('machineModel'))
        .countBy((key) => key)
        .reduce(
          (prev, nb, model) =>
            `${prev}<div>${model || 'non définit'}: ${nb} machines </div>`,
          ''
        );

      const projectInfo = machines
        .map((machine) => machine.getIn(['project', 'name']))
        .countBy((key) => key)
        .reduce(
          (prev, nb, projectName) =>
            `${prev}<div>${projectName}: ${nb} machines</div>`,
          ''
        );

      const groupedProjectName = machines
        .map((machine) => machine.getIn(['project', 'name']))
        .toSet()
        .reduce((prev, value, projectName) => `${prev} | ${projectName}`, '')
        .substr(3);

      const site = siteList.find(
        (tmpSite) =>
          tmpSite && tmpSite.get('latitude') && tmpSite.get('longitude')
      );

      if (site && site.get('latitude') && site.get('longitude')) {
        const description =
          site.get('formattedAddress') + '\n' + projectInfo + machineInfo;

        return fromJS({
          position: {
            lat: site.get('latitude'),
            lng: site.get('longitude'),
          },
          key: `${site.get('latitude')}|${site.get('longitude')}`,
          id: `${site.get('latitude')}|${site.get('longitude')}`,
          popup: {
            caption: site.get('siteId') || groupedProjectName,
            description,
          },
        });
      }

      return null;
    });

  // const groupedMarkers = markers
  //   .filter(marker => marker)
  //   .groupBy(
  //     marker =>
  //       `${marker.getIn(['position', 'lat'])}|${marker.getIn([
  //         'position',
  //         'lng',
  //       ])}`
  //   );

  return state
    .set('currentSites', currentSites)
    .set('markers', markers.toList())
    .set('currentSitesWithMachineToPlan', currentSitesWithMachineToPlan);
}

function initializeMap(state, regenerateCurrentSites = false) {
  let currentSites = state.get('currentSites');

  if (regenerateCurrentSites || !currentSites) {
    currentSites = state.getIn(['currentMachines', 'hydra:member'])
      ? state
          .getIn(['currentMachines', 'hydra:member'])
          .map((machine) => machine.get('site'))
          .toSet()
          .toList()
      : List();
  }

  const unpositionnedSites = currentSites.filter(
    (site) => !site || !site.get('latitude') || !site.get('longitude')
  );

  return setCurrentSites(state, currentSites, state.get('currentProject')).set(
    'unpositionnedSites',
    unpositionnedSites
  );
}

function receiveMachineList(state, machineList) {
  return initializeMap(state.merge({ currentMachines: machineList }), true);
}

function importMachineFiles(state, files) {
  const immutableFiles = fromJS(files);
  const entries = immutableFiles
    .toKeyedSeq()
    .mapEntries((kv) => [kv[1].preview, kv[1]]);

  return state.mergeIn(['uploadingFiles'], entries);
}

function receiveColumnsFromImport(state, fromFile, importedFile) {
  const newUploadingFiles = state
    .get('uploadingFiles')
    .delete(fromFile.preview);

  return state
    .set('importedFile', fromJS(importedFile))
    .set('uploadingFiles', newUploadingFiles)
    .set('currentMapping', fromJS(importedFile.mappingSuggestion) || Map());
}

function resetImport(state) {
  return state
    .delete('importedFile')
    .delete('uploadingFiles')
    .delete('showLoader');
}

function receiveAlert(state, message, style, dismissAfter, position) {
  return state
    .merge(
      fromJS({
        alert: { message, style, dismissAfter, position },
      })
    )
    .delete('showLoader');
}

function dismissAlert(state) {
  return state.remove('alert');
}

function importAddToMapping(state, mapping) {
  return state.mergeIn(['currentMapping'], fromJS(mapping));
}

function setImportedFile(state, file) {
  return state.set('importedFile', file).delete('showLoader');
}

function setLastImportedFile(state, file) {
  return state.set('lastImportedFile', file);
}

function removeLastImportedFile(state) {
  return state.delete('lastImportedFile');
}

function importingFile(state) {
  return state.set('showLoader', true);
}

function selectMachine(state, machine) {
  const selectedMachines = state.get('selectedMachines') || List();

  return state.set('selectedMachines', selectedMachines.push(machine));
}

function selectMachineList(state, machineList) {
  return state.set('selectedMachines', machineList);
}

function updateMachine(initialState, machine) {
  const state = initialState.get('currentMachine')
    ? initialState.set('currentMachine', machine)
    : initialState;

  if (!state.get('currentMachines')) {
    return state;
  }

  const currentMachines = state.getIn(['currentMachines', 'hydra:member']);
  const toUpdate = currentMachines.findIndex(
    (tmp) => machine.get('@id') === tmp.get('@id')
  );

  if (toUpdate > -1) {
    return state.setIn(
      ['currentMachines', 'hydra:member'],
      currentMachines.set(toUpdate, machine)
    );
  }

  return state;
}

function updateMachineInTask(state, machine) {
  if (!state.get('currentTask')) {
    return state;
  }

  const currentMachines = state.getIn(['currentTask', 'machineList']);
  const toUpdate = currentMachines.findIndex(
    (tmp) => machine.get('@id') === tmp.get('@id')
  );

  if (toUpdate > -1) {
    return state.setIn(
      ['currentTask', 'machineList'],
      currentMachines.set(toUpdate, machine)
    );
  }

  return state;
}

function receiveNewMachine(state) {
  return state.delete('currentMachines');
}

function removeCurrentProjectMap(state) {
  return state
    .delete('currentSites')
    .delete('markers')
    .delete('currentSitesWithMachineToPlan');
}

function handleBoundsChange(state, bounds) {
  return state.set('bounds', bounds);
}

function handleMarkerClick(state, marker) {
  return state.set('openedMarker', marker.get('id'));
}

function closeMarker(state) {
  return state.delete('openedMarker');
}

function siteGeolocated(state, site) {
  const currentSites = state.get('currentSites');
  const toUpdate = currentSites.findIndex(
    (tmp) => tmp && site.get('@id') === tmp.get('@id')
  );

  let newState = state;

  if (toUpdate > -1) {
    newState = setCurrentSites(
      newState,
      currentSites.set(toUpdate, site),
      state.get('currentProject')
    );
  }

  if (state.getIn(['currentEditingSite', '@id']) === site.get('@id')) {
    newState = newState.delete('currentEditingSite');
  }

  return initializeMap(newState);
}

function removeCurrentMachine(state) {
  return state.remove('currentMachine');
}

function receiveCurrentMachine(state, machine) {
  return state.set('currentMachine', machine);
}

function displayEditAddress(state, siteId) {
  const currentSites = state.get('currentSites');
  const toUpdate = currentSites.findIndex((tmp) => siteId === tmp.get('@id'));

  if (toUpdate > -1) {
    return state.set('currentEditingSite', currentSites.get(toUpdate));
  }

  return state;
}

function setMapLayout(state, layout) {
  return state.set('mapLayout', layout);
}

function selectMachineForSite(state, latLng) {
  const selectedMachines = state
    .getIn(['currentMachines', 'hydra:member'])
    .filter((machine) => {
      const tmpLatLng = `${machine.getIn([
        'site',
        'latitude',
      ])}|${machine.getIn(['site', 'longitude'])}`;

      return tmpLatLng === latLng && machine.get('status') === 'to_plan';
    });

  const newSelectedMachines =
    state.get('selectedMachines') && state.get('selectedMachines').size > 0
      ? state.get('selectedMachines').concat(selectedMachines).toSet()
      : selectedMachines;

  return state.set('selectedMachines', newSelectedMachines);
}

function removeMachineFromSelected(state, machine) {
  const selectedMachines = state.get('selectedMachines');

  return state.set(
    'selectedMachines',
    selectedMachines.filterNot((tmp) => machine.get('@id') === tmp.get('@id'))
  );
}

function removeMachinesFromCurrent(state, machines) {
  if (!state.getIn(['currentMachines', 'hydra:member'])) {
    return state;
  }

  const newMachines = state
    .getIn(['currentMachines', 'hydra:member'])
    .filterNot(
      (machine) =>
        machines.findIndex((tmp) => tmp.get('@id') === machine.get('@id')) >= 0
    );

  return initializeMap(
    state.setIn(['currentMachines', 'hydra:member'], newMachines),
    true
  );
}

function removeAllSelectedMachines(state) {
  return state.delete('selectedMachines');
}

function receiveCurrentTask(state, task) {
  return state.set('currentTask', task);
}

function removeCurrentTask(state) {
  return state.delete('currentTask');
}

function setTeamListFilter(state, teamList) {
  const newState = state.setIn(['savedFilters', 'teamList'], teamList);
  return newState;
}

function setProjectListFilter(state, projectList) {
  return state.setIn(['savedFilters', 'projectList'], projectList);
}

function setAllUserFilter(state, checked) {
  return state.setIn(['savedFilters', 'allUserFilter'], checked);
}

function clearMachineTableFilter(state) {
  return state.deleteIn(['filters', 'machineTable']);
}

function addMachineTableFilter(state, filter) {
  return state.setIn(
    ['filters', 'machineTable', filter.columnKey],
    filter.filterTerm
  );
}

function updateTask(initialState, task) {
  const state = initialState;

  // update task appointments
  const taskWithoutAppointment = state.get('taskWithoutAppointment');

  if (!taskWithoutAppointment) {
    return state;
  }

  const newTaskWithoutAppointment = taskWithoutAppointment
    .getMembers()
    .map((t) =>
      t.get('@id') === task.get('@id') ? t.set('status', task.status) : t
    )
    .filter((t) => t.status === 'needs_appointment');

  return state.setIn(
    ['taskWithoutAppointment', 'hydra:member'],
    newTaskWithoutAppointment
  );
}

function selectTask(state, task) {
  const selectedTaskList = state.get('selectedTaskList') || List();

  if (selectedTaskList.contains(task)) {
    return state.set(
      'selectedTaskList',
      selectedTaskList.filterNot((t) => t.get('@id') === task.get('@id'))
    );
  }

  return state.set('selectedTaskList', selectedTaskList.push(task));
}

function updatedMassTask(state) {
  return state.remove('selectedTaskList').set('isEditMode', false);
}

function taskDropFiles(state, files) {
  return state.set('taskFilesUploading', List(files));
}

function taskFileImported(state, file, fileList) {
  return state
    .set(
      'taskFilesUploading',
      state.get('taskFilesUploading').filter((tmpFile) => file !== tmpFile)
    )
    .set('taskFilesUploaded', fileList);
}

function updateProjectContact(state, projectContact) {
  const projectContactList = state.getIn([
    'currentProject',
    'projectContactList',
  ]);
  const toUpdate = projectContactList.findIndex(
    (tmp) => projectContact.get('@id') === tmp.get('@id')
  );

  if (toUpdate > -1) {
    return state.setIn(
      ['currentProject', 'projectContactList', toUpdate],
      projectContact
    );
  }

  return state;
}

const FetchStatus = Record({
  status: null,
  error: null,
});
const initialState = Map({
  'login-fetch-status': new FetchStatus(),
  bounds: {
    center: {
      lat: 46.9,
      lng: 1.8,
    },
    zoom: 5,
  },
  form: Map({
    select: Map({
      projectList: List(),
      'projectList-fetch-status': null,
    }),
  }),
});

const IGNORE_ACTION_TYPES = [
  '@@INIT',
  'REDUX_STORAGE_LOAD',
  'REDUX_STORAGE_SAVE',
];
const IGNORE_ACTION_NAMESPACE = ['@@redux', '@@router'];

export default function (state = initialState, action) {
  switch (action.type) {
    case 'SET_STATE':
      return setState(state, action.state);
    case 'RECEIVE_ALL_BRAND':
      return receiveAllBrand(state, action.brandList);
    case FORM_RECEIVE_PROJECT_LIST:
      return formReceiveAllProject(state, action.projectList);
    // project group
    case 'RECEIVE_ALL_PROJECT_GROUP':
      return receiveAllProjectGroup(state, action.projectGroupList);
    case 'DELETE_PROJECT_GROUP':
      return deleteProjectGroup(state, action.projectGroup);
    case 'RECEIVE_CURRENT_PROJECT_GROUP':
      return receiveProjectGroup(state, action.projectGroup);

    case 'DELETE_MACHINE':
      return deleteMachine(state, action.machine);
    case 'RECEIVE_MACHINE_LIST':
      return receiveMachineList(state, action.machineList);
    case 'REMOVE_CURRENT_MACHINE_LIST':
      return state.remove('currentMachines');
    case 'REMOVE_CURRENT_PROJECT_MAP':
      return removeCurrentProjectMap(state);
    case 'RECEIVE_CURRENT_PROJECT':
      return receiveCurrentProject(state, action.project);
    case 'REMOVE_CURRENT_PROJECT':
      return removeCurrentProject(state);
    case 'MACHINES_DROP_FILES':
      return importMachineFiles(state, action.files);
    case 'MACHINES_IMPORTED':
      return receiveColumnsFromImport(state, action.file, action.importedFile);
    case 'RESET_IMPORT':
      return resetImport(state);
    case 'ALERT':
      return receiveAlert(
        state,
        action.message,
        action.style,
        action.dismissAfter,
        action.position
      );
    case 'DISMISS_ALERT':
      return dismissAlert(state);
    case 'MACHINE_IMPORT_ADD_TO_MAPPING':
      return importAddToMapping(state, action.mapping);
    case 'SET_IMPORTED_FILE':
      return setImportedFile(state, action.file);
    case 'SET_LAST_IMPORTED_FILE':
      return setLastImportedFile(state, action.file);
    case 'REMOVE_LAST_IMPORTED_FILE':
      return removeLastImportedFile(state);
    case 'IMPORTING_FILE':
      return importingFile(state);
    case 'SELECT_MACHINE':
      return selectMachine(state, action.machine);
    case 'SELECT_MACHINE_LIST':
      return selectMachineList(state, action.machineList);
    case 'UPDATE_MACHINE': {
      const nextState = updateMachine(state, action.machine);
      return updateMachineInTask(nextState, action.machine);
    }
    case 'RECEIVE_NEW_MACHINE':
      return receiveNewMachine(state, action.machine);

    case 'HANDLE_MARKER_CLICK':
      return handleMarkerClick(state, action.marker);
    case 'HANDLE_BOUNDS_CHANGE':
      return handleBoundsChange(state, action.bounds);
    case 'CLOSE_MARKER':
      return closeMarker(state);
    case 'SITE_GEOLOCATED':
      return siteGeolocated(state, action.site);
    case 'REMOVE_CURRENT_MACHINE':
      return removeCurrentMachine(state);
    case 'RECEIVE_CURRENT_MACHINE':
      return receiveCurrentMachine(state, action.machine);

    // login
    case 'LOGIN_ATTEMPT':
      return loginAttempt(state);
    case 'LOGIN_SUCCEEDED':
      return loginSucceeded(state, action.me);
    case 'LOGIN_FAILED':
      return loginFailed(state);
    // users
    case 'RECEIVE_ME':
      return receiveMe(state, action.me);
    case 'LOGOUT':
      return logout(state);
    case 'RECEIVE_ALL_USER':
      return receiveAllUser(state, action.userList);
    case 'RECEIVE_HIDDEN_USERS':
      return receiveHiddenUsers(state, action.userList);
    case 'UPDATE_USER':
      return updateUser(state, action.user);
    case 'RECEIVE_CURRENT_USER':
      return receiveCurrentUser(state, action.user);
    case 'REMOVE_CURRENT_USER':
      return removeCurrentUser(state);

    // teams
    case 'RECEIVE_ALL_TEAM':
      return receiveAllTeam(state, action.teamList);
    case 'UPDATE_TEAM':
      return updateTeam(state, action.team);
    case 'RECEIVE_CURRENT_TEAM':
      return receiveCurrentTeam(state, action.team);
    case 'REMOVE_CURRENT_TEAM':
      return removeCurrentTeam(state);
    case 'DELETE_TEAM':
      return deleteTeam(state, action.team);

    // edit address
    case 'DISPLAY_EDIT_ADDRESS':
      return displayEditAddress(state, action.siteId);

    // teams
    case 'RECEIVE_ALL_MATERIAL_RESOURCE':
      return receiveAllMaterialResource(state, action.materialResourceList);

    case 'SET_MAP_LAYOUT':
      return setMapLayout(state, action.layout);

    // plan
    case 'SELECT_MACHINE_FOR_SITE':
      return selectMachineForSite(state, action.latLng);
    case 'REMOVE_MACHINE_FROM_SELECTED':
      return removeMachineFromSelected(state, action.machine);
    case 'REMOVE_ALL_SELECTED_MACHINES':
      return removeAllSelectedMachines(state);
    case 'REMOVE_MACHINES_FROM_CURRENT':
      return removeMachinesFromCurrent(state, action.machines);

    // tasks
    case 'RECEIVE_CURRENT_TASK':
      return receiveCurrentTask(state, action.task);
    case 'REMOVE_CURRENT_TASK':
      return removeCurrentTask(state);
    case 'REQUEST_TASKS_WITHOUT_APPOINTMENT':
      return state.set('taskWithoutAppointment-fetch-status', 'IN_PROGRESS');
    case 'RECEIVE_TASKS_WITHOUT_APPOINTMENT':
      return state.merge({
        taskWithoutAppointment: action.taskList,
        'taskWithoutAppointment-fetch-status': 'SUCCEEDED',
      });
    case 'UPDATE_TASK':
      return updateTask(state, action.task);
    case 'SELECT_TASK':
      return selectTask(state, action.task);
    case 'UPDATED_MASS_TASK':
      return updatedMassTask(state);
    case 'TASK_DROP_FILES':
      return taskDropFiles(state, action.files);
    case 'TASK_FILE_IMPORTED':
      return taskFileImported(state, action.file, action.fileList);

    // filters
    case 'SET_TEAM_LIST_FILTER':
      return setTeamListFilter(state, action.teamList);

    case 'SET_PROJECT_LIST_FILTER':
      return setProjectListFilter(state, action.projectList);

    case 'SET_ALL_USER_FILTER':
      return setAllUserFilter(state, action.checked);

    case 'CLEAR_MACHINE_TABLE_FILTER':
      return clearMachineTableFilter(state);

    case 'ADD_MACHINE_TABLE_FILTER':
      return addMachineTableFilter(state, action.filter);

    case 'SET_EDIT_MODE':
      return state.set('isEditMode', action.editMode);

    case 'RECEIVE_CURRENT_PROJECT_CONTACT':
      return state.set('currentProjectContact', action.projectContact);

    case 'UPDATE_PROJECT_CONTACT':
      return updateProjectContact(state, action.projectContact);

    case 'REMOVE_CURRENT_PROJECT_CONTACT':
      return state.delete('currentProjectContact');

    default: {
      const ignoreActionType = IGNORE_ACTION_TYPES.includes(action.type);

      const splittedType = action.type.split('/');
      const actionNamespace = splittedType.length > 1 ? splittedType[0] : null;
      const ignoreActionTypeNamespace =
        actionNamespace !== null &&
        IGNORE_ACTION_NAMESPACE.includes(actionNamespace);

      if (!ignoreActionType && !ignoreActionTypeNamespace) {
        // eslint-disable-next-line no-console
        console.error(`Main reducer does not respond to type ${action.type}`);
      }
      return state;
    }
  }
}
