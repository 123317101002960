import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { shortId } from '../../idTools';
import MachineLogList from './MachineLogList';

export default function MachineLogListModal({ machineId, onHide }) {
  return (
    <Modal show onHide={onHide}>
      <Modal.Header>Historique de la machine {shortId(machineId)}</Modal.Header>

      <Modal.Body>
        <MachineLogList machineId={machineId} />
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Fermer
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

MachineLogListModal.propTypes = {
  machineId: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
};
