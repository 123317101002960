import config from '../config';
import AbstractClient from './abstractClient';

class StockDetailClient extends AbstractClient {
  getPathBase() {
    return `${config.api.version}/stock_details`;
  }

  findByProject(project, params) {
    if (params.page === 1) {
      delete params.page;
    }

    return this.findBy(Object.assign({ project: project.get('@id') }, params));
  }
}

export default new StockDetailClient();
