import createDecorator from 'final-form-calculate';

export default createDecorator(
  {
    field: 'startDate',
    updates: {
      // ...update endDate if it was before
      endDate: (startDate, allValues) => {
        if (startDate > allValues.endDate || !allValues.endDate) {
          const endDate = new Date(startDate);
          endDate.setHours(23, 59, 59);

          return endDate;
        }

        return allValues.endDate;
      },
    },
  },
  {
    field: 'endDate',
    updates: {
      // ...update startDate if it was higher
      startDate: (endDate, allValues) => {
        if (endDate < allValues.startDate) {
          const startDate = new Date(endDate);
          startDate.setHours(0, 0, 0);

          return startDate;
        }
        return allValues.startDate;
      },
    },
  }
);
