import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'react-bootstrap';
import Task from '../../entity/Task';
import { MdCheck, MdClear } from 'react-icons/md';

function trans(status) {
  switch (status) {
    case 'to_prepare':
      return 'A préparer';
    case 'ready':
      return 'Prête';
    case 'on_site':
      return 'Sur site';
    case 'not_applicable':
      return 'Non applicable';
    case 'waiting_info':
      return "En attente d'info";
    default:
      // eslint-disable-next-line no-console
      console.Warning(
        `Impossible de trouver la traduction pou le status "${status}"`
      );
      return status;
  }
}

function TaskMachinePreparationStatus({ task, asTitle }) {
  if (task.areMachinesPrepared !== true && task.areMachinesPrepared !== false) {
    if (asTitle) {
      return null;
    } else {
      return 'Non concerné';
    }
  }
  const status = trans(task.machinePreparationStatus);
  const labelSyle = task.areMachinesPrepared ? 'success' : 'warning';

  return (
    <Fragment>
      <Badge variant={labelSyle} title={asTitle ? status : ''}>
        {task.areMachinesPrepared ? <MdCheck /> : <MdClear />}
      </Badge>
      {!asTitle && ` ${status}`}
    </Fragment>
  );
}

TaskMachinePreparationStatus.defaultProps = {
  asTitle: false,
};

TaskMachinePreparationStatus.propTypes = {
  task: PropTypes.instanceOf(Task).isRequired,
  asTitle: PropTypes.bool,
};

export { trans as transMachinePreparationStatus };
export default TaskMachinePreparationStatus;
