import { useState, useEffect } from 'react';
import projectApi from '../../../api/project';
import { List } from 'immutable';

/**
 * fetch customInstallationFields for a given project
 *
 * @param {string}  projectId
 * @return {[List, boolean]}
 */
export function useCustomInstallationFields(projectId) {
  const [customInstallationFields, setCustomInstallationFields] = useState(
    null
  );
  const [
    customInstallationFieldsLoaded,
    setCustomInstallationFieldsLoaded,
  ] = useState(false);

  useEffect(() => {
    projectApi
      .find(projectId, {
        fields: '@id,customInstallationFields',
      })
      .then((project) => {
        setCustomInstallationFields(project.customInstallationFields || List());
        setCustomInstallationFieldsLoaded(true);
      });
  }, [projectId]);

  return [customInstallationFields, customInstallationFieldsLoaded];
}
