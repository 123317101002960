import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Modal, Button, ButtonToolbar } from 'react-bootstrap';
import { getText, getGroupName } from './Column';
import { MdClear } from 'react-icons/md';

function InnerMappingModal({ title, choices, onHide, onChange, ...props }) {
  return (
    <Modal onHide={onHide} {...props}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {choices &&
          choices
            .filter((choice) => 'hidden' !== choice.get('type'))
            .groupBy((choice) => choice.get('subgroup'))
            .map((choicesInGroup, subgroup) => (
              <Fragment key={subgroup}>
                <h4>{subgroup}</h4>
                <ButtonToolbar>
                  {choicesInGroup
                    .map((choice) => (
                      <Button
                        key={choice.get('value')}
                        disabled={choice.get('disabled')}
                        onClick={() => onChange(choice.get('value'))}
                      >
                        {choice.get('text')}
                      </Button>
                    ))
                    .toArray()}
                </ButtonToolbar>
              </Fragment>
            ))
            .toArray()}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Fermer
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

InnerMappingModal.defaultProps = {
  title: null,
};
InnerMappingModal.propTypes = {
  title: PropTypes.string,
  choices: ImmutablePropTypes.list.isRequired,
  onHide: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

class MappingModal extends PureComponent {
  constructor(props) {
    super(props);

    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleChangeAndClose = this.handleChangeAndClose.bind(this);

    this.state = {
      openedGroupKey: null,
    };
  }

  handleCloseModal() {
    this.setState({ openedGroupKey: null });
  }

  handleChangeAndClose(value) {
    this.handleCloseModal();
    this.props.onChange(value);
  }

  render() {
    const { choices, groupName, onHide, ...rest } = this.props;

    return (
      <Modal onHide={onHide} {...rest}>
        <Modal.Header closeButton>
          <Modal.Title>Associer à une colonne</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <ButtonToolbar>
            {choices
              .get('group')
              .map((innerChoices, key) => {
                return (
                  <Fragment key={key}>
                    <Button
                      variant={key === groupName ? 'primary' : 'secondary'}
                      onClick={() => {
                        this.setState({ openedGroupKey: key });
                      }}
                    >
                      {key}
                    </Button>

                    <InnerMappingModal
                      show={this.state.openedGroupKey === key}
                      title={this.state.openedGroupKey}
                      choices={innerChoices}
                      onHide={this.handleCloseModal}
                      onChange={this.handleChangeAndClose}
                    />
                  </Fragment>
                );
              })
              .toArray()}
          </ButtonToolbar>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
MappingModal.propTypes = {
  choices: ImmutablePropTypes.map,
  groupName: PropTypes.string,
  onHide: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export class ImportSelect extends PureComponent {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.clearValue = this.clearValue.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);

    this.state = {
      value: this.props.value,
      modalOpened: false,
    };
  }

  handleChange(value) {
    const mapping = {
      [this.props.itemID]: value,
    };
    this.setState({
      value,
      modalOpened: false,
    });
    this.props.handleMappingChange(mapping);
  }

  clearValue() {
    this.setState({
      value: '',
    });
    this.props.handleMappingChange({
      [this.props.itemID]: '',
    });
  }

  handleCloseModal() {
    this.setState({ modalOpened: false });
  }

  handleOpenModal() {
    this.setState({ modalOpened: true });
  }

  render() {
    const groupName = this.state.value && getGroupName(this.state.value);

    return (
      <Fragment>
        <Button variant="secondary" onClick={this.handleOpenModal}>
          {this.state.value ? (
            <Fragment>
              {groupName && `${groupName} > `}
              {getText(this.state.value)}
            </Fragment>
          ) : (
            'Ajouter'
          )}
        </Button>{' '}
        {this.state.value && (
          <a
            href="#removeMapping"
            onClick={() => {
              this.handleChange(null);
            }}
          >
            <MdClear />
          </a>
        )}
        <MappingModal
          groupName={groupName}
          show={this.state.modalOpened}
          onHide={this.handleCloseModal}
          choices={this.props.choices}
          onChange={this.handleChange}
        />
      </Fragment>
    );
  }
}

ImportSelect.propTypes = {
  value: PropTypes.string,
  itemID: PropTypes.string.isRequired,
  choices: ImmutablePropTypes.map,
  handleMappingChange: PropTypes.func.isRequired,
};
