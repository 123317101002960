import { useEffect, useState } from 'react';
import machineApi from '../../api/machine';
import projectApi from '../../api/project';

function useFetch(api, id, fields) {
  const [entity, setEntity] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    api
      .find(id, { fields })
      .then(setEntity)
      .then(() => setIsLoading(false));
  }, [api, id, fields]);

  return [entity, isLoading, setEntity];
}

export function useMachineFetch(id, fields) {
  return useFetch(machineApi, id, fields);
}

export function useProjectFetch(id, fields) {
  return useFetch(projectApi, id, fields);
}
