import React from 'react';

class Required extends React.Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return <span style={{ color: '#a94442' }}>*</span>;
  }
}

export default Required;
