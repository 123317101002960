import config from '../config';
import AbstractClient from './abstractClient';

class LogClient extends AbstractClient {
  getPathBase() {
    return `${config.api.version}/logs`;
  }
}

export default new LogClient();
