import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { is, List, Map } from 'immutable';
import { connect } from 'react-redux';
import { Alert, Button, ButtonGroup, Card, Pagination } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import MachineTable from '../machine/MachineTable';
import { entityShortId } from '../../idTools';
import RouterPropTypes from '../../prop-types/router';
import * as projectActions from '../../actions/project';
import {
  findMachinesForProject,
  handleMachineStatusChange,
  addMachineTableFilter,
} from '../../actions/machine';
import { currentUserIsAdminSelector } from '../../selector';
import PagedCollection from '../../entity/PagedCollection';
import Project from '../../entity/Project';

export class Machine extends React.PureComponent {
  constructor(props) {
    super(props);

    this.getCurrentMachines = this.getCurrentMachines.bind(this);
    this.fetchMachines = this.fetchMachines.bind(this);
    this.deleteImportedFile = this.deleteImportedFile.bind(this);
    this.getNbPage = this.getNbPage.bind(this);
    this.goToPage = this.goToPage.bind(this);
    this.exportProjectAsXls = this.exportProjectAsXls.bind(this);
  }

  componentDidMount() {
    this.fetchMachines();
  }

  componentDidUpdate(prevProps) {
    const oldProject = prevProps.match.params.projectId;
    const newProject = this.props.match.params.projectId;
    const oldPage = prevProps.match.params.page;
    const newPage = this.props.match.params.page;

    if (
      newProject &&
      (!this.props.currentMachines ||
        oldProject !== newProject ||
        oldPage !== newPage)
    ) {
      this.fetchMachines();
    }

    if (!is(prevProps.machineFilters, this.props.machineFilters)) {
      this.fetchMachines();
    }
  }

  getCurrentMachines() {
    return this.props.currentMachines || List();
  }

  getNbPage() {
    return this.props.currentMachines.getNbPages();
  }

  deleteImportedFile() {
    this.props.deleteImportedFile(
      this.props.lastImportedFile.get('@id'),
      this.props.currentProject
    );
  }

  fetchMachines() {
    if (this.props.currentProject) {
      this.props.findMachinesForProject(
        this.props.currentProject,
        Object.assign(
          { page: this.props.currentPage },
          this.props.machineFilters.toJS()
        )
      );
    }
  }

  goToPage(eventKey) {
    this.props.projectMachineGoToPage(
      entityShortId(this.props.currentProject),
      eventKey
    );
  }

  exportProjectAsXls() {
    const { currentProject } = this.props;

    this.props.exportProjectAsXls(
      entityShortId(currentProject),
      currentProject.get('name')
    );
  }

  render() {
    if (!this.props.currentProject) {
      return null;
    }

    return (
      <div>
        {this.props.lastImportedFile && (
          <Alert variant="info">
            {'Le fichier à bien été importé. '}
            {!this.props.lastImportedFile.get('isBrokeImport') && (
              <a
                href="#cancelImport"
                className="alert-link"
                onClick={() => this.deleteImportedFile()}
              >
                Annuler
              </a>
            )}
          </Alert>
        )}

        {this.props.currentUserIsSet && (
          <Card body>
            <ButtonGroup>
              {this.props.isAdmin && (
                <LinkContainer
                  to={`/projects/${entityShortId(
                    this.props.currentProject
                  )}/machines/import`}
                >
                  <Button variant="secondary">Importer un fichier XLS</Button>
                </LinkContainer>
              )}
              <LinkContainer to="#export" onClick={this.exportProjectAsXls}>
                <Button variant="secondary">Exporter les machines (XLS)</Button>
              </LinkContainer>
              {this.props.isAdmin && (
                <Fragment>
                  <LinkContainer
                    to={`/machines/create?project=${this.props.currentProject.get(
                      '@id'
                    )}`}
                  >
                    <Button variant="secondary">+ Ajouter une machine</Button>
                  </LinkContainer>
                  <LinkContainer
                    to={`/tasks/create?project=${this.props.currentProject.get(
                      '@id'
                    )}`}
                  >
                    <Button variant="primary">Créer une tâche</Button>
                  </LinkContainer>
                </Fragment>
              )}
              {/*
                  <Button variant='warning'>Noter comme terminé</Button>
                */}
            </ButtonGroup>
          </Card>
        )}

        <MachineTable
          hideColumns={['project', 'preparationStatus']}
          machineFilters={this.props.machineFilters}
        />

        {this.props.currentMachines && (
          <Pagination onSelect={this.goToPage}>
            {Array(this.getNbPage())
              .fill(null)
              .map((_, k) => (
                <Pagination.Item
                  key={k}
                  active={k + 1 === this.props.currentPage}
                >
                  {k + 1}
                </Pagination.Item>
              ))}
          </Pagination>
        )}
      </div>
    );
  }
}

Machine.propTypes = {
  currentMachines: null,
  lastImportedFile: null,
};
Machine.propTypes = {
  currentUserIsSet: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  currentMachines: PropTypes.instanceOf(PagedCollection),
  currentProject: PropTypes.instanceOf(Project),
  match: RouterPropTypes.params({
    page: PropTypes.string.isRequired,
    projectId: PropTypes.string.isRequired,
  }).isRequired,
  machineFilters: ImmutablePropTypes.map.isRequired,
  deleteImportedFile: PropTypes.func.isRequired,
  lastImportedFile: ImmutablePropTypes.map,
  findMachinesForProject: PropTypes.func.isRequired,
  projectMachineGoToPage: PropTypes.func.isRequired,
  exportProjectAsXls: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    currentProject: state.app.get('currentProject'),
    currentMachines: state.app.get('currentMachines'),
    currentPage: parseInt(ownProps.match.params.page, 10) || 1,
    lastImportedFile: state.app.get('lastImportedFile'),
    machineFilters:
      state.app.getIn(['filters', 'machineTable']) ||
      Map({ status: 'to_plan' }),
    currentUserIsSet: !!state.app.get('me'),
    isAdmin: currentUserIsAdminSelector(state),
  };
}

export const MachineContainer = connect(
  mapStateToProps,
  Object.assign({}, projectActions, {
    findMachinesForProject,
    addMachineTableFilter,
    handleMachineStatusChange,
  })
)(Machine);
