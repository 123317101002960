import PropTypes from 'prop-types';
import React from 'react';
import { Alert, FormLabel, FormGroup } from 'react-bootstrap';
import Required from './Required';

class ReduxFormGroup extends React.PureComponent {
  render() {
    const { meta, label, children, isRequired } = this.props;

    return (
      <FormGroup>
        {label && (
          <FormLabel>
            {label} {isRequired && <Required />}
          </FormLabel>
        )}

        {children}

        {meta.touched && meta.error && (
          <Alert variant="danger">{meta.error}</Alert>
        )}
      </FormGroup>
    );
  }
}

ReduxFormGroup.defaultProps = {
  label: null,
  isRequired: false,
  field: null,
  meta: null,
};

ReduxFormGroup.propTypes = {
  field: PropTypes.object,
  meta: PropTypes.object,
  children: PropTypes.node.isRequired,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
};

export default ReduxFormGroup;
