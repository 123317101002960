import superagent from 'superagent';
import config from '../config';
import { createEntity } from '../entity/factory';
import { shortId } from '../idTools';
import AuthIntercept from './AuthIntercept';
import LogMissingFields from './LogMissingFields';

class AbstractClient {
  getPathBase() {
    // eslint-disable-next-line no-console
    console.error(
      'AbstractClient can not be called directly. ' +
        'You must implement `getPathBase` method.'
    );
  }

  fetchUrl(url, queryParams = {}) {
    return this._getAccessToken().then(
      (accessToken) =>
        new Promise((resolve, reject) => {
          superagent
            .get(`${config.api.url}${url}`)
            .query(queryParams)
            .use(LogMissingFields)
            .use(AuthIntercept)
            .set('Authorization', accessToken && `Bearer ${accessToken}`)
            .accept('json')
            .end((err, res) => {
              if (err) {
                reject(err);
              } else {
                resolve(createEntity(JSON.parse(res.text)));
              }
            });
        })
    );
  }

  find(id, queryParams = {}) {
    return this.fetchUrl(`${this.getPathBase()}/${shortId(id)}`, queryParams);
  }

  findAll(queryParams = {}) {
    return this.fetchUrl(`${this.getPathBase()}`, queryParams);
  }

  findBy(params) {
    return this.fetchUrl(`${this.getPathBase()}`, params);
  }

  create(values, queryParams = {}) {
    if (!queryParams.fields) {
      // eslint-disable-next-line no-console
      console.warn(
        'Not passing `fields` query parameter for update query is deprecated.'
      );
    }

    return this._getAccessToken().then(
      (accessToken) =>
        new Promise((resolve, reject) => {
          superagent
            .post(`${config.api.url}${this.getPathBase()}`)
            .set('Authorization', `Bearer ${accessToken}`)
            .query(queryParams)
            .use(LogMissingFields)
            .use(AuthIntercept)
            .accept('json')
            .send(values)
            .end((err, res) => {
              if (err) {
                reject(err);
              } else {
                resolve(createEntity(JSON.parse(res.text)));
              }
            });
        })
    );
  }

  update(id, values, queryParams = {}) {
    if (!queryParams.fields) {
      // eslint-disable-next-line no-console
      console.warn(
        'Not passing `fields` query parameter for update query is deprecated.'
      );
    }

    return this._getAccessToken().then(
      (accessToken) =>
        new Promise((resolve, reject) => {
          superagent
            .put(`${config.api.url}${id}`)
            .set('Authorization', `Bearer ${accessToken}`)
            .query(queryParams)
            .use(LogMissingFields)
            .use(AuthIntercept)
            .accept('json')
            .send(values)
            .end((err, res) => {
              if (err) {
                reject(err);
              } else {
                resolve(createEntity(JSON.parse(res.text)));
              }
            });
        })
    );
  }

  del(id) {
    return this._getAccessToken().then(
      (accessToken) =>
        new Promise((resolve, reject) => {
          superagent
            .del(`${config.api.url}${id}`)
            .set('Authorization', `Bearer ${accessToken}`)
            .use(LogMissingFields)
            .use(AuthIntercept)
            .end((err) => {
              if (err) {
                reject(err);
              } else {
                resolve();
              }
            });
        })
    );
  }

  _getAccessToken() {
    return window.oauthClient.getAccessToken();
  }
}

export default AbstractClient;
