import { dispatchError } from '../actions/error';

const apiError = (store) => (next) => (action) => {
  // Call the next dispatch method in the middleware chain.
  if (action) {
    const isPromise =
      typeof action === 'object' && typeof action.then === 'function';

    // Check if it's a promise
    if (!isPromise) {
      return next(action);
    }

    action
      .catch((err) => {
        if (err.status === 403) {
          next(dispatchError(err, store.dispatch));
        }
      })
      .then((resolution) => {
        if (resolution) {
          next(store.dispatch(resolution));
        }
      });

    // This will likely be the action itself, unless
    // a middleware further in chain changed it.
    // return returnValue;
  }
};

export default apiError;
