export const transType = (type) => {
  switch (type) {
    case 'phone_call':
      return 'Appel téléphonique';
    case 'task_created':
      return 'Création de la tache';
    case 'task_deleted':
      return 'Suppression de la tache';
    case 'email':
      return 'Courriel';
    case 'task_start_date_changed':
      return 'Date de la tache changée';
    case 'task_status_changed':
      return 'Status de la tache changé';
    case 'machine_transfer_status_changed':
      return 'Statut du transfert de machine changé';
    case 'machine_preparation_status_changed':
      return 'Statut de la préparation de machine changé';
    case 'import_file_create_machine':
      return 'Import de la machine par fichier';
    case 'import_file_update_machine':
      return 'Modification de la machine par fichier';
    case 'machine_status_changed':
      return 'Statut de la machiné changé';

    default:
      // eslint-disable-next-line no-console
      console.error(`type ${type} not translated`);
      return type;
  }
};
