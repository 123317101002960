export function shortId(id) {
  const index = id.toString().lastIndexOf('/');

  if (index > 0) {
    return id.substr(index + 1);
  }

  return id;
}

export function entityShortId(entity) {
  return shortId(entityId(entity));
}

export function entityId(entity) {
  if (typeof entity === 'string') {
    return entity;
  }

  if (typeof entity === 'object' && typeof entity.get === 'function') {
    return entity.get('@id');
  }

  throw new Error(
    "Unable to call `enttity.get('@id')` on non-string nor immutable object"
  );
}
