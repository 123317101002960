import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { Button, ButtonGroup, Table } from 'react-bootstrap';
import * as planActions from '../../actions/plan';
import { entityShortId } from '../../idTools';
import { currentUserIsAdminSelector } from '../../selector';
import Machine from '../../entity/Machine';
import Project from '../../entity/Project';
import MachineInstallDates from '../machine/MachineInstallDates';
import MachineLogListModal from '../log/MachineLogListModal';
import { MdClear, MdFormatListBulleted } from 'react-icons/md';

class PlanMachineTable extends React.Component {
  constructor(props) {
    super(props);

    this.handleHideMachineLogListModal = this.handleHideMachineLogListModal.bind(
      this
    );

    this.state = {
      machineLogListModalId: null,
    };
  }

  componentDidMount() {
    return this.props.removeAllSelectedMachines();
  }

  handleHideMachineLogListModal() {
    this.setState({ machineLogListModalId: null });
  }

  render() {
    const { machineLogListModalId } = this.state;

    return (
      <div>
        {this.props.selectedMachines && (
          <div>
            {this.props.isAdmin && (
              <LinkContainer to={{ pathname: '/tasks/create' }}>
                <Button variant="secondary">
                  Créer une tâche avec les machines suivantes :
                </Button>
              </LinkContainer>
            )}

            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  {!this.props.currentProject && <td>Projet</td>}
                  <td>Adresse</td>
                  <td>Machine</td>
                  <td>Reprise</td>
                  <td>Commentaires</td>
                  <td>Installation requise</td>
                  <td />
                </tr>
              </thead>
              <tbody>
                {this.props.selectedMachines.map((machine) => (
                  <tr key={machine.get('@id')}>
                    {!this.props.currentProject && (
                      <td>{machine.getIn(['project', 'name'])}</td>
                    )}
                    <td>{machine.getIn(['site', 'formattedAddress'])}</td>
                    <td>
                      <div>{machine.get('machineSerialNumber')}</div>
                      <div>
                        {machine.get('machineModel')}
                        {machine.get('machineModel') &&
                          machine.get('machinePN') &&
                          ' - '}
                        {machine.get('machinePN')}
                      </div>
                    </td>
                    <td>
                      <div>
                        {machine.get('takebackType') &&
                          `Type: ${machine.get('takebackType')}`}
                      </div>
                      <div>
                        {machine.get('takebackSN') &&
                          `SN: ${machine.get('takebackSN')}`}
                      </div>
                    </td>
                    <td>{machine.get('comment')}</td>
                    <td>
                      <MachineInstallDates machine={machine} />
                    </td>
                    <td>
                      <ButtonGroup>
                        <Link
                          className="btn btn-secondary"
                          to={`/machines/${entityShortId(machine)}`}
                          onClick={(event) => {
                            event.preventDefault();
                            this.setState({
                              machineLogListModalId: machine.get('@id'),
                            });
                          }}
                        >
                          <MdFormatListBulleted /> Logs
                        </Link>
                        <a
                          className="btn btn-danger"
                          href={`#removeMachine-${entityShortId(machine)}`}
                          onClick={() =>
                            this.props.removeMachineFromSelected(machine)
                          }
                        >
                          <MdClear /> Supprimer
                        </a>
                      </ButtonGroup>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}

        {machineLogListModalId && (
          <MachineLogListModal
            machineId={machineLogListModalId}
            onHide={this.handleHideMachineLogListModal}
          />
        )}
      </div>
    );
  }
}

PlanMachineTable.propTypes = {
  removeAllSelectedMachines: PropTypes.func.isRequired,
  removeMachineFromSelected: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  selectedMachines: ImmutablePropTypes.listOf(PropTypes.instanceOf(Machine)),
  currentProject: PropTypes.instanceOf(Project),
};

// export default Plan;

function mapStateToProps(state) {
  return {
    selectedMachines: state.app.get('selectedMachines'),
    currentProject: state.app.get('currentProject'),
    isAdmin: currentUserIsAdminSelector(state),
  };
}

export default connect(mapStateToProps, planActions)(PlanMachineTable);
