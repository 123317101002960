import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { List, Map } from 'immutable';
import { connect } from 'react-redux';
import { Badge } from 'react-bootstrap';
import { MdLocalShipping, MdPower } from 'react-icons/md';
import * as materialResourceAction from '../../actions/materialResource';
import './planningLegend.css';
import PagedCollection from '../../entity/PagedCollection';

class PlanningLegend extends React.PureComponent {
  componentDidMount() {
    this.props.findAllMaterialResources();
  }

  render() {
    if (!this.props.materialResourceList) {
      return <div />;
    }

    const groupedMatRes = this.props.materialResourceList
      .getMembers()
      .filter(
        (mr) =>
          this.props.teamFilter.size <= 0 ||
          this.props.teamFilter.indexOf(mr.getIn(['team', '@id'])) >= 0
      )
      .groupBy((mr) => Map({ team: mr.get('team'), type: mr.get('type') }));

    return (
      <div>
        {groupedMatRes.entrySeq().map((item) => {
          const group = item[0];
          const site = group.get('team');
          const type = group.get('type');
          const materialResourceList = item[1];

          return (
            <div
              key={`${site.get('@id')}${type}`}
              className="planningLegend__badgesContainer"
            >
              <span>{site.get('name')}: </span>

              {materialResourceList.map((mr) => {
                const colors = {
                  backgroundColor: mr.get('backgroundColor') || '#fff',
                  color: mr.get('foregroundColor') || '#000',
                };

                return (
                  <Badge
                    key={mr.get('@id')}
                    style={colors}
                    className="planningLegend__badges"
                  >
                    {mr.get('type') === 'vehicle' ? (
                      <MdLocalShipping />
                    ) : (
                      <MdPower />
                    )}{' '}
                    {mr.get('name')}
                  </Badge>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  }
}

PlanningLegend.defaultProps = {
  materialResourceList: null,
};
PlanningLegend.propTypes = {
  findAllMaterialResources: PropTypes.func.isRequired,
  materialResourceList: PropTypes.instanceOf(PagedCollection),
  teamFilter: ImmutablePropTypes.listOf(
    ImmutablePropTypes.mapContains({
      team: ImmutablePropTypes.mapContains({
        '@id': PropTypes.string.isRequired,
      }),
    })
  ).isRequired,
};

function mapStateToProps(state) {
  return {
    materialResourceList: state.app.get('materialResourceList'),
    teamFilter: state.app.getIn(['savedFilters', 'teamList']) || List(),
  };
}

export default connect(mapStateToProps, materialResourceAction)(PlanningLegend);
