import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect, useSelector } from 'react-redux';
import Loader from 'react-loader';
import { Button, ButtonToolbar, Modal, Container, Row } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import Project from '../../entity/Project';
import { currentUserIsAdminSelector } from '../../selector';
import ProjectButton from './ProjectButton';
import ProjectGroup from './ProjectGroup';
import { useHomeProject } from './ProjectListFetch';

function ProjectList({ activeBrandList, allBrandActive }) {
  const [openedProjectGroup, setOpenedProjectGroup] = useState(null);

  const isAdmin = useSelector(currentUserIsAdminSelector);
  const { projectList, isLoaded } = useHomeProject();

  function isProjectActive(project) {
    if (activeBrandList === null || allBrandActive) {
      return true;
    }

    return project
      .get('brandList')
      .some((brand) => activeBrandList.contains(brand.get('@id')));
  }

  const activeProjectList = projectList.filter(
    (project) =>
      isProjectActive(project) && project.get('status') === 'in_progress'
  );

  const waitingProjectList = projectList.filter(
    (project) => isProjectActive(project) && project.get('status') === 'waiting'
  );

  function getProjectGoupList(projectList) {
    return projectList
      .sort(Project.compareNames)
      .groupBy((project) => project.get('projectGroup') || project);
  }

  return (
    <Loader parentClassName="loader loader--inline" loaded={isLoaded}>
      <div className="container">
        <h2>Projets en cours</h2>

        {openedProjectGroup && (
          <Modal
            show
            onHide={() => {
              setOpenedProjectGroup(null);
            }}
          >
            <Modal.Header>{openedProjectGroup.projectName}</Modal.Header>
            <Modal.Body>
              <ButtonToolbar>
                {openedProjectGroup.projectList.map((project) => (
                  <ProjectButton key={project.get('@id')} project={project} />
                ))}
              </ButtonToolbar>
            </Modal.Body>
          </Modal>
        )}

        <Container>
          <Row>
            {getProjectGoupList(activeProjectList)
              .entrySeq()
              .map((entry) => (
                <ProjectGroup
                  key={entry[0].get('@id')}
                  projectGroup={entry[0]}
                  projectList={entry[1]}
                  onOpenProjectGroup={() => {
                    setOpenedProjectGroup({
                      projectGroupName: entry[0].get('name'),
                      projectList: entry[1],
                    });
                  }}
                />
              ))}
          </Row>
        </Container>

        {waitingProjectList.count() > 0 ? (
          <div>
            <h2>Projets en attente</h2>
            <Container>
              <Row>
                {getProjectGoupList(waitingProjectList)
                  .entrySeq()
                  .map((entry) => (
                    <ProjectGroup
                      key={entry[0].get('@id')}
                      projectGroup={entry[0]}
                      projectList={entry[1]}
                      onOpenProjectGroup={() => {
                        setOpenedProjectGroup({
                          projectGroupName: entry[0].get('name'),
                          projectList: entry[1],
                        });
                      }}
                    />
                  ))}
              </Row>
            </Container>
          </div>
        ) : null}
      </div>

      <h2>Actions</h2>
      {isAdmin && (
        <LinkContainer to="/projects/create">
          <Button variant="primary">+ Ajouter un projet</Button>
        </LinkContainer>
      )}
      <LinkContainer to={'/archived-projects'}>
        <Button variant="link">Voir les projets archivés</Button>
      </LinkContainer>
      {isAdmin && (
        <LinkContainer to={'/project-groups/'}>
          <Button variant="link">Modifier les groupes de projets</Button>
        </LinkContainer>
      )}
    </Loader>
  );
}

ProjectList.defaultProps = {
  activeBrandList: null,
};
ProjectList.propTypes = {
  activeBrandList: ImmutablePropTypes.setOf(PropTypes.string),
  allBrandActive: PropTypes.bool.isRequired,
};

export default ProjectList;
