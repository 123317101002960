import { Map } from 'immutable';

export default function (state = Map(), action) {
  switch (action.type) {
    case 'RECEIVE_ME':
      return state.set('token', action.me.get('azureToken').access_token);

    case 'ONE_DRIVE_AUTH_TOKEN_RECEIVED':
      return state.set('token', action.token);

    case 'ONE_DRIVE_DATA_FETCHED':
      return state
        .set('currentData', action.currentData)
        .delete('fetchingData');

    case 'ONE_DRIVE_FETCH_DATA':
      return state.set('fetchingData', true);

    case 'ONE_DRIVE_DATA_FETCH_ERROR':
      return state.delete('fetchingData');

    case 'ONE_DRIVE_FOLDER_CREATED':
      return state.delete('currentData');

    default:
      return state;
  }
}
