import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import urijs from 'urijs';
import { Button, ButtonToolbar, Modal } from 'react-bootstrap';
import Machine from '../../../entity/Machine';
import history from '../../../history';
import {
  handleMachineStatusChange,
  handleMachineTransferStatusChange,
} from '../../../actions/machine';
import machineApi from '../../../api/machine';

function PVErrorModal({ error, onHide }) {
  return (
    <Modal show={!!error} onHide={onHide}>
      <Modal.Header>Erreur dans le téléchargement du PV</Modal.Header>
      <Modal.Body>{error}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Fermer
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
PVErrorModal.defaultProps = {
  error: null,
};
PVErrorModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  error: PropTypes.string,
};

class MachineActions extends PureComponent {
  constructor(props) {
    super(props);
    this.onMachineFinish = this.onMachineFinish.bind(this);
    this.onMachinePlanned = this.onMachinePlanned.bind(this);
    this.onMachineTransfered = this.onMachineTransfered.bind(this);
    this.onMachineUntransfered = this.onMachineUntransfered.bind(this);
    this.downloadPV = this.downloadPV.bind(this);

    this.state = {
      pvError: null,
    };
  }

  onMachineFinish() {
    this.props.handleMachineStatusChange(this.props.machine, 'done');
  }

  onMachinePlanned() {
    this.props.handleMachineStatusChange(this.props.machine, 'planned');
  }

  onMachineTransfered() {
    this.props.handleMachineTransferStatusChange(
      this.props.machine,
      'transfered'
    );
  }

  onMachineUntransfered() {
    this.props.handleMachineTransferStatusChange(
      this.props.machine,
      'transfer_planned'
    );
  }

  downloadPV() {
    const { machine } = this.props;

    machineApi.openPV(machine).catch((e) => {
      // alert(e);
      this.setState({ pvError: e });
    });
  }

  render() {
    const { machine, fromTransferTask } = this.props;
    return (
      <Fragment>
        <PVErrorModal
          error={this.state.pvError}
          onHide={() => this.setState({ pvError: null })}
        />
        <h3>Actions</h3>
        <ButtonToolbar>
          <div>
            {fromTransferTask ? (
              machine.transferStatus !== 'transfered' ? (
                <Button variant="primary" onClick={this.onMachineTransfered}>
                  {'Passer à "transférée"'}
                </Button>
              ) : (
                <div>
                  <Button variant="success">Machine transférée</Button>
                  <Button
                    variant="warning"
                    onClick={this.onMachineUntransfered}
                  >
                    {'Passer en "à transférer"'}
                  </Button>
                </div>
              )
            ) : machine.get('status') !== 'done' ? (
              <Button variant="primary" onClick={this.onMachineFinish}>
                {'Passer à "terminée"'}
              </Button>
            ) : (
              <div>
                <Button variant="success">Machine terminée</Button>
                <Button variant="warning" onClick={this.onMachinePlanned}>
                  {'Passer en "non terminé"'}
                </Button>
              </div>
            )}
          </div>
          <Button
            variant="link"
            onClick={this.downloadPV}
            disabled={!machine.isPVAvailable}
            title={
              !machine.isPVAvailable
                ? 'PV non téléchargeable (pas de template dans le projet)'
                : null
            }
          >
            Télécharger le PV
          </Button>
          <Button variant="link" onClick={history.goBack}>
            Retour
          </Button>
        </ButtonToolbar>
      </Fragment>
    );
  }
}
MachineActions.propTypes = {
  machine: PropTypes.instanceOf(Machine).isRequired,
  fromTransferTask: PropTypes.bool.isRequired,
  handleMachineStatusChange: PropTypes.func.isRequired,
  handleMachineTransferStatusChange: PropTypes.func.isRequired,
};

function mapStateToProps() {
  const { fromTransferTask } = urijs(window.location.href).query(true);

  return {
    fromTransferTask: !!fromTransferTask,
  };
}

export default connect(mapStateToProps, {
  handleMachineStatusChange,
  handleMachineTransferStatusChange,
})(MachineActions);
