import { fromJS, List } from 'immutable';
import history from '../history';

import projectApi from '../api/project';
import importedFileApi from '../api/importedFile';

import { findMachinesForProject } from './machine';
import { dispatchError } from './error';

function receiveCurrentProject(project) {
  return {
    type: 'RECEIVE_CURRENT_PROJECT',
    project: fromJS(project),
  };
}

export function removeCurrentProject() {
  return {
    type: 'REMOVE_CURRENT_PROJECT',
  };
}

export function findProject(id) {
  return (dispatch) => {
    projectApi
      .find(id, {
        fields:
          '@id,name,slug,logo,status,projectGroup{@id,name},active,brandList{@id,name},machineStatus,projectContactList{@id,job,name,email,phone,comment}',
      })
      .then((project) => {
        dispatch(receiveCurrentProject(project));
      });
  };
}

export function deleteProject(project) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      projectApi
        .del(project.get('@id'))
        .then(() => {
          dispatch({
            type: 'DELETE_PROJECT',
            project,
          });

          history.push('/');
          resolve();
        })
        .catch((err) => {
          dispatchError(err, dispatch);
          reject();
        });
    });
}

export function deleteImportedFile(id, project) {
  return (dispatch) => {
    importedFileApi
      .del(id)
      .then(() => {
        dispatch({
          type: 'REMOVE_LAST_IMPORTED_FILE',
        });

        dispatch(findMachinesForProject(project));
      })
      .catch((err) => {
        dispatch({
          type: 'ALERT',
          style: 'danger',
          message: JSON.parse(err.response.text)['hydra:description'],
          dismissAfter: 5000,
        });
      });
  };
}

function updateProject(values, dispatch) {
  return new Promise((resolve, reject) => {
    projectApi
      .update(values.id, values, { fields: '@id' })
      .then(() => {
        history.push('/');
        resolve();
      })
      .catch((err) => {
        dispatch({
          type: 'ALERT',
          style: 'danger',
          message: JSON.parse(err.response.text)['hydra:description'],
          dismissAfter: 5000,
        });

        reject();

        // reject({ name: 'pro', _error: JSON.parse(err.response.text)['hydra:description'] });
      });
  });
}

function createProject(values, dispatch) {
  return new Promise((resolve, reject) => {
    projectApi
      .create(values, { fields: '@id' })
      .then(() => {
        history.push('/');
        resolve();
      })
      .catch((err) => {
        dispatch({
          type: 'ALERT',
          style: 'danger',
          message: JSON.parse(err.response.text)['hydra:description'],
          dismissAfter: 5000,
        });
        reject();
      });
  });
}

export function createUpdateProject(valuesParam) {
  return (dispatch) => {
    const values = valuesParam;
    if (!values.projectGroup && values.projectGroupName) {
      values.projectGroup = { name: values.projectGroupName };
    }

    if (values.id) {
      return updateProject(values, dispatch);
    }

    return createProject(values, dispatch);
  };
}

export function projectMachineGoToPage(projectId, page) {
  history.push(`/projects/${projectId}/machines/page-${page}`);
}

export function projectStockGoToPage(projectId, page) {
  history.push(`/projects/${projectId}/stocks/page-${page}`);
}

export function selectMachine(machine) {
  return {
    type: 'SELECT_MACHINE',
    machine,
  };
}

export function exportProjectAsXls(projectId, projectName) {
  return (dispatch) => {
    projectApi.exportProjectAsXls(projectId, projectName).catch((error) => {
      dispatchError(error.toString(), dispatch);
    });
  };
}

export function filterProjectList(values) {
  return {
    type: 'SET_PROJECT_LIST_FILTER',
    projectList: List(values.projectListFilter),
  };
}
