import superagent from 'superagent';
import config from '../config';
import AbstractClient from './abstractClient';
import { createEntity } from '../entity/factory';

class MediaObjectClient extends AbstractClient {
  getPathBase() {
    return `${config.api.version}/media_objects`;
  }

  uploadFile(file, fields) {
    return this._getAccessToken().then(
      (accessToken) =>
        new Promise((resolve, reject) => {
          superagent
            .post(`${config.api.url}${this.getPathBase()}?fields=${fields}`)
            .set('Authorization', `Bearer ${accessToken}`)
            .accept('json')
            .attach('file', file)
            .end((err, res) => {
              if (err) {
                reject(err);
              } else {
                resolve(createEntity(JSON.parse(res.text)));
              }
            });
        })
    );
  }
}

export default new MediaObjectClient();
