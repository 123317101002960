import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Loader from 'react-loader';
import {
  Button,
  FormLabel,
  FormGroup,
  FormControl,
  Modal,
} from 'react-bootstrap';
import Task from '../../entity/Task';
import logApi from '../../api/log';
import Log from '../../entity/Log';

export default class TaskDeletionModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      logMessage: '',
      isSubmitting: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLogMessageChange = this.handleLogMessageChange.bind(this);
  }
  handleLogMessageChange(e) {
    this.setState({
      logMessage: e.target.value,
    });
  }
  handleSubmit() {
    const { logMessage } = this.state;
    const { onSubmit, dispatchError, task } = this.props;
    if (!logMessage) {
      // avoid useless http call as the API already create a empty log line if none is provided
      onSubmit();
      return;
    }
    const log = new Log({
      type: 'task_deleted',
      comment: logMessage,
    }).set('task', task.get('@id')); // hack because task.@id is already a string and createEntity does not manage this
    this.setState({
      isSubmitting: true,
    });
    logApi.create(log, { fields: '@id' }).then(onSubmit).catch(dispatchError);
  }
  render() {
    const { show, onHide } = this.props;
    const { logMessage, isSubmitting } = this.state;
    return (
      <Loader loaded={!isSubmitting}>
        <Modal show={show} onHide={onHide}>
          <Modal.Header closeButton>
            <Modal.Title>Supprimer cette tâche ?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Vous pouvez laisser un commentaire indiquant pourquoi vous
              supprimez cette tâche :
            </p>
            <FormGroup controlId="logMessage">
              <FormLabel>Commentaire</FormLabel>
              <FormControl
                as="textarea"
                value={logMessage}
                onChange={this.handleLogMessageChange}
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" type="button" onClick={onHide}>
              Retour
            </Button>
            <Button type="submit" variant="primary" onClick={this.handleSubmit}>
              Supprimer
            </Button>
          </Modal.Footer>
        </Modal>
      </Loader>
    );
  }
}

TaskDeletionModal.propTypes = {
  task: PropTypes.instanceOf(Task).isRequired,
  onHide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  dispatchError: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};
