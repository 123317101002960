import React from 'react';
import PropTypes from 'prop-types';
import MediaObjectInput from '../../form/MediaObjectInput';
import Project from '../../../entity/Project';
import { Alert } from 'react-bootstrap';

function InstallationTemplate({ project, onChange }) {
  return (
    <div>
      <Alert variant="info">
        <p>
          Importer ici le ficher de template pour la dématérialisation des PV du
          projet.
        </p>
        <p>
          Le fichier doit être au format &quot;.docx&quot; et contenir les
          champs de template pré-renseignés.
        </p>
      </Alert>

      <MediaObjectInput
        value={project.get('installationTemplate') || ''}
        onChange={onChange}
      />
    </div>
  );
}

InstallationTemplate.propTypes = {
  project: PropTypes.instanceOf(Project).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default InstallationTemplate;
