import PropTypes from 'prop-types';
import React, { useReducer } from 'react';
import { parse } from 'query-string';
import { is, List, Map } from 'immutable';
import Loader from 'react-loader';
import moment from 'moment';
import 'moment/locale/fr';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { Alert, Button, ButtonGroup } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import BigCalendar from 'react-big-calendar';
import {
  selectTask,
  moveTaskInPosition,
  setEditMode,
} from '../../actions/task';
import { currentUserIsAdminSelector } from '../../selector';
import TaskInfo from './taskInfo';
import PlanningLegend from './planningLegend';
import TeamFilter from '../filters/TeamFilter';
import AllUserFilter from '../filters/AllUserFilter';
import MassTask from '../form/MassTask';
import { entityShortId } from '../../idTools';
import OgipCalendarView from './OgipCalendarView';
import { dayPropGetter, filterWeekendTasks } from './utils';
import taskApi from '../../api/task';
import userApi from '../../api/user';
import './globalPlanning.css';
import { MdEdit } from 'react-icons/md';

const USER_PLANNING_FIELDS =
  '@id,firstname,lastname,isActive,position,team{@id}';
export const PLANNING_FIELDS = `@id,type,status,areMachinesPrepared,areMachinesTransfered,machineTransferStatus,machinePreparationStatus,planningComment,startDate,endDate,position,project{@id,name},mainSite{@id,siteId,city},machineList{@id,machineModel,site{siteId,city}},userList{${USER_PLANNING_FIELDS}},materialResourceList{@id,type,foregroundColor,backgroundColor}`;

const startAccessor = (task) => task.startDate && task.startDate.toDate();

class FixedElement extends React.Component {
  constructor(props) {
    super(props);

    this.reposition = this.reposition.bind(this);

    this.ref = null;
    this.timeout = null;
  }

  componentDidMount() {
    this.reposition();

    window.addEventListener('resize', this.reposition);
    window.addEventListener('toggleNavBar', this.reposition);
  }

  componentDidUpdate() {
    this.reposition();
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    window.removeEventListener('resize', this.reposition);
    window.removeEventListener('toggleNavBar', this.reposition);
  }

  reposition() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => {
      this.ref.style.position = 'initial';
      this.ref.style.top = `${this.ref.offsetTop}px`;
      this.ref.style.position = 'absolute';
      this.ref.style.width = `${this.ref.parentElement.offsetWidth}px`;
      this.ref.style.height = `${this.ref.parentElement.offsetHeight}px`;
    }, 0);
  }

  render() {
    const { className, children } = this.props;
    return (
      <div
        className={className}
        ref={(ref) => {
          this.ref = ref;
        }}
      >
        {children}
      </div>
    );
  }
}

FixedElement.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

moment.locale('fr');
BigCalendar.momentLocalizer(moment);

class GlobalPlanning extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleEditToggle = this.handleEditToggle.bind(this);
    this.handleSelectEvent = this.handleSelectEvent.bind(this);
    this.renderTask = this.renderTask.bind(this);
    this.renderUserLine = this.renderUserLine.bind(this);
    this.renderUserGroup = this.renderUserGroup.bind(this);
    this.getPreviousTask = this.getPreviousTask.bind(this);
    this.getNextTask = this.getNextTask.bind(this);
    this.getCurrentTasksByUser = this.getCurrentTasksByUser.bind(this);
    this.getCurrentTasksForUser = this.getCurrentTasksForUser.bind(this);
    this.getTeam = this.getTeam.bind(this);
    this.getUserTeam = this.getUserTeam.bind(this);

    this.globalCalendar = null;
    this.currentTasksByUser = null;
  }

  componentDidMount() {
    this.fetchPlanning();
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (
      this.props.currentTasksByUser !== nextProps.currentTasksByUser ||
      this.props.displayAllUsers !== nextProps.displayAllUsers ||
      this.props.teamListFilter !== nextProps.teamListFilter ||
      this.props.hiddenUserList !== nextProps.hiddenUserList
    ) {
      this.currentTasksByUser = null;
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.currentDate.isSame(prevProps.currentDate, 'day')) {
      this.fetchPlanning();
      this.setHorizontalScroll();
    }

    if (
      prevProps.selectedTaskList.size !== 0 &&
      this.props.selectedTaskList.size === 0
    ) {
      this.fetchPlanning();
    }
  }

  getCurrentTasksByUser() {
    // may be manager in a selector
    if (this.currentTasksByUser === null) {
      const hiddenUsers =
        this.props.displayAllUsers && this.props.hiddenUserList
          ? this.props.hiddenUserList
          : List();

      this.currentTasksByUser = this.props.currentTasksByUser
        .merge(Map(hiddenUsers.map((user) => [user, List()])))
        .filter((task, user) => user.isActive)
        .filter(
          (task, user) =>
            this.props.teamListFilter.size === 0 ||
            this.props.teamListFilter.includes(user.getTeamId())
        );
    }

    return this.currentTasksByUser;
  }

  getCurrentTasksForUser(user) {
    return this.getCurrentTasksByUser().get(user);
  }

  getPreviousTask(task, user) {
    const sortedTaskList = this.getCurrentTasksForUser(user)
      .filterNot(
        (t) => t.startDate > task.endDate || t.endDate < task.startDate
      )
      .sort((a, b) => {
        if (a.position < b.position) {
          return -1;
        }
        return 1;
      });

    const previousTask = sortedTaskList
      .takeUntil((t) => t.get('@id') === task.get('@id'))
      .get(-1);

    return previousTask;
  }

  getNextTask(task, user) {
    const sortedTaskList = this.getCurrentTasksForUser(user)
      .filterNot(
        (t) => t.startDate > task.endDate || t.endDate < task.startDate
      )
      .sort((a, b) => {
        if (a.position < b.position) {
          return -1;
        }
        return 1;
      });

    const nextTask = sortedTaskList
      .skipUntil((t) => t.get('@id') === task.get('@id'))
      .get(1);

    return nextTask;
  }

  isTaskSelectable(task) {
    if (!this.props.isEditMode) {
      return false;
    }

    if (this.props.selectedTaskList.size === 0) {
      return true;
    }

    const date = this.props.selectedTaskList.getIn(['0', 'startDate']);
    const user = this.props.selectedTaskList.getIn(['0', 'user']);
    return (
      date.isSame(task.get('startDate'), 'day') && is(user, task.get('user'))
    );
  }

  fetchPlanning() {
    const week = `${this.props.currentDate.isoWeek()}-${
      this.props.currentDate.isoWeek() + 1
    }`;

    this.props.fetchPlanningByWeek(this.props.currentDate.isoWeekYear(), week);
  }

  setHorizontalScroll(evt) {
    let scrollLeft = 0;
    if (evt) {
      scrollLeft = evt.target.scrollLeft;
    }
    const scrollList = document.querySelectorAll('.globalPlanning__team');

    for (let i = 0; i < scrollList.length; i++) {
      if (
        (!evt || evt.target !== scrollList[i]) &&
        typeof scrollList[i].scrollTo === 'function'
      ) {
        scrollList[i].scrollTo(scrollLeft, 0);
      }
    }
  }

  getUserTeam(user) {
    const teamId = user.getIn(['team', '@id']) || user.team;
    return this.getTeam(teamId);
  }

  getTeam(teamId) {
    return this.props.teamList.find((t) => t.get('@id') === teamId);
  }

  handleEditToggle() {
    this.props.setEditMode(!this.props.isEditMode);
  }

  handleSelectEvent(task) {
    this.props.selectTask(task);
  }

  renderTaskTitle(task) {
    const machineGroup = task.machineList.groupBy((m) => m.get('machineModel'));
    return machineGroup
      .map(
        (list, model) =>
          `${model} : ${list.size} machine${list.size > 1 ? 's' : ''}`
      )
      .toList()
      .reduce((prev, curr) => `${prev}\n${curr}`);
  }

  renderTask(user) {
    const {
      isEditMode,
      moveTaskInPosition,
      selectedTaskList,
      selectTask,
    } = this.props;

    return (event) => {
      const task = event.event;

      // rendering optimization, as there is no need to get previous / next task when not in edit mode
      const previousTask = isEditMode ? this.getPreviousTask(task, user) : null;
      const nextTask = isEditMode ? this.getNextTask(task, user) : null;

      return (
        <TaskInfo
          task={task}
          selectTask={selectTask}
          displayMoveButtons={isEditMode}
          displaySelectTask={this.isTaskSelectable(task)}
          isChecked={selectedTaskList.contains(task)}
          moveTaskInPosition={moveTaskInPosition}
          previousTask={previousTask}
          nextTask={nextTask}
        />
      );
    };
  }

  renderTitle() {
    const startDate = this.props.currentDate.clone().startOf('week');
    const endDate = this.props.currentDate
      .clone()
      .add(1, 'weeks')
      .endOf('week')
      .subtract(2, 'days');

    const sameYear = startDate.isSame(endDate, 'year');
    const sameMonth = sameYear && startDate.isSame(endDate, 'month');

    let startFormat = 'Do MMM YY';
    if (sameMonth) {
      startFormat = 'Do';
    } else if (sameYear) {
      startFormat = 'Do MMM';
    }

    return (
      <h1 className="text-center">
        <div className="float-left">
          <ButtonGroup>
            <LinkContainer
              to={`/planning/?currentDate=${startDate
                .clone()
                .subtract(1, 'week')
                .format('YYYY-MM-DD')}`}
            >
              <Button variant="secondary">&lt; </Button>
            </LinkContainer>

            <LinkContainer
              to={`/planning/?currentDate=${startDate
                .clone()
                .add(1, 'week')
                .format('YYYY-MM-DD')}`}
            >
              <Button variant="secondary"> &gt;</Button>
            </LinkContainer>
          </ButtonGroup>
        </div>
        Semaines du {startDate.format(startFormat)} au{' '}
        {endDate.format('Do MMM YY')}
      </h1>
    );
  }

  renderUsername(user) {
    const team = this.getUserTeam(user);

    return (
      <FixedElement className="globalPlanning__userName">
        {user && `${user.get('firstname')} ${user.get('lastname')}`}
        {team && team.get('agency') && ` - ${team.get('name')}`}
      </FixedElement>
    );
  }

  renderUserLine(user, teamChanges) {
    const taskList = this.getCurrentTasksForUser(user);

    const taskRenderer = this.renderTask(user);

    // TODO : just use only one calendar and manager the users in the custom view
    return (
      <BigCalendar
        key={user.get('@id')}
        className={[
          'globalPlanning__calendarWeek',
          teamChanges && 'team-changes',
        ]}
        culture={'fr'}
        messages={{ allDay: this.renderUsername(user) }}
        events={filterWeekendTasks(taskList.toArray())}
        allDayAccessor={() => true}
        dayPropGetter={dayPropGetter}
        startAccessor={startAccessor}
        endAccessor={(task) => {
          let endDate = task.endDate;
          if (!endDate) {
            return null;
          }

          const currentDate = this.props.currentDate;
          const maxEndDate = currentDate.clone().add(1, 'weeks').endOf('week'); // same as OgipCalendarView

          // if task go further our max date, ignore dates after
          if (maxEndDate < endDate) {
            endDate = maxEndDate;
          }

          const startDate =
            currentDate > task.startDate ? currentDate : task.startDate;

          // hacky way to resolve the fact that we do not show week-end days,
          // so the layout must not contains 2 more days width.
          // At this point, the date does not matter anymore, only the number of shown days matters
          const diffDays = endDate.diff(startDate, 'days');

          if (diffDays <= 2) {
            // not including a week-end
            return endDate.toDate();
          }

          const nbWeek = Math.floor((startDate.isoWeekday() + diffDays) / 7);

          if (nbWeek === 0) {
            return endDate.toDate();
          }

          return endDate
            .clone()
            .subtract(2 * nbWeek, 'days')
            .toDate();
        }}
        tooltipAccessor={this.renderTaskTitle}
        components={{ event: taskRenderer }}
        date={this.props.currentDate.toDate()}
        onNavigate={() => null}
        min={new Date('2016-01-01 00:00:00')}
        max={new Date('2016-01-01 00:00:00')}
        defaultView={'ogip'}
        views={{ ogip: OgipCalendarView }}
        toolbar={false}
        formats={{ dayFormat: 'ddd DD/MM ([S]WW)' }}
      />
    );
  }

  renderTeamTitle(teamId) {
    if (teamId === 'agency') {
      return <h3>Agences</h3>;
    }

    const team = this.getTeam(teamId);
    if (team) {
      return <h3>{team.get('name')}</h3>;
    }
  }

  renderUserGroup(userList, teamId) {
    return (
      <div
        className="globalPlanning__team"
        key={teamId}
        onScroll={this.setHorizontalScroll}
      >
        {this.renderTeamTitle(teamId)}
        <div className="globalPlanning__teamPlanning">
          {userList.map((user, key) => this.renderUserLine(user, key === 0))}
        </div>
      </div>
    );
  }

  render() {
    const groupedUserList = this.getCurrentTasksByUser()
      .keySeq()
      .sort((a, b) => a.position - b.position)
      .groupBy((user) => {
        const team = this.getUserTeam(user);

        if (team) {
          if (team.get('agency') === true) {
            return 'agency';
          }
          return team.get('@id');
        }
      });

    return (
      <div>
        {this.renderTitle()}

        <div
          className="global-calendar"
          ref={(ref) => {
            this.globalCalendar = ref;
          }}
        >
          <Loader
            loaded={this.props.isLoaded}
            parentClassName="loader loader--inline"
          >
            {groupedUserList.size > 0 ? (
              groupedUserList
                .entrySeq()
                .map(([teamId, userList]) =>
                  this.renderUserGroup(userList, teamId)
                )
            ) : (
              <Alert variant="warning">
                Aucune tâche assignée pour cette semaine
              </Alert>
            )}
          </Loader>
        </div>

        {this.props.isEditMode && <MassTask />}

        <h2>Légende</h2>
        <PlanningLegend />

        <h2>Filtres</h2>
        <TeamFilter />
        <AllUserFilter />

        {this.props.isAdmin && (
          <div>
            <h2>Actions</h2>
            <ButtonGroup>
              <LinkContainer to="/tasks/create">
                <Button variant="primary">+ Créer une tâche</Button>
              </LinkContainer>

              <Button variant="secondary" onClick={this.handleEditToggle}>
                {this.props.isEditMode ? 'Désactiver ' : 'Activer '}
                le mode édition
              </Button>
            </ButtonGroup>
          </div>
        )}

        {this.props.currentTasksWithoutUser.size > 0 && (
          <h2>Tâches non assignées</h2>
        )}

        {this.props.currentTasksWithoutUser.map((task) => (
          <div key={task.get('@id')}>
            {task.get('@id')}
            {' : '}
            {task.get('machineList').size} machines
            <LinkContainer to={`/tasks/${entityShortId(task)}/edit`}>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a>
                <MdEdit />
              </a>
            </LinkContainer>
          </div>
        ))}
      </div>
    );
  }
}

GlobalPlanning.defaultProps = {
  hiddenUserList: null,
};

GlobalPlanning.propTypes = {
  isLoaded: PropTypes.bool.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  selectTask: PropTypes.func.isRequired,
  selectedTaskList: PropTypes.object.isRequired,
  currentTasksWithoutUser: PropTypes.object.isRequired,
  currentTasksByUser: PropTypes.object.isRequired,
  moveTaskInPosition: PropTypes.func.isRequired,
  currentDate: PropTypes.object.isRequired,
  teamList: PropTypes.object.isRequired,
  setEditMode: PropTypes.func.isRequired,
  fetchPlanningByWeek: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  hiddenUserList: PropTypes.object,
  teamListFilter: PropTypes.object.isRequired,
  displayAllUsers: PropTypes.bool.isRequired,
};

function mapStateToProps(state, ownProps) {
  const teamListFilter =
    state.app.getIn(['savedFilters', 'teamList']) || List();

  return {
    teamListFilter,
    currentTasksWithoutUser: state.app.get('currentTasksWithoutUser') || List(),
    currentDate: moment(parse(ownProps.location.search).currentDate),
    isEditMode: !!state.app.get('isEditMode'),
    selectedTaskList: state.app.get('selectedTaskList') || List(),
    teamList: state.app.getIn(['teamList', 'hydra:member']) || List(),
    hiddenUserList: state.app.getIn(['hiddenUserList', 'hydra:member']),
    isAdmin: currentUserIsAdminSelector(state),
    displayAllUsers:
      state.app.getIn(['savedFilters', 'allUserFilter']) || false,
  };
}

function fetchPlanningByWeek(year, week) {
  return (dispatch, state) => {
    // state is not redux's `getState` as we are on hooks here, not on redux
    dispatch({
      type: 'REQUEST_TASK_LIST',
    });

    const promises = [taskApi.findByWeek(year, week, PLANNING_FIELDS)];

    if (!state.extraUsers) {
      promises.push(
        userApi.findBy({
          showInTaskList: 'always',
          fields: USER_PLANNING_FIELDS,
        })
      );
    }

    Promise.all(promises).then(([taskList, userList]) => {
      dispatch({
        type: 'RECEIVE_TASKS',
        taskList,
        extraUsers: userList,
      });
    });
  };
}

function initState() {
  return { taskList: List(), taskListLoaded: false, extraUsers: null };
}
function reducer(state, action) {
  switch (action.type) {
    case 'REQUEST_TASK_LIST':
      return {
        taskListLoaded: false,
        taskList: List(),
      };

    case 'RECEIVE_TASKS':
      return {
        taskListLoaded: true,
        taskList: action.taskList,
        extraUsers: action.extraUsers || state.extraUsers,
      };

    default:
      throw new Error(`Unknown action ${action.type}`);
  }
}
const taskListObjectSelector = (state) => state.taskList;
const extraUserListSelector = (state) => state.extraUsers;
const getCurrentTaskByUser = createSelector(
  taskListObjectSelector,
  extraUserListSelector,
  (taskList, extraUsers) => {
    if (!taskList || taskList.size === 0) {
      return List();
    }

    const sortedTaskList = taskList.getHavingUser();

    let currentTasksByUser = Map();
    sortedTaskList.forEach((task) =>
      task.userList.forEach((user) => {
        if (!currentTasksByUser.get(user)) {
          currentTasksByUser = currentTasksByUser.set(user, List.of(task));
        } else {
          currentTasksByUser = currentTasksByUser.update(user, (l) =>
            l.push(task)
          );
        }
      })
    );

    extraUsers &&
      extraUsers
        .getMembers()
        .filter(
          (user) =>
            !currentTasksByUser.findKey(
              (_, i) => i.get('@id') === user.get('@id')
            )
        )
        .forEach((user) => {
          currentTasksByUser = currentTasksByUser.set(user, List());
        });

    return currentTasksByUser;
  }
);

function GlobalPlanningLocalFetch({ ...props }) {
  const [state, dispatch] = useReducer(reducer, null, initState);

  return (
    <GlobalPlanning
      fetchPlanningByWeek={(year, week) =>
        fetchPlanningByWeek(year, week)(dispatch, state)
      }
      currentTasksByUser={getCurrentTaskByUser(state)}
      isLoaded={state.taskListLoaded}
      {...props}
    />
  );
}

export default connect(mapStateToProps, {
  selectTask,
  moveTaskInPosition,
  setEditMode,
})(GlobalPlanningLocalFetch);
