export function dayPropGetter(date) {
  const now = new Date();

  const isPast = date < now && now.toDateString() !== date.toDateString();

  return {
    className: isPast ? 'rbc-past-day' : '',
  };
}

export function filterWeekendTasks(taskList) {
  return taskList.filter((task) => {
    if (!task.startDate) {
      return false;
    }

    if (6 === task.startDate.day() || 0 === task.startDate.day()) {
      if (!task.endDate) {
        return false;
      } else {
        const nbDays = 6 === task.startDate.day() ? 2 : 1;
        const nextMonday = task.startDate.clone().add(nbDays, 'days');

        return task.endDate > nextMonday;
      }
    }

    return true;
  });
}
