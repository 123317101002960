import config from '../config';
import AbstractClient from './abstractClient';

class ProjectContactClient extends AbstractClient {
  getPathBase() {
    return `${config.api.version}/project_contacts`;
  }
}

export default new ProjectContactClient();
