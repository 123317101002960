import superagent from 'superagent';
import config from '../config';

export default {
  del(id) {
    return window.oauthClient.getAccessToken().then(
      (accessToken) =>
        new Promise((resolve, reject) => {
          superagent
            .del(`${config.api.url}${id}`)
            .set('Authorization', `Bearer ${accessToken}`)
            .accept('json')
            .end((err) => {
              if (err) {
                reject(err);
              } else {
                resolve();
              }
            });
        })
    );
  },
};
