import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { List } from 'immutable';
import { Button, ListGroup, ListGroupItem } from 'react-bootstrap';
import AlertContainer from '../AlertContainer';
import config from '../../config';
import { handleAddFileToTask, deleteTaskFile } from '../../actions/task';
import AddFilesModal from './AddFilesModal';
import { currentUserIsAdminSelector } from '../../selector';
import { MdClear } from 'react-icons/md';

class AttachedFiles extends PureComponent {
  constructor(props) {
    super(props);

    this.handleOpenDropzone = this.handleOpenDropzone.bind(this);
    this.handleCloseDropzone = this.handleCloseDropzone.bind(this);
    this.handleFileDrop = this.handleFileDrop.bind(this);
    this.handleTaskFileDelete = this.handleTaskFileDelete.bind(this);
    this.getTaskFiles = this.getTaskFiles.bind(this);

    this.state = {
      isDropzoneOpened: false,
      deletedTaskFileIds: [],
    };
  }

  handleFileDrop(files) {
    this.props.handleFileDrop(this.props.task, files);

    this.handleCloseDropzone();
  }

  handleOpenDropzone() {
    this.setState({
      isDropzoneOpened: true,
    });
  }

  handleCloseDropzone() {
    this.setState({
      isDropzoneOpened: false,
    });
  }

  handleTaskFileDelete(file) {
    this.props.deleteTaskFile(file);
    this.setState((prevState) => ({
      deletedTaskFileIds: [...prevState.deletedTaskFileIds, file.get('@id')],
    }));
  }

  getTaskFiles() {
    const { fileList } = this.props;
    const { deletedTaskFileIds } = this.state;

    return fileList.filter((file) => {
      return !deletedTaskFileIds.includes(file.get('@id'));
    });
  }

  render() {
    const { taskFilesUploading, isAdmin } = this.props;

    const taskFileList = this.getTaskFiles();

    return (
      <Fragment>
        {taskFileList.size > 0 || taskFilesUploading.size > 0 ? (
          <ListGroup>
            {taskFileList.map((file) => (
              <ListGroupItem
                key={file.get('@id')}
                className="d-flex justify-content-between"
              >
                <a href={`${config.api.url}${file.get('@id')}/download`}>
                  {file.get('uploadedName')}
                </a>

                <a
                  href="#deleteTaskFile"
                  onClick={() => {
                    this.handleTaskFileDelete(file);
                  }}
                >
                  <MdClear />
                </a>
              </ListGroupItem>
            ))}
            {taskFilesUploading.map((file) => (
              <ListGroupItem disabled key={file.name}>
                {file.name}
              </ListGroupItem>
            ))}
          </ListGroup>
        ) : (
          <div className="text-muted">Aucun fichier attaché</div>
        )}
        <AlertContainer position="FILE_IMPORT" />
        {isAdmin && (
          <Button variant="secondary" onClick={this.handleOpenDropzone}>
            Ajouter un fichier
          </Button>
        )}

        <AddFilesModal
          show={this.state.isDropzoneOpened}
          onHide={this.handleCloseDropzone}
          onDrop={this.handleFileDrop}
        />
      </Fragment>
    );
  }
}

AttachedFiles.propTypes = {
  task: PropTypes.object.isRequired,
  taskFilesUploading: PropTypes.object.isRequired,
  handleFileDrop: PropTypes.func.isRequired,
  deleteTaskFile: PropTypes.func.isRequired,
  fileList: ImmutablePropTypes.list,
  isAdmin: PropTypes.bool.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    taskFilesUploading: state.app.get('taskFilesUploading') || List(),
    fileList: state.app.get('taskFilesUploaded') || ownProps.task.fileList,
    isAdmin: currentUserIsAdminSelector(state),
  };
}

export default connect(mapStateToProps, {
  handleFileDrop: handleAddFileToTask,
  deleteTaskFile,
})(AttachedFiles);
