import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import {
  FormGroup,
  FormLabel,
  FormControl,
  FormText,
  Button,
} from 'react-bootstrap';
import Required from './Required';

const required = (value) => (value ? undefined : 'Requis');

function InstallationNewFieldForm({ onSubmit }) {
  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Field name="title" validate={required}>
            {({ input, meta }) => (
              <FormGroup controlId="formBasicText">
                <FormLabel>
                  Titre <Required />
                </FormLabel>
                <FormControl
                  {...input}
                  isValid={meta.touched && !meta.error}
                  isInvalid={meta.touched && meta.error}
                  aria-describedby="titleHelper"
                  type="text"
                  placeholder="Titre"
                />
                <FormControl.Feedback />
                <FormText muted id="titleHelper">
                  Permet d&apos;afficher le titre du champ dans le fichier docx.
                </FormText>
              </FormGroup>
            )}
          </Field>

          <Field
            name="type"
            validate={required}
            initialValue="yes_no_na_comment"
          >
            {({ input, meta }) => (
              <FormGroup controlId="type">
                <FormLabel>
                  Type <Required />
                </FormLabel>
                <FormControl
                  as="select"
                  placeholder="Type"
                  isValid={meta.touched && !meta.error}
                  isInvalid={meta.touched && meta.error}
                  {...input}
                >
                  <option />
                  <option value="yes_no_na_comment">
                    Oui / Non / NA + commentaire
                  </option>
                </FormControl>
                <FormControl.Feedback />
              </FormGroup>
            )}
          </Field>

          <Button variant="primary" type="submit" disabled={submitting}>
            Valider
          </Button>
        </form>
      )}
    </Form>
  );
}

InstallationNewFieldForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default InstallationNewFieldForm;
