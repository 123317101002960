/**
 * We use an Error-like object for backward compatibility as people may call
 * PropTypes directly and inspect their output. However, we don't use real
 * Errors anymore. We don't inspect their stack anyway, and creating them
 * is prohibitively expensive if they are created too often, such as what
 * happens in oneOfType() for any type before the one that matched.
 */
function PropTypeError(message, data) {
  this.message = message;
  this.data = data && typeof data === 'object' ? data : {};
  this.stack = '';
}
// Make `instanceof Error` still work for returned errors.
PropTypeError.prototype = Error.prototype;

function createChainableTypeChecker(validate) {
  function checkType(
    isRequired,
    props,
    propName,
    componentName,
    location,
    propFullName,
    ...rest
  ) {
    propFullName = propFullName || propName;
    if (props[propName] == null) {
      var locationName = location;
      if (isRequired) {
        return new Error(
          `Required ${locationName} \`${propFullName}\` was not specified in ` +
            `\`${componentName}\`.`
        );
      }
    } else {
      return validate(
        props,
        propName,
        componentName,
        location,
        propFullName,
        ...rest
      );
    }
  }

  var chainedCheckType = checkType.bind(null, false);
  chainedCheckType.isRequired = checkType.bind(null, true);

  return chainedCheckType;
}

function createParamsTypeChecker(shapeTypes) {
  function validate(
    props,
    propName,
    componentName,
    location,
    propFullName,
    ...rest
  ) {
    const match = props[propName];
    const { params } = match;

    if (typeof params !== 'object') {
      return new PropTypeError(
        'Property `match` of component `' +
          componentName +
          '` does not contain an object. Did you linked the component to react router ?'
      );
    }

    for (var key in shapeTypes) {
      const typeChecker = shapeTypes[key];

      if (typeof typeChecker !== 'function') {
        return new PropTypeError(
          'Property `key` of component `' +
            componentName +
            '` has invalid PropType notation inside match.'
        );
      }

      var error = typeChecker(
        params,
        key,
        componentName,
        location,
        `${propFullName}.${key}`,
        ...rest
      );

      if (error) {
        return error;
      }
    }
  }
  return createChainableTypeChecker(validate);
}

export default {
  params: createParamsTypeChecker,
};
