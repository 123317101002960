import React from 'react';
import ReactDOM from 'react-dom';
import URI from 'urijs';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootswatch/dist/flatly/bootstrap.min.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './ogip.css';
import App from './App';
import {
  /*registerServiceWorker, */ unregister,
} from './registerServiceWorker';
import 'react-select/dist/react-select.css';

// eslint-disable-next-line no-undef
const appHost = process.env.REACT_APP_HOST;
if (appHost !== window.location.host) {
  const href = new URI(window.location.href).host(appHost).toString();
  window.location.href = href;
}

ReactDOM.render(<App />, document.getElementById('root'));
// registerServiceWorker();
unregister();
