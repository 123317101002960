import { fromJS } from 'immutable';
import history from '../history';
import projectGroupApi from '../api/projectGroup';

function dispatchError(err, dispatch) {
  dispatch({
    type: 'ALERT',
    style: 'danger',
    message: JSON.parse(err.response.text)['hydra:description'],
    dismissAfter: 5000,
  });
}

export function findProjectGroup(id) {
  return (dispatch) =>
    projectGroupApi.find(id).then((projectGroup) => {
      dispatch({
        type: 'RECEIVE_CURRENT_PROJECT_GROUP',
        projectGroup,
      });
    });
}

export function findAllProjectGroups() {
  return (dispatch) =>
    projectGroupApi
      .findAll({ fields: '@id,name,slug' })
      .then((projectGroupList) => {
        dispatch({
          type: 'RECEIVE_ALL_PROJECT_GROUP',
          projectGroupList: fromJS(projectGroupList),
        });
      });
}

export function deleteProjectGroup(projectGroup) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      projectGroupApi
        .del(projectGroup.get('@id'))
        .then(() => {
          dispatch({
            type: 'DELETE_PROJECT_GROUP',
            projectGroup,
          });

          resolve();
        })
        .catch((err) => {
          dispatchError(err, dispatch);
          reject();
        });
    });
}

function createProjectGroup(values, dispatch) {
  return new Promise((resolve, reject) => {
    projectGroupApi
      .create(values)
      .then((projectGroup) => {
        dispatch({
          type: 'RECEIVE_NEW_PROJECT_GROUP',
          projectGroup,
        });

        history.push('/project-groups/');
        resolve();
      })
      .catch((err) => {
        dispatchError(err, dispatch);
        reject();
      });
  });
}

function updateProjectGroup(values, dispatch) {
  return new Promise((resolve, reject) => {
    projectGroupApi
      .update(values['@id'], values)
      .then(() => {
        history.push('/project-groups/');
        resolve();
      })
      .catch((err) => {
        dispatchError(err, dispatch);
        reject();
      });
  });
}

export function createUpdateProjectGroup(values) {
  return (dispatch) => {
    if (values['@id']) {
      return updateProjectGroup(values, dispatch);
    }

    return createProjectGroup(values, dispatch);
  };
}
