import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import { Table, Button, ButtonToolbar, ProgressBar } from 'react-bootstrap';
import Loader from 'react-loader';
import Project from '../../../entity/Project';
import { ImportSelect } from './ImportSelect';
import * as importActions from '../../../actions/projectImport';
import columnChoices from './Column';

export class MachineImport extends React.PureComponent {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.getChoices = this.getChoices.bind(this);
    this.renderButtons = this.renderButtons.bind(this);
    this.getDefaultValue = this.getDefaultValue.bind(this);
  }

  getDefaultValue(column) {
    if (this.props.currentMapping[column]) {
      return this.props.currentMapping[column];
    }

    return this.props.importedFile.get('mappingSuggestion')
      ? this.props.importedFile.get('mappingSuggestion').get(column)
      : Map();
  }

  getChoices() {
    const choices = columnChoices;

    const mapping = this.props.currentMapping;

    if (!mapping) {
      return choices;
    }

    const outgroup = choices
      .get('group')
      .map((choices) =>
        choices.map((item) =>
          mapping.contains(item.get('value'))
            ? item.set('disabled', true)
            : item
        )
      );

    return choices.set('group', outgroup);
  }

  handleSubmit(e) {
    e.preventDefault();

    this.props.createMachineFromFile(
      this.props.importedFile,
      this.props.currentMapping,
      this.props.currentProject
    );
  }

  renderButtons() {
    return (
      <div className="d-flex justify-content-between">
        <Loader loaded={!this.props.showLoader}>
          <Button type="submit" variant="primary">
            Importer
          </Button>
        </Loader>

        <Button variant="warning" onClick={this.props.resetImport}>
          Annuler et importer un nouveau fichier
        </Button>
      </div>
    );
  }

  render() {
    if (this.props.hasAlert) {
      return <div />;
    }

    return (
      <div>
        {this.props.importedFile ? (
          <form onSubmit={this.handleSubmit}>
            {this.props.importedFile.get('isBrokeImport') ? (
              <div>
                <h3>
                  Ce fichier est une mise à jour de fichier machine existante.
                </h3>

                <ButtonToolbar>
                  <Button variant="primary" type="submit">
                    Continuer
                  </Button>

                  <Button variant="secondary" onClick={this.props.resetImport}>
                    Annuler
                  </Button>
                </ButtonToolbar>
              </div>
            ) : (
              <div>
                <div style={{ marginBottom: 10 }}>{this.renderButtons()}</div>

                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Colonne</th>
                      <th>Ligne 1</th>
                      <th>Ligne 2</th>
                      <th>Associer à</th>
                    </tr>
                  </thead>

                  <tbody>
                    {this.props.importedFile.get('columns').map((column) => (
                      <tr key={column.get('column')}>
                        <td>{column.get('column')}</td>
                        <td>{column.get('lines').get('1')}</td>
                        <td>{column.get('lines').get('2')}</td>
                        <td>
                          <ImportSelect
                            choices={this.getChoices()}
                            itemID={column.get('column')}
                            handleMappingChange={this.props.handleMappingChange}
                            value={this.getDefaultValue(column.get('column'))}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

                {this.renderButtons()}
              </div>
            )}
          </form>
        ) : this.props.uploadingFiles ? (
          <div>
            <h2>Envoi de fichier...</h2>
            <ProgressBar animated now={100} />
          </div>
        ) : (
          <Dropzone onDrop={this.props.onMachineDrop}>
            <div>
              Glisser / déposer le fichier à importer ou cliquer pour ouvrir la
              fenêtre de sélection.
            </div>
          </Dropzone>
        )}
      </div>
    );
  }
}

MachineImport.propTypes = {
  onMachineDrop: PropTypes.func.isRequired,
  uploadingFiles: PropTypes.bool.isRequired,
  handleMappingChange: PropTypes.func.isRequired,
  currentMapping: PropTypes.object,
  hasAlert: PropTypes.bool.isRequired,
  showLoader: PropTypes.bool.isRequired,
  resetImport: PropTypes.func.isRequired,
  currentProject: PropTypes.instanceOf(Project),
  createMachineFromFile: PropTypes.func.isRequired,
  importedFile: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    hasAlert: !!state.app.get('alert'),
    uploadingFiles: !!state.app.get('uploadingFiles'),
    importedFile: state.app.get('importedFile'),
    currentProject: state.app.get('currentProject'),
    currentMapping: state.app.get('currentMapping'),
    showLoader: !!state.app.get('showLoader'),
  };
}

export const MachineImportContainer = connect(
  mapStateToProps,
  importActions
)(MachineImport);
