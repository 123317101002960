import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb } from 'react-bootstrap';
import Task from '../../../entity/Task';
import { LinkContainer } from 'react-router-bootstrap';
import { entityShortId, entityId } from '../../../idTools';
import { useHistory, Switch, Route, useRouteMatch } from 'react-router';
import TaskHeader from '../TaskHeader';
import SignTask from './SignTask';
import TaskInstallationForm from './TaskInstallationForm';
import {
  SIGN_STEPS,
  SIGN_TASK_PATH_INSTALLATION,
  SIGN_TASK_PATH_TECH,
  SIGN_TASK_PATH_CLIENT,
  SIGN_TASK_PATH_PER_MACHINE,
} from './SignTaskRoutes';
import TaskMachineInstallation from './TaskMachineInstallation';
import { useCustomInstallationFields } from './hooks';

function ActiveBreadcrumbItem({ path, label, taskId }) {
  const isActive = !!useRouteMatch(path);

  return (
    <LinkContainer to={path.replace(':taskId', taskId)}>
      <Breadcrumb.Item active={isActive}>{label}</Breadcrumb.Item>
    </LinkContainer>
  );
}
ActiveBreadcrumbItem.propTypes = {
  taskId: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

function SignTaskContainer({ task, onSign, onInstallationChange }) {
  const history = useHistory();
  const taskId = entityShortId(task);

  const [
    customInstallationFields,
    customInstallationFieldsLoaded,
  ] = useCustomInstallationFields(entityId(task.project));

  if (!customInstallationFieldsLoaded) {
    return null;
  }

  return (
    <>
      <Breadcrumb>
        {SIGN_STEPS.map(({ path, label }) => (
          <ActiveBreadcrumbItem
            key={path}
            path={path}
            label={label}
            taskId={taskId}
          />
        ))}
      </Breadcrumb>

      <TaskHeader task={task} hideUsers />

      <Switch>
        <Route exact path={SIGN_TASK_PATH_INSTALLATION}>
          <TaskInstallationForm
            task={task}
            customInstallationFields={customInstallationFields}
            onSubmit={(installation, path) => {
              onInstallationChange(installation);
              history.push(path.replace(':taskId', taskId));
            }}
          />
        </Route>
        <Route exact path={SIGN_TASK_PATH_PER_MACHINE}>
          <TaskMachineInstallation
            task={task}
            customInstallationFields={customInstallationFields}
            onSubmit={() => {
              history.push(SIGN_TASK_PATH_TECH.replace(':taskId', taskId));
            }}
          />
        </Route>
        <Route exact path={SIGN_TASK_PATH_TECH}>
          <SignTask
            key="tech"
            userType="tech"
            task={task}
            onSign={() =>
              history.push(SIGN_TASK_PATH_CLIENT.replace(':taskId', taskId))
            }
          />
        </Route>
        <Route exact path={SIGN_TASK_PATH_CLIENT}>
          <SignTask
            key="client"
            userType="client"
            task={task}
            onSign={onSign}
          />
        </Route>
      </Switch>
    </>
  );
}
SignTaskContainer.propTypes = {
  task: PropTypes.instanceOf(Task).isRequired,
  onSign: PropTypes.func.isRequired,
  onInstallationChange: PropTypes.func.isRequired,
};

export default SignTaskContainer;
