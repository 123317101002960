import React from 'react';
import PropTypes from 'prop-types';
import { parse } from 'query-string';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import {
  Alert,
  FormLabel,
  FormGroup,
  FormControl,
  Button,
  ButtonToolbar,
} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import * as userActions from '../../actions/user';
import { formControlFields } from './utils';

function validatePasswordForm(values) {
  const errors = {};

  if (values.plainPassword && values.plainPassword !== values.secondPassword) {
    errors.secondPassword = 'Les mots de passe ne sont pas identiques';
  }

  return errors;
}

class ChangePasswordForm extends React.PureComponent {
  render() {
    const { changePassword, initialValues, changeRequested } = this.props;

    return (
      <Form
        validate={validatePasswordForm}
        onSubmit={changePassword}
        initialValues={initialValues}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            {changeRequested && (
              <Alert variant="warning">
                Une demande de réinitialisation de mot de passe à été demandée
                par un administrateur
              </Alert>
            )}

            <h1>Changement de mot de passe</h1>

            <Field name="plainPassword">
              {({ input, meta }) => (
                <FormGroup>
                  <FormLabel>Nouveau mot de passe</FormLabel>
                  <FormControl type="password" {...formControlFields(input)} />
                  {meta.touched && meta.error && (
                    <Alert variant="danger">{meta.error}</Alert>
                  )}
                </FormGroup>
              )}
            </Field>

            <Field name="secondPassword">
              {({ input, meta }) => (
                <FormGroup>
                  <FormLabel>Retapez le mot de passe</FormLabel>
                  <FormControl type="password" {...formControlFields(input)} />
                  {meta.touched && meta.error && (
                    <Alert variant="danger">{meta.error}</Alert>
                  )}
                </FormGroup>
              )}
            </Field>

            {/* Roles */}
            <ButtonToolbar>
              <Button type="submit" variant="primary">
                Modifier
              </Button>
              <LinkContainer to="/">
                <Button variant="link">Annuler</Button>
              </LinkContainer>
            </ButtonToolbar>
          </form>
        )}
      </Form>
    );
  }
}

ChangePasswordForm.propTypes = {
  changeRequested: PropTypes.bool.isRequired,
  changePassword: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
  const currentUser = state.app.get('me');

  const initialValues = currentUser ? currentUser.toJS() : {};

  return {
    changeRequested:
      parseInt(parse(ownProps.location.search).changeRequested, 10) === 1,
    initialValues,
  };
}

export default connect(mapStateToProps, userActions)(ChangePasswordForm);
