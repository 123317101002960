import PropTypes from 'prop-types';
import React from 'react';
import {
  Navbar as BsNavBar,
  Nav,
  NavDropdown,
  Container,
} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { connect } from 'react-redux';
import { logout } from '../actions/login';
import { entityShortId } from '../idTools';
import MainLink from './navigation/MainLink';

class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.handleLogout = this.handleLogout.bind(this);
  }

  handleLogout() {
    this.props.logout();
  }

  toggleNavBar() {
    setTimeout(() => {
      window.dispatchEvent(new Event('toggleNavBar'));
    }, 350);
  }

  render() {
    return (
      <BsNavBar
        onToggle={this.toggleNavBar}
        className="mb-2"
        bg="primary"
        variant="dark"
      >
        <Container>
          <LinkContainer to="/" exact>
            <BsNavBar.Brand>OGIP</BsNavBar.Brand>
          </LinkContainer>
          <BsNavBar.Toggle aria-controls="basic-navbar-nav" />

          {this.props.me && (
            <BsNavBar.Collapse
              id="basic-navbar-nav"
              className="justify-content-between"
            >
              <Nav>
                {!this.props.me.isServiceProvider() && (
                  <MainLink to="/" exact eventKey={1}>
                    {this.props.me.isExternal()
                      ? 'Projets'
                      : 'Marques + Projets'}
                  </MainLink>
                )}
                {!this.props.me.isAdmin() && !this.props.me.isExternal() && (
                  <MainLink
                    to={`/planning/${entityShortId(this.props.me)}`}
                    eventKey={2}
                  >
                    Mon planning
                  </MainLink>
                )}

                {!this.props.me.isExternal() &&
                  !this.props.me.isServiceProvider() && (
                    <MainLink to="/planning/" eventKey={3}>
                      {this.props.me.isAdmin()
                        ? 'Planning'
                        : 'Planning général'}
                    </MainLink>
                  )}

                {!this.props.me.isExternal() &&
                  !this.props.me.isServiceProvider() && (
                    <MainLink to="/overtime" eventKey={4}>
                      Heures sup.
                    </MainLink>
                  )}
                {this.props.me.isAdmin() && (
                  <NavDropdown title="Admin" id="menu-admin">
                    <LinkContainer to="/machines/">
                      <NavDropdown.Item eventKey={5.1}>
                        Machines
                      </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/plan/">
                      <NavDropdown.Item eventKey={5.2}>
                        Planification
                      </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/appointment/">
                      <NavDropdown.Item eventKey={5.3}>
                        Prise de rendez-vous
                      </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/users/">
                      <NavDropdown.Item eventKey={5.4}>
                        Utilisateurs
                      </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/material-resources/">
                      <NavDropdown.Item eventKey={5.5}>
                        Ressources matérielles
                      </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/preparation/">
                      <NavDropdown.Item eventKey={5.6}>
                        Préparation atelier
                      </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/transfer/">
                      <NavDropdown.Item eventKey={5.7}>
                        Transfert de machines
                      </NavDropdown.Item>
                    </LinkContainer>
                  </NavDropdown>
                )}
              </Nav>
              <Nav>
                <NavDropdown
                  title={this.props.me.fullname}
                  id="menu-user"
                  alignRight
                >
                  <LinkContainer to="/change-password">
                    <NavDropdown.Item eventKey={6.1}>
                      Changer de mot de passe
                    </NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Item eventKey={6.2} onClick={this.handleLogout}>
                    Déconnexion
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>

              {/*
          <form
            className="navbar-form navbar-right"
            role="search"
          >
            <FormControl
              type="search"
              placeholder="Rechercher..."

            />
            <Button type="submit">
              Chercher
            </Button>
          </form>
          */}
            </BsNavBar.Collapse>
          )}
        </Container>
      </BsNavBar>
    );
  }
}

NavBar.propTypes = {
  me: PropTypes.object,
  logout: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    me: state.app.get('me'),
  };
}

export default connect(mapStateToProps, { logout })(NavBar);
