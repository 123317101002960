import projectApi from '../api/project';

export const FORM_RECEIVE_PROJECT_LIST = 'form/RECEIVE_PROJECT_LIST';

export function findAllProjects() {
  return (dispatch) =>
    projectApi.findAll({ fields: '@id,name,status' }).then((projectList) => {
      dispatch({
        type: FORM_RECEIVE_PROJECT_LIST,
        projectList: projectList.getMembers(),
      });
    });
}
