import { List, Map, Record } from 'immutable';
import { createEntity } from './factory';

const defaultValues = {
  '@id': null,
  '@type': null,
  email: null,
  firstname: null,
  lastname: null,
  fullname: null,
  team: null,
  showInTaskList: null,
  roles: List(),
  position: 0,
  isActive: false,
  needPasswordChange: false,
};

const defaultValuesNotInFields = {
  projectList: List(),
  brandList: List(),
  azureToken: Map(),
};

class User extends Record({ ...defaultValues, ...defaultValuesNotInFields }) {
  constructor(data) {
    const val = data;

    val.roles = val.roles && createEntity(val.roles);
    val.team = val.team && createEntity(val.team);
    val.projectList = val.projectList && createEntity(val.projectList);
    val.brandList = val.brandList && createEntity(val.brandList);
    val.azureToken = val.azureToken && createEntity(val.azureToken);

    return super(val);
  }

  isAdmin() {
    return this.roles.contains('ROLE_BROKE_ADMIN');
  }

  isExternal() {
    return this.roles.contains('ROLE_EXTERNAL');
  }

  isServiceProvider() {
    return this.roles.contains('ROLE_SERVICE_PROVIDER');
  }

  getTeamId() {
    return this.getIn(['team', '@id']) || this.get('team');
  }
}

export const USER_DEFAULT_FIELDS = Object.keys(defaultValues).join(',');
export const USER_DEFAULT_FIELDS_WITHOUT_TEAM = Object.keys(defaultValues)
  .filter((k) => k !== 'team')
  .join(',');

export default User;
