import React from 'react';
import { useParams, Route, Switch } from 'react-router';
import Loader from 'react-loader';
import { Nav, NavItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useProjectFetch } from '../../hooks/fetch';
import projectApi from '../../../api/project';
import CustomInstallationFieldsComponent from './CustomInstallationFields';
import InstallationTemplate from './InstallationTemplate';
import { entityShortId } from '../../../idTools';
import Notification from './Notification';

const FIELDS =
  '@id,customInstallationFields,installationTemplate,installationNotificationEmail';

function Installation() {
  const { projectId: projectShortId } = useParams();
  const [project, isLoading, setProject] = useProjectFetch(
    projectShortId,
    FIELDS
  );

  function onSubmitCustomInstallationFields(customInstallationFields) {
    projectApi
      .update(
        project.get('@id'),
        {
          customInstallationFields,
        },
        { fields: FIELDS }
      )
      .then(setProject);
  }

  function onChangeTemplate(installationTemplateId) {
    projectApi
      .update(
        project.get('@id'),
        {
          installationTemplate: installationTemplateId,
        },
        { fields: FIELDS }
      )
      .then(setProject);
  }

  function onChangeNotification(values) {
    projectApi
      .update(project.get('@id'), values, { fields: FIELDS })
      .then(setProject);
  }

  const shortId = project && entityShortId(project);

  return (
    <Loader loaded={!isLoading} parentClassName="loader loader--inline">
      {project && (
        <>
          <Nav variant="tabs">
            <NavItem>
              <LinkContainer exact to={`/projects/${shortId}/installation/`}>
                <Nav.Link>Champs personnalisés</Nav.Link>
              </LinkContainer>
            </NavItem>
            <NavItem>
              <LinkContainer
                exact
                to={`/projects/${shortId}/installation/template`}
              >
                <Nav.Link>Fichier template d&apos;intervention</Nav.Link>
              </LinkContainer>
            </NavItem>
            <NavItem>
              <LinkContainer
                exact
                to={`/projects/${shortId}/installation/notifications`}
              >
                <Nav.Link>Notifications</Nav.Link>
              </LinkContainer>
            </NavItem>
          </Nav>

          <Switch>
            <Route exact path="/projects/:projectId(\d+)/installation/">
              <CustomInstallationFieldsComponent
                project={project}
                onSubmit={onSubmitCustomInstallationFields}
              />
            </Route>
            <Route exact path="/projects/:projectId(\d+)/installation/template">
              <InstallationTemplate
                project={project}
                onChange={onChangeTemplate}
              />
            </Route>
            <Route
              exact
              path="/projects/:projectId(\d+)/installation/notifications"
            >
              <Notification project={project} onSubmit={onChangeNotification} />
            </Route>
          </Switch>
        </>
      )}
    </Loader>
  );
}

export default Installation;
