export default function MachineInstallDates({ machine }) {
  if (!machine.installAfter && !machine.installBefore) {
    return null;
  } else if (machine.installAfter && machine.installBefore) {
    return `${machine.installAfter.format(
      'll'
    )} → ${machine.installBefore.format('ll')}`;
  } else if (machine.installAfter) {
    return `Après le ${machine.installAfter.format('ll')}`;
  } else {
    return `Avant le ${machine.installBefore.format('ll')}`;
  }
}
