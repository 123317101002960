import React, { useReducer, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loader from 'react-loader';
import { dispatchErrorAction } from '../../actions/error';
import { currentUserIsServiceProviderSelector } from '../../selector';
import TaskDetail from './TaskDetail';
import {
  handleMachineListStatusChange,
  handleMachineListTransferStatusChange,
} from '../../actions/machine';
import taskApi from '../../api/task';
import history from '../../history';
import { Route, useParams, Switch } from 'react-router';
import SignTask from './SignTask';
import { SIGN_TASK_PATH } from './SignTask/SignTaskRoutes';
import { entityId, entityShortId } from '../../idTools';

export const INSTALLATION_FIELDS =
  '@id,techComment,customerComment,liftOperationNeeded,customData';
const SITE_FIELDS = '@id,formattedAddress,siteId'; // Need this because the API need one fields per entity. It's a bug
const FIELDS = `
  @id,type,status,startDate,endDate,comment,areMachinesTransfered,
  machineTransferStatus,areMachinesPrepared,machinePreparationStatus,
  isSignedByTech,isSignedByClient,installation{${INSTALLATION_FIELDS},techSignatory,clientSignatory},
  userList{
    @id,firstname,lastname
  },
  project{
    @id,name
  },
  machineList{
    @id,machineModel,machineSerialNumber,serviceName,serviceBuilding,
    serviceOffice,serviceFloor,status,transferStatus,siteContactFirstname,
    siteContactName,siteContactPhone,siteContactEmail,installation{${INSTALLATION_FIELDS}},
    site{${SITE_FIELDS}},hasInstallationFile
  },
  mainSite{${SITE_FIELDS}},
  materialResourceList{
    @id,name,description,foregroundColor,backgroundColor
  },
  fileList{@id,uploadedName,type}
`.replace(/\n\s*/g, '');

function reducer(state, action) {
  switch (action.type) {
    case 'REQUEST_CURRENT_TASK':
      return {
        isLoaded: false,
      };
    case 'RECEIVE_CURRENT_TASK':
      return {
        isLoaded: true,
        currentTask: action.task,
      };
    case 'TASK_INSTALLATION_CHANGED':
      return {
        ...state,
        currentTask: state.currentTask.set('installation', action.installation),
      };
    case 'UPDATE_MACHINE_STATUS': {
      return {
        ...state,
        currentTask: state.currentTask.update('machineList', (machineList) =>
          machineList.map((machine) =>
            machine.set(
              action.statusField,
              action.values[entityId(machine)] ?? machine.status
            )
          )
        ),
      };
    }
    default:
      throw new Error(`Unknown action ${action.type}`);
  }
}

function deleteTask(task, dispatchError) {
  taskApi
    .del(task.get('@id'))
    .then(() => {
      history.goBack();
    })
    .catch(dispatchError);
}

function TaskDetailPages({
  dispatchError,
  handleMachineListStatusChange,
  handleMachineListTransferStatusChange,
  ...props
}) {
  const { taskId } = useParams();
  const [state, dispatch] = useReducer(reducer, {
    currentTask: null,
    isLoaded: false,
  });

  const fetchTask = useCallback(() => {
    taskApi
      .find(taskId, {
        fields: FIELDS,
      })
      .then((task) => {
        dispatch({
          type: 'RECEIVE_CURRENT_TASK',
          task,
        });
      });
  }, [taskId]);

  useEffect(() => {
    dispatch({
      type: 'REQUEST_CURRENT_TASK',
    });
    fetchTask();
  }, [fetchTask]);

  const { currentTask, isLoaded } = state;
  if (!isLoaded) {
    return <Loader parentClassName="loader loader--inline" loaded={false} />;
  }

  const isSigned = currentTask.isSignedByTech && currentTask.isSignedByClient;

  return (
    <Switch>
      <Route exact path="/tasks/:taskId">
        <TaskDetail
          currentTask={currentTask}
          deleteTask={(id) => deleteTask(id, dispatchError)}
          dispatchError={dispatchError}
          isSigned={isSigned}
          handleMachineListStatusChange={(values) => {
            dispatch({
              type: 'UPDATE_MACHINE_STATUS',
              values,
              statusField: 'status',
            });
            handleMachineListStatusChange(values);
          }}
          handleMachineListTransferStatusChange={(values) => {
            dispatch({
              type: 'UPDATE_MACHINE_STATUS',
              values,
              statusField: 'transferStatus',
            });
            handleMachineListTransferStatusChange(values);
          }}
          {...props}
        />
      </Route>
      <Route path={SIGN_TASK_PATH}>
        <SignTask
          task={currentTask}
          onSign={() => {
            fetchTask();
            history.push(`/tasks/${entityShortId(currentTask)}`);
          }}
          onInstallationChange={(installation) =>
            dispatch({ type: 'TASK_INSTALLATION_CHANGED', installation })
          }
        />
      </Route>
    </Switch>
  );
}

TaskDetailPages.propTypes = {
  dispatchError: PropTypes.func.isRequired,
  handleMachineListStatusChange: PropTypes.func.isRequired,
  handleMachineListTransferStatusChange: PropTypes.func.isRequired,
};
function mapStateToProps(state) {
  return {
    isServiceProvider: currentUserIsServiceProviderSelector(state),
  };
}
export default connect(mapStateToProps, {
  handleMachineListStatusChange,
  handleMachineListTransferStatusChange,
  dispatchError: dispatchErrorAction,
})(TaskDetailPages);
