import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import React from 'react';
import { List } from 'immutable';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import {
  InfoWindow,
  GoogleMap,
  withGoogleMap,
  withScriptjs,
  Marker,
} from 'react-google-maps';
import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer';
import { MdEdit } from 'react-icons/md';
// import { triggerEvent } from 'react-google-maps/lib/utils';

class Map extends React.PureComponent {
  constructor(props) {
    super(props);

    this.selectMachine = this.selectMachine.bind(this);
    this.editAddress = this.editAddress.bind(this);
    this.handleMarkerClick = this.handleMarkerClick.bind(this);
    this.handleBoundsChange = this.handleBoundsChange.bind(this);
    this.isMarkerOpen = this.isMarkerOpen.bind(this);

    this.map = null;
  }

  componentDidUpdate() {
    const mapNode = ReactDOM.findDOMNode(this.map); //eslint-disable-line react/no-find-dom-node
    window.google.maps.event.trigger(mapNode, 'resize');
  }

  getStyles() {
    return {
      caption: {
        wordWrap: 'break-word',
      },
      img: {
        display: 'block',
        margin: 'auto',
      },
    };
  }

  selectMachine(id) {
    this.props.selectMachineMarker(id);
    this.props.closeMarker();
  }

  editAddress(id) {
    this.props.closeMarker();
    return this.props.editAddressForSite && this.props.editAddressForSite(id);
  }

  handleMarkerClick(marker) {
    this.props.handleMarkerClick(marker);
  }

  handleBoundsChange() {
    const bounds = this.map.getBounds();

    if (
      !this.props.bounds ||
      !this.props.bounds.object ||
      !this.props.bounds.object.equals(bounds)
    ) {
      const newBounds = {
        object: bounds,
        center: {
          lat: this.map.getCenter().lat(),
          lng: this.map.getCenter().lng(),
        },
        zoom: this.map.getZoom(),
        bounds: bounds.toUrlValue(),
      };

      clearTimeout(this.refreshTimeout);
      this.refreshTimeout = setTimeout(
        () => this.props.handleBoundsChange(newBounds),
        500
      );
    }
  }

  isMarkerOpen(marker) {
    return marker.get('id') === this.props.openedMarker;
  }

  render() {
    const styles = this.getStyles();

    return (
      <GoogleMap
        defaultZoom={this.props.defaultZoom}
        defaultCenter={this.props.defaultCenter}
        onBoundsChanged={this.handleBoundsChange}
        ref={(map) => {
          this.map = map;
        }}
      >
        <MarkerClusterer
          averageCenter
          gridSize={20}
          enableRetinaIcons
          styles={[
            {
              textColor: 'black',
              url: '/images/markercluster/m1.png',
              height: 52,
              width: 53,
            },
            {
              textColor: 'black',
              url: '/images/markercluster/m2.png',
              height: 55,
              width: 56,
            },
            {
              textColor: 'black',
              url: '/images/markercluster/m3.png',
              height: 65,
              width: 66,
            },
            {
              textColor: 'black',
              url: '/images/markercluster/m4.png',
              height: 77,
              width: 78,
            },
            {
              textColor: 'black',
              url: '/images/markercluster/m5.png',
              height: 89,
              width: 90,
            },
          ]}
        >
          {this.props.markers
            ? this.props.markers
                .filter((marker) => !!marker)
                .map((marker) => {
                  return (
                    <Marker
                      {...marker.toJS()}
                      key={`map_marker_${marker.get('id')}`}
                      onClick={() => this.handleMarkerClick(marker)}
                    >
                      {this.isMarkerOpen(marker) ? (
                        <InfoWindow
                          onCloseClick={() => this.props.closeMarker()}
                        >
                          <div>
                            <div style={styles.caption}>
                              <strong>
                                {marker.get('popup').get('caption')}
                              </strong>{' '}
                              <Button
                                variant="link"
                                onClick={() =>
                                  this.editAddress(marker.get('id'), marker)
                                }
                              >
                                <MdEdit aria-label="Modifier" />
                              </Button>
                            </div>
                            <div
                              style={styles.caption}
                              dangerouslySetInnerHTML={{
                                __html: marker.get('popup').get('description'),
                              }}
                            />
                            <div>
                              <Button
                                variant="link"
                                onClick={() =>
                                  this.selectMachine(marker.get('id'), marker)
                                }
                              >
                                Sélectionner les machines
                              </Button>
                            </div>
                          </div>
                        </InfoWindow>
                      ) : null}
                    </Marker>
                  );
                })
            : null}
        </MarkerClusterer>
      </GoogleMap>
    );
  }
}

Map.propTypes = {
  defaultCenter: PropTypes.object,
  markers: PropTypes.object,
  style: PropTypes.object,
  defaultZoom: PropTypes.number,
  handleBoundsChange: PropTypes.func.isRequired,
  selectMachineMarker: PropTypes.func.isRequired,
  closeMarker: PropTypes.func.isRequired,
  editAddressForSite: PropTypes.func.isRequired,
  handleMarkerClick: PropTypes.func.isRequired,
  bounds: PropTypes.shape({
    zoom: PropTypes.number.isRequired,
    center: PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
    }).isRequired,
    object: PropTypes.shape({
      north: PropTypes.number,
      west: PropTypes.number,
      south: PropTypes.number,
      east: PropTypes.number,
      equals: PropTypes.func.isRequired,
    }),
  }),
  openedMarker: PropTypes.string,
};

Map.defaultProps = {
  defaultCenter: { lat: 0, lng: 0 },
  style: {},
  defaultZoom: 3,
  markers: List(),
  openedMarker: null,
};

const MapHOC = withScriptjs(withGoogleMap((props) => <Map {...props} />));

const BindedMap = ({ height, ...rest }) => (
  <MapHOC
    loadingElement={<div style={{ height: '100%' }} />}
    containerElement={<div style={{ height }} />}
    mapElement={<div style={{ height: '100%' }} />}
    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBBtslS-0KImk89OS06Tw85QbXgncq6O3A"
    {...rest}
  />
);
BindedMap.propTypes = {
  height: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    openedMarker: state.app.get('openedMarker'),
  };
}
export default connect(mapStateToProps)(BindedMap);
