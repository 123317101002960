import React, { useEffect, useReducer } from 'react';
import { List } from 'immutable';
import projectApi from '../../api/project';

function findAllProject(dispatch) {
  dispatch({
    type: 'REQUEST_ALL_PROJECT',
  });

  projectApi
    .findAll({
      fields:
        '@id,@type,name,active,status,projectGroup{@id,name},brandList{@id,name}',
    })
    .then((projectList) => {
      dispatch({
        type: 'RECEIVE_ALL_PROJECT',
        projectList: projectList.getMembers(),
      });
    });
}

function reducer(state, action) {
  switch (action.type) {
    case 'REQUEST_ALL_PROJECT':
      return {
        projectList: List(),
        isLoaded: false,
      };
    case 'RECEIVE_ALL_PROJECT':
      return {
        projectList: action.projectList,
        isLoaded: true,
      };
    default:
      throw new Error(`Unknown action ${action.type}`);
  }
}

export function useHomeProject() {
  const [state, dispatch] = useReducer(reducer, {
    projectList: List(),
    isLoaded: true,
  });

  useEffect(() => {
    findAllProject(dispatch);
  }, []);

  return state;
}
