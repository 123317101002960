import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Form, Field } from 'react-final-form';
import {
  FormCheck,
  FormGroup,
  FormControl,
  FormLabel,
  FormText,
  Container,
  Col,
  Row,
} from 'react-bootstrap';
import { FormControlAdapter } from '../../react-bootstrap-final-form';

const required = (value) => (value ? undefined : 'Requis');

/* eslint-disable react/prop-types */
const InnerFormControlAdapter = ({ input, meta, ...rest }) => (
  <FormControl {...input} {...rest} />
);

const YesNoNaAdapter = ({ input, meta, title }) => {
  const handleChange = (event) => {
    input.onChange(event.target.value);
  };

  const values = {
    yes: 'Oui',
    no: 'Non',
    na: 'N/A',
  };

  return (
    <FormGroup>
      {Object.entries(values).map(([key, label]) => (
        <FormCheck
          type="radio"
          key={key}
          name={title}
          inline
          onChange={handleChange}
          checked={input.value === key}
          value={key}
          label={`${label} `}
          isValid={meta.touched && !meta.error}
          isInvalid={meta.touched && meta.error}
        />
      ))}
    </FormGroup>
  );
};

/* eslint-enable react/prop-types */

export function InstallationForm({
  onSubmit,
  initialValues,
  customInstallationFields,
  ActionElement,
  formId,
}) {
  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} id={formId}>
          <Container fluid>
            <Field
              label="Commentaire du technicien"
              name="techComment"
              component={FormControlAdapter}
              as="textarea"
            />

            <Field
              label="Commentaire du client"
              name="customerComment"
              component={FormControlAdapter}
              as="textarea"
            />

            <FormGroup>
              <Field name="liftOperationNeeded" type="checkbox">
                {({ input, meta }) => (
                  <FormCheck
                    type="checkbox"
                    {...input}
                    id="InstallationForm__liftOperationNeeded"
                    label="Une action de portage a-t-elle été nécessaire ? (à la main
                    ou avec diable électrique)"
                  />
                )}
              </Field>
              <FormText>
                (Equipement sur roulette ou poids nécessitant l’intervention de
                2 techniciens pour l‘accès en étage sans ascenseur)
              </FormText>
            </FormGroup>
          </Container>

          {customInstallationFields.map((field, index) => {
            const type = field.get('type');
            const title = field.get('title');

            if ('yes_no_na_comment' !== type) {
              throw new Error(`Unable to manage field of type ${type}`);
            }

            return (
              <Container fluid key={title}>
                <Row>
                  <Col sm={3}>
                    <FormLabel>{title}</FormLabel>
                    <Field
                      name={`customData[${index}].title`}
                      component="input"
                      type="hidden"
                      initialValue={title}
                    />
                    <Field
                      name={`customData[${index}].type`}
                      component="input"
                      type="hidden"
                      initialValue="yes_no_na"
                    />
                  </Col>
                  <Col sm={3}>
                    <Field
                      name={`customData[${index}].yes_no_na`}
                      component={YesNoNaAdapter}
                      title={title}
                      validate={required}
                    />
                  </Col>
                  <Col sm={6}>
                    <Field
                      name={`customData[${index}].comment`}
                      component={InnerFormControlAdapter}
                      as="input"
                    />
                  </Col>
                </Row>
              </Container>
            );
          })}

          {ActionElement}
        </form>
      )}
    </Form>
  );
}

InstallationForm.defaultProps = {
  initialValues: {},
  ActionElement: null,
  formId: undefined,
};

InstallationForm.propTypes = {
  customInstallationFields: ImmutablePropTypes.listOf(ImmutablePropTypes.map)
    .isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    techComment: PropTypes.string,
    customerComment: PropTypes.string,
    liftOperationNeeded: PropTypes.bool,
  }),
  ActionElement: PropTypes.element,
  formId: PropTypes.string,
};

export default InstallationForm;
