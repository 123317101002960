import { Map } from 'immutable';

export function formControlFields(field /*, { type } = {}*/) {
  return (
    Map(field)
      .filter((value, key) => {
        return ['checked', 'value', 'name', 'onChange', 'onBlur'].includes(key);
      })
      // .map((value, key) => {
      //   if (type === 'checkbox' && (key === 'checked' || key === 'value')) {
      //     return !!value;
      //   }

      //   return value;
      // })
      .toJS()
  );
}
