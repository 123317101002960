import React from 'react';
import { connect } from 'react-redux';
import urijs from 'urijs';
import config from '../config';
import * as onedriveActions from '../actions/onedrive';

class OnedriveCallback extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
    };
  }

  componentDidMount() {
    this.onAuthCallback();
  }

  onAuthCallback() {
    this.getAuthInfoFromUrl().then((authInfo) => {
      window.opener.onAuthenticated(authInfo, window);
    });
    // const token = authInfo.access_token;
    // const expiry = parseInt(authInfo.expires_in, 10);
    // setCookie(token, expiry);
  }

  getAuthInfoFromUrl() {
    if (urijs(window.location.href).hasQuery('code')) {
      const code = urijs.parseQuery(urijs.parse(window.location.href).query)
        .code;

      // const url = 'https://login.live.com/oauth20_token.srf';
      // const url = 'https://login.microsoftonline.com/common/oauth2/token';
      const url = `${config.api.url}/onedrive/oauth2/token`;

      // const params = {
      //   client_id: config.oneDrive.clientId,
      //   client_secret: config.oneDrive.oneDriveUnlimitedKey,
      //   // scope: encodeURIComponent(config.oneDrive.scopes),
      //   grant_type: 'authorization_code',
      //   redirect_uri: config.oneDrive.redirectUrl,
      //   code,
      //   resource: 'https://api.office.com/discovery/',
      //   // resource: 'https://brokesystemes-my.sharepoint.com/',
      // };

      const formData = new FormData();
      formData.append('client_id', config.oneDrive.clientId);
      formData.append('client_secret', config.oneDrive.oneDriveUnlimitedKey);
      formData.append('grant_type', 'authorization_code');
      // formData.append('redirect_uri', config.oneDrive.redirectUrl);
      formData.append('code', code);
      // formData.append('resource', 'https://api.office.com/discovery/');
      formData.append('resource', 'https://brokesystemes-my.sharepoint.com/');

      return fetch(url, {
        method: 'POST',
        body: formData,
      }).then((response) => response.json());
    }

    if (window.location.hash) {
      const authResponse = window.location.hash.substring(1);
      const authInfo = JSON.parse(
        `{"${authResponse.replace(/&/g, '","').replace(/=/g, '":"')}"}`,
        (key, value) => (key === '' ? value : decodeURIComponent(value))
      );

      return authInfo;
    }

    this.setState({
      error: "Erreur dans la recupération du jeton d'authentification",
    });
  }

  render() {
    if (this.state.error) {
      return <div>{this.state.error}</div>;
    }

    return <div>Connection sur onedrive en cours. Veuillez patienter...</div>;
  }
}

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps, onedriveActions)(OnedriveCallback);
