import PropTypes from 'prop-types';
import React from 'react';
import { is, List } from 'immutable';
import { connect } from 'react-redux';
import { Button, ButtonToolbar, Table } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import * as projectGroupActions from '../actions/projectGroup';

class ProjectGroupList extends React.PureComponent {
  constructor(props) {
    super(props);

    this.deleteProjectGroup = this.deleteProjectGroup.bind(this);
  }

  componentDidMount() {
    this.props.findAllProjectGroups();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.projectGroupList &&
      !is(prevProps.projectGroupList, this.props.projectGroupList)
    ) {
      this.props.findAllProjectGroups();
    }
  }

  getShortId(projectGroup) {
    return projectGroup.get('@id').replace('/v1/project_groups/', '');
  }

  getProjectGroupList() {
    return this.props.projectGroupList
      ? this.props.projectGroupList.get('hydra:member')
      : List();
  }

  deleteProjectGroup(projectGroup) {
    if (window.confirm(`Supprimer le groupe ${projectGroup.get('name')}`)) {
      return this.props.deleteProjectGroup(projectGroup);
    }
  }

  render() {
    return (
      <div>
        <h1>Groupes de projets</h1>
        <Table striped hover>
          <thead>
            <tr>
              <td>nom</td>
              <td>Actions</td>
            </tr>
          </thead>
          <tbody>
            {this.getProjectGroupList().map((projectGroup) => (
              <tr key={projectGroup.get('@id')}>
                <td>{projectGroup.get('name')}</td>
                <td>
                  <ButtonToolbar>
                    <LinkContainer
                      to={`/project-groups/${this.getShortId(
                        projectGroup
                      )}/edit`}
                    >
                      <Button variant="secondary" size="sm">
                        modifier
                      </Button>
                    </LinkContainer>
                    <Button
                      size="sm"
                      variant="danger"
                      onClick={() => this.deleteProjectGroup(projectGroup)}
                    >
                      supprimer
                    </Button>
                  </ButtonToolbar>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <h2>Actions</h2>
        <ButtonToolbar>
          <LinkContainer to="/project-groups/create">
            <Button variant="primary">+ Ajouter un groupe de projet</Button>
          </LinkContainer>
          <LinkContainer to={'/'}>
            <Button variant="link">Retour</Button>
          </LinkContainer>
        </ButtonToolbar>
      </div>
    );
  }
}

ProjectGroupList.propTypes = {
  findAllProjectGroups: PropTypes.func.isRequired,
  projectGroupList: PropTypes.object,
  deleteProjectGroup: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    projectGroupList: state.app.get('projectGroupList'),
  };
}

export default connect(mapStateToProps, projectGroupActions)(ProjectGroupList);
