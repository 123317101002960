import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, Table, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { entityShortId } from '../../idTools';
import ProjectEntity from '../../entity/Project';
import * as projectActions from '../../actions/project';
import Onedrive from '../Onedrive';
import ProjectContact from './Contacts';
import { currentUserIsAdminSelector } from '../../selector';
import { MdEdit } from 'react-icons/md';

function ProjectHomeHeader({ currentProject, isAdmin }) {
  return (
    <h1>
      {currentProject.get('name')}
      <small>
        &nbsp;
        {currentProject.get('brandList').map((brand, key) => {
          return (key > 0 ? ', ' : '') + brand.get('name');
        })}
      </small>{' '}
      {isAdmin && (
        <LinkContainer to={`/projects/${entityShortId(currentProject)}/edit`}>
          <Button size="sm" variant="secondary">
            <MdEdit aria-label="Modifier" />
          </Button>
        </LinkContainer>
      )}
    </h1>
  );
}

ProjectHomeHeader.propTypes = {
  currentProject: PropTypes.instanceOf(ProjectEntity).isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

function MachineSummary({ projectId, machineStatus }) {
  return (
    machineStatus && (
      <Table striped hover>
        <tbody>
          <tr>
            <th>Machines installées</th>
            <td>
              <Link to={`/projects/${projectId}/machines/`}>
                {machineStatus.get('done')}
                {' / '}
                {machineStatus.get('total')}
                {' totales'}
              </Link>
            </td>
          </tr>

          <tr>
            <th>Planifiées</th>
            <td>
              <Link to={`/projects/${projectId}/machines/?status=planned`}>
                {machineStatus.get('planned')}
                {' / '}
                {machineStatus.get('to_install')}
                {' non installées'}
              </Link>
            </td>
          </tr>

          <tr>
            <th>Reste à planifier</th>
            <td>
              <Link to={`/projects/${projectId}/plan/`}>
                {machineStatus.get('to_plan')}
                {' à planifier'}
              </Link>
            </td>
          </tr>

          <tr>
            <th>En attente</th>
            <td>
              <Link to={`/projects/${projectId}/machines/?status=waiting`}>
                {machineStatus.get('waiting')}
                {' en attente'}
              </Link>
            </td>
          </tr>
        </tbody>
      </Table>
    )
  );
}

MachineSummary.propTypes = {
  projectId: PropTypes.string.isRequired,
  machineStatus: PropTypes.shape({
    get: PropTypes.func.isRequired,
  }).isRequired,
};

export function ProjectHome({ isAdmin, currentProject, isOneDriveUser }) {
  if (!currentProject) {
    return null;
  }

  return (
    <Card>
      <Card.Header>
        <ProjectHomeHeader currentProject={currentProject} isAdmin={isAdmin} />
      </Card.Header>

      <Card.Header>Avancement du projet</Card.Header>
      <MachineSummary
        projectId={entityShortId(currentProject)}
        machineStatus={currentProject.get('machineStatus')}
      />

      <ProjectContact project={currentProject} isAdmin={isAdmin} />

      {isOneDriveUser && (
        <Card.Body>
          <Onedrive projectName={currentProject.get('name')} />
        </Card.Body>
      )}
    </Card>
  );
}

ProjectHome.defaultProps = {
  currentProject: null,
};
ProjectHome.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  isOneDriveUser: PropTypes.bool.isRequired,
  currentProject: PropTypes.instanceOf(ProjectEntity),
};

function mapStateToProps(state) {
  const me = state.app.get('me');

  return {
    currentProject: state.app.get('currentProject'),
    isAdmin: currentUserIsAdminSelector(state),
    isOneDriveUser: me ? me.azureToken.size > 0 : false,
  };
}

export const ProjectHomeContainer = connect(
  mapStateToProps,
  projectActions
)(ProjectHome);
