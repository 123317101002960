import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import Dropzone from 'react-dropzone';

const DROPZONE_STYLES = {
  width: '100%',
  height: '200px',
  borderWidth: '2px',
  borderColor: 'rgb(102, 102, 102)',
  borderStyle: 'dashed',
  borderRadius: '5px',
};

function AddFilesModal({ onHide, onDrop, ...rest }) {
  return (
    <Modal onHide={onHide} {...rest}>
      <Modal.Header closeButton>
        <Modal.Title>Ajouter des fichiers à la tâche</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Dropzone onDrop={onDrop} style={DROPZONE_STYLES}>
          <div>
            Glisser / déposer le fichier à importer ou cliquer pour ouvrir la
            fenêtre de sélection.
          </div>
        </Dropzone>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Annuler
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

AddFilesModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,
};

export default AddFilesModal;
