import 'whatwg-fetch';
import userClient from './user';
import localforage from 'localforage';
import urijs from 'urijs';
import config from '../config';

// const ONE_DRIVE_ROOT_URL = 'https://api.onedrive.com/v1.0/drive';
// const ONE_DRIVE_ROOT_URL = 'https://brokesystemes-my.sharepoint.com/_api/v2.0/drive';
const ONE_DRIVE_ROOT_URL = `https://brokesystemes-my.sharepoint.com/_api/v2.0/drives/${config.oneDrive.ogipDriveId}`;
const ONE_DRIVE_OGIP_ITEM_ID = config.oneDrive.ogipFolderId;

class Onedrive {
  fetchFolderByPath(path) {
    const url = urijs(
      `${ONE_DRIVE_ROOT_URL}/items/${ONE_DRIVE_OGIP_ITEM_ID}:${path}`
    );
    url.addSearch({ expand: 'children' });
    return this._fetchUrl(url);
  }

  fetchFolderById(id) {
    const append = id ? `/items/${id}` : '/root';
    const url = urijs(`${ONE_DRIVE_ROOT_URL}${append}`);
    url.addSearch({ expand: 'children' });
    return this._fetchUrl(url);
  }

  createFolderForParentId(name, parentId) {
    const url = urijs(`${ONE_DRIVE_ROOT_URL}/items/${parentId}/children`);
    return this._fetchUrl(url, {
      method: 'POST',
      body: JSON.stringify({ name, folder: {} }),
    });
  }

  createRootFolder(name) {
    const url = urijs(
      `${ONE_DRIVE_ROOT_URL}/items/${ONE_DRIVE_OGIP_ITEM_ID}/children`
    );
    return this._fetchUrl(url, {
      method: 'POST',
      body: JSON.stringify({ name, folder: {} }),
    });
  }

  setToken(token) {
    return localforage.setItem('onedrive.auth.token', token.toJS());
  }

  _fetchUrl(url, params) {
    return this._fetchWithToken(url, params);
  }

  _fetchWithToken(input, init, retryOnFailure = true) {
    let params = init;

    return this._getAccessToken().then((accessToken) => {
      const tokenHeaders = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      };

      if (params) {
        if (!params.headers) {
          params.headers = {};
        }

        params.headers = Object.assign(params.headers, tokenHeaders);
      } else {
        params = { headers: tokenHeaders };
      }

      return fetch(input, params)
        .then((response) => {
          if (response.status === 401) {
            throw new Error('Forbidden access: 401 found !', response);
          } else if (response.status === 403) {
            throw new Error('Forbidden access: 403 found !', response);
          }

          return response;
        })
        .catch((error) => {
          // seems like the token may be expired
          if (retryOnFailure === true) {
            return userClient
              .refreshToken()
              .then((user) => this.setToken(user.get('azureToken')))
              .then(() => this._fetchWithToken(input, init, false));
          } else {
            throw error;
          }
        });
    });
  }

  _getAccessToken() {
    return localforage
      .getItem('onedrive.auth.token')
      .then((token) => token && token.access_token);
  }
}

export default Onedrive;
