import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Loader from 'react-loader';
import { LinkContainer } from 'react-router-bootstrap';
import Project from '../../entity/Project';
import ProjectGroupEntity from '../../entity/ProjectGroup';
import { entityShortId } from '../../idTools';
import { useHomeProject } from './ProjectListFetch';

const ProjectLink = ({ project }) => (
  <LinkContainer to={`/projects/${entityShortId(project)}/`}>
    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
    <a>{project.get('name')}</a>
  </LinkContainer>
);
ProjectLink.propTypes = {
  project: PropTypes.instanceOf(Project).isRequired,
};

const ProjectGroup = ({ projectGroup, projectList }) => {
  if (projectGroup.get('@type') === 'Project') {
    return (
      <li>
        <ProjectLink project={projectGroup} />
      </li>
    );
  }

  if (projectList.size === 1) {
    return (
      <li>
        <ProjectLink project={projectList.get('0')} />
      </li>
    );
  }

  return (
    <li>
      {projectGroup && <div>{projectGroup.get('name')}</div>}

      <ul>
        {projectList.map((project) => (
          <li key={project.get('@id')}>
            <ProjectLink project={project} />
          </li>
        ))}
      </ul>
    </li>
  );
};
ProjectGroup.propTypes = {
  projectGroup: PropTypes.instanceOf(ProjectGroupEntity).isRequired,
  projectList: ImmutablePropTypes.listOf(PropTypes.instanceOf(Project))
    .isRequired,
};

export class ArchivedProjectList extends React.PureComponent {
  constructor(props) {
    super(props);

    this.getArchivedProjectList = this.getArchivedProjectList.bind(this);
    this.getProjectGoupList = this.getProjectGoupList.bind(this);
  }

  getArchivedProjectList() {
    return this.props.projectList.filter(
      (project) => project.get('status') === 'archived'
    );
  }

  getProjectGoupList(projectList) {
    return (
      projectList &&
      projectList.groupBy((project) => project.get('projectGroup') || project)
    );
  }

  render() {
    return (
      <Loader
        parentClassName="loader loader--inline"
        loaded={this.props.isLoaded}
      >
        <h2>Projets archivés</h2>
        <ul>
          {this.getProjectGoupList(this.getArchivedProjectList())
            .entrySeq()
            .map((entry) => {
              const projectGroup = entry[0];
              const projectList = entry[1];

              const key = projectGroup
                ? projectGroup.get('@id')
                : projectList.getIn(['0', '@id']);

              return (
                <ProjectGroup
                  key={key}
                  projectGroup={projectGroup}
                  projectList={projectList}
                />
              );
            })}
        </ul>
      </Loader>
    );
  }
}

ArchivedProjectList.propTypes = {
  isLoaded: PropTypes.bool.isRequired,
  projectList: ImmutablePropTypes.listOf(PropTypes.instanceOf(Project))
    .isRequired,
};

export function ArchivedProjectContainer(props) {
  const { projectList, isLoaded } = useHomeProject();
  return (
    <ArchivedProjectList
      projectList={projectList}
      isLoaded={isLoaded}
      {...props}
    />
  );
}
