import superagent from 'superagent';
import URI from 'urijs';
import config from '../config';

const ACCESS_TOKEN_KEY = 'broke.ogip.api.access_token';

class OauthClient {
  constructor(clientId, clientSecret, asyncStorage) {
    this._clientId = clientId;
    this._clientSecret = clientSecret;

    this.setAsyncStorage(asyncStorage);
    this.setOauthConfig(config.oauth);
  }

  setOauthConfig(localConfig) {
    this._oauthConfig = localConfig;

    return this;
  }

  setAsyncStorage(asyncStorage) {
    this._asyncStorage = asyncStorage;
  }

  hasAccessToken() {
    return this._asyncStorage
      .getItem(ACCESS_TOKEN_KEY)
      .then((accessToken) => !!accessToken);
  }

  getAccessToken() {
    return this._asyncStorage
      .getItem(ACCESS_TOKEN_KEY)
      .then((token) => token && JSON.parse(token).token);
  }

  logout() {
    return this._asyncStorage.removeItem(ACCESS_TOKEN_KEY);
  }

  // eslint-disable-next-line no-unused-vars
  getUserToken(username, password, scope) {
    const formData = new FormData();
    // formData.append('grant_type', 'password');
    formData.append('_username', username);
    formData.append('_password', password);
    // formData.append('scope', scope);

    return this._fetchOauth(formData);
  }

  refreshToken(scope) {
    return this._asyncStorage.getItem(ACCESS_TOKEN_KEY).then((data) => {
      const json = JSON.parse(data);
      const accessToken = json.token;
      const refreshToken = json.refresh_token;

      const formData = new FormData();
      formData.append('grant_type', 'refresh_token');
      formData.append('access_token', accessToken);
      formData.append('refresh_token', refreshToken);

      if (typeof scope !== 'undefined') {
        formData.append('scope', scope);
      }

      return this._fetchOauth(formData);
    });
  }

  _storeAccessToken(responseData) {
    return this._asyncStorage.setItem(
      ACCESS_TOKEN_KEY,
      JSON.stringify(responseData)
    );
  }

  _fetchOauth(formData) {
    // formData.append('client_id', this._clientId);
    // formData.append('client_secret', this._clientSecret);

    const uri = new URI(this._oauthConfig.url);

    const url = uri.toString();

    const fetch = new Promise((resolve, reject) =>
      superagent
        .post(url)
        .accept('json')
        .send(formData)
        .end((err, res) => {
          if (err) {
            reject(err);
          } else {
            resolve(JSON.parse(res.text));
          }
        })
    );

    return fetch.then((responseData) => {
      return this._storeAccessToken(responseData).then(() => responseData);
    });
  }
}

export default OauthClient;
