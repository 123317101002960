import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import columns from '../../projects/machines/Column';
import Machine from '../../../entity/Machine';
import EditSNModal from './EditSNModal';
import { MdCameraAlt } from 'react-icons/md';

const makeValueReadable = (value) => {
  if (moment.isMoment(value)) {
    return value.format('lll');
  }
  return value;
};

function Action({ machine, column }) {
  const [isEditSNModalOpened, setIsEditSNModalOpened] = useState(false);

  if (column === 'machineSerialNumber') {
    return (
      <>
        {isEditSNModalOpened && (
          <EditSNModal
            machine={machine}
            onCancel={() => setIsEditSNModalOpened(false)}
          />
        )}
        <Button
          variant="link"
          bsSize="small"
          onClick={() => setIsEditSNModalOpened(true)}
        >
          <MdCameraAlt />
        </Button>
      </>
    );
  }

  return null;
}
Action.propTypes = {
  machine: PropTypes.instanceOf(Machine).isRequired,
  column: PropTypes.string.isRequired,
};

export default function MachineDetailColumns({ machine }) {
  return (
    <Fragment>
      {columns.getIn(['groupOrder', 'machineDetail']).map((name) => {
        const group = columns.getIn(['group', name]);
        return (
          <div key={name}>
            <h3 className="MachineDetail__groupTitle">{name}</h3>

            {group.map((column) => {
              const value = machine.get(column.get('value'));
              if (value || name !== 'Options') {
                const readableValue = makeValueReadable(value);
                return (
                  <dl className="MachineDetail__dl" key={column}>
                    <dt className="MachineDetail__dt">{column.get('text')}</dt>
                    <dd className="MachineDetail__dd">
                      {readableValue || 'N/A'}

                      <Action machine={machine} column={column.get('value')} />
                    </dd>
                  </dl>
                );
              }
              return null;
            })}
          </div>
        );
      })}

      <h3>Commentaires</h3>
      <div>{machine.get('comment') || 'Pas de commentaires'}</div>
    </Fragment>
  );
}

MachineDetailColumns.propTypes = {
  machine: PropTypes.instanceOf(Machine).isRequired,
};
