import { createSelector } from 'reselect';

export const currentUserSelector = (state) => state.app.get('me');

export const currentUserIsAdminSelector = createSelector(
  currentUserSelector,
  (currentUser) => (currentUser ? currentUser.isAdmin() : false)
);

export const currentUserIsServiceProviderSelector = createSelector(
  currentUserSelector,
  (currentUser) => (currentUser ? currentUser.isServiceProvider() : false)
);
