import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { List } from 'immutable';
import Loader from 'react-loader';
import { Row, Col } from 'react-bootstrap';
import MachineTable from '../machine/MachineTable';
import PagedCollection from '../../entity/PagedCollection';
import { findMachinesToPrepare } from '../../actions/machine';
import TeamFilter from '../filters/TeamFilter';
import ProjectFilter from '../filters/ProjectFilter';

const hiddenColumns = ['contact', 'takeback'];

function FilterList() {
  return (
    <Row>
      <Col sm={6}>
        <TeamFilter />
      </Col>
      <Col sm={6}>
        <ProjectFilter />
      </Col>
    </Row>
  );
}

class Preparation extends PureComponent {
  componentDidMount() {
    this.props.findMachinesToPrepare();
  }

  componentDidUpdate(prevProps) {
    const { projectListFilter, teamListFilter } = this.props;

    if (
      prevProps.projectListFilter !== projectListFilter ||
      prevProps.teamListFilter !== teamListFilter
    ) {
      this.props.findMachinesToPrepare({
        project: projectListFilter.toArray().join(','),
        team: teamListFilter.toArray().join(','),
      });
    }
  }

  render() {
    return (
      <div>
        <h1>Préparation atelier</h1>

        <FilterList />

        <Loader loaded={!!this.props.machineList}>
          <MachineTable hideColumns={hiddenColumns} />
        </Loader>
      </div>
    );
  }
}
Preparation.propTypes = {
  machineList: PropTypes.instanceOf(PagedCollection),
  findMachinesToPrepare: PropTypes.func,
  projectListFilter: ImmutablePropTypes.listOf(PropTypes.string),
  teamListFilter: ImmutablePropTypes.listOf(PropTypes.string),
};
Preparation.defaultProps = {
  machineList: null,
  projectListFilter: List(),
  teamListFilter: List(),
};

const mapStateToProps = (state) => ({
  projectListFilter: state.app.getIn(['savedFilters', 'projectList']),
  teamListFilter: state.app.getIn(['savedFilters', 'teamList']),
  machineList: state.app.get('currentMachines'),
});
const mapDispatchToProps = {
  findMachinesToPrepare,
};

export default connect(mapStateToProps, mapDispatchToProps)(Preparation);
