import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import Loader from 'react-loader';
import Project from '../../entity/Project';
import PrettySelect from './PrettySelect';
import { findAllProjects } from '../../actions/form';

class ProjectSelect extends PureComponent {
  componentDidMount() {
    const { projectListFetchStatus, findAllProjects } = this.props;

    if (null === projectListFetchStatus) {
      findAllProjects();
    }
  }

  render() {
    const {
      projectList,
      inStatusList,
      projectListFetchStatus,
      ...rest
    } = this.props;

    return (
      <Loader
        parentClassName="loader loader--inline"
        loaded={'SUCCEEDED' === projectListFetchStatus}
      >
        <PrettySelect
          options={projectList
            .filter(
              (project) =>
                inStatusList.length === 0 ||
                inStatusList.indexOf(project.status) > -1
            )
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((project) => ({
              id: project.get('@id'),
              label: project.name,
            }))
            .toArray()}
          {...rest}
        />
      </Loader>
    );
  }
}

ProjectSelect.defaultProps = {
  inStatusList: [],
  projectListFetchStatus: null,
};

ProjectSelect.propTypes = {
  projectList: ImmutablePropTypes.listOf(
    PropTypes.instanceOf(Project).isRequired
  ).isRequired,
  projectListFetchStatus: PropTypes.string,
  inStatusList: PropTypes.arrayOf(PropTypes.string),
  findAllProjects: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const projectList = state.app.getIn(['form', 'select', 'projectList']);
  const projectListFetchStatus = state.app.getIn([
    'form',
    'select',
    'projectList-fetch-status',
  ]);

  return {
    projectList,
    projectListFetchStatus,
  };
}

export default connect(mapStateToProps, { findAllProjects })(ProjectSelect);
