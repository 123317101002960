import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import Loader from 'react-loader';
import mediaObjectClient from '../../api/mediaObject';
import config from '../../config';
import { Button } from 'react-bootstrap';
import { MdClear } from 'react-icons/md';

const DROPZONE_STYLES = {
  width: '100%',
  height: '200px',
  borderWidth: '2px',
  borderColor: 'rgb(102, 102, 102)',
  borderStyle: 'dashed',
  borderRadius: '5px',
};

function MediaObjectInput({ value, onChange, isMultiple }) {
  if (isMultiple) {
    throw new Error('multiple file upload is not managed for now');
  }

  const [currentContentUrl, setCurrentContentUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (value) {
      setIsLoading(true);
      mediaObjectClient
        .find(value, { fields: '@id,contentUrl' })
        .then((mediaObject) => {
          setCurrentContentUrl(mediaObject.get('contentUrl'));
          setIsLoading(false);
        });
    }
  }, [value]);

  const onDrop = (files) => {
    setIsLoading(true);
    const promiseList = files.map((file) =>
      mediaObjectClient.uploadFile(file, '@id,contentUrl')
    );

    Promise.all(promiseList).then((mediaObjectIdList) => {
      setIsLoading(false);
      if (isMultiple) {
        onChange(mediaObjectIdList.map((i) => i.get('@id')));
      } else {
        setCurrentContentUrl(mediaObjectIdList[0].get('contentUrl'));
        onChange(mediaObjectIdList[0].get('@id'));
      }
    });
  };

  return (
    <Loader parentClassName="loader loader--inline" loaded={!isLoading}>
      <input type="hidden" value={value} />

      {!currentContentUrl && (
        <Dropzone onDrop={onDrop} style={DROPZONE_STYLES}>
          <div>
            Glisser / déposer le fichier à importer ou cliquer pour ouvrir la
            fenêtre de sélection.
          </div>
        </Dropzone>
      )}
      {currentContentUrl && (
        <div>
          {currentContentUrl.match(/\.(png|jpg|jpeg|svg)$/) ? (
            <img
              alt=""
              src={`${config.api.url}${currentContentUrl}`}
              width="200"
            />
          ) : (
            <span>{currentContentUrl}</span>
          )}

          <Button
            variant="link"
            onClick={() => {
              onChange(null);
              setCurrentContentUrl(null);
            }}
          >
            <MdClear />
          </Button>
        </div>
      )}
    </Loader>
  );
}

MediaObjectInput.defaultProps = {
  isMultiple: false,
};
MediaObjectInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isMultiple: PropTypes.bool,
};

export default MediaObjectInput;
