import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Button, Col } from 'react-bootstrap';
import { MdViewModule } from 'react-icons/md';
import Project from '../../entity/Project';
import ProjectGroupEntity from '../../entity/ProjectGroup';
import ProjectButton from './ProjectButton';

export default function ProjectGroup({
  projectGroup,
  projectList,
  onOpenProjectGroup,
}) {
  if (projectGroup.get('@type') === 'Project') {
    return (
      <Col sm={3} style={{ paddingBottom: 10 }} key={projectGroup.get('@id')}>
        <ProjectButton project={projectGroup} />
      </Col>
    );
  } else if (projectList.size === 1) {
    return (
      <Col sm={3} style={{ paddingBottom: 10 }} key={projectGroup.get('@id')}>
        <ProjectButton project={projectList.get(0)} />
      </Col>
    );
  } else {
    return (
      <Col sm={3} style={{ paddingBottom: 10 }} key={projectGroup.get('@id')}>
        <div>
          <Button variant="secondary" block onClick={onOpenProjectGroup}>
            <MdViewModule /> {projectGroup.get('name') || 'Groupe de projets'}
          </Button>
        </div>
      </Col>
    );
  }
}

ProjectGroup.propTypes = {
  projectList: ImmutablePropTypes.listOf(PropTypes.instanceOf(Project))
    .isRequired,
  projectGroup: PropTypes.instanceOf(ProjectGroupEntity).isRequired,
  onOpenProjectGroup: PropTypes.func.isRequired,
};
