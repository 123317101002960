import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import {
  Button,
  FormText,
  FormGroup,
  FormLabel,
  FormControl,
} from 'react-bootstrap';
import Project from '../../../entity/Project';

const validateEmail = (value) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? null
    : 'Email invalide';

function Notification({ project, onSubmit }) {
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        installationNotificationEmail:
          project.installationNotificationEmail || '',
      }}
      render={({ handleSubmit, submitting, pristine }) => (
        <form onSubmit={handleSubmit}>
          <h2>Email à notifier lors de l&apos;édition d&apos;un PV</h2>
          <Field name="installationNotificationEmail" validate={validateEmail}>
            {({ input, meta }) => (
              <FormGroup>
                <FormLabel>Email</FormLabel>
                <FormControl
                  aria-describedby="emailHelpBlock"
                  type="email"
                  isValid={meta.touched && !meta.error}
                  isInvalid={meta.touched && meta.error}
                  {...input}
                />
                <FormControl.Feedback />

                {meta.touched && meta.error && (
                  <FormText id="emailHelpBlock" muted>
                    L&apos;adresse email n&apos;est pas valide.
                  </FormText>
                )}
              </FormGroup>
            )}
          </Field>

          <div className="text-right">
            <Button
              type="submit"
              variant="primary"
              disabled={submitting || pristine}
            >
              Enregistrer
            </Button>
          </div>
        </form>
      )}
    />
  );
}

Notification.propTypes = {
  project: PropTypes.instanceOf(Project).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default Notification;
