import React from 'react';
import { FormGroup, FormLabel, FormControl } from 'react-bootstrap';

/* eslint-disable react/prop-types */
export const FormControlAdapter = ({ input, meta, label, ...rest }) => (
  <FormGroup controlId={input.name}>
    <FormLabel>{label}</FormLabel>
    <FormControl
      isValid={meta.touched && !meta.error}
      isInvalid={meta.touched && meta.error}
      {...input}
      {...rest}
    />

    <FormControl.Feedback />
  </FormGroup>
);
