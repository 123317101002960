import { Record } from 'immutable';

class ProjectGroup extends Record({
  '@id': null,
  '@type': null,
  name: null,
  slug: null,
}) {
  constructor(data) {
    const val = data;

    return super(val);
  }
}

export default ProjectGroup;
