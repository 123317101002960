import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RouterPropTypes from '../../prop-types/router';
import { default as BasePlan } from '../map/Plan';
import PlanFilter from '../filters/PlanFilter';
import * as projectActions from '../../actions/project';
import { findMachinesForProjectWithBounds } from '../../actions/machine';
import * as planActions from '../../actions/plan';
import Project from '../../entity/Project';

export class Plan extends PureComponent {
  constructor(props) {
    super(props);

    this.fetchMachines = this.fetchMachines.bind(this);
    this.handleFilterSubmit = this.handleFilterSubmit.bind(this);

    this.state = {
      filterValues: null,
    };
  }

  componentDidMount() {
    this.fetchMachines();
  }

  componentDidUpdate(prevProps, prevState) {
    const { match, bounds, currentMachines, currentSites } = this.props;

    const oldProject = prevProps.match.params.projectId;
    const newProject = match.params.projectId;

    if (
      (newProject &&
        (oldProject !== newProject || !currentMachines || !currentSites)) ||
      bounds.bounds !== prevProps.bounds.bounds ||
      this.state.filterValues !== prevState.filterValues
    ) {
      this.fetchMachines();
    }
  }

  componentWillUnmount() {
    this.props.removeCurrentProjectMap();
  }

  handleFilterSubmit(values) {
    this.setState({
      filterValues: values,
    });

    this.fetchMachines(values);
  }

  fetchMachines() {
    const {
      currentProject,
      bounds,
      findMachinesForProjectWithBounds,
    } = this.props;

    if (currentProject && bounds) {
      findMachinesForProjectWithBounds(
        currentProject,
        bounds.bounds,
        this.state.filterValues
      );
    }
  }

  render() {
    return (
      <Fragment>
        <PlanFilter
          onlyFields={['machineStatus']}
          onSubmit={this.handleFilterSubmit}
        />
        <BasePlan {...this.props} />
      </Fragment>
    );
  }
}

Plan.propTypes = {
  bounds: PropTypes.object.isRequired,
  findMachinesForProjectWithBounds: PropTypes.func.isRequired,
  currentProject: PropTypes.instanceOf(Project),
  removeCurrentProjectMap: PropTypes.func.isRequired,
  currentSites: PropTypes.object,
  currentMachines: PropTypes.object,
  match: RouterPropTypes.params({
    projectId: PropTypes.string.isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    bounds: state.app.get('bounds'),
    currentProject: state.app.get('currentProject'),
    currentMachines: state.app.get('currentMachines'),
    currentSites: state.app.get('currentSitesWithMachineToPlan'),
    markers: state.app.get('markers'),
    unpositionnedSites: state.app.get('unpositionnedSites'),
    currentEditingSite: state.app.get('currentEditingSite'),
    selectedMachines: state.app.get('selectedMachines'),
  };
}

export const PlanContainer = connect(
  mapStateToProps,
  Object.assign({}, projectActions, planActions, {
    findMachinesForProjectWithBounds,
  })
)(Plan);
