/* global google */
import { fromJS } from 'immutable';
import siteApi from '../api/site';

export function selectMachineMarker(latLng) {
  return {
    type: 'SELECT_MACHINE_FOR_SITE',
    latLng,
  };
}

export function handleMarkerClick(marker) {
  return {
    type: 'HANDLE_MARKER_CLICK',
    marker,
  };
}

export function handleBoundsChange(bounds) {
  return {
    type: 'HANDLE_BOUNDS_CHANGE',
    bounds,
  };
}

export function closeMarker() {
  return {
    type: 'CLOSE_MARKER',
  };
}

export function removeCurrentProjectMap() {
  return {
    type: 'REMOVE_CURRENT_PROJECT_MAP',
  };
}

export function geolocate(initialSite, address) {
  return (dispatch) => {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address }, (results, status) => {
      let newSite;
      if (status === google.maps.GeocoderStatus.OK) {
        const location = results[0].geometry.location;
        newSite = initialSite
          .set('formattedAddress', address)
          .set('latitude', location.lat())
          .set('longitude', location.lng())
          .set('geolocationStatus', null);
      } else {
        newSite = initialSite.set('geolocationStatus', status);

        dispatch({
          type: 'ALERT',
          style: 'danger',
          message: `Impossible de géocoder l'adresse "${address}"
              pour la raison suivante: ${status}`,
          dismissAfter: 5000,
        });
      }

      siteApi
        .update(newSite.get('@id'), newSite)
        .then((site) => {
          dispatch({
            type: 'SITE_GEOLOCATED',
            site: fromJS(site),
          });
        })
        .catch(() => {
          dispatch({
            type: 'ALERT',
            style: 'danger',
            message: "Erreur lors de la mise à jour de l'adresse du site",
            dismissAfter: 2000,
          });
        });
    });
  };
}

export function editAddressForSite(id) {
  return {
    type: 'DISPLAY_EDIT_ADDRESS',
    siteId: id,
  };
}

export function removeMachineFromSelected(machine) {
  return {
    type: 'REMOVE_MACHINE_FROM_SELECTED',
    machine,
  };
}

export function removeAllSelectedMachines() {
  return {
    type: 'REMOVE_ALL_SELECTED_MACHINES',
  };
}
