import { fromJS } from 'immutable';
import machineApi from '../api/machine';
import fileApi from '../api/file';
import history from '../history';
import { entityShortId } from '../idTools';

function onMachineImported(file, importedFile) {
  return {
    type: 'MACHINES_IMPORTED',
    file,
    importedFile,
  };
}

function onImportFileError(dispatch, message) {
  dispatch({
    type: 'ALERT',
    message,
    style: 'danger',
  });

  dispatch({
    type: 'RESET_IMPORT',
  });
}

export function onMachineDrop(files) {
  return (dispatch) => {
    dispatch({
      type: 'MACHINES_DROP_FILES',
      files,
    });

    files.forEach((file) =>
      machineApi
        .uploadFile(file)
        .then((importedFile) => dispatch(onMachineImported(file, importedFile)))
        .catch(() => {
          onImportFileError(
            dispatch,
            "Oops ! Une erreur est survenue lors de l'import du fichier"
          );
        })
    );
  };
}

export function resetImport() {
  return {
    type: 'RESET_IMPORT',
  };
}

export function createMachineFromFile(initialFile, mapping, project) {
  return (dispatch) => {
    const newFile = initialFile.set('mapping', mapping);

    dispatch({
      type: 'IMPORTING_FILE',
    });

    fileApi.update(newFile.toJS()).then((file) => {
      machineApi
        .createFromFile(fromJS(file), project)
        .then(() => {
          dispatch({
            type: 'SET_LAST_IMPORTED_FILE',
            file: fromJS(file),
          });
          dispatch({
            type: 'RESET_IMPORT',
          });

          history.push(`/projects/${entityShortId(project)}/machines/`);
        })
        .catch((e) => {
          const response = e.response;
          const text = response && response.text;
          const json = text && JSON.parse(text);
          const message =
            json && json.error
              ? json.error
              : 'Une erreur est survenue lors de la création des machines';
          onImportFileError(dispatch, message);
        });
    });
  };
}

export function handleMappingChange(mapping) {
  return {
    type: 'MACHINE_IMPORT_ADD_TO_MAPPING',
    mapping,
  };
}

export function dismissError() {
  return {
    type: 'DISMISS_ERROR',
  };
}
