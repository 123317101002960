import superagentIntercept from 'superagent-intercept';
import urijs from 'urijs';

export default superagentIntercept((err, res) => {
  //eslint-disable-next-line no-undef
  if (!process.env.REACT_APP_LOG_CALL_WITHOUT_FIELDS) {
    return;
  }

  if (!res || !res.req) {
    return;
  }

  const { method, url } = res.req;

  if (!['GET', 'PUT', 'POST'].includes(method)) {
    return;
  }

  const search = urijs(url).search();

  if (search.includes('?fields=') || search.includes('&fields=')) {
    return;
  }

  // eslint-disable-next-line no-console
  console.error(
    `You should use "fields=" query parameter for the "${method}" request to "${url}"`
  );
});
