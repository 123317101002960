import config from '../config';
import AbstractClient from './abstractClient';

class TeamClient extends AbstractClient {
  getPathBase() {
    return `${config.api.version}/teams`;
  }
}

export default new TeamClient();
