import { List, Map, Record } from 'immutable';
import moment from 'moment';
import { createEntity } from './factory';

const defaultValues = {
  '@id': null,
  '@type': null,
  status: null,
  preparationStatus: null,
  transferStatus: null,
  machineSerialNumber: null,
  machineMAC: null,
  machinePN: null,
  machineModel: null,
  partnerId: null,
  orderNumber: null,
  serviceName: null,
  serviceBuilding: null,
  serviceFloor: null,
  serviceOffice: null,
  serviceMapID: null,
  serviceLift: null,
  locationStorageRoom: null,
  siteContactFirstname: null,
  siteContactName: null,
  siteContactPhone: null,
  siteContactEmail: null,
  itContactFirstname: null,
  itContactName: null,
  itContactPhone: null,
  itContactEmail: null,
  contact2Firstname: null,
  contact2Name: null,
  contact2Phone: null,
  contact2Email: null,
  contact3Firstname: null,
  contact3Name: null,
  contact3Phone: null,
  contact3Email: null,
  project: Map(),
  site: Map(),
  importedFile: null,
  techinfoIP: null,
  techinfoMask: null,
  techinfoBridge: null,
  techinfoHostname: null,
  techinfoFax: null,
  techinfoDomain: null,
  techinfoDNS1: null,
  techinfoDNS2: null,
  techinfoSMTP: null,
  techinfoResponseAddress: null,
  techinfoSMTPUsername: null,
  techinfoSMTPPassword: null,
  techinfoUsername: null,
  techinfoPassword: null,
  techinfoUNCorFTP: null,
  takebackType: null,
  takebackSN: null,
  takebackHostname: null,
  locationClientName: null,
  option1: null,
  option2: null,
  option3: null,
  option4: null,
  option5: null,
  task: null,
  comment: null,
  actionName: null,
  option1Qtt: null,
  option1Designation: null,
  option2Qtt: null,
  option2Designation: null,
  option3Qtt: null,
  option3Designation: null,
  option4Qtt: null,
  option4Designation: null,
  option5Qtt: null,
  option5Designation: null,
  schedule: null,
  desiredDate: null,
  deadline: null,
  observation: null,
  custom1: null,
  custom2: null,
  custom3: null,
  custom4: null,
  custom5: null,
  custom6: null,
  custom7: null,
  custom8: null,
  custom9: null,
  custom10: null,
  installAfter: null,
  installBefore: null,
  installation: null,
  hasInstallationFile: null,
};

const defaultValuesNotInFields = {
  logList: null,
  isPVAvailable: null,
};

class Machine extends Record({
  ...defaultValues,
  ...defaultValuesNotInFields,
}) {
  constructor(data) {
    const val = data;

    val.task = val.task && createEntity(val.task);
    val.site = val.site && createEntity(val.site);
    val.project = val.project && createEntity(val.project);
    val.installAfter = val.installAfter ? moment(val.installAfter) : null;
    val.installBefore = val.installBefore ? moment(val.installBefore) : null;
    val.logList = val.logList ? val.logList.map(createEntity) : null;
    if (Array.isArray(val.logList)) {
      val.logList = List(val.logList);
    }
    val.installation = createEntity(val.installation);

    return super(val);
  }
}

export const MACHINE_DEFAULT_FIELDS = Object.keys(defaultValues)
  .concat([
    'site{siteId,code,addressLine1,addressLine2,zipcode,city,state,country}',
  ])
  .join(',');

export const MACHINE_FIELD_FOR_TABLE_AND_PLAN =
  '@id,isPVAvailable,machineModel,machinePN,machineSerialNumber,serviceName,serviceBuilding,serviceFloor,serviceOffice,siteContactFirstname,siteContactName,siteContactPhone,siteContactEmail,comment,takebackType,takebackSN,status,preparationStatus,installAfter,installBefore,project{@id,name},task{@id,status,startDate,userList{fullname}},site{@id,siteId,formattedAddress,latitude,longitude,nbMachineToPlan}';

export default Machine;
