import { List, Record } from 'immutable';
import moment from 'moment';
import { createEntity } from './factory';
import User from './User';

class Task extends Record({
  '@id': null,
  '@type': null,
  type: null,
  machineList: List(),
  project: Record(),
  createdAt: null,
  updatedAt: null,
  userList: null,
  startDate: null,
  endDate: null,
  materialResourceList: List(),
  status: null,
  machinePreparationStatus: null,
  machineTransferStatus: null,
  mainSite: Record(),
  comment: null,
  planningComment: null,
  position: 0,
  fileList: List(),
  areMachinesPrepared: null,
  areMachinesTransfered: null,
  isSignedByClient: null,
  isSignedByTech: null,
  installation: null,
}) {
  constructor(data) {
    const val = data;

    if (val) {
      val.userList =
        val.userList && List(val.userList).map((user) => new User(user));
      val.machineList = Array.isArray(val.machineList)
        ? List(val.machineList.map(createEntity))
        : List();
      val.materialResourceList = createEntity(val.materialResourceList);
      val.fileList = createEntity(val.fileList);
      val.mainSite = createEntity(val.mainSite);
      val.project = createEntity(val.project);
      val.installation = createEntity(val.installation);

      val.startDate = val.startDate && moment(val.startDate);
      val.endDate = val.endDate && moment(val.endDate);
    }

    return super(val);
  }

  getVehicle() {
    return this.get('materialResourceList').find(
      (materialResource) => materialResource.get('type') === 'vehicle'
    );
  }

  getSiteIds() {
    // fields for this method is `machineList{site{siteId}}`
    const siteSet = this.machineList
      .map((machine) => machine.getIn(['site', 'siteId']))
      .toSet();

    if (siteSet.size > 0) {
      return siteSet.reduce((prev, value) => `${prev} | ${value}`);
    }

    return null;
  }

  getShortTitle() {
    // fields for this method is `project{name},machine{site{siteId}},mainSite{city}`
    const projectName =
      this.project &&
      this.project.get('name') &&
      this.project.get('name').toUpperCase();

    const siteIds = this.getSiteIds() || '';
    const city = this.mainSite ? this.mainSite.get('city') : '';

    if (projectName || city || siteIds) {
      return `${projectName || ''} ${city} ${siteIds}`;
    }

    return 'Tâche sans nom';
  }

  getNbMachinesToTransfer() {
    return this.machineList.filter(
      (m) => m.get('transferStatus') === 'to_transfer'
    ).size;
  }

  isTransfer() {
    return this.type === 'transfer';
  }

  areAllMachinesWithInstallationFiles() {
    return this.machineList.every((machine) => machine.hasInstallationFile);
  }
}

export default Task;
