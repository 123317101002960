import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { List } from 'immutable';
import { connect } from 'react-redux';
import { Button, ButtonGroup, Table } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { findAllBrand } from '../../actions/home';
import { deleteBrand } from '../../actions/brand';
import { entityShortId } from '../../idTools';
import config from '../../config';

class BrandList extends React.PureComponent {
  constructor(props) {
    super(props);

    this.deleteBrand = this.deleteBrand.bind(this);
  }

  componentDidMount() {
    this.props.findAllBrand();
  }

  deleteBrand(brand) {
    if (window.confirm(`Supprimer la marque "${brand.get('name')}" ?`)) {
      return this.props.deleteBrand(brand);
    }
  }

  render() {
    const { brandList } = this.props;

    return (
      <div>
        <h2>Marques</h2>
        <div>
          <Table striped hover>
            <tbody>
              {brandList.map((brand) => (
                <tr key={brand.get('@id')}>
                  <td>{brand.get('@id')}</td>
                  <td>
                    {brand.getIn(['logo', 'contentUrl']) && (
                      <img
                        src={`${config.api.url}${brand.getIn([
                          'logo',
                          'contentUrl',
                        ])}`}
                        alt=""
                        style={{ height: '1em' }}
                      />
                    )}{' '}
                    {brand.get('name')}
                  </td>
                  <td>
                    <ButtonGroup>
                      <LinkContainer
                        to={`/brands/${entityShortId(brand)}/edit`}
                      >
                        <Button variant="secondary" size="sm">
                          modifier
                        </Button>
                      </LinkContainer>
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => this.deleteBrand(brand)}
                      >
                        supprimer
                      </Button>
                    </ButtonGroup>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <h2>Actions</h2>
          <LinkContainer to="/brands/create">
            <Button variant="primary">+ Ajouter une marque</Button>
          </LinkContainer>
          <LinkContainer to="/">
            <Button variant="link">Retour</Button>
          </LinkContainer>
        </div>
      </div>
    );
  }
}
BrandList.propTypes = {
  findAllBrand: PropTypes.func.isRequired,
  deleteBrand: PropTypes.func.isRequired,
  brandList: ImmutablePropTypes.listOf(
    ImmutablePropTypes.mapContains({
      '@id': PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
};

function mapStateToProps(state) {
  const brandList = state.app.get('brandList');
  return {
    brandList: brandList ? brandList.get('hydra:member') : List(),
  };
}

const BrandListContainer = connect(mapStateToProps, {
  findAllBrand,
  deleteBrand,
})(BrandList);

export default BrandListContainer;
