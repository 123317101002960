import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormCheck } from 'react-bootstrap';
import { findHiddenUsers, setAllUserFilter } from '../../actions/user';

class AllUserFilter extends PureComponent {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.findHiddenUsers = this.findHiddenUsers.bind(this);
  }

  componentDidMount() {
    this.findHiddenUsers();
  }

  componentDidUpdate() {
    this.findHiddenUsers();
  }

  handleChange(event) {
    this.props.setAllUserFilter(event.target.checked);

    if (this.props.onChange) {
      this.props.onChange(event);
    }
  }

  findHiddenUsers() {
    const { doFetch, checked, hiddenUserList, findHiddenUsers } = this.props;
    if (doFetch === true && checked && hiddenUserList === null) {
      findHiddenUsers();
    }
  }

  render() {
    return (
      <FormCheck
        type="checkbox"
        checked={this.props.checked}
        onChange={this.handleChange}
        id="AllUserFilter__checkbox"
        label="Afficher tous les utilisateurs"
      />
    );
  }
}

AllUserFilter.defaultProps = {
  hiddenUserList: null,
  onChange: null,
  doFetch: true,
};

AllUserFilter.propTypes = {
  findHiddenUsers: PropTypes.func.isRequired,
  hiddenUserList: PropTypes.object,
  onChange: PropTypes.func,
  setAllUserFilter: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  doFetch: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  checked: state.app.getIn(['savedFilters', 'allUserFilter']) || false,
  hiddenUserList: state.app.getIn(['hiddenUserList', 'hydra:member']),
});

const mapDispatchToProps = {
  findHiddenUsers,
  setAllUserFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(AllUserFilter);
