import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { useDispatch } from 'react-redux';
import { Alert, Button, Container, Modal } from 'react-bootstrap';
import Task from '../../../entity/Task';
import { entityShortId, entityId } from '../../../idTools';
import InstallationForm from './InstallationForm';
import installationApi from '../../../api/installation';
import { dispatchError } from '../../../actions/error';
import SignTaskMachinesDetail from './SignTaskMachinesDetail';
import {
  SIGN_TASK_PATH_PER_MACHINE,
  SIGN_TASK_PATH_TECH,
} from './SignTaskRoutes';
import { Link } from 'react-router-dom';
import { INSTALLATION_FIELDS } from '../TaskDetailPages';

function RoutingModal({ show, onHide, onSubmit }) {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Personnaliser les machines ?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Vous pouvez si besoin personnaliser les machines, soit valider le
        rapport pour toutes les machines d&apos;un coup et aller directement à
        la signature.
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => onSubmit(SIGN_TASK_PATH_PER_MACHINE)}
        >
          Personnaliser les machines
        </Button>
        <Button
          variant="secondary"
          onClick={() => onSubmit(SIGN_TASK_PATH_TECH)}
        >
          Aller directement à la signature
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
RoutingModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default function TaskInstallationForm({
  task,
  onSubmit,
  customInstallationFields,
}) {
  const dispatch = useDispatch();
  const [isRoutingModalVisible, setIsRoutingModalVisible] = useState(false);
  const [newInstallation, setNewInstallation] = useState(null);

  async function handleSubmit(values) {
    try {
      const queryParams = { fields: INSTALLATION_FIELDS };
      const newValue = { ...values, task: task.get('@id') };

      let innerNewInstallation;
      if (task.installation) {
        innerNewInstallation = await installationApi.update(
          entityId(task.installation),
          newValue,
          queryParams
        );
      } else {
        innerNewInstallation = await installationApi.create(
          newValue,
          queryParams
        );
      }

      setNewInstallation(innerNewInstallation);

      setIsRoutingModalVisible(true);
    } catch (err) {
      dispatchError(err, dispatch);
      console.error(err);
    }
  }

  const { installation } = task;

  return (
    <>
      <RoutingModal
        show={isRoutingModalVisible}
        onHide={() => setIsRoutingModalVisible(false)}
        onSubmit={(path) => {
          setIsRoutingModalVisible(false);
          onSubmit(newInstallation, path);
        }}
      />

      <SignTaskMachinesDetail task={task} />

      <Container fluid>
        <Alert variant="info">
          Le formulaire ci-dessous permet de renseigner les même informations
          pour toutes les machines. Vous pourrez ensuite surcharger si besoin
          ces informations machine par machine.
          <br />
          Les informations de chaque machines seront pré-renseignées avec ces
          informations
        </Alert>
      </Container>

      <InstallationForm
        customInstallationFields={customInstallationFields}
        onSubmit={handleSubmit}
        initialValues={installation ? installation.toJS() : undefined}
        ActionElement={
          <>
            <hr />

            <Container fluid>
              <div className="d-flex justify-content-end">
                <Link
                  className="btn btn-link"
                  to={`/tasks/${entityShortId(task)}`}
                >
                  Retour
                </Link>
                <Button variant="primary" type="submit">
                  Valider
                </Button>
              </div>
            </Container>
          </>
        }
      />
    </>
  );
}

TaskInstallationForm.propTypes = {
  task: PropTypes.instanceOf(Task).isRequired,
  customInstallationFields: ImmutablePropTypes.listOf(ImmutablePropTypes.map)
    .isRequired,
  onSubmit: PropTypes.func.isRequired,
};
