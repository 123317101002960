import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NavBar from './NavBar';
import { findMe } from '../actions/user';
import AlertContainer from './AlertContainer';
import PassedTaskWarning from './task/PassedTaskWarning';
import history from '../history';

class App extends React.PureComponent {
  componentDidMount() {
    this.props.findMe();
  }

  componentDidUpdate() {
    if (
      this.props.needPasswordChange &&
      this.props.location.pathname !== '/change-password'
    ) {
      this.props.locationPush('/change-password?changeRequested=1');
    }
  }

  render() {
    const { location } = this.props;

    return (
      <div>
        <NavBar location={location} />

        <PassedTaskWarning />

        <AlertContainer />
      </div>
    );
  }
}

App.defaultProps = {
  needPasswordChange: false,
};

App.propTypes = {
  needPasswordChange: PropTypes.bool,
  location: PropTypes.object.isRequired,
  findMe: PropTypes.func.isRequired,
  locationPush: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    needPasswordChange:
      state.app.get('me') && state.app.get('me').needPasswordChange,
    alert: state.app.get('alert'),
  };
}

export const AppContainer = connect(mapStateToProps, {
  findMe,
  locationPush: history.push,
})(App);
