import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  FormGroup,
  FormLabel,
  FormControl,
  InputGroup,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import Machine from '../../../entity/Machine';
import { getText } from '../../projects/machines/Column';
import { updateMachine } from '../../../actions/machine';
import BarcodeScanner from '../../BarcodeScanner';
import { MdCameraAlt } from 'react-icons/md';

function EditSNFormWitRef({ machineSerialNumber, onSubmit, formRef }) {
  const [isCameraModalOpened, setIsCameraModalOpened] = useState(false);
  const [localSN, setLocalSN] = useState(null);

  const closeCameraModal = () => setIsCameraModalOpened(false);

  return (
    <>
      <Modal show={isCameraModalOpened} onHide={closeCameraModal}>
        <Modal.Body>
          <BarcodeScanner
            onDetected={(value) => {
              closeCameraModal();
              setLocalSN(value);
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeCameraModal}>
            Annuler le scan
          </Button>
        </Modal.Footer>
      </Modal>
      <Form
        onSubmit={onSubmit}
        initialValues={{ machineSerialNumber: localSN || machineSerialNumber }}
      >
        {({ handleSubmit }) => (
          <form ref={formRef} onSubmit={handleSubmit}>
            <Field name="machineSerialNumber">
              {({ input }) => (
                <FormGroup>
                  <FormLabel>{getText('machineSerialNumber')}</FormLabel>

                  <InputGroup>
                    <FormControl autoFocus type="text" {...input} />
                    <InputGroup.Prepend>
                      <Button
                        variant="secondary"
                        onClick={() => setIsCameraModalOpened(true)}
                      >
                        <MdCameraAlt />
                      </Button>
                    </InputGroup.Prepend>
                  </InputGroup>
                </FormGroup>
              )}
            </Field>
          </form>
        )}
      </Form>
    </>
  );
}

EditSNFormWitRef.defaultProps = { machineSerialNumber: '' };
EditSNFormWitRef.propTypes = {
  machineSerialNumber: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  formRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }).isRequired,
};

function forwardRef(props, ref) {
  return <EditSNFormWitRef {...props} formRef={ref} />;
}
forwardRef.displayName = `formWithRef(EditSNFormWitRef)`;
const EditSNForm = React.forwardRef(forwardRef);

function EditSNModal({ machine, onCancel, updateMachine }) {
  const formRef = useRef(null);

  function onSubmit(values) {
    updateMachine({ '@id': machine.get('@id'), ...values });
    onCancel();
  }

  return (
    <Modal show onHide={onCancel} aria-labelledby="edit-sn-modal-title">
      <Modal.Header closeButton>
        <Modal.Title id="edit-sn-modal-title">
          Editer le numéro de série de la machine
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <EditSNForm
          ref={formRef}
          machineSerialNumber={machine.get('machineSerialNumber')}
          onSubmit={onSubmit}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onCancel}>Fermer</Button>

        <Button
          onClick={() => {
            formRef.current.dispatchEvent(
              new Event('submit', { cancelable: true })
            );
          }}
        >
          Modifier le numéro de série
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
EditSNModal.propTypes = {
  machine: PropTypes.instanceOf(Machine).isRequired,
  onCancel: PropTypes.func.isRequired,
  updateMachine: PropTypes.func.isRequired,
};

export default connect(() => ({}), {
  updateMachine,
})(EditSNModal);
