import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import cn from 'classnames';
import { Button, Tabs, Tab } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { is, fromJS } from 'immutable';
import Task from '../../../entity/Task';
import InstallationForm from './InstallationForm';
import installationApi from '../../../api/installation';
import { entityShortId } from '../../../idTools';
import { INSTALLATION_FIELDS } from '../TaskDetailPages';
import './TaskMachineInstallation.css';
import MachineImportInstallationFile from './MachineImportInstallationFile';

const prepareInstallation = (installation) =>
  installation.remove('task').remove('machine').remove('@id');

const isCustomized = (machineInstallation, defaultInstallation) =>
  machineInstallation &&
  !is(
    prepareInstallation(machineInstallation),
    prepareInstallation(defaultInstallation)
  );

function TaskMachineInstallation({ task, customInstallationFields, onSubmit }) {
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);
  const [activeKey, setActiveKey] = useState(
    task.machineList.first()?.get('@id')
  );
  const [machineInstallationList, setMachineInstallationList] = useState(
    Object.fromEntries(
      task.machineList.map((m) => [m.get('@id'), m.installation])
    )
  );
  const [hasInstallationFile, setHasInstallationFile] = useState(
    Object.fromEntries(
      task.machineList.map((m) => [m.get('@id'), m.hasInstallationFile])
    )
  );

  const { installation: taskInstallation } = task;

  async function submitCurrentMachineInstallation(values) {
    const machineId = activeKey;
    const newValue = { ...values, machine: machineId };
    if (taskInstallation && !isCustomized(fromJS(values), taskInstallation)) {
      return;
    }

    const queryParams = { fields: INSTALLATION_FIELDS };

    let machineInstallation;

    if (machineInstallationList[machineId]) {
      machineInstallation = await installationApi.update(
        values['@id'],
        newValue,
        queryParams
      );
    } else {
      machineInstallation = await installationApi.create(newValue, queryParams);
    }

    setMachineInstallationList((prev) => ({
      ...prev,
      [machineId]: machineInstallation,
    }));
  }

  let initialValues;
  if (machineInstallationList[activeKey]) {
    initialValues = machineInstallationList[activeKey].toJS();
  } else if (taskInstallation) {
    initialValues = taskInstallation.remove('@id').toJS();
  }

  return (
    <div>
      <Tabs
        activeKey={activeKey}
        onSelect={(machineId) => {
          // submit form for current machine
          document
            .getElementById(`InstallationForm-${activeKey}`)
            .dispatchEvent(new Event('submit', { cancelable: true }));

          setActiveKey(machineId);
        }}
        id="machine-installation-list"
      >
        {task.machineList.map((machine) => (
          <Tab
            tabClassName={cn(
              'TaskMachineInstallation__Tab',
              machineInstallationList[machine.get('@id')] &&
                'TaskMachineInstallation__Tab--customized',
              !hasInstallationFile[machine.get('@id')] &&
                'TaskMachineInstallation__Tab--missingFile'
            )}
            eventKey={machine.get('@id')}
            key={machine.get('@id')}
            title={[machine.machineModel, machine.machineSerialNumber]
              .filter(Boolean)
              .join(' - ')}
          >
            <InstallationForm
              formId={`InstallationForm-${machine.get('@id')}`}
              customInstallationFields={customInstallationFields}
              onSubmit={submitCurrentMachineInstallation}
              initialValues={initialValues}
            />

            <MachineImportInstallationFile
              machineId={machine.get('@id')}
              onUploadStart={() => setIsSubmitButtonDisabled(true)}
              onUploadEnd={() => setIsSubmitButtonDisabled(false)}
              onChange={(fileList) => {
                setHasInstallationFile((prev) => ({
                  ...prev,
                  [machine.get('@id')]: fileList?.size > 0,
                }));
              }}
            />
          </Tab>
        ))}
      </Tabs>

      <hr />

      <div className="text-right">
        <Link
          className="btn btn-link"
          to={`/tasks/${entityShortId(task)}/sign/installation`}
        >
          Retour
        </Link>
        <Button
          type="button"
          disabled={isSubmitButtonDisabled}
          onClick={() => {
            document
              .getElementById(`InstallationForm-${activeKey}`)
              .dispatchEvent(new Event('submit', { cancelable: true }));

            onSubmit();
          }}
        >
          Valider et aller à la signature
        </Button>
      </div>
    </div>
  );
}

TaskMachineInstallation.propTypes = {
  task: PropTypes.instanceOf(Task).isRequired,
  customInstallationFields: ImmutablePropTypes.listOf(ImmutablePropTypes.map)
    .isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default TaskMachineInstallation;
