import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { fromJS } from 'immutable';
import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';
import Loader from 'react-loader';
import { Button, ButtonToolbar, FormLabel, FormControl } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import RouterPropTypes from '../../prop-types/router';
import brandApi from '../../api/brand';
import * as brandActions from '../../actions/brand';
import { formControlFields } from './utils';
import MediaObjectInput from './MediaObjectInput';

class BrandForm extends React.Component {
  constructor(props) {
    super(props);

    this.fetchBrand = this.fetchBrand.bind(this);
  }

  componentDidMount() {
    this.fetchBrand();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.fetchBrand();
    }
  }

  fetchBrand() {
    if (this.props.match.params.id) {
      this.props.findBrand(this.props.match.params.id);
    }
  }
  render() {
    const { createUpdateBrand, currentBrand } = this.props;

    const initialValues = currentBrand
      ? {
          id: currentBrand.get('@id'),
          name: currentBrand.get('name'),
          logo: currentBrand.get('logo'),
        }
      : null;

    return (
      <Loader
        loaded={this.props.isLoaded}
        parentClassName="loader loader--inline"
      >
        <Form onSubmit={createUpdateBrand} initialValues={initialValues}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Field name="id">
                {({ input }) => (
                  <FormControl type="hidden" {...formControlFields(input)} />
                )}
              </Field>

              <FormLabel>Marque</FormLabel>
              <Field name="name">
                {({ input }) => (
                  <FormControl type="text" {...formControlFields(input)} />
                )}
              </Field>

              <FormLabel>Logo</FormLabel>
              <Field name="logo">
                {({ input }) => <MediaObjectInput {...input} />}
              </Field>

              <ButtonToolbar>
                <Button type="submit" variant="primary">
                  {this.props.isEdition ? 'Modifier' : 'Créer'}
                </Button>

                <LinkContainer to="/brands">
                  <Button variant="link">Annuler</Button>
                </LinkContainer>
              </ButtonToolbar>
            </form>
          )}
        </Form>
      </Loader>
    );
  }
}
BrandForm.defaultProps = {
  currentBrand: null,
};

BrandForm.propTypes = {
  id: PropTypes.string,
  currentBrand: ImmutablePropTypes.map,
  findBrand: PropTypes.func.isRequired,
  isLoaded: PropTypes.bool.isRequired,
  name: PropTypes.string,
  createUpdateBrand: PropTypes.func.isRequired,
  isEdition: PropTypes.bool.isRequired,
  match: RouterPropTypes.params({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    isEdition: !!ownProps.match.params.id,
  };
}

const FormElement = connect(mapStateToProps, brandActions)(BrandForm);

function findBrand(id) {
  return (dispatch) => {
    dispatch({ type: 'REQUEST_CURRENT_BRAND' });
    brandApi
      .find(id, {
        fields: '@id,name,logo',
      })
      .then((brand) => {
        dispatch({
          type: 'RECEIVE_CURRENT_BRAND',
          brand: fromJS(brand),
        });
      });
  };
}

function reducer(state, action) {
  switch (action.type) {
    case 'REQUEST_CURRENT_BRAND':
      return {
        isLoaded: false,
      };
    case 'RECEIVE_CURRENT_BRAND':
      return {
        currentBrand: action.brand,
        isLoaded: true,
      };
    default:
      throw new Error(`Unknown action ${action.type}`);
  }
}
function BrandFormWithFetch({ ...props }) {
  const [state, dispatch] = useReducer(reducer, {
    currentBrand: null,
    isLoaded: true,
  });
  return (
    <FormElement
      findBrand={(id) => findBrand(id)(dispatch)}
      isLoaded={state.isLoaded}
      currentBrand={state.currentBrand}
      {...props}
    />
  );
}

export default BrandFormWithFetch;
