import PropTypes from 'prop-types';
import React, { useReducer } from 'react';
import { fromJS, List } from 'immutable';
import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';
import Loader from 'react-loader';
import {
  Col,
  FormLabel,
  Container,
  FormControl,
  Button,
  ButtonToolbar,
  Row,
} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import ColorPicker from 'rc-color-picker';
import RouterPropTypes from '../../prop-types/router';
import materialResourceApi from '../../api/materialResource';
import PagedCollection from '../../entity/PagedCollection';
import ReduxFormGroup from './ReduxFormGroup';
import {
  findAllMaterialResources,
  createUpdateMaterialResource,
} from '../../actions/materialResource';
import { findAllTeams } from '../../actions/team';
import { formControlFields } from './utils';
import 'rc-color-picker/assets/index.css';

function validateMaterialResourceForm(values) {
  const errors = {};

  if (!values.name) {
    errors.name = 'Le champ nom est obligatoire';
  }

  if (!values.type) {
    errors.type = 'Le type est obligatoire';
  }

  if (!values.team) {
    errors.team = "L'équipe est obligatoire";
  }

  return errors;
}

class MaterialResourceForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.fetchMaterialResource = this.fetchMaterialResource.bind(this);
    this.getTeamList = this.getTeamList.bind(this);
  }

  componentDidMount() {
    this.fetchMaterialResource();
    this.props.findAllTeams();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.match.params.materialResourceId !==
      this.props.match.params.materialResourceId
    ) {
      this.fetchMaterialResource();
      this.props.findAllTeams();
    }
  }

  componentWillUnmount() {
    this.props.findAllMaterialResources();
  }

  getTeamList() {
    return this.props.teamList
      ? this.props.teamList.get('hydra:member')
      : List();
  }

  fetchMaterialResource() {
    if (this.props.match.params.materialResourceId) {
      this.props.findMaterialResource(
        this.props.match.params.materialResourceId
      );
    }
  }

  render() {
    const {
      currentMaterialResource,
      createUpdateMaterialResource,
    } = this.props;
    return (
      <Loader
        loaded={this.props.isLoaded}
        parentClassName="loader loader--inline"
      >
        <Form
          onSubmit={createUpdateMaterialResource}
          validate={validateMaterialResourceForm}
          initialValues={
            currentMaterialResource ? currentMaterialResource.toJS() : null
          }
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <h1>
                {this.props.isEdition ? 'Édition' : 'Création'} ressource
                matérielle
              </h1>

              <Field name="name">
                {({ input, meta }) => (
                  <ReduxFormGroup meta={meta}>
                    <FormLabel>Nom</FormLabel>
                    <FormControl
                      type="text"
                      isValid={meta.touched && !meta.error}
                      isInvalid={meta.touched && meta.error}
                      {...formControlFields(input)}
                      autoFocus
                    />
                  </ReduxFormGroup>
                )}
              </Field>

              <Field name="description">
                {({ input, meta }) => (
                  <ReduxFormGroup meta={meta}>
                    <FormLabel>Description</FormLabel>
                    <FormControl
                      type="text"
                      isValid={meta.touched && !meta.error}
                      isInvalid={meta.touched && meta.error}
                      {...formControlFields(input)}
                    />
                  </ReduxFormGroup>
                )}
              </Field>

              {/* Color */}
              <Container fluid>
                <Row>
                  <Col md={4}>
                    <Field name="backgroundColor">
                      {({ input, meta }) => (
                        <ReduxFormGroup meta={meta}>
                          <FormLabel style={{ marginRight: 10 }}>
                            Couleur de fond
                          </FormLabel>
                          <ColorPicker
                            {...input}
                            color={input.value || '#ffffff'}
                            onChange={(param) => input.onChange(param.color)}
                          />
                        </ReduxFormGroup>
                      )}
                    </Field>
                  </Col>

                  <Col md={4}>
                    <Field name="foregroundColor">
                      {({ input, meta }) => (
                        <ReduxFormGroup meta={meta}>
                          <FormLabel style={{ marginRight: 10 }}>
                            Couleur de texte
                          </FormLabel>
                          <ColorPicker
                            {...input}
                            color={input.value || '#000'}
                            onChange={(param) => input.onChange(param.color)}
                          />
                        </ReduxFormGroup>
                      )}
                    </Field>
                  </Col>

                  <Col md={4}>
                    <Field
                      name="backgroundColor"
                      subscription={{ value: true }}
                    >
                      {({ input: { value: backgroundValue } }) => (
                        <Field
                          name="foregroundColor"
                          subscription={{ value: true }}
                        >
                          {({ input: { value: foregroundValue } }) => (
                            <div
                              style={{
                                backgroundColor: backgroundValue,
                                color: foregroundValue,
                                textAlign: 'center',
                              }}
                            >
                              Exemple texte
                            </div>
                          )}
                        </Field>
                      )}
                    </Field>
                  </Col>
                </Row>
              </Container>

              <Field name="type">
                {({ input, meta }) => (
                  <ReduxFormGroup meta={meta}>
                    <FormLabel>Type</FormLabel>
                    <FormControl
                      as="select"
                      isValid={meta.touched && !meta.error}
                      isInvalid={meta.touched && meta.error}
                      {...formControlFields(input)}
                    >
                      <option />
                      <option value="vehicle">Véhicule</option>
                      <option value="other">Autres</option>
                    </FormControl>
                  </ReduxFormGroup>
                )}
              </Field>

              <Field name="team">
                {({ input, meta }) => (
                  <ReduxFormGroup meta={meta}>
                    <FormLabel>Équipe</FormLabel>
                    <FormControl
                      as="select"
                      isValid={meta.touched && !meta.error}
                      isInvalid={meta.touched && meta.error}
                      {...formControlFields(input)}
                    >
                      <option />
                      {this.getTeamList().map((team) => (
                        <option key={team.get('@id')} value={team.get('@id')}>
                          {team.get('name')}
                        </option>
                      ))}
                    </FormControl>
                  </ReduxFormGroup>
                )}
              </Field>

              <ButtonToolbar>
                <Button type="submit" variant="primary">
                  {this.props.isEdition ? 'Modifier' : 'Créer'}
                </Button>
                <LinkContainer to="/material-resources/">
                  <Button variant="link">Annuler</Button>
                </LinkContainer>
              </ButtonToolbar>
            </form>
          )}
        </Form>
      </Loader>
    );
  }
}

MaterialResourceForm.defaultProps = {
  teamList: null,
  currentMaterialResource: null,
};

MaterialResourceForm.propTypes = {
  isEdition: PropTypes.bool.isRequired,
  createUpdateMaterialResource: PropTypes.func.isRequired,
  findMaterialResource: PropTypes.func.isRequired,
  findAllTeams: PropTypes.func.isRequired,
  findAllMaterialResources: PropTypes.func.isRequired,
  teamList: PropTypes.instanceOf(PagedCollection),
  isLoaded: PropTypes.bool.isRequired,
  match: RouterPropTypes.params({
    materialResourceId: PropTypes.string.isRequired,
  }).isRequired,
  currentMaterialResource: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
  return {
    isEdition: !!ownProps.currentMaterialResource,
    teamList: state.app.get('teamList'),
  };
}

const FormElement = connect(mapStateToProps, {
  findAllMaterialResources,
  createUpdateMaterialResource,
  findAllTeams,
})(MaterialResourceForm);

function findMaterialResource(id) {
  return (dispatch) => {
    dispatch({ type: 'REQUEST_CURRENT_MATERIAL_RESOURCE' });

    materialResourceApi
      .find(id, {
        fields:
          '@id,name,description,backgroundColor,foregroundColor,type,team',
      })
      .then((materialResource) => {
        dispatch({
          type: 'RECEIVE_CURRENT_MATERIAL_RESOURCE',
          materialResource: fromJS(materialResource),
        });
      });
  };
}

function reducer(state, action) {
  switch (action.type) {
    case 'REQUEST_CURRENT_MATERIAL_RESOURCE':
      return {
        isLoaded: false,
      };

    case 'RECEIVE_CURRENT_MATERIAL_RESOURCE':
      return {
        currentMaterialResource: action.materialResource,
        isLoaded: true,
      };

    default:
      throw new Error(`Unknown action ${action.type}`);
  }
}

function MaterialResourceFormWithFetch({ ...props }) {
  const [state, dispatch] = useReducer(reducer, {
    currentMaterialResource: null,
    isLoaded: true,
  });

  return (
    <FormElement
      findMaterialResource={(id) => findMaterialResource(id)(dispatch)}
      isLoaded={state.isLoaded}
      currentMaterialResource={state.currentMaterialResource}
      {...props}
    />
  );
}

export default MaterialResourceFormWithFetch;
