import superagent from 'superagent';
import config from '../config';

export default {
  update(file) {
    return window.oauthClient.getAccessToken().then(
      (accessToken) =>
        new Promise((resolve, reject) => {
          superagent
            .put(`${config.api.url}${file['@id']}`)
            .set('Authorization', `Bearer ${accessToken}`)
            .send(file)
            .accept('json')
            .end((err, res) => {
              if (err) {
                reject(err);
              } else {
                resolve(JSON.parse(res.text));
              }
            });
        })
    );
  },
};
