import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RouterPropTypes from '../../prop-types/router';
import NavBar from './NavBar';
import Project from '../../entity/Project';
import { findProject, removeCurrentProject } from '../../actions/project';
import { entityShortId } from '../../idTools';
import { currentUserIsAdminSelector } from '../../selector';

export class ProjectLayout extends React.PureComponent {
  constructor(props) {
    super(props);

    this.fetchProject = this.fetchProject.bind(this);
  }

  componentDidMount() {
    this.fetchProject();
  }

  componentDidUpdate(prevProps) {
    const oldProject = prevProps.match.params.projectId;
    const newProject = this.props.match.params.projectId;

    if (oldProject !== newProject) {
      this.fetchProject();
    }
  }

  componentWillUnmount() {
    this.props.removeCurrentProject();
  }

  fetchProject() {
    this.props.findProject(this.props.match.params.projectId);
  }

  render() {
    const { currentProject } = this.props;

    if (!currentProject) {
      return null;
    }

    return (
      <NavBar
        name={`Accueil ${currentProject.get('name')}`}
        projectId={entityShortId(currentProject)}
        isAdmin={this.props.isAdmin}
      />
    );
  }
}

ProjectLayout.defaultProps = {
  currentProject: null,
};

ProjectLayout.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  children: PropTypes.node,
  currentProject: PropTypes.instanceOf(Project),
  findProject: PropTypes.func.isRequired,
  removeCurrentProject: PropTypes.func.isRequired,
  match: RouterPropTypes.params({
    projectId: PropTypes.string.isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    currentProject: state.app.get('currentProject'),
    isAdmin: currentUserIsAdminSelector(state),
  };
}

export const ProjectLayoutContainer = connect(mapStateToProps, {
  findProject,
  removeCurrentProject,
})(ProjectLayout);
