import superagent from 'superagent';
import config from '../config';
import AbstractClient from './abstractClient';
import AuthIntercept from './AuthIntercept';
import { createEntity } from '../entity/factory';
import { entityShortId } from '../idTools';
import { openFileFromBlob } from '../utils';

let currentMachineRequest = null;

const MIME_TYPE_XLS = 'application/vnd.ms-excel';
const MIME_TYPE_DOCX =
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

class MachineClient extends AbstractClient {
  getPathBase() {
    return `${config.api.version}/machines`;
  }

  // find(id) {
  //   return new Promise((resolve, reject) => {
  //     superagent
  //       .get(`${config.api.url}/v1/machines/${id}`)
  //       .accept('json')
  //       .end((err, res) => {
  //         if (err) {
  //           reject(err);
  //         } else {
  //           resolve(JSON.parse(res.text));
  //         }
  //       });
  //   });
  // }

  findBy(values) {
    return this._getAccessToken().then(
      (accessToken) =>
        new Promise((resolve, reject) => {
          if (currentMachineRequest) {
            currentMachineRequest.abort();
          }

          currentMachineRequest = superagent
            .get(`${config.api.url}/v1/machines`)
            .set('Authorization', `Bearer ${accessToken}`)
            .query(
              Object.assign({}, values, {
                itemsPerPage: config.nbMachinesToLoad,
              })
            )
            .accept('json')
            .end((err, res) => {
              currentMachineRequest = null;

              if (err) {
                reject(err);
              } else {
                const body = JSON.parse(res.text);
                resolve(createEntity(body));
              }
            });
        })
    );
  }

  findByProject(project, params) {
    return this.findBy(Object.assign({ project: project.get('@id') }, params));
  }

  uploadFile(file) {
    return this._getAccessToken().then(
      (accessToken) =>
        new Promise((resolve, reject) => {
          superagent
            .post(`${config.api.url}/v1/imported_files/import`)
            .set('Authorization', `Bearer ${accessToken}`)
            .accept('json')
            .attach('file', file)
            .end((err, res) => {
              if (err) {
                reject(err);
              } else {
                resolve(JSON.parse(res.text));
              }
            });
        })
    );
  }

  importInstallationFile(machineId, file) {
    return this._getAccessToken().then(
      (accessToken) =>
        new Promise((resolve, reject) => {
          superagent
            .post(
              `${config.api.url}/v1/machines/${machineId}/import_installation_file`
            )
            .set('Authorization', `Bearer ${accessToken}`)
            .accept('json')
            .attach('file', file)
            .end((err, res) => {
              if (err) {
                reject(err);
              } else {
                resolve(JSON.parse(res.text));
              }
            });
        })
    );
  }

  createFromFile(file, project) {
    return this._getAccessToken().then(
      (accessToken) =>
        new Promise((resolve, reject) => {
          superagent
            .post(`${config.api.url}/v1/machines/from_file`)
            .set('Authorization', `Bearer ${accessToken}`)
            .accept('json')
            .send({ file: file.get('@id'), project: project.get('@id') })
            .end((err) => {
              if (err) {
                reject(err);
              } else {
                resolve();
              }
            });
        })
    );
  }

  // create(values) {
  //   return new Promise((resolve, reject) => {
  //     superagent
  //       .post(`${config.api.url}/v1/machines`)
  //       .accept('json')
  //       .send(values)
  //       .end((err, res) => {
  //         if (err) {
  //           reject(err);
  //         } else {
  //           resolve(JSON.parse(res.text));
  //         }
  //       });
  //   });
  // }

  // update(id, values) {
  //   return new Promise((resolve, reject) => {
  //     superagent
  //       .put(`${config.api.url}${id}`)
  //       .accept('json')
  //       .send(values)
  //       .end((err, res) => {
  //         if (err) {
  //           reject(err);
  //         } else {
  //           resolve(JSON.parse(res.text));
  //         }
  //       });
  //   });
  // }

  exportXls(params) {
    return this._getAccessToken().then(
      (accessToken) =>
        new Promise((resolve, reject) => {
          superagent
            .get(`${config.api.url}${config.api.version}/machines.xls`)
            .query(params)
            .set('Authorization', `Bearer ${accessToken}`)
            .responseType('blob')
            .use(AuthIntercept)
            .end((err, res) => {
              if (err) {
                reject(err);
              } else {
                const blob = res.xhr.response;

                openFileFromBlob(
                  blob,
                  'ExportOGIP-tous-projets.xls',
                  MIME_TYPE_XLS
                );
              }
            });
        })
    );
  }

  openPV(machine) {
    const id = entityShortId(machine);

    return this._getAccessToken().then(
      (accessToken) =>
        new Promise((resolve, reject) => {
          superagent
            .get(
              `${config.api.url}${config.api.version}/machines/${id}/pv.docx`
            )
            .set('Authorization', `Bearer ${accessToken}`)
            .responseType('blob')
            .use(AuthIntercept)
            .end((err, res) => {
              if (res && res.status === 404) {
                reject(JSON.parse(res.text)['hydra:description']);
              } else if (err) {
                reject(err);
              } else {
                const blob = res.xhr.response;

                openFileFromBlob(blob, `pv-${id}.docx`, MIME_TYPE_DOCX);
              }
            });
        })
    );
  }
}

export default new MachineClient();
