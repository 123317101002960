import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { List } from 'immutable';
import { connect } from 'react-redux';
import {
  Alert,
  Button,
  Col,
  Container,
  FormControl,
  Card,
  Table,
  Row,
} from 'react-bootstrap';
import Map from './Map';
import PlanMachineTable from '../plan/PlanMachineTable';
import config from '../../config';
import * as planActions from '../../actions/plan';
import { currentUserIsAdminSelector } from '../../selector';
import { MdCheck, MdRepeat, MdViewHeadline, MdViewList } from 'react-icons/md';

class Plan extends React.PureComponent {
  constructor(props) {
    super(props);

    this.geolocateFirst = this.geolocateFirst.bind(this);
    this.getOverQueryLimitSite = this.getOverQueryLimitSite.bind(this);
    this.getSitesToPosition = this.getSitesToPosition.bind(this);
    this.geolocate = this.geolocate.bind(this);
    this.getColSize = this.getColSize.bind(this);
    this.getMapHeight = this.getMapHeight.bind(this);
    this.getToggleIcon = this.getToggleIcon.bind(this);
    this.setMapLayout = this.setMapLayout.bind(this);
    this.handleFormattedAddressChanged = this.handleFormattedAddressChanged.bind(
      this
    );

    this.state = {
      newFormattedAddress: {},
    };
  }

  componentDidUpdate() {
    this.geolocateFirst();
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  getOverQueryLimitSite() {
    return this.props.unpositionnedSites
      ? this.props.unpositionnedSites.filter(
          (site) => site && site.get('geolocationStatus') === 'OVER_QUERY_LIMIT'
        )
      : List();
  }

  getSitesToPosition() {
    return (this.props.unpositionnedSites || List())
      .unshift(this.props.currentEditingSite)
      .filter((site) => site);
  }

  getColSize() {
    if (this.props.mapLayout === 'half-width') {
      return 6;
    }

    return 12;
  }

  getMapHeight() {
    return this.getColSize() === 12 ? '400px' : '700px';
  }

  getToggleIcon() {
    return this.getColSize() === 12 ? <MdViewList /> : <MdViewHeadline />;
  }

  setMapLayout() {
    this.props.setMapLayout(
      this.props.mapLayout === 'half-width' ? 'full-width' : 'half-width'
    );
  }

  geolocate(site) {
    const formattedAddress = this.state.newFormattedAddress[site.get('@id')]
      ? this.state.newFormattedAddress[site.get('@id')]
      : site.get('formattedAddress');

    return this.props.geolocate(site, formattedAddress);
  }

  geolocateFirst() {
    const overQueryLimitSite = this.getOverQueryLimitSite();
    if (overQueryLimitSite.size > 0) {
      this.timeout = setTimeout(() => {
        const site = overQueryLimitSite.first();
        this.props.geolocate(site, site.get('formattedAddress'));
      }, 2000);
    }
  }

  handleFormattedAddressChanged(site, event) {
    const value = event.target.value;

    this.setState((prevState) => ({
      newFormattedAddress: {
        ...prevState.newFormattedAddress,
        [site.get('@id')]: value,
      },
    }));
  }
  render() {
    // const tableTitle = (<div>
    //  <Glyphicon glyph={'ok'} />
    //  <span>Adresses en erreur</span>
    // <div>);
    const tableTitle = (
      <div>
        <Button variant="link" onClick={() => this.setMapLayout()}>
          {this.getToggleIcon()}
        </Button>{' '}
        <span>Adresses + machines</span>
      </div>
    );

    return (
      <Container fluid>
        <Row>
          <Col md={this.getColSize()}>
            {this.props.currentMachineNumber > config.nbMachinesToLoad && (
              <Alert variant="warning">
                Attention, plus de {config.nbMachinesToLoad} machines à
                afficher, zoomez sur une zone pour tout afficher
              </Alert>
            )}

            <Map
              {...this.props}
              height={this.getMapHeight()}
              defaultCenter={this.props.bounds.center}
              defaultZoom={this.props.bounds.zoom}
            />
          </Col>

          <Col md={this.getColSize()}>
            <Card body header={tableTitle}>
              <PlanMachineTable />

              {this.getSitesToPosition().size > 0 && (
                <Table striped bordered hover size="sm">
                  <thead>
                    <tr>
                      <td>Status</td>
                      <td>Adresse connue</td>
                      <td>Nouvelle address</td>
                      {this.props.isAdmin && <td>Action</td>}
                    </tr>
                  </thead>
                  <tbody>
                    {this.getSitesToPosition().map((site) => {
                      const isOverQuota =
                        site.get('geolocationStatus') === 'OVER_QUERY_LIMIT';
                      return (
                        <tr key={site.get('@id')}>
                          <td>{site.get('geolocationStatus')}</td>
                          <td>{site.get('formattedAddress')}</td>
                          <td>
                            {!isOverQuota ? (
                              <FormControl
                                type="text"
                                defaultValue={site.get('formattedAddress')}
                                onChange={(event) =>
                                  this.handleFormattedAddressChanged(
                                    site,
                                    event
                                  )
                                }
                              />
                            ) : null}
                          </td>
                          {this.props.isAdmin && (
                            <td>
                              <a
                                href="#geocode"
                                onClick={() => this.geolocate(site)}
                              >
                                {isOverQuota ? <MdRepeat /> : <MdCheck />}
                              </a>
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

Plan.defaultProps = {
  unpositionnedSites: null,
  currentEditingSite: null,
  mapLayout: null,
  currentMachineNumber: null,
};

Plan.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  unpositionnedSites: ImmutablePropTypes.list,
  setMapLayout: PropTypes.func.isRequired,
  geolocate: PropTypes.func.isRequired,
  currentEditingSite: PropTypes.object,
  bounds: PropTypes.shape({
    zoom: PropTypes.number.isRequired,
    center: PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
    }).isRequired,
  }),
  mapLayout: PropTypes.string,
  currentMachineNumber: PropTypes.number,
};

function mapStateToProps(state) {
  return {
    mapLayout: state.app.get('mapLayout'),
    selectedMachines: state.app.get('selectedMachines'),
    currentMachineNumber: state.app.getIn([
      'currentMachines',
      'hydra:totalItems',
    ]),
    bounds: state.app.get('bounds'),
    isAdmin: currentUserIsAdminSelector(state),
  };
}

export default connect(
  mapStateToProps,
  Object.assign(
    {
      setMapLayout: (layoutType) => ({
        type: 'SET_MAP_LAYOUT',
        layout: layoutType,
      }),
    },
    planActions
  )
)(Plan);
