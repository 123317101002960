import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert } from 'react-bootstrap';
import { dismissAlert } from '../actions/layout';

class AlertContainer extends PureComponent {
  componentDidUpdate(prevProps) {
    if (
      !prevProps.alert &&
      this.props.alert &&
      this.props.alert.get('dismissAfter')
    ) {
      this.dismissAlertTimer = setTimeout(
        this.props.dismissAlert,
        this.props.alert.get('dismissAfter')
      );
    }
  }

  componentWillUnmount() {
    clearTimeout(this.dismissAlertTimer);
  }
  render() {
    const { alert, dismissAlert, position } = this.props;
    if (!alert) {
      return null;
    }

    if (alert.get('position') && alert.get('position') !== position) {
      return null;
    }

    const message = this.props.alert.get('message');
    const messageLines = message ? message.split('\n') : ['Erreur inattendue'];
    return (
      <Alert variant={this.props.alert.get('style')} onClose={dismissAlert}>
        {messageLines.map((item) => (
          <Fragment key={item}>
            {item}
            <br />
          </Fragment>
        ))}
      </Alert>
    );
  }
}

AlertContainer.defaultProps = {
  alert: null,
  position: null,
};

AlertContainer.propTypes = {
  alert: PropTypes.object,
  dismissAlert: PropTypes.func.isRequired,
  position: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    alert: state.app.get('alert'),
  };
}

export default connect(mapStateToProps, {
  dismissAlert,
})(AlertContainer);
