import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'react-bootstrap';
import Task from '../../entity/Task';
import { MdSend } from 'react-icons/md';

function trans(status) {
  switch (status) {
    case 'waiting_info':
      return "En attente d'info";
    case 'to_transfer':
      return 'A transférer';
    case 'transfer_planned':
      return 'Transfert plannifié';
    case 'transfered':
      return 'Transférée';
    case 'not_applicable':
      return 'Non applicable';
    default:
      // eslint-disable-next-line no-console
      console.warn(
        `Impossible de trouver la traduction pou le status de transfert "${status}"`
      );
      return status;
  }
}

function TaskMachineTransferStatus({ task, asTitle }) {
  if (task.isTransfer()) {
    // return null;
  }

  if (
    task.areMachinesTransfered !== true &&
    task.areMachinesTransfered !== false
  ) {
    if (asTitle) {
      return null;
    } else {
      return 'Non concerné';
    }
  }
  const status = trans(task.machineTransferStatus);
  const labelSyle = task.areMachinesTransfered ? 'success' : 'warning';

  return (
    <Fragment>
      <Badge variant={labelSyle} title={asTitle ? status : ''}>
        <MdSend />
      </Badge>
      {!asTitle && ` ${status}`}
    </Fragment>
  );
}

TaskMachineTransferStatus.defaultProps = {
  asTitle: false,
};

TaskMachineTransferStatus.propTypes = {
  task: PropTypes.instanceOf(Task).isRequired,
  asTitle: PropTypes.bool,
};

export { trans as transMachineTransferStatus };
export default TaskMachineTransferStatus;
