import 'core-js/stable';
import 'regenerator-runtime/runtime';
import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';
import React from 'react';
import { compose, createStore, applyMiddleware, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import localforage from 'localforage';

import * as storage from 'redux-storage';
import createEngine from 'redux-storage-engine-localstorage';
import storageMerger from 'redux-storage-merger-immutablejs';
import filter from 'redux-storage-decorator-filter';

import reducer from './reducer';
import onedriveReducer from './reducer/onedrive';
import Routes from './routes';

import apiErrorMiddleware from './middleware/errorMiddleware';
import OauthClient from './api/OauthClient';
import OnedriveApi from './api/Onedrive';
import { createEntity } from './entity/factory';

window.oauthClient = new OauthClient(
  '3_5zc05rmakc8wkckkwg4k0sg408ss8ccoosokwwc8kc0so4ww48',
  '1uxot8hhmh8g4wgggg0o4800oogkgsgc4s0w4ks4g8kos800ws',
  localforage
);

window.oneDriveApi = new OnedriveApi();

const allReducers = combineReducers({
  app: reducer,
  onedrive: onedriveReducer,
});

const storageReducer = storage.reducer(allReducers, storageMerger);
const engine = filter(createEngine('ogip'), [
  ['app', 'savedFilters'], // whitelist the app.filters key
  ['onedrive', 'token'],
  ['onedrive', 'expiresAt'],
]);

const storageMiddleware = storage.createMiddleware(engine);

const middleware = [apiErrorMiddleware, thunk, storageMiddleware];

const prodComposition = [applyMiddleware(...middleware)];

const composition =
  process.env.NODE_ENV === 'production' // eslint-disable-line no-undef
    ? prodComposition
    : prodComposition.concat([
        window.__REDUX_DEVTOOLS_EXTENSION__
          ? window.__REDUX_DEVTOOLS_EXTENSION__({
              actionsBlacklist: ['REDUX_STORAGE_SAVE'],
            })
          : (f) => f,
      ]);

const createStoreWithMiddleware = compose(...composition)(createStore);

const store = createStoreWithMiddleware(storageReducer);

const load = storage.createLoader(engine);
load(store);

Sentry.init({
  dsn: 'https://d71d5faec6384713af397d72e5d234f3@sentry.io/148323',
  environment: process.env.NODE_ENV, // eslint-disable-line no-undef
  release: process.env.REACT_APP_RELEASE_NUMBER || null, // eslint-disable-line no-undef
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  ignoreErrors: [
    "Cannot read property 'scrollHeight' of undefined",
    "TypeError: Cannot read properties of undefined (reading 'scrollHeight')",
    "Cannot read properties of undefined (reading 'scrollHeight')",
    "undefined is not an object (evaluating 'r.refs.content.scrollHeight')",
    'r.refs.content is undefined',
    "Unable to get property 'scrollHeight' of undefined or null reference",
    'Unauthorized',
  ],
});

window.onAuthenticated = function onAuthenticated(token, authWindow) {
  if (token) {
    localforage.setItem('onedrive.auth.token', token).then(() => {
      store.dispatch({
        type: 'ONE_DRIVE_AUTH_TOKEN_RECEIVED',
        token: createEntity(token),
      });

      if (authWindow) {
        authWindow.close();
      }
    });
  }
};

function logException(ex, context) {
  Sentry.withScope((scope) => {
    scope.setExtra(context);
    Sentry.captureException(ex);
  });
  /* eslint no-console:0 */
  if (window.console && console.error) {
    console.error(ex);
  }
}

function App() {
  try {
    return (
      <div>
        <Provider store={store}>
          <Routes />
        </Provider>
      </div>
    );
  } catch (ex) {
    logException(ex);
  }
}

export default App;
