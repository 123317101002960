import PropTypes from 'prop-types';
import React from 'react';
import { List } from 'immutable';
import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';
import {
  Button,
  ButtonToolbar,
  FormLabel,
  FormControl,
  Card,
} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import Autocomplete from 'react-autocomplete';
import RouterPropTypes from '../../prop-types/router';
import {
  addContactIdToProject,
  createUpdateProjectContact,
  findProjectContact,
  removeCurrentProjectContact,
} from '../../actions/projectContact';
import Required from './Required';
import ReduxFormGroup from './ReduxFormGroup';
import projectContactApi from '../../api/projectContact';
import { formControlFields } from './utils';

import './ProjectContact.css';

export function validateProjectContactForm(values) {
  const errors = {};

  if (!values.name) {
    errors.name = 'Le nom est obligatoire';
  }

  if (!values.job) {
    errors.job = 'Le poste est obligatoire';
  }

  return errors;
}

class ProjectContactForm extends React.Component {
  constructor(props) {
    super(props);

    this.fetchContact = this.fetchContact.bind(this);
    this.resetSearch = this.resetSearch.bind(this);
    this.validSearch = this.validSearch.bind(this);

    this.state = {
      selectedContactSearch: '',
      selectedContact: null,
      matchingContacts: [],
      loading: false,
    };
  }

  componentDidMount() {
    this.fetchContact();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.match.params.contactId !== this.props.match.params.contactId
    ) {
      this.fetchContact();
    }
  }

  componentWillUnmount() {
    this.props.removeCurrentProjectContact();
  }

  fetchContact() {
    if (this.props.match.params.contactId) {
      this.props.findProjectContact(this.props.match.params.contactId);
    }
  }

  resetSearch() {
    this.setState({
      selectedContactSearch: '',
      selectedContact: null,
      matchingContacts: [],
      loading: false,
    });
  }

  validSearch() {
    this.props.addContactIdToProject(
      this.props.project,
      this.state.selectedContact
    );
  }

  render() {
    const { project } = this.props;

    if (!project) {
      return <div />;
    }

    return (
      <div>
        <h2>Chercher parmis les contacts existants</h2>
        <label>Nom ou email du contact :</label>{' '}
        <Autocomplete
          inputProps={{
            className: 'form-control',
          }}
          value={this.state.selectedContactSearch}
          items={this.state.matchingContacts}
          getItemValue={(item) => item['@id']}
          onSelect={(selectedId) => {
            const selectedContact = List(this.state.matchingContacts).find(
              (c) => c['@id'] === selectedId
            );
            // set the menu to only the selected item
            this.setState({
              selectedContact,
              matchingContacts: [],
            });
          }}
          onChange={(event, value) => {
            this.setState({ selectedContactSearch: value, loading: true });

            projectContactApi
              .findBy({
                q: value,
                projectNotIn: project.get('@id'),
                fields: '@id,name,job,phone,email',
              })
              .then((items) => {
                this.setState({
                  matchingContacts: items.get('hydra:member').toJS(),
                  loading: false,
                });
              });
          }}
          renderItem={(item, isHighlighted) => (
            <div
              key={item['@id']}
              className={`ProjectContact__item ${
                isHighlighted && 'ProjectContact__highlight'
              }`}
            >
              <div>
                <strong>
                  {item.name} ({item.job})
                </strong>
              </div>
              <div>
                {item.email} {item.phone}
              </div>
            </div>
          )}
        />
        {this.state.selectedContact && (
          <Card
            body
            variant="primary"
            header={
              <h3>
                Ajouter {this.state.selectedContact.name} aux contacts du projet
                ?
              </h3>
            }
          >
            <ButtonToolbar>
              <Button variant="secondary" onClick={this.validSearch}>
                Oui
              </Button>
              <Button variant="secondary" onClick={this.resetSearch}>
                Non
              </Button>
            </ButtonToolbar>
          </Card>
        )}
        <h2>Nouveau contact</h2>
        <Form
          onSubmit={this.props.createUpdateProjectContact}
          validate={validateProjectContactForm}
          initialValues={this.props.initialValues}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Field name="projectId">
                {({ input }) => (
                  <FormControl type="hidden" {...formControlFields(input)} />
                )}
              </Field>
              <Field name="projectContactList">
                {({ input }) => (
                  <FormControl type="hidden" {...formControlFields(input)} />
                )}
              </Field>
              <Field name="job">
                {({ input, meta }) => (
                  <ReduxFormGroup meta={meta}>
                    <FormLabel>
                      Poste <Required />
                    </FormLabel>
                    <FormControl
                      type="text"
                      isValid={meta.touched && !meta.error}
                      isInvalid={meta.touched && meta.error}
                      {...formControlFields(input)}
                    />
                  </ReduxFormGroup>
                )}
              </Field>

              <Field name="name">
                {({ input, meta }) => (
                  <ReduxFormGroup meta={meta}>
                    <FormLabel>
                      Nom <Required />
                    </FormLabel>
                    <FormControl
                      type="text"
                      isValid={meta.touched && !meta.error}
                      isInvalid={meta.touched && meta.error}
                      {...formControlFields(input)}
                    />
                  </ReduxFormGroup>
                )}
              </Field>

              <FormLabel>Email</FormLabel>
              <Field name="email">
                {({ input }) => (
                  <FormControl type="email" {...formControlFields(input)} />
                )}
              </Field>

              <FormLabel>Téléphone</FormLabel>
              <Field name="phone">
                {({ input }) => (
                  <FormControl type="text" {...formControlFields(input)} />
                )}
              </Field>

              <FormLabel>Commentaires</FormLabel>
              <Field name="comment">
                {({ input }) => (
                  <FormControl type="text" {...formControlFields(input)} />
                )}
              </Field>

              <ButtonToolbar>
                <Button type="submit" variant="primary">
                  {this.props.isEdition ? 'Modifier' : 'Créer'}
                </Button>

                <LinkContainer
                  to={`/projects/${this.props.match.params.projectId}/`}
                >
                  <Button variant="link">Annuler</Button>
                </LinkContainer>
              </ButtonToolbar>
            </form>
          )}
        </Form>
      </div>
    );
  }
}

ProjectContactForm.propTypes = {
  '@id': PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  job: PropTypes.string,
  comment: PropTypes.string,
  params: PropTypes.object,
  project: PropTypes.object,
  isEdition: PropTypes.bool.isRequired,
  match: RouterPropTypes.params({
    projectId: PropTypes.string.isRequired,
    contactId: PropTypes.string,
  }).isRequired,
  addContactIdToProject: PropTypes.func.isRequired,
  createUpdateProjectContact: PropTypes.func.isRequired,
  findProjectContact: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  removeCurrentProjectContact: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const currentProjectContact = state.app.get('currentProjectContact');
  const currentProject = state.app.get('currentProject');

  const initialValues = currentProjectContact
    ? currentProjectContact.toJS()
    : {};
  initialValues.projectId = currentProject && currentProject.get('@id');
  initialValues.projectContactList =
    currentProject && currentProject.get('projectContactList');

  return {
    project: currentProject,
    isEdition: !!currentProjectContact,
    initialValues,
  };
}

export default connect(mapStateToProps, {
  addContactIdToProject,
  createUpdateProjectContact,
  removeCurrentProjectContact,
  findProjectContact,
})(ProjectContactForm);
