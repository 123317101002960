import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import machineApi from '../../api/machine';
import LogList from './LogList';

export default function MachineLogList({ machineId }) {
  const [logList, setLogList] = useState(null);

  useEffect(() => {
    machineApi
      .find(machineId, {
        fields: '@id,logList{createdAt,createdBy{@id,fullname},type,comment}',
      })
      .then((machine) => {
        setLogList(machine.logList);
      });
  }, [machineId]);

  return <LogList logList={logList} />;
}
MachineLogList.propTypes = {
  machineId: PropTypes.string.isRequired,
};
