import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { Button, FormLabel } from 'react-bootstrap';
import PrettySelect from '../form/PrettySelect';
import ProjectSelect from '../form/ProjectSelect';

class PlanFilter extends PureComponent {
  constructor(props) {
    super(props);

    this.handleToggleFilter = this.handleToggleFilter.bind(this);
    this.shouldDisplayField = this.shouldDisplayField.bind(this);

    this.state = {
      isDropdownOpen: false,
    };
  }

  handleToggleFilter() {
    this.setState((prevProps) => ({
      isDropdownOpen: !prevProps.isDropdownOpen,
    }));
  }

  shouldDisplayField(name) {
    return this.props.onlyFields.indexOf(name) > -1;
  }

  render() {
    const { onSubmit } = this.props;

    return (
      <div>
        <div>
          <a href="#filter" onClick={this.handleToggleFilter}>
            Filtrer
          </a>
        </div>

        {this.state.isDropdownOpen && (
          <Form
            initialValues={{
              machineStatus: ['to_plan'],
              projectStatus: ['in_progress'],
              projectList: [],
            }}
            onSubmit={({ machineStatus, projectStatus, projectList }) => {
              onSubmit({
                machineStatus: machineStatus.map((s) =>
                  typeof s === 'object' ? s.id : s
                ),
                projectStatus: projectStatus.map((s) =>
                  typeof s === 'object' ? s.id : s
                ),
                projectList: projectList.map((s) =>
                  typeof s === 'object' ? s.id : s
                ),
              });
            }}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                {this.shouldDisplayField('machineStatus') && (
                  <Fragment>
                    <FormLabel>Status des machines</FormLabel>
                    <Field name="machineStatus">
                      {({ input, meta }) => (
                        <PrettySelect
                          multiple
                          options={[
                            { id: 'to_plan', label: 'A planifier' },
                            { id: 'planned', label: 'Planifiées' },
                            { id: 'done', label: 'Terminées' },
                            { id: 'waiting', label: 'En attente' },
                          ]}
                          {...input}
                        />
                      )}
                    </Field>
                  </Fragment>
                )}

                {this.shouldDisplayField('projectStatus') && (
                  <Fragment>
                    <FormLabel>Status du projet</FormLabel>
                    <Field name="projectStatus">
                      {({ input, meta }) => (
                        <PrettySelect
                          multiple
                          placeholder="Status du project"
                          options={[
                            { id: 'waiting', label: 'En attente' },
                            { id: 'in_progress', label: 'En cours' },
                            { id: 'archived', label: 'Archivé' },
                          ]}
                          {...input}
                        />
                      )}
                    </Field>
                  </Fragment>
                )}

                {this.shouldDisplayField('project') && (
                  <div>
                    <FormLabel>Projet</FormLabel>
                    <Field name="projectStatus" subscription={{ value: true }}>
                      {({ input: projectStatusInput }) => (
                        <Field name="projectList">
                          {({ input, meta }) => (
                            <ProjectSelect
                              multiple
                              inStatusList={
                                projectStatusInput.value
                                  ? projectStatusInput.value.map((item) =>
                                      typeof item === 'object' ? item.id : item
                                    )
                                  : []
                              }
                              {...input}
                            />
                          )}
                        </Field>
                      )}
                    </Field>
                  </div>
                )}

                <Button variant="primary" type="submit">
                  Filtrer
                </Button>
              </form>
            )}
          </Form>
        )}
      </div>
    );
  }
}

PlanFilter.defaultProps = {
  onlyFields: ['machineStatus', 'projectStatus', 'project'],
};

PlanFilter.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onlyFields: PropTypes.arrayOf(
    PropTypes.oneOf(['machineStatus', 'projectStatus', 'project'])
  ),
};

export default PlanFilter;
