import { fromJS, List } from 'immutable';
import moment from 'moment';
import history from '../history';
import taskApi from '../api/task';
import taskFileApi from '../api/taskFile';
import { dispatchError } from './error';

function fixDateValues(initialValues, defaultNow = true) {
  const values = initialValues;
  if (values.endDate && moment(values.endDate).isValid()) {
    values.endDate = moment(values.endDate).endOf('day').toISOString();
  } else if (defaultNow) {
    values.endDate = moment().endOf('day').toISOString();
  }

  if (values.startDate && moment(values.startDate).isValid()) {
    values.startDate = moment(values.startDate).startOf('day').toISOString();
  } else if (defaultNow) {
    values.startDate = moment().startOf('day').toISOString();
  }

  return values;
}

function createTask(values, dispatch) {
  return taskApi.create(fixDateValues(values)).then((task) => {
    dispatch({
      type: 'ALERT',
      style: 'success',
      message: 'Tâche créée',
      dismissAfter: 5000,
    });

    dispatch({
      type: 'REMOVE_MACHINES_FROM_CURRENT',
      machines: task.machineList,
    });
  });
}

function updateTask(
  values,
  options = { dontGoBack: false, nextLocation: null }
) {
  return (dispatch) => {
    const innerValues = fromJS(fixDateValues(values, false))
      .merge({
        machineList: values.machineList
          ? values.machineList.map((m) => m['@id'] || m)
          : null,
      })
      .toJS();

    // if no machine list was passed, remove machine list from update as it will trigger a 400
    if (typeof values.machineList === 'undefined') {
      delete innerValues.machineList;
    }

    taskApi
      .update(values['@id'], innerValues)
      .then((task) => {
        dispatch({
          type: 'UPDATE_TASK',
          task,
        });

        if (options.nextLocation) {
          history.push(options.nextLocation);
        } else if (options.dontGoBack !== true) {
          history.goBack();
        }
      })
      .catch((err) => {
        dispatchError(err, dispatch);
        window.scrollTo(0, 0);
      });
  };
}

export function setEditMode(editMode) {
  return {
    type: 'SET_EDIT_MODE',
    editMode,
  };
}

export function fetchPlanningByProject(project) {
  return (dispatch) => {
    taskApi.findByProject(project).then((taskList) => {
      dispatch({
        type: 'RECEIVE_TASKS_FOR_PROJECT',
        taskList,
      });
    });
  };
}

export function fetchTasksWithoutAppointment() {
  return (dispatch) => {
    dispatch({
      type: 'REQUEST_TASKS_WITHOUT_APPOINTMENT',
    });

    taskApi.findWithAppointmentNeeded().then((taskList) => {
      dispatch({
        type: 'RECEIVE_TASKS_WITHOUT_APPOINTMENT',
        taskList,
      });
    });
  };
}

export function findTask(id, fields) {
  return (dispatch) =>
    taskApi.find(id, { fields }).then((task) => {
      dispatch({
        type: 'RECEIVE_CURRENT_TASK',
        task,
      });
    });
}

export function updateMatResAllDay(taskId, matResIdList) {
  taskApi.updateMatResAllDay(taskId, matResIdList).then(() => {
    history.goBack();
  });
}

export function removeCurrentTask() {
  return {
    type: 'REMOVE_CURRENT_TASK',
  };
}

export function appointmentTaken(initialTask) {
  return (dispatch) =>
    taskApi
      .update(initialTask.get('@id'), { status: 'appointment_taken' })
      .then((task) => {
        dispatch({
          type: 'UPDATE_TASK',
          task,
        });
      });
}

export function createUpdateTask(
  initialValues,
  selectedMachines,
  nextLocation
) {
  return (dispatch) => {
    let filteredValues = initialValues;

    if (filteredValues.project === '') {
      delete filteredValues.project;
    }
    if (filteredValues.machinePreparationStatus === '') {
      delete filteredValues.machinePreparationStatus;
    }
    if (filteredValues.machineTransferStatus === '') {
      delete filteredValues.machineTransferStatus;
    }
    const values = filteredValues;

    if (values['@id']) {
      return updateTask(values, { nextLocation })(dispatch);
    }

    // Update
    let promises = List();

    if (selectedMachines) {
      const distinctSiteProjects = selectedMachines.groupBy((machine) =>
        List([
          machine.getIn(['site', '@id']),
          machine.getIn(['project', '@id']),
        ])
      );

      distinctSiteProjects.forEach((machineList) => {
        const tmpValues = fromJS(values).merge({
          machineList: machineList.map((m) => m.get('@id')).toJS(),
          project: machineList.first().getIn(['project', '@id']),
        });

        const tp = createTask(tmpValues.toJS(), dispatch);
        promises = promises.push(tp);
      });
    } else {
      const tp = createTask(values, dispatch);
      promises = promises.push(tp);
    }

    Promise.all(promises.toArray())
      .then(history.goBack)
      .catch((err) => {
        dispatchError(err, dispatch, null);
        window.scrollTo(0, 0);
      });
  };
}

export function moveTaskInPosition(task, position) {
  return (dispatch) => {
    createUpdateTask(
      {
        '@id': task.get('@id'),
        position,
      },
      null,
      `/planning/?currentDate=${moment(task.startDate).format('YYYY-MM-DD')}`
    )(dispatch);
  };
}

export function selectTask(task) {
  return {
    type: 'SELECT_TASK',
    task,
  };
}

export function massUpdateTasks(values, selectedTask) {
  return (dispatch) => {
    const taskList = selectedTask.map((task) => ({
      ...values,
      '@id': task.get('@id'),
    }));

    taskApi
      .updateBulk(taskList, '@id')
      .then(() => {
        dispatch({
          type: 'UPDATED_MASS_TASK',
        });
      })
      .catch((err) => {
        dispatchError(err, dispatch);
        window.scrollTo(0, 0);
      });
  };
}

export function validateTaskForm(values) {
  const errors = {};

  if (!values.userList || values.userList.length === 0) {
    errors.userList = 'Un technicien au minimum doit être renseigné';
  }

  if (!values.startDate) {
    errors.startDate = 'La date de début doit être renseignée';
  }

  return errors;
}

function onTaskImportedFile(file, task) {
  return {
    type: 'TASK_FILE_IMPORTED',
    file,
    fileList: task.fileList,
  };
}

function onImporTaskFileError(dispatch, message) {
  dispatch({
    type: 'ALERT',
    message,
    style: 'danger',
    position: 'FILE_IMPORT',
  });

  dispatch({
    type: 'RESET_TASK_IMPORT',
  });
}

export function handleAddFileToTask(task, files) {
  return (dispatch) => {
    dispatch({
      type: 'TASK_DROP_FILES',
      files,
    });

    files.forEach((file) =>
      taskApi
        .uploadFile(task, file)
        .then((task) => dispatch(onTaskImportedFile(file, task)))
        .catch(() => {
          onImporTaskFileError(
            dispatch,
            "Oops ! Une erreur est survenue lors de l'import du fichier"
          );
        })
    );
  };
}

export function deleteTaskFile(file) {
  taskFileApi.del(file.get('@id'));
}
