import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import Task from '../../../entity/Task';

export default function SignTaskMachinesDetail({ task }) {
  const machineGroup = task.machineList.groupBy((m) => m.get('machineModel'));
  return (
    <Card body>
      <h4>
        {task.machineList.size} machine{task.machineList.size > 1 ? 's' : ''}
      </h4>
      {machineGroup
        .map((list, model) => (
          <p key={model}>
            {model} : {list.size} machine{list.size > 1 ? 's' : ''}
          </p>
        ))
        .toList()}
    </Card>
  );
}

SignTaskMachinesDetail.propTypes = {
  task: PropTypes.instanceOf(Task).isRequired,
};
