import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { connect } from 'react-redux';
import { Button, ButtonToolbar, Table } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { MdLocalShipping, MdPower } from 'react-icons/md';
import * as materialResourceActions from '../../actions/materialResource';
import PagedCollection from '../../entity/PagedCollection';

class MaterialResource extends React.PureComponent {
  constructor(props) {
    super(props);

    this.getMaterialResourceList = this.getMaterialResourceList.bind(this);
  }

  componentDidMount() {
    this.props.findAllMaterialResources();
  }

  getShortId(materialResource) {
    return materialResource.get('@id').replace('/v1/material_resources/', '');
  }

  getMaterialResourceList() {
    return this.props.materialResourceList
      ? this.props.materialResourceList.getMembers()
      : List();
  }

  render() {
    return (
      <div>
        <div>
          <h1>Ressources matérielles</h1>
          <Table striped hover>
            <thead>
              <tr>
                <td>Id</td>
                <td />
                <td>nom</td>
                <td>description</td>
                <td>couleur</td>
                <td>équipe</td>
                <td>Actions</td>
              </tr>
            </thead>
            <tbody>
              {this.getMaterialResourceList().map((materialResource) => (
                <tr key={materialResource.get('@id')}>
                  <td>{this.getShortId(materialResource)}</td>
                  <td>
                    {materialResource.get('type') === 'vehicle' ? (
                      <MdLocalShipping />
                    ) : (
                      <MdPower />
                    )}
                  </td>
                  <td>{materialResource.get('name')}</td>
                  <td>{materialResource.get('description')}</td>
                  <td>
                    <div
                      style={{
                        textAlign: 'center',
                        backgroundColor: materialResource.get(
                          'backgroundColor'
                        ),
                        color: materialResource.get('foregroundColor'),
                      }}
                    >
                      Exemple texte
                    </div>
                  </td>
                  <td>{materialResource.getIn(['team', 'name'])}</td>
                  <td>
                    <ButtonToolbar>
                      <LinkContainer
                        to={`/material-resources/${this.getShortId(
                          materialResource
                        )}/edit`}
                      >
                        <Button variant="secondary" size="sm">
                          modifier
                        </Button>
                      </LinkContainer>
                    </ButtonToolbar>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <h2>Actions</h2>
          <ButtonToolbar>
            <LinkContainer to="/material-resources/create">
              <Button variant="primary">
                + Ajouter une ressource matérielle
              </Button>
            </LinkContainer>
            <LinkContainer to={'/'}>
              <Button variant="link">Retour</Button>
            </LinkContainer>
          </ButtonToolbar>
        </div>
      </div>
    );
  }
}
MaterialResource.propTypes = {
  materialResourceList: null,
};
MaterialResource.propTypes = {
  findAllMaterialResources: PropTypes.func.isRequired,
  materialResourceList: PropTypes.instanceOf(PagedCollection),
};

function mapStateToProps(state) {
  return {
    materialResourceList: state.app.get('materialResourceList'),
  };
}

export default connect(
  mapStateToProps,
  materialResourceActions
)(MaterialResource);
