import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loader from 'react-loader';
import { Redirect } from 'react-router-dom';
import { entityShortId } from '../../idTools';
import ProjectListContainer from './ProjectList';
import BrandFilterContainer from './BrandFilter';
import User from '../../entity/User';

class Home extends React.PureComponent {
  constructor(props) {
    super(props);

    this.handleActiveBrandListChanged = this.handleActiveBrandListChanged.bind(
      this
    );

    this.state = {
      activeBrandList: null,
      allBrandActive: true,
    };
  }

  handleActiveBrandListChanged(activeBrandList, allBrandActive) {
    this.setState({
      activeBrandList,
      allBrandActive,
    });
  }

  render() {
    const { me } = this.props;
    const { activeBrandList, allBrandActive } = this.state;

    if (!me) {
      return <Loader loaded={false} />;
    }
    if (me.isServiceProvider()) {
      return (
        <Redirect
          to={{
            pathname: `/planning/${entityShortId(me)}`,
          }}
        />
      );
    }
    return (
      <Fragment>
        <ProjectListContainer
          activeBrandList={activeBrandList}
          allBrandActive={allBrandActive}
        />

        {!me.isExternal() && (
          <BrandFilterContainer
            onActiveBrandListChange={this.handleActiveBrandListChanged}
            style={{ marginTop: 10 }}
          />
        )}
      </Fragment>
    );
  }
}

Home.defaultProps = {
  me: null,
};

Home.propTypes = {
  me: PropTypes.instanceOf(User),
};

const mapStateToProps = (state) => ({
  me: state.app.get('me'),
});

export const HomeContainer = connect(mapStateToProps, {})(Home);
