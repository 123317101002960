import PropTypes from 'prop-types';
import React from 'react';
import { Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

export default function MainLink({ to, children, exact, ...rest }) {
  return (
    <LinkContainer exact={exact} to={to}>
      <Nav.Item>
        <LinkContainer exact={exact} to={to}>
          <Nav.Link {...rest}>{children}</Nav.Link>
        </LinkContainer>
      </Nav.Item>
    </LinkContainer>
  );
}
MainLink.defaultProps = {
  exact: false,
};
MainLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  exact: PropTypes.bool,
};
