import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Pagination, Table } from 'react-bootstrap';
import moment from 'moment';
import Project from '../../entity/Project';
import stockDetailApi from '../../api/stockDetail';
import RouterPropTypes from '../../prop-types/router';
import { projectStockGoToPage } from '../../actions/project';
import { entityShortId } from '../../idTools';

class ProjectStock extends PureComponent {
  constructor(props) {
    super(props);

    this.goToPage = this.goToPage.bind(this);

    this.state = {
      stockDetailList: null,
    };
  }

  componentDidMount() {
    this.fetchStocks();
  }

  componentDidUpdate(prevProps) {
    const oldProject = prevProps.match.params.projectId;
    const newProject = this.props.match.params.projectId;
    const oldPage = prevProps.match.params.page;
    const newPage = this.props.match.params.page;

    if (
      newProject &&
      (null === this.state.stockDetailList ||
        oldProject !== newProject ||
        oldPage !== newPage)
    ) {
      this.fetchStocks();
    }
  }

  fetchStocks() {
    const { currentProject, currentPage } = this.props;

    if (!currentProject) {
      return;
    }

    stockDetailApi
      .findByProject(currentProject, {
        page: currentPage,
        'quantity[gt]': 0,
        itemsPerPage: 100,
        'order[depot.name]': 'ASC',
        'order[article.refConstr]': 'ASC',
      })
      .then((stockDetailList) => {
        this.setState({
          stockDetailList,
        });
      });
  }

  goToPage(eventKey) {
    this.props.projectStockGoToPage(
      entityShortId(this.props.currentProject),
      eventKey
    );
  }

  render() {
    const { stockDetailList } = this.state;
    const { currentProject, currentPage } = this.props;

    if (null === stockDetailList || !currentProject) {
      return null;
    }

    return (
      <div>
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>Code article</th>
              <th>Code client</th>
              <th>Client affecté</th>
              <th>Référence constructeur</th>
              <th>Dépôt</th>
              <th>Libellé</th>
              <th>N° Série interne</th>
              <th>Mac Adresse</th>
              <th>Q. physique</th>
              <th>D. entrée</th>
              <th>Poids</th>
            </tr>
          </thead>

          <tbody>
            {stockDetailList.getMembers().map((stockDetail) => (
              <tr key={stockDetail.get('@id')}>
                <td>{stockDetail.getIn(['article', 'code'])}</td>
                <td>{entityShortId(stockDetail.get('client'))}</td>
                <td>{stockDetail.getIn(['client', 'name'])}</td>
                <td>{stockDetail.getIn(['article', 'refConstr'])}</td>
                <td>{stockDetail.getIn(['depot', 'name'])}</td>
                <td>{stockDetail.getIn(['article', 'name'])}</td>
                <td>{stockDetail.getIn(['serialNumber'])}</td>
                <td>{stockDetail.getIn(['macAddress'])}</td>
                <td>{stockDetail.getIn(['quantity'])}</td>
                <td>
                  {moment(stockDetail.getIn(['effectiveDate'])).format(
                    'DD/MM/YYYY'
                  )}
                </td>
                <td>{stockDetail.getIn(['article', 'weight'])}</td>
              </tr>
            ))}
          </tbody>
        </Table>

        <Pagination
          items={stockDetailList.getNbPages()}
          activePage={currentPage}
          onSelect={this.goToPage}
        />
      </div>
    );
  }
}

ProjectStock.propTypes = {
  projectStockGoToPage: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  currentProject: PropTypes.instanceOf(Project),
  match: RouterPropTypes.params({
    projectId: PropTypes.string.isRequired,
  }).isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    currentProject: state.app.get('currentProject'),
    currentPage: parseInt(ownProps.match.params.page, 10) || 1,
  };
}

export default connect(mapStateToProps, { projectStockGoToPage })(ProjectStock);
