import config from '../config';
import AbstractClient from './abstractClient';

class MaterialResourceClient extends AbstractClient {
  getPathBase() {
    return `${config.api.version}/material_resources`;
  }
}

export default new MaterialResourceClient();
