import brandApi from '../api/brand';
import history from '../history';

function updateBrand(values, dispatch) {
  return new Promise((resolve, reject) => {
    brandApi
      .update(values.id, values, { fields: '@id' })
      .then(() => {
        history.push('/brands');
        resolve();
      })
      .catch((err) => {
        dispatch({
          type: 'ALERT',
          style: 'danger',
          message: JSON.parse(err.response.text)['hydra:description'],
          dismissAfter: 5000,
        });

        reject();
      });
  });
}

function createBrand(values, dispatch) {
  return new Promise((resolve, reject) => {
    brandApi
      .create(values, { fields: '@id' })
      .then(() => {
        history.push('/brands');
        resolve();
      })
      .catch((err) => {
        dispatch({
          type: 'ALERT',
          style: 'danger',
          message: JSON.parse(err.response.text)['hydra:description'],
          dismissAfter: 5000,
        });
        reject();
      });
  });
}

export function createUpdateBrand(values) {
  return (dispatch) => {
    if (values.id) {
      return updateBrand(values, dispatch);
    }

    return createBrand(values, dispatch);
  };
}

export function deleteBrand(brand) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      brandApi
        .del(brand.get('@id'))
        .then(() => {
          history.push('/brands');
          resolve();
        })
        .catch((err) => {
          dispatch({
            type: 'ALERT',
            style: 'danger',
            message: JSON.parse(err.response.text)['hydra:description'],
            dismissAfter: 5000,
          });
          reject();
        });
    });
}
