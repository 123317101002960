import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { List } from 'immutable';
import { connect } from 'react-redux';
import { Table } from 'react-bootstrap';
import Loader from 'react-loader';
import {
  fetchTasksWithoutAppointment,
  appointmentTaken,
} from '../../actions/task';
import TeamFilter from '../filters/TeamFilter';
import AppointmentRow from './AppointmentRow';
import './index.css';

class Appointment extends PureComponent {
  constructor(props) {
    super(props);

    this.fetchTasks = this.fetchTasks.bind(this);
    this.handleAppointmentTaken = this.handleAppointmentTaken.bind(this);
  }

  componentDidMount() {
    this.fetchTasks();
  }

  fetchTasks() {
    this.props.fetchTasksWithoutAppointment();
  }

  handleAppointmentTaken(task) {
    if (window.confirm('Noter ce rendez-vous comme confirmé ?')) {
      this.props.appointmentTaken(task);
    }
  }

  render() {
    return (
      <Loader loaded={this.props.fetchStatus !== 'IN_PROGRESS'}>
        <div>
          <h1>Rendez-vous à prendre</h1>

          <TeamFilter />

          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <td>Projet</td>
                <td>Machines</td>
                <td>Date</td>
                <td>Contact</td>
                <td>Adresse</td>
                <td>Techiciens</td>
                <td>Commentaire</td>
                <td>Commentaire planning</td>
                <td>Actions</td>
              </tr>
            </thead>
            <tbody>
              {this.props.taskWithoutAppointment.map((task) => (
                <AppointmentRow
                  key={task.get('@id')}
                  task={task}
                  handleAppointmentTaken={this.handleAppointmentTaken}
                />
              ))}
            </tbody>
          </Table>
        </div>
      </Loader>
    );
  }
}

Appointment.propTypes = {
  fetchTasksWithoutAppointment: PropTypes.func.isRequired,
  appointmentTaken: PropTypes.func.isRequired,
  taskWithoutAppointment: ImmutablePropTypes.list.isRequired,
  fetchStatus: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  const teamListFilter =
    state.app.getIn(['savedFilters', 'teamList']) || List();
  const taskWithoutAppointment =
    state.app.getIn(['taskWithoutAppointment', 'hydra:member']) || List();

  const filteredTaskWithoutAppointment = taskWithoutAppointment.filter(
    (task) =>
      teamListFilter.size === 0 ||
      task
        .get('userList')
        .map((user) => user.get('team'))
        .toSet()
        .intersect(teamListFilter).size > 0
  );

  return {
    taskWithoutAppointment: filteredTaskWithoutAppointment,
    fetchStatus: state.app.get('taskWithoutAppointment-fetch-status') || '',
  };
}

export default connect(mapStateToProps, {
  fetchTasksWithoutAppointment,
  appointmentTaken,
})(Appointment);
