import superagent from 'superagent';
import config from '../config';
import AbstractClient from './abstractClient';
import { createEntity } from '../entity/factory';

class TaskClient extends AbstractClient {
  getPathBase() {
    return `${config.api.version}/tasks`;
  }

  findWithAppointmentNeeded() {
    return super.findBy({
      status: 'needs_appointment',
      'order[startDate]': 'ASC',
      fields:
        '@id,status,startDate,endDate,comment,planningComment,mainSite{formattedAddress,siteId},project{name},machineList{machineModel,siteContactFirstname,siteContactName,siteContactPhone,siteContactEmail,site{formattedAddress,siteId}},userList{firstname,lastname,team}',
    });
  }

  findByProject(project) {
    return this.findBy({ project: project.get('@id') });
  }

  findByWeek(year, week, fields) {
    return this.findBy({ year, week, fields, 'order[position]': 'ASC' });
  }

  findPassedForUser(user) {
    return this.findBy({
      user: user.get('@id'),
      'endDate[before]': 'now',
      'machine[status]': 'planned',
      itemsPerPage: 5,
      'order[startDate]': 'ASC',
      fields:
        '@id,startDate,project{name},machine{site{siteId}},mainSite{city}',
    });
  }

  findForUserByMonth(user, year, month, fields) {
    return this.findBy({
      user: user.get('@id'),
      year,
      month,
      fields,
      'order[position]': 'ASC',
    });
  }

  updateMatResAllDay(taskId, matResIdList) {
    return this._getAccessToken().then(
      (accessToken) =>
        new Promise((resolve, reject) => {
          superagent
            .put(`${config.api.url}${taskId}/mat-res-all-day`)
            .set('Authorization', `Bearer ${accessToken}`)
            .send({ matResIdList })
            .accept('json')
            .end((err) => {
              if (err) {
                reject(err);
              } else {
                resolve();
              }
            });
        })
    );
  }

  updateBulk(taskList, fields) {
    return this._getAccessToken().then(
      (accessToken) =>
        new Promise((resolve, reject) => {
          superagent
            .put(`${config.api.url}/v1/tasks/bulk?fields=${fields}`)
            .set('Authorization', `Bearer ${accessToken}`)
            .send({ 'hydra:member': taskList })
            .accept('json')
            .end((err) => {
              if (err) {
                reject(err);
              } else {
                resolve();
              }
            });
        })
    );
  }

  uploadFile(task, file) {
    return this._getAccessToken().then(
      (accessToken) =>
        new Promise((resolve, reject) => {
          superagent
            .post(`${config.api.url}${task.get('@id')}/import`)
            .query({
              fields: 'fileList{@id,uploadedName,type,signatory}',
            })
            .set('Authorization', `Bearer ${accessToken}`)
            .accept('json')
            .attach('file', file)
            .end((err, res) => {
              if (err) {
                reject(err);
              } else {
                const entity = createEntity(JSON.parse(res.text));

                resolve(entity);
              }
            });
        })
    );
  }

  /**
   * @param {Task} task
   * @param {File} signatureFile
   * @param {"client"|"tech"} signatoryType
   * @param {string} signatory
   */
  signTask(task, signatureFile, signatoryType, signatory, fields) {
    return this._getAccessToken().then(
      (accessToken) =>
        new Promise((resolve, reject) => {
          superagent
            .post(`${config.api.url}${task.get('@id')}/sign`)
            .query({
              fields,
              [`${signatoryType}Signatory`]: signatory,
            })
            .set('Authorization', `Bearer ${accessToken}`)
            .accept('json')
            .attach('file', signatureFile)
            .end((err, res) => {
              if (err) {
                reject(err);
              } else {
                const entity = createEntity(JSON.parse(res.text));

                resolve(entity);
              }
            });
        })
    );
  }
}

export default new TaskClient();
