import React, { PureComponent, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  FormControl,
  FormGroup,
  InputGroup,
} from 'react-bootstrap';
import { MdSwitchCamera } from 'react-icons/md';

import './BarcodeScanner.css';

class BarcodeScanner extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      inputDevices: null,
      currentDevice: null,
    };
    this.handleDeviceChange = this.handleDeviceChange.bind(this);
    this.handleCodeFound = this.handleCodeFound.bind(this);
    this.selectNextDevice = this.selectNextDevice.bind(this);
    this.videoRef = React.createRef();
  }
  componentDidMount() {
    this.props.codeReader
      .listVideoInputDevices()
      .then((videoInputDevices) => {
        this.setState({
          inputDevices: videoInputDevices,
          currentDevice: videoInputDevices ? videoInputDevices[0] : null,
        });
      })
      .catch((err) => console.error(err));
  }
  componentDidUpdate() {
    const { inputDevices, currentDevice } = this.state;
    if (inputDevices && currentDevice) {
      this.props.codeReader
        .decodeFromInputVideoDevice(
          currentDevice.deviceId,
          this.videoRef.current
        )
        .then(this.handleCodeFound)
        .catch((err) => console.error(err));
    }
  }
  handleCodeFound(result) {
    this.props.onDetected(result.text);
  }
  handleDeviceChange(event) {
    const currentDevice = this.state.inputDevices.find(
      (device) => device.deviceId === event.target.value
    );
    this.setState({ currentDevice });
  }
  selectNextDevice() {
    const { currentDevice, inputDevices } = this.state;
    const currentDeviceIndex = inputDevices.findIndex(
      (d) => d === currentDevice
    );
    const nextDeviceIndex =
      currentDeviceIndex + 1 >= inputDevices.length
        ? 0
        : currentDeviceIndex + 1;
    this.setState({
      currentDevice: inputDevices[nextDeviceIndex],
    });
  }
  render() {
    const { inputDevices, currentDevice } = this.state;
    if (null === inputDevices) {
      return null;
    }
    return (
      <>
        <Card body>
          <div className="text-center">
            <div className="VideoCapture--dash">
              <video ref={this.videoRef} id="video" width="75%" />
            </div>
          </div>

          {inputDevices.length > 1 && (
            <FormGroup>
              <InputGroup>
                <FormControl
                  as="select"
                  onChange={this.handleDeviceChange}
                  value={currentDevice.deviceId}
                >
                  {inputDevices.map((device) => (
                    <option key={device.deviceId} value={device.deviceId}>
                      {device.label}
                    </option>
                  ))}
                </FormControl>

                <InputGroup.Prepend>
                  <Button variant="secondary" onClick={this.selectNextDevice}>
                    <MdSwitchCamera />
                  </Button>
                </InputGroup.Prepend>
              </InputGroup>
            </FormGroup>
          )}

          {process.env.NODE_ENV !== 'production' && (
            <Button
              onClick={() => {
                this.props.onDetected(
                  String(Math.round(Math.random() * 1000000))
                );
              }}
            >
              Generate random code
            </Button>
          )}
        </Card>
      </>
    );
  }
}

BarcodeScanner.propTypes = {
  onDetected: PropTypes.func.isRequired,
  codeReader: PropTypes.object.isRequired,
};

export default function BarcodeScannerWithXzing(props) {
  const [codeReader, setCodeReader] = useState(null);
  useEffect(() => {
    import('@zxing/library').then(({ BrowserMultiFormatReader }) => {
      setCodeReader(new BrowserMultiFormatReader());
    });
  }, []);

  if (!codeReader) {
    return 'Chargement...';
  }

  return <BarcodeScanner codeReader={codeReader} {...props} />;
}
