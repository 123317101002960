import PropTypes from 'prop-types';
import React from 'react';
import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';
import { FormLabel, FormControl, Button, ButtonToolbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import ReduxFormGroup from './ReduxFormGroup';
import {
  createUpdateProjectGroup,
  findProjectGroup,
} from '../../actions/projectGroup';
import { formControlFields } from './utils';

function validateProjectGroup(values) {
  const errors = {};

  if (!values.name) {
    errors.name = 'Le champ nom est obligatoire';
  }

  return errors;
}

class ProjectGroupForm extends React.PureComponent {
  componentDidMount() {
    this.fetchProjectGroup();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.match.params.projectGroupId !==
      this.props.match.params.projectGroupId
    ) {
      this.fetchProjectGroup();
    }
  }

  fetchProjectGroup() {
    if (this.props.match.params.projectGroupId) {
      this.props.findProjectGroup(this.props.match.params.projectGroupId);
    }
  }

  render() {
    const { createUpdateProjectGroup, initialValues } = this.props;

    return (
      <Form
        onSubmit={createUpdateProjectGroup}
        validate={validateProjectGroup}
        initialValues={initialValues}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <h1>
              {this.props.isEdition ? 'Édition' : 'Création'} groupe de projet
            </h1>

            <Field name="name">
              {({ input, meta }) => (
                <ReduxFormGroup meta={meta}>
                  <FormLabel>Nom</FormLabel>
                  <FormControl
                    type="text"
                    isValid={meta.touched && !meta.error}
                    isInvalid={meta.touched && meta.error}
                    {...formControlFields(input)}
                  />
                </ReduxFormGroup>
              )}
            </Field>

            <ButtonToolbar>
              <Button type="submit" variant="primary">
                {this.props.isEdition ? 'Modifier' : 'Créer'}
              </Button>
              <LinkContainer to="/project-groups/">
                <Button variant="link">Annuler</Button>
              </LinkContainer>
            </ButtonToolbar>
          </form>
        )}
      </Form>
    );
  }
}

ProjectGroupForm.propTypes = {
  '@id': PropTypes.string,
  initialValues: PropTypes.object.isRequired,
  createUpdateProjectGroup: PropTypes.func.isRequired,
  findProjectGroup: PropTypes.func.isRequired,
  isEdition: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const currentProjectGroup = state.app.get('currentProjectGroup');

  return {
    isEdition: !!currentProjectGroup,
    initialValues: currentProjectGroup ? currentProjectGroup.toJS() : {},
  };
}

export default connect(mapStateToProps, {
  createUpdateProjectGroup,
  findProjectGroup,
})(ProjectGroupForm);
