import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { List } from 'immutable';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { FormControl, Button, ButtonToolbar, FormCheck } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import ReduxFormGroup from './ReduxFormGroup';
import {
  createUpdateUser,
  findUser,
  removeCurrentUser,
  findAllUsers,
} from '../../actions/user';
import { findAllTeams } from '../../actions/team';
import { findAllBrand } from '../../actions/home';
import { formControlFields } from './utils';
import PrettySelect from './PrettySelect';
import ProjectSelect from './ProjectSelect';
import User from '../../entity/User';
import PagedCollection from '../../entity/PagedCollection';
import RouterPropTypes from '../../prop-types/router';

function validateUserForm(values) {
  const errors = {};

  if (!values['@id'] && !values.plainPassword) {
    errors.plainPassword =
      'Le mot de passe est obligatoire à la création du compte';
  }

  if (values.plainPassword && values.plainPassword !== values.secondPassword) {
    errors.secondPassword = 'Les mots de passe ne sont pas identiques';
  }

  if (!values.email) {
    errors.email = 'Le champ email est obligatoire';
  }

  if (!values.firstname) {
    errors.firstname = 'Le prénom est obligatoire';
  }

  if (!values.lastname) {
    errors.lastname = 'Le nom est obligatoire';
  }

  if (!values.team) {
    errors.team = "L'équipe est obligatoire";
  }

  return errors;
}

class UserForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.fetchUser = this.fetchUser.bind(this);
    this.getTeamList = this.getTeamList.bind(this);
  }

  componentDidMount() {
    this.fetchUser();
    this.props.findAllTeams();
    this.props.findAllBrand();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.userId !== this.props.match.params.userId) {
      this.fetchUser();
      this.props.findAllTeams();
      this.props.findAllBrand();
    }
  }

  componentWillUnmount() {
    this.props.removeCurrentUser();
    this.props.findAllUsers();
  }

  getTeamList() {
    return this.props.teamList
      ? this.props.teamList.get('hydra:member')
      : List();
  }

  fetchUser() {
    if (this.props.match.params.userId) {
      this.props.findUser(this.props.match.params.userId);
    }
  }

  render() {
    const { createUpdateUser, currentUser } = this.props;
    const initialValues = currentUser
      ? currentUser.toJS()
      : {
          roles: [],
          isActive: true,
          needPasswordChange: false,
        };

    return (
      <Form
        initialValues={initialValues}
        validate={validateUserForm}
        onSubmit={createUpdateUser}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <h1>{this.props.isEdition ? 'Édition' : 'Création'} utilisateur</h1>

            <Field name="email">
              {({ input, meta }) => (
                <ReduxFormGroup meta={meta} label="Email" isRequired>
                  <FormControl
                    isValid={meta.touched && !meta.error}
                    isInvalid={meta.touched && meta.error}
                    type="email"
                    {...formControlFields(input)}
                  />
                </ReduxFormGroup>
              )}
            </Field>

            <Field name="firstname">
              {({ input, meta }) => (
                <ReduxFormGroup meta={meta} isRequired label="Prénom">
                  <FormControl
                    isValid={meta.touched && !meta.error}
                    isInvalid={meta.touched && meta.error}
                    type="string"
                    {...formControlFields(input)}
                  />
                </ReduxFormGroup>
              )}
            </Field>

            <Field name="lastname">
              {({ input, meta }) => (
                <ReduxFormGroup meta={meta} isRequired label="Nom">
                  <FormControl
                    isValid={meta.touched && !meta.error}
                    isInvalid={meta.touched && meta.error}
                    type="string"
                    {...formControlFields(input)}
                  />
                </ReduxFormGroup>
              )}
            </Field>

            <Field name="plainPassword">
              {({ input, meta }) => (
                <ReduxFormGroup
                  meta={meta}
                  isRequired={!this.props.isEdition}
                  label="Mot de passe"
                >
                  <FormControl
                    isValid={meta.touched && !meta.error}
                    isInvalid={meta.touched && meta.error}
                    type="password"
                    {...formControlFields(input)}
                  />
                </ReduxFormGroup>
              )}
            </Field>

            <Field name="secondPassword">
              {({ input, meta }) => (
                <ReduxFormGroup meta={meta} label="Retapez le mot de passe">
                  <FormControl
                    isValid={meta.touched && !meta.error}
                    isInvalid={meta.touched && meta.error}
                    type="password"
                    {...formControlFields(input)}
                  />
                </ReduxFormGroup>
              )}
            </Field>

            <Field name="team">
              {({ input, meta }) => (
                <ReduxFormGroup meta={meta} isRequired label="Équipe">
                  <FormControl
                    isValid={meta.touched && !meta.error}
                    isInvalid={meta.touched && meta.error}
                    as="select"
                    {...formControlFields(input)}
                  >
                    <option />
                    {this.getTeamList().map((team) => (
                      <option key={team.get('@id')} value={team.get('@id')}>
                        {team.get('name')}
                      </option>
                    ))}
                  </FormControl>
                </ReduxFormGroup>
              )}
            </Field>

            <Field name="isActive">
              {({ input }) => (
                <FormCheck
                  type="checkbox"
                  {...formControlFields(input)}
                  id="UserForm__isActive"
                  label="Actif"
                />
              )}
            </Field>

            <Field name="needPasswordChange">
              {({ input }) => (
                <FormCheck
                  type="checkbox"
                  {...formControlFields(input)}
                  id="UserForm__needPasswordChange"
                  label="Forcer le changement de mot de passe"
                />
              )}
            </Field>

            <Field name="roles">
              {({ input, meta }) => (
                <ReduxFormGroup meta={meta} label="Role">
                  <PrettySelect
                    multiple
                    {...formControlFields(input)}
                    options={[
                      { id: 'ROLE_EXTERNAL', label: 'Externe' },
                      { id: 'ROLE_SERVICE_PROVIDER', label: 'Prestataire' },
                      { id: 'ROLE_BROKE_USER', label: 'Utilisateur' },
                      { id: 'ROLE_BROKE_ADMIN', label: 'Admin' },
                    ]}
                  />
                </ReduxFormGroup>
              )}
            </Field>

            <Field name="roles" subscription={{ value: true }}>
              {({ input: rolesInput }) =>
                rolesInput.value.indexOf('ROLE_EXTERNAL') > -1 && (
                  <Fragment>
                    <Field name="projectList">
                      {({ input, meta }) => (
                        <ReduxFormGroup meta={meta} label="Projets">
                          <ProjectSelect
                            multiple
                            {...formControlFields(input)}
                          />
                        </ReduxFormGroup>
                      )}
                    </Field>

                    <Field name="brandList">
                      {({ input, meta }) => (
                        <ReduxFormGroup meta={meta} label="Marque">
                          <PrettySelect
                            multiple
                            {...formControlFields(input)}
                            options={
                              this.props.brandList &&
                              this.props.brandList
                                .getMembers()
                                .map((brand) => ({
                                  id: brand.get('@id'),
                                  label: brand.get('name'),
                                }))
                                .toArray()
                            }
                          />
                        </ReduxFormGroup>
                      )}
                    </Field>
                  </Fragment>
                )
              }
            </Field>

            <Field name="showInTaskList">
              {({ input, meta }) => (
                <ReduxFormGroup
                  meta={meta}
                  label="Afficher dans le planning / les tâches"
                >
                  <FormControl as="select" {...formControlFields(input)}>
                    <option />
                    <option value="default">Défaut</option>
                    <option value="always">Toujours</option>
                    <option value="never">Jamais</option>
                  </FormControl>
                </ReduxFormGroup>
              )}
            </Field>

            <ButtonToolbar>
              <Button type="submit" variant="primary">
                {this.props.isEdition ? 'Modifier' : 'Créer'}
              </Button>
              <LinkContainer to="/users/">
                <Button variant="link">Annuler</Button>
              </LinkContainer>
            </ButtonToolbar>
          </form>
        )}
      </Form>
    );
  }
}

UserForm.defaultTypes = {
  currentUser: null,
  brandList: null,
  isEdition: false,
};

UserForm.propTypes = {
  createUpdateUser: PropTypes.func.isRequired,
  currentUser: PropTypes.instanceOf(User),
  brandList: PropTypes.instanceOf(PagedCollection),
  findAllTeams: PropTypes.func.isRequired,
  findAllBrand: PropTypes.func.isRequired,
  findUser: PropTypes.func.isRequired,
  removeCurrentUser: PropTypes.func.isRequired,
  findAllUsers: PropTypes.func.isRequired,
  isEdition: PropTypes.bool,
  teamList: PropTypes.any,
  match: RouterPropTypes.params({
    userId: PropTypes.string.isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  let currentUser = state.app.get('currentUser');

  if (currentUser && currentUser.getIn(['team', '@id'])) {
    currentUser = currentUser.set('team', currentUser.getIn(['team', '@id']));
  }

  return {
    currentUser,
    isEdition: !!currentUser,
    teamList: state.app.get('teamList'),
    brandList: state.app.get('brandList'),
  };
}

export default connect(mapStateToProps, {
  createUpdateUser,
  findUser,
  removeCurrentUser,
  findAllUsers,
  findAllTeams,
  findAllBrand,
})(UserForm);
