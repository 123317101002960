import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';
import { MdRemoveRedEye, MdPower, MdWarning } from 'react-icons/md';
import { LinkContainer } from 'react-router-bootstrap';
import TaskMachinePreparationStatus from '../task/TaskMachinePreparationStatus';
import TaskMachineTransferStatus from '../task/TaskMachineTransferStatus';
import { entityShortId } from '../../idTools';
import './TaskInfo.css';

class TaskInfo extends React.PureComponent {
  constructor(props) {
    super(props);

    this.onMoveDown = this.onMoveDown.bind(this);
    this.onMoveUp = this.onMoveUp.bind(this);
  }

  onMoveDown() {
    const { task } = this.props;
    this.props.moveTaskInPosition(task, this.props.nextTask.position + 1);
  }

  onMoveUp() {
    const { task } = this.props;
    this.props.moveTaskInPosition(task, this.props.previousTask.position - 1);
  }

  renderMaterialResource(materialResource) {
    if (!materialResource || materialResource.get('type') === 'vehicle') {
      return null;
    }

    const styles = {
      marginLeft: 2,
      padding: 3,
      borderRadius: 2,
      backgroundColor: materialResource.get('backgroundColor') || '#fff',
      color: materialResource.get('foregroundColor') || '#000',
    };

    return (
      <span key={materialResource.get('@id')} style={styles}>
        <MdPower />
      </span>
    );
  }

  render() {
    const {
      task,
      displayMoveButtons,
      displaySelectTask,
      isChecked,
      selectTask,
      previousTask,
      nextTask,
    } = this.props;

    const backgroundColor = task.getVehicle()?.get('backgroundColor');
    const color = task.getVehicle()?.get('foregroundColor');

    const style =
      color || backgroundColor ? { color, backgroundColor } : undefined;

    return (
      <div className="TaskInfo" style={style}>
        <div>
          <span>
            {task.get('status') === 'needs_appointment' && (
              <MdWarning title="RDV non validé" />
            )}{' '}
            <TaskMachinePreparationStatus task={task} asTitle />{' '}
            <TaskMachineTransferStatus task={task} asTitle />{' '}
            {task.getShortTitle()}
          </span>{' '}
          {task.get('materialResourceList').map(this.renderMaterialResource)}{' '}
          {task.get('planningComment') && (
            <em>{task.get('planningComment')}</em>
          )}{' '}
          {displayMoveButtons ? (
            <span>
              {previousTask && (
                <Button
                  variant="secondary"
                  size="sm"
                  className="mr-1"
                  onClick={this.onMoveUp}
                >
                  ↑
                </Button>
              )}
              {nextTask && (
                <Button
                  variant="secondary"
                  size="sm"
                  className="mr-1"
                  onClick={this.onMoveDown}
                >
                  ↓
                </Button>
              )}
            </span>
          ) : (
            <LinkContainer to={`/tasks/${entityShortId(task)}`}>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a>
                <MdRemoveRedEye title="Voir la tâche" />
              </a>
            </LinkContainer>
          )}
          {displaySelectTask && (
            <input
              type="checkbox"
              defaultChecked={isChecked}
              onChange={() => selectTask(task)}
            />
          )}
        </div>
      </div>
    );
  }
}

TaskInfo.propTypes = {
  selectTask: PropTypes.func.isRequired,
  isChecked: PropTypes.bool,
  displayMoveButtons: PropTypes.bool,
  displaySelectTask: PropTypes.bool,
  task: PropTypes.object.isRequired,
  moveTaskInPosition: PropTypes.func,
  previousTask: PropTypes.object,
  nextTask: PropTypes.object,
};

export default TaskInfo;
