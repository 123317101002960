import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {
  Button,
  FormGroup,
  FormLabel,
  FormControl,
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import SignaturePad from 'react-signature-pad-wrapper';
import { useSelector } from 'react-redux';
import { currentUserSelector } from '../../../selector';
import Task from '../../../entity/Task';
import { LinkContainer } from 'react-router-bootstrap';
import { entityShortId } from '../../../idTools';
import { Form, Field } from 'react-final-form';
import { FormControlAdapter } from '../../react-bootstrap-final-form';
import taskApi from '../../../api/task';
import { SIGN_TASK_PATH_PER_MACHINE } from './SignTaskRoutes';

const required = (value) => (value ? undefined : 'Requis');

export default function SignTask({ task, onSign, userType }) {
  const currentUser = useSelector(currentUserSelector);
  const isSigned =
    userType === 'client' ? task.isSignedByClient : task.isSignedByTech;

  const signatureRef = useRef(null);
  function handleSubmit({ clientName, signature }) {
    if (isSigned) {
      onSign();
    } else {
      fetch(signature)
        .then((response) => response.blob())
        .then((blob) => {
          blob.name = 'signature.png';
          taskApi.signTask(task, blob, userType, clientName, '@id').then(() => {
            onSign();
          });
        });
    }
  }

  const userSuffix = userType === 'client' ? 'client' : 'technicien';

  const initialValues = {
    clientName:
      userType === 'client'
        ? task.installation?.get('clientSignatory')
        : task.installation?.get('techSignatory') || currentUser?.fullname,
  };

  return (
    <Form onSubmit={handleSubmit} initialValues={initialValues}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Field
            label={`Nom du ${userSuffix}`}
            name="clientName"
            component={FormControlAdapter}
            validate={required}
            disabled={isSigned}
          />

          {isSigned ? (
            <p>Déjà signé par {initialValues.clientName}</p>
          ) : (
            <Field
              name="signature"
              validate={() =>
                signatureRef.current.isEmpty() ? 'Requis' : undefined
              }
            >
              {({ input, meta }) => (
                <FormGroup controlId={input.name}>
                  <FormLabel>{`Signature du ${userSuffix}`}</FormLabel>
                  <div
                    className={cn({
                      'form-control': true,
                      'is-valid': meta.touched && !meta.error,
                      'is-invalid': meta.touched && meta.error,
                    })}
                    style={{ height: 'auto' }}
                  >
                    <SignaturePad
                      ref={signatureRef}
                      redrawOnResize
                      options={{
                        onBegin: input.onFocus,
                        onEnd: () => {
                          input.onChange(
                            signatureRef.current.toDataURL('image/png')
                          );
                          input.onBlur();
                        },
                      }}
                    />
                  </div>
                  <FormControl.Feedback />
                </FormGroup>
              )}
            </Field>
          )}

          <Container fluid>
            <Row>
              <Col xs={6}>
                {signatureRef.current && (
                  <Button
                    variant="warning"
                    onClick={() => {
                      signatureRef.current.clear();
                      signatureRef.current._signaturePad.onEnd();
                    }}
                  >
                    Effacer
                  </Button>
                )}
              </Col>

              <Col xs={6}>
                <div className="text-right">
                  <LinkContainer
                    to={SIGN_TASK_PATH_PER_MACHINE.replace(
                      ':taskId',
                      entityShortId(task)
                    )}
                  >
                    <Button variant="link">Retour</Button>
                  </LinkContainer>

                  <Button variant="primary" type="submit">
                    Valider
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </form>
      )}
    </Form>
  );
}

SignTask.propTypes = {
  onSign: PropTypes.func.isRequired,
  task: PropTypes.instanceOf(Task).isRequired,
  userType: PropTypes.oneOf(['tech', 'client']).isRequired,
};
