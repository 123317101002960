import history from '../history';
import localforage from 'localforage';
import userApi from '../api/user';

function getNextUrl() {
  return new Promise((resolve) => {
    localforage
      .getItem('nextUrl')
      .then((url) => {
        localforage.removeItem('nextUrl').then(() => resolve(url || '/'));
      })
      .catch(() => {
        resolve('/');
      });
  });
}

export function doLogin(values) {
  return (dispatch) => {
    dispatch({
      type: 'LOGIN_ATTEMPT',
    });

    window.oauthClient
      .getUserToken(values.email, values.password, 'ogip')
      .then(() => {
        userApi.findMe().then((me) => {
          dispatch({
            type: 'LOGIN_SUCCEEDED',
            me,
          });

          getNextUrl().then((url) => {
            history.push(url);
          });
        });
      })
      .catch(() => {
        dispatch({
          type: 'LOGIN_FAILED',
        });
      });
  };
}

export function doLoginWithToken(token) {
  return (dispatch) => {
    dispatch({
      type: 'ONE_DRIVE_AUTH_TOKEN_RECEIVED',
      token: {
        access_token: token,
      },
    });

    window.oauthClient._storeAccessToken({ token }).then(() => {
      userApi.findMe().then((me) => {
        window.oneDriveApi.setToken(me.get('azureToken')).then(() => {
          dispatch({
            type: 'RECEIVE_ME',
            me,
          });

          getNextUrl().then((url) => {
            history.push(url);
          });
        });
      });
    });
  };
}

export function logout() {
  return (dispatch) => {
    window.oauthClient.logout().then(() => {
      dispatch({ type: 'LOGOUT' });
      history.push('/login');
    });
  };
}
