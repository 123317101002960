import superagent from 'superagent';
import config from '../config';
import AbstractClient from './abstractClient';
import AuthIntercept from './AuthIntercept';

class ProjectClient extends AbstractClient {
  getPathBase() {
    return `${config.api.version}/projects`;
  }

  exportProjectAsXls(id, projectName) {
    return this._getAccessToken().then(
      (accessToken) =>
        new Promise((resolve, reject) => {
          superagent
            .get(`${config.api.url}${this.getPathBase()}/${id}/machines.xls`)
            .set('Authorization', `Bearer ${accessToken}`)
            .responseType('blob')
            .use(AuthIntercept)
            .end((err, res) => {
              if (err) {
                reject(err);
              } else {
                const blob = res.xhr.response;
                blob.lastModifiedDate = new Date();
                blob.name = 'a.xls';
                const objectUrl = URL.createObjectURL(blob);

                const saveData = (function saveData() {
                  const a = document.createElement('a');
                  document.body.appendChild(a);
                  a.style = 'display: none';
                  return (data, fileName) => {
                    a.href = objectUrl;
                    a.download = fileName;
                    a.click();
                    URL.revokeObjectURL(objectUrl);
                  };
                })();

                saveData(blob, `ExportOGIP-${projectName}.xls`);
              }
            });
        })
    );
  }
}

export default new ProjectClient();
