import config from '../config';
import AbstractClient from './abstractClient';
import { shortId } from '../idTools';

class InstallationClient extends AbstractClient {
  getPathBase() {
    return `${config.api.version}/installations`;
  }

  findForTask(taskId, queryParams = {}) {
    return this.fetchUrl(
      `${config.api.version}/machines/${shortId(taskId)}/installation`,
      queryParams
    );
  }
}

export default new InstallationClient();
