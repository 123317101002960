import React from 'react';

const Overtime = () => (
  <iframe
    title="Heures supplémentaires"
    src="https://docs.google.com/forms/d/e/1FAIpQLSe3TjC2qY3F4s7KkqThYVzpkbYkrlRav-bAs2vwgwxicsT_ig/viewform?embedded=true"
    width="100%"
    height="500"
    frameBorder="0"
    marginHeight="0"
    marginWidth="0"
  >
    Chargement en cours...
  </iframe>
);

export default Overtime;
