import PropTypes from 'prop-types';
import React from 'react';
import { List } from 'immutable';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import {
  Alert,
  Button,
  ButtonToolbar,
  FormLabel,
  FormControl,
  FormGroup,
} from 'react-bootstrap';
import { deleteProject, createUpdateProject } from '../../actions/project';
import { findAllProjectGroups } from '../../actions/projectGroup';
import { findAllBrand } from '../../actions/home';
import PrettySelect from './PrettySelect';
import { formControlFields } from './utils';
import { entityShortId } from '../../idTools';
import stockClientClient from '../../api/stockClient';
import MediaObjectInput from './MediaObjectInput';

function validateProjectForm(values) {
  const errors = {};

  if (!values.name) {
    errors.name = 'Le nom du projet est obligatoire';
  }

  if (!values.projectGroup && !values.projectGroupName) {
    errors.projectGroup = 'Le groupe de projet est obligatoire';
  }

  return errors;
}

class ProjectForm extends React.Component {
  constructor(props) {
    super(props);

    this.onDeleteCurrentProject = this.onDeleteCurrentProject.bind(this);

    this.state = {
      stockClientList: null,
    };
  }

  componentDidMount() {
    this.props.findAllProjectGroups();
    this.props.findAllBrand();
    stockClientClient.findAll().then((stockClientList) => {
      this.setState({ stockClientList: stockClientList.get('hydra:member') });
    });
  }

  onDeleteCurrentProject() {
    const project = this.props.currentProject;

    const msg = `Supprimer tous le projet "${project.get('name')}" ?
      Attention ! Toutes les données liées seront supprimées.`;
    if (window.confirm(msg)) {
      this.props.deleteProject(project);
    }
  }

  render() {
    const { createUpdateProject, initialValues } = this.props;

    return (
      <Form
        onSubmit={createUpdateProject}
        validate={validateProjectForm}
        initialValues={initialValues}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Field name="id">
              {({ input }) => (
                <FormControl type="hidden" {...formControlFields(input)} />
              )}
            </Field>

            <Field name="name">
              {({ input, meta }) => (
                <FormGroup>
                  <FormLabel>Nom du projet</FormLabel>
                  <FormControl type="text" {...formControlFields(input)} />
                  {meta.touched && meta.error && (
                    <Alert variant="danger">{meta.error}</Alert>
                  )}
                </FormGroup>
              )}
            </Field>

            <Field name="projectGroup">
              {({ input }) => (
                <FormGroup>
                  <FormLabel>Groupe</FormLabel>
                  <FormControl as="select" {...formControlFields(input)}>
                    <option key="newProject" value="">
                      Nouveau Groupe de projets
                    </option>
                    {this.props.projectGroupList.map((pg) => (
                      <option key={pg} value={pg.get('@id')}>
                        {pg.get('name')}
                      </option>
                    ))}
                  </FormControl>
                </FormGroup>
              )}
            </Field>

            <Field name="projectGroupName">
              {({ input, meta }) => (
                <FormGroup>
                  <FormLabel>Ou créer un nouveau groupe</FormLabel>
                  <FormControl type="text" {...formControlFields(input)} />
                  {meta.touched && meta.error && (
                    <Alert variant="danger">{meta.error}</Alert>
                  )}
                </FormGroup>
              )}
            </Field>

            <Field name="brandList">
              {({ input }) => (
                <FormGroup>
                  <FormLabel>Marques</FormLabel>
                  <PrettySelect
                    multiple
                    {...input}
                    options={
                      this.props.brandList &&
                      this.props.brandList
                        .map((brand) => ({
                          id: brand.get('@id'),
                          label: brand.get('name'),
                        }))
                        .toArray()
                    }
                  />
                </FormGroup>
              )}
            </Field>

            <Field name="stockClientList">
              {({ input }) => (
                <FormGroup>
                  <FormLabel>Liaison stock client</FormLabel>
                  <PrettySelect
                    multiple
                    {...input}
                    options={
                      this.state.stockClientList &&
                      this.state.stockClientList
                        .map((client) => ({
                          id: entityShortId(client),
                          label: client.get('name'),
                        }))
                        .toArray()
                    }
                  />
                </FormGroup>
              )}
            </Field>

            <Field name="status">
              {({ input }) => (
                <FormGroup>
                  <FormLabel>Statut du projet</FormLabel>
                  <FormControl as="select" {...formControlFields(input)}>
                    <option value="waiting">En attente</option>
                    <option value="in_progress">En cours</option>
                    <option value="archived">Archivé</option>
                  </FormControl>
                </FormGroup>
              )}
            </Field>

            <Field name="logo">
              {({ input }) => (
                <FormGroup>
                  <FormLabel>Logo</FormLabel>
                  <MediaObjectInput {...input} />
                </FormGroup>
              )}
            </Field>

            <ButtonToolbar>
              <Button type="submit" variant="primary">
                {this.props.isEdition ? 'Modifier' : 'Créer'}
              </Button>

              {this.props.isEdition && (
                <Button variant="danger" onClick={this.onDeleteCurrentProject}>
                  Supprimer le projet
                </Button>
              )}
            </ButtonToolbar>
          </form>
        )}
      </Form>
    );
  }
}

ProjectForm.propTypes = {
  currentProject: null,
};
ProjectForm.propTypes = {
  // id: PropTypes.string,
  // name: PropTypes.string,
  // status: PropTypes.string,
  brandList: PropTypes.object,
  // stockClientList: PropTypes.object,
  deleteProject: PropTypes.func.isRequired,
  createUpdateProject: PropTypes.func.isRequired,
  findAllProjectGroups: PropTypes.func.isRequired,
  findAllBrand: PropTypes.func.isRequired,
  projectGroupList: PropTypes.object.isRequired,
  currentProject: PropTypes.object,
  isEdition: PropTypes.bool.isRequired,
  initialValues: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const currentProject = state.app.get('currentProject');
  const initialValues = currentProject
    ? {
        id: currentProject.get('@id'),
        name: currentProject.get('name'),
        status: currentProject.get('status'),
        logo: currentProject.get('logo'),
        brandList: currentProject
          .get('brandList')
          .map((brand) => brand.get('@id'))
          .toJS(),
        stockClientList: currentProject.get('stockClientList').toJS(),
        projectGroup: currentProject.getIn(['projectGroup', '@id']),
      }
    : {
        status: 'waiting',
      };

  return {
    currentProject,
    isEdition: !!state.app.get('currentProject'),
    brandList: state.app.getIn(['brandList', 'hydra:member']),
    projectGroupList:
      state.app.getIn(['projectGroupList', 'hydra:member']) || List(),
    initialValues,
  };
}

export const ProjectFormContainer = connect(mapStateToProps, {
  deleteProject,
  createUpdateProject,
  findAllProjectGroups,
  findAllBrand,
})(ProjectForm);
