import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Loader from 'react-loader';
import taskApi from '../../api/task';
import { entityShortId } from '../../idTools';
import { MdRepeat } from 'react-icons/md';

class PassedTaskWarning extends PureComponent {
  constructor(props) {
    super(props);

    this.fetchPassedTasks = this.fetchPassedTasks.bind(this);
    this.fetchPassedTasksInBackground = this.fetchPassedTasksInBackground.bind(
      this
    );
    this.fetchInterval = null;

    this.state = {
      taskList: null,
      isLoading: false,
    };
  }

  componentDidMount() {
    this.fetchPassedTasks({ displayLoader: false });

    this.fetchInterval = setInterval(
      this.fetchPassedTasksInBackground,
      3600 * 1000
    );
  }

  componentDidUpdate(prevProps) {
    if (!this.props.me) {
      return;
    }

    if (!prevProps.me || prevProps.me.get('@id') !== this.props.me.get('@id')) {
      this.fetchPassedTasks({ displayLoader: false });
    }
  }

  componentWillUnmount() {
    if (this.fetchInterval) {
      clearInterval(this.fetchInterval);
    }
  }

  fetchPassedTasksInBackground() {
    return this.fetchPassedTasks({ inBackground: true });
  }

  fetchPassedTasks({ displayLoader = true, inBackground = false } = {}) {
    const { me } = this.props;

    this.setState({
      taskList: inBackground ? this.state.taskList : null,
      isLoading: displayLoader,
    });

    if (!me || me.isExternal()) {
      return;
    }

    taskApi.findPassedForUser(me).then((taskList) => {
      this.setState({
        taskList,
        isLoading: false,
      });
    });
  }

  render() {
    const { isLoading, taskList } = this.state;

    if (isLoading) {
      return (
        <Alert variant="warning">
          <Loader loaded={false} parentClassName="loader loader--inline" />
        </Alert>
      );
    }

    if (!taskList) {
      return null;
    }
    const nbTasks = taskList.get('hydra:totalItems');

    if (nbTasks === 0) {
      return null;
    }

    return (
      <Loader loaded={isLoading === false}>
        <Alert variant="warning">
          {nbTasks}
          {' tâches sont notées comme "à plannifier" mais devraient être '}
          terminées.
          <Button
            variant="link"
            onClick={this.fetchPassedTasks}
            title="Re-vérifier"
          >
            <MdRepeat />
          </Button>
          <br />
          Exemples :
          <ul>
            {taskList.get('hydra:member').map((task) => (
              <li key={task.get('@id')}>
                <Link
                  to={`/tasks/${entityShortId(task)}`}
                  className="alert-link"
                >
                  {task.getShortTitle()} - {task.startDate.format('LL')}
                </Link>
              </li>
            ))}
          </ul>
        </Alert>
      </Loader>
    );
  }
}

PassedTaskWarning.defaultPropTypes = {
  me: null,
};

PassedTaskWarning.propTypes = {
  me: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    me: state.app.get('me'),
  };
}

export default connect(mapStateToProps)(PassedTaskWarning);
