/* globals process */
export default {
  oneDrive: {
    password: process.env.REACT_APP_ONEDRIVE_PASSWORD,
    clientId: process.env.REACT_APP_ONEDRIVE_CLIENTID,
    redirectUrl: process.env.REACT_APP_ONE_DRIVE_REDIRECT_URL,
    // scopes: 'onedrive.readwrite',

    oneDriveUnlimitedKey: process.env.REACT_APP_ONEDRIVE_UNLIMITED_KEY,

    ogipDriveId: process.env.REACT_APP_ONEDRIVE_OGIP_DRIVE_ID,
    ogipFolderId: process.env.REACT_APP_ONEDRIVE_OGIP_FOLDER_ID,
  },
  api: {
    url: process.env.REACT_APP_API_URL,
    version: process.env.REACT_APP_API_VERSION,
  },
  oauth: {
    url: process.env.REACT_APP_OAUTH_URL,
  },
  nbMachinesToLoad: process.env.REACT_APP_NB_MACHINES_TO_LOAD,
};
