import { fromJS } from 'immutable';

const choices = fromJS({
  groupOrder: {
    machineDetail: [
      'Action',
      'Machine',
      'Reprise',
      'Info techniques',
      'Emplacement',
      'Contacts',
      'Divers',
    ],
  },
  group: {
    Action: [
      {
        value: 'actionName',
        text: 'Action (Installation/Remplacement/Retrait/Move)',
      },
    ],

    Machine: [
      { value: 'machineSerialNumber', text: 'Num. Série' },
      { value: 'machineMAC', text: 'Adresse MAC' },
      { value: 'machinePN', text: 'PN' },
      { value: 'machineModel', text: 'Modele' },
      { subgroup: 'Option 1', value: 'option1', text: 'Ref accessoire 1' },
      {
        subgroup: 'Option 1',
        value: 'option1Qtt',
        text: 'Quantité accessoire 1',
      },
      {
        subgroup: 'Option 1',
        value: 'option1Designation',
        text: 'Designation accessoire 1',
      },
      { subgroup: 'Option 2', value: 'option2', text: 'Ref accessoire 2' },
      {
        subgroup: 'Option 2',
        value: 'option2Qtt',
        text: 'Quantité accessoire 2',
      },
      {
        subgroup: 'Option 2',
        value: 'option2Designation',
        text: 'Designation accessoire 2',
      },
      { subgroup: 'Option 3', value: 'option3', text: 'Ref accessoire 3' },
      {
        subgroup: 'Option 3',
        value: 'option3Qtt',
        text: 'Quantité accessoire 3',
      },
      {
        subgroup: 'Option 3',
        value: 'option3Designation',
        text: 'Designation accessoire 3',
      },
      { subgroup: 'Option 4', value: 'option4', text: 'Ref accessoire 4' },
      {
        subgroup: 'Option 4',
        value: 'option4Qtt',
        text: 'Quantité accessoire 4',
      },
      {
        subgroup: 'Option 4',
        value: 'option4Designation',
        text: 'Designation accessoire 4',
      },
      { subgroup: 'Option 5', value: 'option5', text: 'Ref accessoire 5' },
      {
        subgroup: 'Option 5',
        value: 'option5Qtt',
        text: 'Quantité accessoire 5',
      },
      {
        subgroup: 'Option 5',
        value: 'option5Designation',
        text: 'Designation accessoire 5',
      },
    ],

    Reprise: [
      { value: 'takebackType', text: 'Reprise type machine' },
      { value: 'takebackSN', text: 'Reprise SN' },
      { value: 'takebackHostname', text: 'Ancien hostname' },
    ],

    'Info techniques': [
      { subgroup: 'Basic', value: 'techinfoIP', text: 'Adresse IP' },
      { subgroup: 'Basic', value: 'techinfoMask', text: 'Masque' },
      { subgroup: 'Basic', value: 'techinfoBridge', text: 'Passerelle' },
      { subgroup: 'Basic', value: 'techinfoHostname', text: "Nom d'hôte" },
      { subgroup: 'Basic', value: 'techinfoFax', text: 'Fax' },
      { subgroup: 'Avancé', value: 'techinfoDomain', text: 'Domaine' },
      { subgroup: 'Avancé', value: 'techinfoDNS1', text: 'DNS 1' },
      { subgroup: 'Avancé', value: 'techinfoDNS2', text: 'DNS 2' },
      { subgroup: 'Avancé', value: 'techinfoSMTP', text: 'SMTP' },
      {
        subgroup: 'Avancé',
        value: 'techinfoResponseAddress',
        text: 'Adresse de Réponse',
      },
      {
        subgroup: 'Avancé',
        value: 'techinfoSMTPUsername',
        text: 'Utilisateur pour authentification SMTP (si necessaire)',
      },
      {
        subgroup: 'Avancé',
        value: 'techinfoSMTPPassword',
        text: 'Mot de passe pour authentification SMTP (si necessaire)',
      },
      {
        subgroup: 'Avancé',
        value: 'techinfoUNCorFTP',
        text: 'Chemin UNC scan Réseau ou FTP',
      },
      {
        subgroup: 'Avancé',
        value: 'techinfoUsername',
        text: 'Utilisateur pour authentification',
      },
      {
        subgroup: 'Avancé',
        value: 'techinfoPassword',
        text:
          "Mot de passe pour authentification (mot de passe n'expire jamais)",
      },
    ],

    Emplacement: [
      { subgroup: 'Adresse', value: 'locationClientName', text: 'Nom Client' },
      { subgroup: 'Adresse', value: 'site_siteId', text: 'Identifiant site' },
      { subgroup: 'Adresse', value: 'site_code', text: 'Code site' },
      { subgroup: 'Adresse', value: 'site_addressLine1', text: 'Adresse 1' },
      { subgroup: 'Adresse', value: 'site_addressLine2', text: 'Adresse 2' },
      { subgroup: 'Adresse', value: 'site_zipcode', text: 'Code postal' },
      { subgroup: 'Adresse', value: 'site_city', text: 'Ville' },
      { subgroup: 'Adresse', value: 'site_state', text: 'État' },
      { subgroup: 'Adresse', value: 'site_country', text: 'Pays' },

      { subgroup: 'Service', value: 'serviceName', text: 'Nom service' },
      { subgroup: 'Service', value: 'serviceBuilding', text: 'Batiment' },
      { subgroup: 'Service', value: 'serviceFloor', text: 'Étage' },
      { subgroup: 'Service', value: 'serviceOffice', text: 'Bureau' },
      { subgroup: 'Service', value: 'serviceMapID', text: 'Map ID' },
      {
        subgroup: 'Service',
        value: 'serviceLift',
        text: 'Ascenceurs / Monte-Charge ?',
      },

      { value: 'locationStorageRoom', text: 'Salle de stockage' },
    ],

    Contacts: [
      {
        subgroup: 'Contact site',
        value: 'siteContactName',
        text: 'Nom contact site',
      },
      {
        subgroup: 'Contact site',
        value: 'siteContactFirstname',
        text: 'Prénom contact site',
      },
      {
        subgroup: 'Contact site',
        value: 'siteContactPhone',
        text: 'Téléphone contact site',
      },
      {
        subgroup: 'Contact site',
        value: 'siteContactEmail',
        text: 'Email contact site',
      },

      {
        subgroup: 'Contact IT',
        value: 'itContactName',
        text: 'Nom contact IT',
      },
      {
        subgroup: 'Contact IT',
        value: 'itContactFirstname',
        text: 'Prénom contact IT',
      },
      {
        subgroup: 'Contact IT',
        value: 'itContactPhone',
        text: 'Téléphone contact IT',
      },
      {
        subgroup: 'Contact IT',
        value: 'itContactEmail',
        text: 'Email contact IT',
      },

      { subgroup: 'Contact 2', value: 'contact2Name', text: 'Nom contact 2' },
      {
        subgroup: 'Contact 2',
        value: 'contact2Firstname',
        text: 'Prénom contact 2',
      },
      {
        subgroup: 'Contact 2',
        value: 'contact2Phone',
        text: 'Téléphone contact 2',
      },
      {
        subgroup: 'Contact 2',
        value: 'contact2Email',
        text: 'Email contact 2',
      },

      { subgroup: 'Contact 3', value: 'contact3Name', text: 'Nom contact 3' },
      {
        subgroup: 'Contact 3',
        value: 'contact3Firstname',
        text: 'Prénom contact 3',
      },
      {
        subgroup: 'Contact 3',
        value: 'contact3Phone',
        text: 'Téléphone contact 3',
      },
      {
        subgroup: 'Contact 3',
        value: 'contact3Email',
        text: 'Email contact 3',
      },
    ],

    Divers: [
      { value: 'brokeId', text: 'Id OGIP', type: 'hidden' },
      { value: 'partnerId', text: 'Id Partenaire' },
      { value: 'orderNumber', text: 'Numéro de commande' },
      { value: 'schedule', text: 'Horaires' },
      { value: 'comment', text: 'Commentaires' },
      { value: 'desiredDate', text: 'Date souhaitée' },
      { value: 'deadline', text: 'Date limite' },
      { value: 'installAfter', text: 'Installation au plus tôt' },
      { value: 'installBefore', text: 'Installation au plus tard' },
      { value: 'observation', text: 'Remarque' },
      { value: 'custom1', text: 'Custom 1' },
      { value: 'custom2', text: 'Custom 2' },
      { value: 'custom3', text: 'Custom 3' },
      { value: 'custom4', text: 'Custom 4' },
      { value: 'custom5', text: 'Custom 5' },
      { value: 'custom6', text: 'Custom 6' },
      { value: 'custom7', text: 'Custom 7' },
      { value: 'custom8', text: 'Custom 8' },
      { value: 'custom9', text: 'Custom 9' },
      { value: 'custom10', text: 'Custom 10' },
    ],
  },
});

function findInList(itemList, value) {
  return itemList.find((item) => item.get('value') === value);
}

export function getGroupName(value) {
  return (
    choices.get('group').findKey((itemList) => findInList(itemList, value)) ||
    null
  );
}

export function getText(value) {
  const foundKey = getGroupName(value);

  if (foundKey) {
    return findInList(choices.getIn(['group', foundKey]), value).get('text');
  }

  return null;
}

export function getAllValues(withSiteModifier = false) {
  return choices
    .get('group')
    .reduce((curr, itemList) => curr.concat(itemList))
    .map((item) => {
      const value = item.get('value');
      if (withSiteModifier) {
        return value.replace('site_', 'site.');
      }
      return value;
    })
    .toArray();
}

export default choices;
