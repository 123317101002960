import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Loader from 'react-loader';
import { Alert, ListGroup, ListGroupItem } from 'react-bootstrap';
import { transType } from './utils';
import Log from '../../entity/Log';

export default function LogList({ logList }) {
  return (
    <Loader loaded={logList !== null} parentClassName="loader loader--inline">
      <ListGroup>
        {logList &&
          logList.map((log) => (
            <ListGroupItem key={log.get('@id')} header={transType(log.type)}>
              {log.comment && (
                <>
                  <span>{log.comment}</span>
                  <br />
                </>
              )}
              <em>
                {log.createdBy && log.createdBy.fullname} -{' '}
                {log.createdAt && log.createdAt.format('lll')}
              </em>
            </ListGroupItem>
          ))}

        {logList && logList.size === 0 && (
          <Alert variant="warning">Aucun log pour cette machine.</Alert>
        )}
      </ListGroup>
    </Loader>
  );
}
LogList.propTypes = {
  logList: ImmutablePropTypes.listOf(Log),
};
