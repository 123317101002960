import history from '../history';
import machineApi from '../api/machine';
import siteApi from '../api/site';
import { shortId, entityId } from '../idTools';
import { dispatchError } from './error';
import {
  MACHINE_DEFAULT_FIELDS,
  MACHINE_FIELD_FOR_TABLE_AND_PLAN,
} from '../entity/Machine';

function receiveCurrentMachine(machine) {
  return {
    type: 'RECEIVE_CURRENT_MACHINE',
    machine,
  };
}

function createMachine(values, dispatch) {
  return machineApi
    .create(values)
    .then((machine) => {
      dispatch({
        type: 'RECEIVE_NEW_MACHINE',
        machine,
      });

      history.push(
        `/projects/${shortId(machine.getIn(['project', '@id']))}/machines/`
      );
    })
    .catch((err) => {
      dispatch({
        type: 'ALERT',
        style: 'danger',
        message: JSON.parse(err.response.text)['hydra:description'],
        dismissAfter: 5000,
      });
    });
}

function updateMachineFromMachinePage(values, dispatch) {
  return new Promise((resolve, reject) => {
    machineApi
      .update(values['@id'], values)
      .then((machine) => {
        dispatch({
          type: 'UPDATE_MACHINE',
          machine,
        });
        history.push(
          `/projects/${shortId(machine.getIn(['project', '@id']))}/machines/`
        );
        resolve();
      })
      .catch((err) => {
        dispatch({
          type: 'ALERT',
          style: 'danger',
          message: JSON.parse(err.response.text)['hydra:description'],
          dismissAfter: 5000,
        });
        reject();
      });
  });
}

export function updateMachine(values) {
  return (dispatch) => {
    machineApi
      .update(values['@id'], values)
      .then((machine) => {
        dispatch({
          type: 'UPDATE_MACHINE',
          machine,
        });
      })
      .catch((err) => {
        dispatch({
          type: 'ALERT',
          style: 'danger',
          message: JSON.parse(err.response.text)['hydra:description'],
          dismissAfter: 5000,
        });
      });
  };
}

function receiveMachineList(machineList) {
  return {
    type: 'RECEIVE_MACHINE_LIST',
    machineList,
  };
}

export function addMachineTableFilter(filter) {
  return {
    type: 'ADD_MACHINE_TABLE_FILTER',
    filter,
  };
}

export function clearMachineTableFilter() {
  return {
    type: 'CLEAR_MACHINE_TABLE_FILTER',
  };
}

export function findMachine(id) {
  return (dispatch) =>
    machineApi.find(id, { fields: MACHINE_DEFAULT_FIELDS }).then((machine) => {
      dispatch(receiveCurrentMachine(machine));
    });
}

export function createUpdateMachine(initialValues) {
  return (dispatch) => {
    const values = initialValues;

    siteApi.findExisting(values.site).then((siteList) => {
      if (siteList['hydra:totalItems'] > 0) {
        const siteId = siteList['hydra:member'][0]['@id'];
        if (values.site) {
          values.site['@id'] = siteId;
        } else {
          values.site = siteId;
        }
      }

      if (values['@id']) {
        return updateMachineFromMachinePage(values, dispatch);
      }

      return createMachine(values, dispatch);
    });
  };
}

export function removeCurrentMachine() {
  return {
    type: 'REMOVE_CURRENT_MACHINE',
  };
}

export function findMachinesForGlobalPlan(bounds, filters) {
  return (dispatch) => {
    const innerFilters = {
      machineStatus: ['to_plan'],
      projectStatus: ['in_progress'],
      projectList: [],
      ...filters,
    };

    const parameters = {
      status: innerFilters.machineStatus.join(','),
      bounds,
      projectStatus: innerFilters.projectStatus.join(','),
      project: innerFilters.projectList.join(','),
      fields: MACHINE_FIELD_FOR_TABLE_AND_PLAN,
    };

    machineApi.findBy(parameters).then((machineList) => {
      dispatch(receiveMachineList(machineList));
    });
  };
}

export function findMachinesForProject(project, params) {
  return (dispatch) =>
    machineApi
      .findByProject(project, {
        ...params,
        fields: MACHINE_FIELD_FOR_TABLE_AND_PLAN,
      })
      .then((machineList) => {
        dispatch(receiveMachineList(machineList));
      })
      .catch(() => dispatch({ type: 'REMOVE_CURRENT_MACHINE_LIST' }));
}

export function findMachinesForProjectWithBounds(project, bounds, filters) {
  return (dispatch) => {
    const innerFilters = {
      machineStatus: ['to_plan'],
      ...filters,
    };

    const parameters = {
      status: innerFilters.machineStatus.join(','),
      bounds,
      project: project.get('@id'),
      fields: MACHINE_FIELD_FOR_TABLE_AND_PLAN,
    };
    machineApi.findBy(parameters).then((machineList) => {
      dispatch(receiveMachineList(machineList));
    });
  };
}

export function findMachinesToPrepare(filters = {}) {
  return (dispatch) =>
    machineApi
      .findBy({
        ...filters,
        preparationStatus: 'to_prepare',
        'order[task.startDate]': 'ASC',
      })
      .then((machineList) => {
        dispatch(receiveMachineList(machineList));
      });
}

export function findMachines(params) {
  return (dispatch) =>
    machineApi
      .findBy({ ...params, fields: MACHINE_FIELD_FOR_TABLE_AND_PLAN })
      .then((machineList) => {
        dispatch(receiveMachineList(machineList));
      });
}

export function handleMachinePreparationStatusChange(
  initialMachine,
  preparationStatus
) {
  return (dispatch) => {
    machineApi
      .update(initialMachine.get('@id'), { preparationStatus })
      .then((machine) => {
        dispatch({
          type: 'UPDATE_MACHINE',
          machine,
        });
      })
      .catch((err) => {
        dispatch({
          type: 'ALERT',
          style: 'danger',
          message: JSON.parse(err.response.text)['hydra:description'],
          dismissAfter: 2000,
        });
      });
  };
}

export function handleMachineStatusChange(initialMachine, status) {
  return (dispatch) => {
    machineApi
      .update(entityId(initialMachine), { status })
      .then((machine) => {
        dispatch({
          type: 'UPDATE_MACHINE',
          machine,
        });
      })
      .catch((err) => {
        dispatch({
          type: 'ALERT',
          style: 'danger',
          message: JSON.parse(err.response.text)['hydra:description'],
          dismissAfter: 2000,
        });
      });
  };
}

export function handleMachineTransferStatusChange(
  initialMachine,
  transferStatus
) {
  return (dispatch) => {
    machineApi
      .update(entityId(initialMachine), { transferStatus })
      .then((machine) => {
        dispatch({
          type: 'UPDATE_MACHINE',
          machine,
        });
      })
      .catch((err) => {
        dispatch({
          type: 'ALERT',
          style: 'danger',
          message: JSON.parse(err.response.text)['hydra:description'],
          dismissAfter: 2000,
        });
      });
  };
}

export function handleMachineListStatusChange(values) {
  return (dispatch) => {
    Object.entries(values).forEach(([machineId, status]) => {
      handleMachineStatusChange(machineId, status)(dispatch);
    });
  };
}

export function handleMachineListTransferStatusChange(values) {
  return (dispatch) => {
    Object.entries(values).forEach(([machineId, transferStatus]) => {
      handleMachineTransferStatusChange(machineId, transferStatus)(dispatch);
    });
  };
}

export function deleteMachine(machine) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      machineApi
        .del(machine.get('@id'))
        .then(() => {
          dispatch({
            type: 'DELETE_MACHINE',
            machine,
          });

          history.goBack();
          resolve();
        })
        .catch((err) => {
          dispatchError(err, dispatch);
          reject();
        });
    });
}

export function machineGoToPage(page) {
  history.push(`/machines/page-${page}`);
}

export function selectMachineList(machineList) {
  return {
    type: 'SELECT_MACHINE_LIST',
    machineList,
  };
}
