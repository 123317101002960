import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Button, Card } from 'react-bootstrap';
import Loader from 'react-loader';
import * as onedriveActions from '../../actions/onedrive';
import './index.css';
import { MdNavigateBefore } from 'react-icons/md';

class Onedrive extends React.PureComponent {
  constructor(props) {
    super(props);

    this.diveInto = this.diveInto.bind(this);
    this.initFolder = this.initFolder.bind(this);
    this.renderContent = this.renderContent.bind(this);
  }

  componentDidMount() {
    this.props.fetchFolderByPath(`/${this.props.projectName}`);
  }

  componentDidUpdate(prevProps) {
    let shouldFetchFolder = false;
    if (!prevProps.hasToken && this.props.hasToken) {
      shouldFetchFolder = true;
    }

    if (
      prevProps.currentData &&
      prevProps.currentData.error &&
      (!this.props.currentData || !this.props.currentData.error)
    ) {
      shouldFetchFolder = true;
    }

    if (shouldFetchFolder) {
      this.props.fetchFolderByPath(`/${this.props.projectName}`);
    }
  }

  diveInto(child, isFolder = false) {
    if (child.folder || isFolder) {
      this.props.fetchFolderById(child.id);
    } else {
      window.open(child.webUrl);
    }
  }

  initFolder() {
    this.props.initFolder(this.props.projectName);
  }

  renderContent() {
    if (!this.props.currentData) {
      return (
        <Card body header="OneDrive">
          Erreur lors de la connexion à OneDrive. Veuillez vous déconnecter /
          reconnecter.
        </Card>
      );
    }

    if (this.props.currentData.error) {
      return (
        <Card body header="OneDrive">
          <div>{this.props.currentData.error.message}</div>

          {this.props.currentData.error.code === 'itemNotFound' && (
            <Button
              variant="secondary"
              className="Onedrive__button"
              onClick={this.initFolder}
            >
              Créer le dossier sur onedrive
            </Button>
          )}
        </Card>
      );
    }

    const isRootFolder =
      !this.props.currentData.parentReference ||
      this.props.currentData.parentReference.path.substr(-11) === '/root:/OGIP';

    return (
      <Card body header="OneDrive">
        {!isRootFolder && (
          <Button
            className="Onedrive__button"
            onClick={() =>
              this.diveInto(this.props.currentData.parentReference, true)
            }
          >
            <MdNavigateBefore />
          </Button>
        )}

        {isRootFolder &&
          (!this.props.currentData.children ||
            this.props.currentData.children.length === 0) && (
            <div>
              Aucun fichier dans le dossier &quot;{this.props.projectName}&quot;
              sur onedrive
            </div>
          )}

        {this.props.currentData.children &&
          this.props.currentData.children.map((child) => (
            <Button
              key={child.id}
              className="Onedrive__button"
              variant={child.file ? 'secondary' : 'primary'}
              onClick={() => this.diveInto(child)}
            >
              {child.name}
              {child.folder && <span> ({child.folder.childCount})</span>}
            </Button>
          ))}
      </Card>
    );
  }

  render() {
    return (
      <Loader loaded={!this.props.fetchingData}>{this.renderContent()}</Loader>
    );
  }
}

Onedrive.propTypes = {
  hasToken: PropTypes.bool.isRequired,
  currentData: PropTypes.object,
  fetchFolderById: PropTypes.func.isRequired,
  fetchFolderByPath: PropTypes.func.isRequired,
  initFolder: PropTypes.func.isRequired,
  fetchingData: PropTypes.bool.isRequired,
  projectName: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    hasToken: !!state.onedrive.get('token'),
    currentData: state.onedrive.get('currentData'),
    fetchingData: !!state.onedrive.get('fetchingData'),
  };
}

export default connect(mapStateToProps, onedriveActions)(Onedrive);
