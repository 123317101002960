export const SIGN_TASK_PATH = '/tasks/:taskId/sign';
export const SIGN_TASK_PATH_INSTALLATION = `${SIGN_TASK_PATH}/installation`;
export const SIGN_TASK_PATH_PER_MACHINE = `${SIGN_TASK_PATH}/per-machine`;
export const SIGN_TASK_PATH_TECH = `${SIGN_TASK_PATH}/tech`;
export const SIGN_TASK_PATH_CLIENT = `${SIGN_TASK_PATH}/client`;
export const SIGN_STEPS = [
  { path: SIGN_TASK_PATH_INSTALLATION, label: 'Compte-rendu' },
  { path: SIGN_TASK_PATH_PER_MACHINE, label: 'Détail par machine' },
  {
    path: SIGN_TASK_PATH_TECH,
    label: 'Signature technicien',
  },
  {
    path: SIGN_TASK_PATH_CLIENT,
    label: 'Signature client',
  },
];
