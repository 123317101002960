import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
// import Loader from 'react-loader';
import {
  Alert,
  Button,
  //   FormLabel,
  //   FormGroup,
  //   FormControl,
  Modal,
} from 'react-bootstrap';
import Task from '../../entity/Task';

function FinishTaskModal({ task, show, onHide, onSubmit }) {
  return (
    // <Loader loaded={!isSubmitting}>
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Terminer l&apos;intervention</Modal.Title>
      </Modal.Header>
      <Form onSubmit={(values) => onSubmit(values.machineList)}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <>
              <Modal.Body>
                <Alert variant="info">
                  Laissez cochées les cases des machines{' '}
                  {task.isTransfer() ? 'transférées' : 'installées'}, décochez
                  les cases des machines qui n&apos;ont pas pu être terminées
                  aujourd&apos;hui.
                </Alert>
                {task.machineList.map((machine) => (
                  <div key={machine.get('@id')} className="checkbox">
                    <label>
                      <Field
                        name={`machineList[${machine.get('@id')}]`}
                        component="input"
                        type="checkbox"
                        initialValue={true}
                      />
                      {machine.get('machineModel') || 'Modele non renseigné'} -
                      {machine.get('machineSerialNumber')}
                    </label>
                  </div>
                ))}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" type="button" onClick={onHide}>
                  Retour
                </Button>
                <Button type="submit" variant="primary">
                  Passer à &quot;terminées&quot; et faire signer
                </Button>
              </Modal.Footer>
            </>
          </form>
        )}
      </Form>
    </Modal>
  );
}

FinishTaskModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  task: PropTypes.instanceOf(Task).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default FinishTaskModal;
