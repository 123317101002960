import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const getId = (item) => item && item.id;

class PrettySelect extends PureComponent {
  render() {
    const { value, onBlur, onChange, multi, multiple, ...props } = this.props; // onBlur and value was on this.props.fields.myField in MyForm

    const isMultiple = multi || multiple;

    return (
      <Select
        value={value || (isMultiple ? [] : '')}
        valueKey="id"
        multi={isMultiple}
        onBlur={() => {
          if (!value) {
            return isMultiple ? onBlur([]) : onBlur(value);
          } else {
            return isMultiple ? onBlur(value.map(getId)) : onBlur(getId(value));
          }
        }}
        onChange={(value) => {
          if (!value) {
            return isMultiple ? onChange([]) : onChange(value);
          } else {
            return isMultiple
              ? onChange(value.map(getId))
              : onChange(getId(value));
          }
        }}
        {...props}
      />
    );
  }
}

PrettySelect.defaultProps = {
  value: null,
  multi: false,
  multiple: false,
};

PrettySelect.propTypes = {
  value: PropTypes.any,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  multi: PropTypes.bool,
  multiple: PropTypes.bool,
};

export default PrettySelect;
