export function openFileFromBlob(blob, filename, type) {
  // It is necessary to create  a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  // pdf for example : "application/pdf"
  const newBlob = new Blob([blob], { type });
  //   var newBlob = blob; // new Blob([blob], { type });

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob);
    return;
  }

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(newBlob);
  const link = document.createElement('a');
  link.href = data;
  link.download = filename;
  link.click();

  setTimeout(function () {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data);
  }, 100);
}
