import React, { Suspense } from 'react';
import {
  // browserHistory,
  // BrowserRouter as Router,
  Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';
import history from './history';
import { AppContainer } from './components/App';
import { HomeContainer } from './components/home';
import { ArchivedProjectContainer } from './components/home/archivedProject';
import { ProjectHomeContainer } from './components/projects/Home';
import { ProjectLayoutContainer } from './components/projects/Layout';
import { MachineContainer } from './components/projects/Machine';
import ProjectStock from './components/projects/ProjectStock';
import { PlanContainer } from './components/projects/Plan';
import ProjectInstallation from './components/projects/installation';
import { MachineImportContainer } from './components/projects/machines/Import';
import { ProjectFormContainer } from './components/form/Project';
import BrandList from './components/brand/List';
import BrandForm from './components/form/Brand';
import { MachineFormContainer } from './components/form/Machine';
import { GlobalMachineContainer } from './components/machine/globalMachine';
import MachinePage from './components/machine/MachineDetail/MachinePage';
import { GlobalPlanContainer } from './components/map/globalPlan';
import Users from './components/user/List';
import GlobalPlanning from './components/planning/globalPlanning';
import UserPlanning from './components/planning/userPlanning';
import UserForm from './components/form/User';
import TeamList from './components/team/List';
import TeamForm from './components/form/Team';
import TaskForm from './components/form/Task';
import TaskLogCreate from './components/form/TaskLogCreate';
import ChangePasswordForm from './components/form/ChangePassword';
import MaterialResourceForm from './components/form/MaterialResourceForm';
import MaterialResources from './components/materialResource/List';
import Login from './components/login';
import OnedriveCallback from './components/OnedriveCallback';
import ProjectGroupContainer from './components/projectGroup';
import ProjectGroupForm from './components/form/ProjectGroup';
import ProjectContactForm from './components/form/ProjectContact';
import Overtime from './components/Overtime';
import Preparation from './components/preparation';
import Transfer from './components/transfer';
import Appointment from './components/appointment';
import MachineExport from './components/machine/Export';
import TaskDetailPages from './components/task/TaskDetailPages';

let Test = () => null;
Test = React.lazy(() => import('./components/Test'));

export const MACHINE_PAGE_PATH = '/machines/:machineId(\\d+)';
export const MACHINE_PAGE_HISTORY_PATH = '/machines/:machineId(\\d+)/logs';

export default function Routes() {
  // const history = syncHistoryWithStore(browserHistory, store);
  // return (<Router history={history}>
  return (
    <Router history={history}>
      <Route component={AppContainer} />
      <Switch>
        <Route exact path="/overtime" component={Overtime} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/change-password" component={ChangePasswordForm} />

        <Route exact path="/" component={HomeContainer} />

        {/* projects */}
        <Route exact path="/onedrive-callback" component={OnedriveCallback} />

        <Route
          exact
          path="/archived-projects"
          component={ArchivedProjectContainer}
        />
        <Route exact path="/projects/create" component={ProjectFormContainer} />

        <Route
          exact
          path="/project-groups/"
          component={ProjectGroupContainer}
        />
        <Route
          exact
          path="/project-groups/:projectGroupId(\d+)/edit"
          component={ProjectGroupForm}
        />

        <Route
          exact
          path="/project-groups/create"
          component={ProjectGroupForm}
        />

        <Route path="/projects/:projectId(\d+)/">
          <Route
            path="/projects/:projectId(\d+)/"
            component={ProjectLayoutContainer}
          />
          <Switch>
            <Route
              exact
              path="/projects/:projectId(\d+)/"
              component={ProjectHomeContainer}
            />

            <Route
              exact
              path="/projects/:projectId(\d+)/contacts/:contactId(\d+)/edit"
              component={ProjectContactForm}
            />
            <Route
              exact
              path="/projects/:projectId(\d+)/contacts/create"
              component={ProjectContactForm}
            />

            <Route
              exact
              path="/projects/:projectId(\d+)/edit"
              component={ProjectFormContainer}
            />
            <Route
              exact
              path="/projects/:projectId(\d+)/machines/import"
              component={MachineImportContainer}
            />
            <Route
              exact
              path="/projects/:projectId(\d+)/stocks/"
              render={(
                { location, match } // as multiple path does not work with react-router 4 and upgrade to 5 is hard
              ) => (
                <Redirect
                  to={{
                    pathname: `/projects/${match.params.projectId}/stocks/page-1`,
                    search: location.search,
                    state: location.state,
                  }}
                />
              )}
            />
            <Route
              exact
              path="/projects/:projectId(\d+)/stocks/page-:page(\d+)"
              component={ProjectStock}
            />
            <Route
              exact
              path="/projects/:projectId(\d+)/machines/"
              render={(
                { location, match } // as multiple path does not work with react-router 4 and upgrade to 5 is hard
              ) => (
                <Redirect
                  to={{
                    pathname: `/projects/${match.params.projectId}/machines/page-1`,
                    search: location.search,
                    state: location.state,
                  }}
                />
              )}
            />
            <Route
              exact
              path="/projects/:projectId(\d+)/machines/page-:page(\d+)"
              component={MachineContainer}
            />
            <Route
              exact
              path="/projects/:projectId(\d+)/plan/"
              component={PlanContainer}
            />
            <Route path="/projects/:projectId(\d+)/installation/">
              <ProjectInstallation />
            </Route>
          </Switch>
        </Route>

        {/* brands */}
        <Route exact path="/brands" component={BrandList} />
        <Route exact path="/brands/:id/edit" component={BrandForm} />
        <Route exact path="/brands/create" component={BrandForm} />

        {/* machines */}
        <Route exact path="/machines/create" component={MachineFormContainer} />
        <Route exact path="/machines/export" component={MachineExport} />
        <Route
          exact
          path="/machines/:machineId(\d+)/edit"
          component={MachineFormContainer}
        />

        {/* users */}
        <Route exact path="/users/" component={Users} />
        <Route exact path="/users/create" component={UserForm} />
        <Route exact path="/users/:userId(\d+)/edit" component={UserForm} />

        {/* team */}
        <Route exact path="/teams/" component={TeamList} />
        <Route exact path="/teams/create" component={TeamForm} />
        <Route exact path="/teams/:teamId(\d+)/edit" component={TeamForm} />

        {/* Global machines */}
        <Route
          exact
          path={[MACHINE_PAGE_PATH, MACHINE_PAGE_HISTORY_PATH]}
          component={MachinePage}
        />
        <Route
          exact
          path="/machines/"
          render={(
            { location } // as multiple path does not work with react-router 4 and upgrade to 5 is hard
          ) => (
            <Redirect
              to={{
                pathname: `/machines/page-1`,
                search: location.search,
                state: location.state,
              }}
            />
          )}
        />
        <Route
          exact
          path="/machines/page-:page(\d+)"
          component={GlobalMachineContainer}
        />

        {/* plan */}
        <Route exact path="/plan/" component={GlobalPlanContainer} />

        {/* material resources */}
        <Route
          exact
          path="/material-resources/"
          component={MaterialResources}
        />
        <Route
          exact
          path="/material-resources/create"
          component={MaterialResourceForm}
        />
        <Route
          exact
          path="/material-resources/:materialResourceId(\d+)/edit"
          component={MaterialResourceForm}
        />

        {/* planning */}
        <Route exact path="/planning/" component={GlobalPlanning} />
        <Route exact path="/planning/:userId(\d+)" component={UserPlanning} />

        {/* task */}
        <Route exact path="/tasks/create" component={TaskForm} />
        <Route
          exact
          path="/tasks/:taskId(\d+)/logs/create"
          component={TaskLogCreate}
        />
        <Route exact path="/tasks/:taskId(\d+)/edit" component={TaskForm} />
        <Route path="/tasks/:taskId(\d+)" component={TaskDetailPages} />

        {/* Appointment */}
        <Route exact path="/appointment/" component={Appointment} />

        {/* Preparation */}
        <Route exact path="/preparation/" component={Preparation} />

        {/* Transfer */}
        <Route exact path="/transfer/" component={Transfer} />

        {/* Test */}
        <Route
          exact
          path="/test/"
          render={() => (
            <Suspense fallback={<div>Chargement...</div>}>
              <Test />
            </Suspense>
          )}
        />
      </Switch>
    </Router>
  );
}
