import { Record } from 'immutable';
import moment from 'moment';
import { createEntity } from './factory';

class Log extends Record({
  '@id': null,
  '@type': null,
  type: null,
  comment: null,
  createdBy: null,
  createdAt: null,
  task: null,
}) {
  constructor(data) {
    const val = data;

    if (val) {
      val.createdBy = val.createdBy ? createEntity(val.createdBy) : null;
      val.createdAt = val.createdAt ? moment(val.createdAt) : null;
      val.task = val.task ? createEntity(val.task) : null;
    }

    return super(val);
  }
}

export default Log;
