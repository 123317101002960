import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Plan from './Plan';
import * as machineActions from '../../actions/machine';
import * as planActions from '../../actions/plan';
import PlanFilter from '../filters/PlanFilter';

export class GlobalPlan extends PureComponent {
  constructor(props) {
    super(props);

    this.handleFilterSubmit = this.handleFilterSubmit.bind(this);

    this.state = {
      filterValues: null,
    };
  }

  componentDidMount() {
    const { bounds, findMachinesForGlobalPlan } = this.props;

    findMachinesForGlobalPlan(bounds.bounds);
  }

  handleFilterSubmit(values) {
    const { bounds, findMachinesForGlobalPlan } = this.props;

    this.setState({
      filterValues: values,
    });

    findMachinesForGlobalPlan(bounds.bounds, values);
  }

  componentDidUpdate(prevProps) {
    const { bounds, findMachinesForGlobalPlan } = this.props;

    if (bounds.bounds !== prevProps.bounds.bounds) {
      findMachinesForGlobalPlan(bounds.bounds, this.state.filterValues);
    }
  }

  render() {
    return (
      <Fragment>
        <PlanFilter onSubmit={this.handleFilterSubmit} />

        <Plan {...this.props} />
      </Fragment>
    );
  }
}

GlobalPlan.propTypes = {
  findMachinesForGlobalPlan: PropTypes.func.isRequired,
  bounds: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    bounds: state.app.get('bounds'),
    currentMachines: state.app.get('currentMachines'),
    currentSites: state.app.get('currentSitesWithMachineToPlan'),
    markers: state.app.get('markers'),
    unpositionnedSites: state.app.get('unpositionnedSites'),
    currentEditingSite: state.app.get('currentEditingSite'),
    selectedMachines: state.app.get('selectedMachines'),
  };
}

export const GlobalPlanContainer = connect(
  mapStateToProps,
  Object.assign({}, machineActions, planActions)
)(GlobalPlan);
