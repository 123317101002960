import { List, Map, Record, fromJS } from 'immutable';
import { createEntity } from './factory';

class Project extends Record({
  '@id': null,
  '@type': null,
  name: null,
  slug: null,
  logo: null,
  status: null,
  projectGroup: null,
  active: true,
  brandList: List(),
  machineStatus: Map(),
  projectContactList: List(),
  stockClientList: List(),
  customInstallationFields: null,
  installationTemplate: null,
  installationNotificationEmail: null,
}) {
  constructor(data) {
    const val = data;

    if (val) {
      val.projectGroup = createEntity(val.projectGroup);
      val.brandList = createEntity(val.brandList);
      val.machineStatus = createEntity(val.machineStatus);
      val.projectContactList = createEntity(val.projectContactList);
      val.stockClientList = List(val.stockClientList);
      val.customInstallationFields = val.customInstallationFields
        ? fromJS(val.customInstallationFields)
        : null;
    }

    return super(val);
  }
}

Project.compareNames = function compareByName(a, b) {
  const aName = a.getIn(['projectGroup', 'name']) || a.name;
  const bName = b.getIn(['projectGroup', 'name']) || b.name;

  if (aName === bName) {
    return 0;
  }
  return aName < bName ? -1 : 1;
};

export default Project;
