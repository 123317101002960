import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { List, Set } from 'immutable';
import { connect } from 'react-redux';
import { Button, Form, Container, Row, Col, Card } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import Loader from 'react-loader';
import brandApi from '../../api/brand';
import { currentUserIsAdminSelector } from '../../selector';
import config from '../../config';

function BrandCheckbox({ id, active, logoUrl, name, onChange }) {
  return (
    <Form.Check
      type="checkbox"
      id={`BranchCheckbox-${id}`}
      checked={active}
      onChange={onChange}
      label={
        <>
          {logoUrl && (
            <>
              <img
                src={`${config.api.url}${logoUrl}`}
                alt={name}
                style={{ height: '1em' }}
              />{' '}
            </>
          )}
          {name}
        </>
      }
    />
  );
}

BrandCheckbox.defaultProps = {
  logoUrl: null,
};
BrandCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  logoUrl: PropTypes.string,
};

function BrandFilterHeader({ isAdmin }) {
  return (
    <div className="d-flex justify-content-between">
      <span>Marques</span>

      {isAdmin && (
        <LinkContainer to="/brands">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a>Gérer les marques</a>
        </LinkContainer>
      )}
    </div>
  );
}

BrandFilterHeader.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
};

export class BrandFilter extends React.PureComponent {
  constructor(props) {
    super(props);

    this.checkAllBrandFilter = this.checkAllBrandFilter.bind(this);
    this.uncheckAllBrandFilter = this.uncheckAllBrandFilter.bind(this);
    this.toggleBrandFilter = this.toggleBrandFilter.bind(this);

    this.state = {
      activeBrandList: Set(),
    };
  }

  componentDidMount() {
    this.props.fetchBrandList();
  }

  componentDidUpdate(prevProps, prevState) {
    const { activeBrandList } = this.state;

    if (!prevProps.isLoaded && this.props.isLoaded) {
      this.checkAllBrandFilter();
    }

    if (prevState.activeBrandList !== activeBrandList) {
      this.props.onActiveBrandListChange(
        activeBrandList,
        activeBrandList.size === this.props.brandList.size
      );
    }
  }

  checkAllBrandFilter() {
    const { brandList } = this.props;

    this.setState({
      activeBrandList: brandList.map((b) => b.get('@id')).toSet(),
    });
  }

  uncheckAllBrandFilter() {
    this.setState({
      activeBrandList: Set(),
    });
  }

  toggleBrandFilter(brand) {
    const brandId = brand.get('@id');

    this.setState(({ activeBrandList }) => ({
      activeBrandList: activeBrandList.includes(brandId)
        ? activeBrandList.remove(brandId)
        : activeBrandList.add(brandId),
    }));
  }

  render() {
    const { brandList, isAdmin, isLoaded, style } = this.props;
    const { activeBrandList } = this.state;

    return (
      <Card style={style}>
        <Card.Header>
          <BrandFilterHeader isAdmin={isAdmin} />
        </Card.Header>

        <Card.Body>
          <Loader parentClassName="loader loader--inline" loaded={isLoaded}>
            <Container fluid>
              <div className="d-flex justify-content-end">
                <Button variant="link" onClick={this.checkAllBrandFilter}>
                  Cocher tout
                </Button>
                <Button variant="link" onClick={this.uncheckAllBrandFilter}>
                  Décocher tout
                </Button>
              </div>

              <Row>
                {brandList.map((brand, key) => (
                  <Col xs={12} sm={3} key={brand.get('name')}>
                    <BrandCheckbox
                      id={brand.get('@id')}
                      name={brand.get('name')}
                      logoUrl={brand.getIn(['logo', 'contentUrl'])}
                      active={activeBrandList.includes(brand.get('@id'))}
                      onChange={() => this.toggleBrandFilter(brand)}
                    />
                  </Col>
                ))}
              </Row>
            </Container>
          </Loader>
        </Card.Body>
      </Card>
    );
  }
}

BrandFilter.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  isLoaded: PropTypes.bool.isRequired,
  style: PropTypes.object.isRequired,
  fetchBrandList: PropTypes.func.isRequired,
  brandList: ImmutablePropTypes.listOf(
    ImmutablePropTypes.mapContains({
      '@id': PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  onActiveBrandListChange: PropTypes.func.isRequired,
};

function reducer(state, action) {
  switch (action.type) {
    case 'REQUEST_BRAND_LIST':
      return {
        brandList: List(),
        isLoaded: false,
      };
    case 'RECEIVE_BRAND_LIST':
      return {
        brandList: action.brandList,
        isLoaded: true,
      };
    default:
      throw new Error(`Unknown action ${action.type}`);
  }
}

function fetchBrandList(dispatch) {
  dispatch({
    type: 'REQUEST_BRAND_LIST',
  });

  brandApi
    .findAll({ fields: '@id,name,logo{@id,contentUrl}' })
    .then((brandList) => {
      dispatch({
        type: 'RECEIVE_BRAND_LIST',
        brandList: brandList.getMembers(),
      });
    });
}

function BrandFilterWithFetch(props) {
  const [state, dispatch] = useReducer(reducer, {
    isLoaded: true,
    brandList: List(),
  });
  return (
    <BrandFilter
      brandList={state.brandList}
      isLoaded={state.isLoaded}
      fetchBrandList={() => fetchBrandList(dispatch)}
      {...props}
    />
  );
}

function mapStateToProps(state) {
  return {
    isAdmin: currentUserIsAdminSelector(state),
  };
}

export default connect(mapStateToProps)(BrandFilterWithFetch);
