import { Map, List, Record } from 'immutable';
import { createEntity } from './factory';

class PagedCollection extends Record({
  '@id': null,
  '@type': null,
  'hydra:totalItems': 0,
  'hydra:itemsPerPage': 0,
  'hydra:member': List(),
  'hydra:view': null,
}) {
  constructor(data) {
    const val = data;
    if (val['hydra:member']) {
      val['hydra:member'] = List(
        val['hydra:member'].map((member) => createEntity(member))
      );
    }

    if (val['hydra:view']) {
      val['hydra:view'] = Map(val['hydra:view']);
    }

    return super(val);
  }

  getMembers() {
    return this['hydra:member'];
  }

  getNbPages() {
    if (this.get('hydra:itemsPerPage')) {
      return Math.ceil(
        this.get('hydra:totalItems') / this.get('hydra:itemsPerPage')
      );
    }

    const lastPage = this.getIn(['hydra:view', 'hydra:last']);

    if (lastPage) {
      const matches = lastPage.match(/\?.*page=(\d+)/);

      if (matches) {
        return matches[1];
      }
    }
  }
}

export default PagedCollection;
