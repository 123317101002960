import brandApi from '../api/brand';

function receiveAllBrand(brandList) {
  return {
    type: 'RECEIVE_ALL_BRAND',
    brandList,
  };
}

// TODO remove when src/components/brand/List.js is redux-free,
// or if not, use a HOC to get brand list on demand instead of globally on all website
export function findAllBrand() {
  return (dispatch) =>
    brandApi
      .findAll({ fields: '@id,name,logo{@id,contentUrl}' })
      .then((brandList) => {
        dispatch(receiveAllBrand(brandList));
      });
}
