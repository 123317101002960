import config from '../config';
import AbstractClient from './abstractClient';

class BrandClient extends AbstractClient {
  getPathBase() {
    return `${config.api.version}/brands`;
  }
}

export default new BrandClient();
