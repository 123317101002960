import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { List } from 'immutable';
import { Form, Field } from 'react-final-form';
import {
  Button,
  ButtonToolbar,
  Col,
  FormGroup,
  FormLabel,
  Row,
} from 'react-bootstrap';
// import 'react-widgets/lib/less/react-widgets.less';
// import 'react-widgets/dist/css/react-widgets.css';
import { massUpdateTasks } from '../../actions/task';
import { findAllUsers } from '../../actions/user';
import { findAllMaterialResources } from '../../actions/materialResource';
import DateInput from './DateInput';
import PrettySelect from './PrettySelect';
import startDateEndDateDecorator from './startDateEndDateDecorator';

class MassTask extends React.PureComponent {
  constructor(props) {
    super(props);
    this.massUpdateTasks = this.massUpdateTasks.bind(this);
  }

  componentDidMount() {
    this.props.findAllUsers();
    this.props.findAllMaterialResources();
  }

  massUpdateTasks(values) {
    this.props.massUpdateTasks(values, this.props.selectedTaskList);
  }

  render() {
    const { initialValues } = this.props;

    const userListOptions = this.props.userList
      .filter((user) => user.showInTaskList !== 'never')
      .map((user) => ({
        id: user.get('@id'),
        label: `${user.get('firstname')} ${user.get('lastname')}`,
      }))
      .unshift({ id: 'select-user', label: 'Sélectionner un utilisateur' })
      .toArray();

    return (
      <Form
        initialValues={initialValues}
        onSubmit={this.massUpdateTasks}
        decorators={[startDateEndDateDecorator]}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <h2>Édition de tâche en masse</h2>

            <Field name="userList">
              {({ input }) => (
                <FormGroup>
                  <FormLabel>Utilisateur</FormLabel>
                  <PrettySelect multiple options={userListOptions} {...input} />
                </FormGroup>
              )}
            </Field>

            <Row>
              <Col sm={6}>
                <Field name="startDate">
                  {({ input }) => (
                    <>
                      <label htmlFor="startDate">Date de début</label>
                      <DateInput id="startDate" field={input} />
                    </>
                  )}
                </Field>
              </Col>

              <Col sm={6}>
                <Field name="endDate">
                  {({ input }) => (
                    <>
                      <label htmlFor="endDate">Date de fin</label>
                      <DateInput id="endDate" isEndDate field={input} />
                    </>
                  )}
                </Field>
              </Col>
            </Row>

            <Field name="materialResourceList">
              {({ input }) => (
                <FormGroup>
                  <FormLabel>Ressources matérielles</FormLabel>
                  <PrettySelect
                    multiple
                    options={this.props.materialResourceList
                      .map((materialResource) => ({
                        id: materialResource.get('@id'),
                        label: `${materialResource.get('name')} ${
                          materialResource.get('description') &&
                          `(${materialResource.get('description')})`
                        }`,
                      }))
                      .toArray()}
                    {...input}
                  />
                </FormGroup>
              )}
            </Field>

            <ButtonToolbar>
              <Button type="submit" variant="primary">
                Modifier les tâches
              </Button>
            </ButtonToolbar>
          </form>
        )}
      </Form>
    );
  }
}

MassTask.propTypes = {
  findAllUsers: PropTypes.func.isRequired,
  findAllMaterialResources: PropTypes.func.isRequired,
  massUpdateTasks: PropTypes.func.isRequired,
  materialResourceList: PropTypes.object.isRequired,
  userList: PropTypes.object.isRequired,
  selectedTaskList: PropTypes.object.isRequired,
  initialValues: PropTypes.shape({
    userList: PropTypes.arrayOf(PropTypes.string),
    materialResourceList: PropTypes.arrayOf(PropTypes.string),
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
  }).isRequired,
};

function mapStateToProps(state) {
  const task = state.app.getIn(['selectedTaskList', 0]);

  const initialValues = {};

  if (task) {
    initialValues.userList = task
      .get('userList')
      .map((user) => user.get('@id'))
      .toArray();
    initialValues.startDate = task.get('startDate').toDate();
    initialValues.endDate = task.get('endDate').toDate();
    initialValues.materialResourceList = task
      .get('materialResourceList')
      .map((mr) => mr.get('@id'))
      .toArray();
  }

  return {
    materialResourceList:
      state.app.getIn(['materialResourceList', 'hydra:member']) || List(),
    userList: state.app.getIn(['userList', 'hydra:member']) || List(),
    selectedTaskList: state.app.get('selectedTaskList') || List(),
    initialValues,
  };
}

export default connect(mapStateToProps, {
  massUpdateTasks,
  findAllUsers,
  findAllMaterialResources,
})(MassTask);
