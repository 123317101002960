import PropTypes from 'prop-types';
import React from 'react';
import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';
import {
  Alert,
  Button,
  ButtonToolbar,
  FormLabel,
  FormControl,
  FormCheck,
} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import * as teamActions from '../../actions/team';
import { formControlFields } from './utils';

class TeamForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.fetchTeam = this.fetchTeam.bind(this);
  }

  componentDidMount() {
    this.fetchTeam();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.teamId !== this.props.match.params.teamId) {
      this.fetchTeam();
    }
  }

  componentWillUnmount() {
    this.props.removeCurrentTeam();
    this.props.findAllTeams();
  }

  fetchTeam() {
    if (this.props.match.params.teamId) {
      this.props.findTeam(this.props.match.params.teamId);
    }
  }

  render() {
    const { createUpdateTeam, initialValues } = this.props;

    return (
      <Form onSubmit={createUpdateTeam} initialValues={initialValues}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <h1>{this.props.isEdition ? 'Édition' : 'Création'} équipe</h1>

            <Field name="name">
              {({ input, meta }) => (
                <>
                  <FormLabel>Nom</FormLabel>
                  <FormControl
                    type="text"
                    {...formControlFields(input)}
                    autoFocus
                  />
                  {meta.touched && meta.error && (
                    <Alert variant="danger">{meta.error}</Alert>
                  )}
                </>
              )}
            </Field>

            <Field name="agency" type="checkbox">
              {({ input }) => (
                <FormCheck
                  type="checkbox"
                  {...formControlFields(input)}
                  id="TeamForm__isAgency"
                  label="Est une agence"
                />
              )}
            </Field>

            <ButtonToolbar>
              <Button type="submit" variant="primary">
                {this.props.isEdition ? 'Modifier' : 'Créer'}
              </Button>
              <LinkContainer to="/teams/">
                <Button variant="link">Annuler</Button>
              </LinkContainer>
            </ButtonToolbar>
          </form>
        )}
      </Form>
    );
  }
}
TeamForm.propTypes = {
  '@id': PropTypes.string,
  name: PropTypes.string,
  initialValues: PropTypes.object.isRequired,
  createUpdateTeam: PropTypes.func.isRequired,
  removeCurrentTeam: PropTypes.func.isRequired,
  findAllTeams: PropTypes.func.isRequired,
  findTeam: PropTypes.func.isRequired,
  isEdition: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const currentTeam = state.app.get('currentTeam');

  return {
    isEdition: !!currentTeam,
    initialValues: currentTeam ? currentTeam.toJS() : {},
  };
}

export default connect(mapStateToProps, teamActions)(TeamForm);
