/* eslint consistent-return: "off" */

import { fromJS } from 'immutable';
import Machine from './Machine';
import Project from './Project';
import ProjectGroup from './ProjectGroup';
import Task from './Task';
import User from './User';
import Log from './Log';
import TaskPagedCollection from '../entity/TaskPagedCollection';
import PagedCollection from '../entity/PagedCollection';

export function createEntity(val) {
  if (!val) {
    return;
  }

  switch (val['@type']) {
    case 'hydra:Collection':
    case 'hydra:PagedCollection': {
      const context = val['@context'];

      switch (context) {
        case '/v1/contexts/Task':
          return new TaskPagedCollection(val);
        default:
          return new PagedCollection(val);
      }
    }

    case 'Task':
      return new Task(val);

    case 'Machine':
      return new Machine(val);

    case 'User':
      return new User(val);

    case 'ProjectGroup':
      return new ProjectGroup(val);

    case 'Project':
      return new Project(val);

    case 'Log':
      return new Log(val);

    default:
      return fromJS(val);
  }
}
