import React, { Fragment, PureComponent } from 'react';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import { Alert, Button } from 'react-bootstrap';
import moment from 'moment';
import machineApi from '../../api/machine';

class Export extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      startDate: null,
      endDate: null,
      errors: {},
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    const { startDate, endDate } = this.state;

    event.preventDefault();

    if (!this.validate()) {
      return false;
    }

    machineApi.exportXls({
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).add(1, 'day').format('YYYY-MM-DD'),
    });
  }

  validate() {
    const { startDate, endDate } = this.state;

    const errors = {};

    if (!startDate) {
      errors.startDate = 'La date de début est requise';
    }

    if (!endDate) {
      errors.endDate = 'La date de fin est requise';
    }

    this.setState({ errors });

    return Object.keys(errors).length === 0;
  }

  render() {
    const { errors, startDate, endDate } = this.state;

    return (
      <Fragment>
        <h1>Export de machines</h1>

        <form onSubmit={this.handleSubmit}>
          <label>
            Depuis le :
            <DateTimePicker
              format="YYYY-MM-DD"
              name="startDate"
              onChange={(value) => this.setState({ startDate: value })}
              time={false}
              value={startDate}
            />
          </label>

          {errors.startDate && (
            <Alert variant="danger">{errors.startDate}</Alert>
          )}

          <label>
            {"Jusqu'au :"}
            <DateTimePicker
              format="YYYY-MM-DD"
              name="endDate"
              onChange={(value) => this.setState({ endDate: value })}
              time={false}
              value={endDate}
            />
          </label>

          {errors.endDate && <Alert variant="danger">{errors.endDate}</Alert>}

          <Button variant="primary" type="submit">
            Exporter
          </Button>
        </form>
      </Fragment>
    );
  }
}

export default Export;
