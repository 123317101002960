import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fromJS } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Form, Field } from 'react-final-form';
import { Button, FormLabel } from 'react-bootstrap';
import projectApi from '../../api/project';
import Project from '../../entity/Project';
import PrettySelect from '../form/PrettySelect';
import { filterProjectList } from '../../actions/project';

export class ProjectFilter extends React.PureComponent {
  constructor(props) {
    super(props);

    this.getProjectListFilterOptions = this.getProjectListFilterOptions.bind(
      this
    );
  }

  componentDidMount() {
    if (null === this.props.projectList) {
      this.props.findAllProject();
    }
  }

  getProjectListFilterOptions() {
    if (!this.props.projectList) {
      return [];
    }

    return this.props.projectList
      .map((project) => ({
        id: project.get('@id'),
        label: project.get('name'),
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
      .toArray();
  }

  render() {
    const { projectListFilter, filterProjectList } = this.props;

    return (
      <Form
        initialValues={{ projectListFilter }}
        onSubmit={({ projectListFilter }) => {
          filterProjectList({
            projectListFilter,
          });
        }}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FormLabel>Projet</FormLabel>
            <Field name="projectListFilter">
              {({ input, meta }) => (
                <PrettySelect
                  multiple
                  options={this.getProjectListFilterOptions()}
                  {...input}
                />
              )}
            </Field>

            <Button variant="primary" type="submit">
              Filtrer
            </Button>
          </form>
        )}
      </Form>
    );
  }
}

ProjectFilter.defaultProps = {
  projectList: null,
};

ProjectFilter.propTypes = {
  findAllProject: PropTypes.func.isRequired,
  projectList: ImmutablePropTypes.listOf(PropTypes.instanceOf(Project)),
  filterProjectList: PropTypes.func.isRequired,
  projectListFilter: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  // little hack because filter can be immutable (from localstorage) or an array (from form)
  const projectListFilter = fromJS(
    state.app.getIn(['savedFilters', 'projectList'])
  );

  return {
    projectListFilter: projectListFilter && projectListFilter.toArray(),
  };
}

const FormElement = connect(mapStateToProps, {
  filterProjectList,
})(ProjectFilter);

function findAllProject() {
  return (dispatch) => {
    dispatch({ type: 'REQUEST_PROJECT_LIST' });
    projectApi
      .findAll({
        fields: '@id,name',
      })
      .then((projectList) => {
        dispatch({
          type: 'RECEIVE_PROJECT_LIST',
          projectList: projectList.getMembers(),
        });
      });
  };
}

function reducer(state, action) {
  switch (action.type) {
    case 'REQUEST_PROJECT_LIST':
      return {
        isLoaded: false,
      };

    case 'RECEIVE_PROJECT_LIST':
      return {
        projectList: action.projectList,

        isLoaded: true,
      };

    default:
      throw new Error(`Unknown action ${action.type}`);
  }
}

function ProjectFilterWithFetch({ ...props }) {
  const [state, dispatch] = useReducer(reducer, {
    projectList: null,
    isLoaded: true,
  });
  return (
    <FormElement
      findAllProject={() => findAllProject()(dispatch)}
      isLoaded={state.isLoaded}
      projectList={state.projectList}
      {...props}
    />
  );
}
export default ProjectFilterWithFetch;
