import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Button, ButtonGroup, Card, Table } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { entityShortId } from '../../idTools';
import * as projectContactActions from '../../actions/projectContact';
import { MdEdit, MdClear } from 'react-icons/md';

class ProjectContact extends React.PureComponent {
  constructor(props) {
    super(props);
    this.removeContactFromProject = this.removeContactFromProject.bind(this);
  }

  removeContactFromProject(contact) {
    if (window.confirm(`Supprimer ${contact.get('name')} du projet ?`)) {
      this.props.removeContactFromProject(this.props.project, contact);
    }
  }

  render() {
    const panelHeader = (
      <div className="d-flex justify-content-between">
        <span>Contact projet</span>
        {this.props.isAdmin && (
          <LinkContainer
            to={`/projects/${entityShortId(
              this.props.project
            )}/contacts/create`}
          >
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a>Ajouter un contact</a>
          </LinkContainer>
        )}
      </div>
    );

    return (
      <Card id="contacts">
        <Card.Header>{panelHeader}</Card.Header>
        <Card.Body>
          {this.props.project.get('projectContactList') &&
          this.props.project.get('projectContactList').size > 0 ? (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <td>Poste</td>
                  <td>Nom</td>
                  <td>Email</td>
                  <td>Téléphone</td>
                  <td>Commentaire</td>
                  {this.props.isAdmin && <td>Actions</td>}
                </tr>
              </thead>
              <tbody>
                {this.props.project.get('projectContactList').map((contact) => (
                  <tr key={contact.get('@id')}>
                    <td>{contact.get('job')}</td>
                    <td>{contact.get('name')}</td>
                    <td>
                      {contact.get('email') && (
                        <a href={`mailto: ${contact.get('email')}`}>
                          {contact.get('email')}
                        </a>
                      )}
                    </td>
                    <td className="text-nowrap">
                      {contact.get('phone') && (
                        <a href={`tel: ${contact.get('phone')}`}>
                          {contact.get('phone')}
                        </a>
                      )}
                    </td>
                    <td>{contact.get('comment')}</td>
                    {this.props.isAdmin && (
                      <td>
                        <ButtonGroup>
                          <LinkContainer
                            to={`/projects/${entityShortId(
                              this.props.project
                            )}/contacts/${entityShortId(contact)}/edit`}
                          >
                            <Button variant="secondary">
                              <MdEdit />
                            </Button>
                          </LinkContainer>
                          <Button
                            variant="danger"
                            type="button"
                            onClick={() =>
                              this.removeContactFromProject(contact)
                            }
                          >
                            <MdClear />
                          </Button>
                        </ButtonGroup>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div>Aucun contact pour ce projet</div>
          )}
        </Card.Body>
      </Card>
    );
  }
}

ProjectContact.propTypes = {
  project: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  removeContactFromProject: PropTypes.func.isRequired,
};

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps, projectContactActions)(ProjectContact);
