import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import Project from '../../../entity/Project';
import InstallationNewFieldForm from '../../form/InstallationNewFieldForm';
import { Button, ListGroup } from 'react-bootstrap';
import { MdClear } from 'react-icons/md';

const DEFAULT_HP_FIELDS = [
  { title: '1. Réseau raccordé ?', type: 'yes_no_na_comment' },
  {
    title: '2. Alimentation raccordée ?',
    type: 'yes_no_na_comment',
  },
  {
    title: '3. Pages de configuration',
    type: 'yes_no_na_comment',
  },
  {
    title: '4. Etiquettes d’identification de l’équipement',
    type: 'yes_no_na_comment',
  },
  {
    title: '5. Formation bouton vert',
    type: 'yes_no_na_comment',
  },
  {
    title: '6. Test impression A3 /A4',
    type: 'yes_no_na_comment',
  },
  { title: '7. Test scan to mail', type: 'yes_no_na_comment' },
  { title: '8. Test scan to folder', type: 'yes_no_na_comment' },
  {
    title: '9. Nombre de driver(s) sur poste(s) installé(s) ',
    type: 'yes_no_na_comment',
  },
];

function CustomInstallationFields({ project, onSubmit }) {
  const customInstallationFields =
    (project && project.get('customInstallationFields')) || List();

  return (
    <div>
      <h2>Liste des champs personnalisé pour la génération de PV</h2>

      <div>
        {customInstallationFields.size > 0 ? (
          <ListGroup>
            {customInstallationFields.map((field) => (
              // eslint-disable-next-line react/jsx-key
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <span>{field.get('title')}</span>
                <Button
                  variant="danger"
                  size="sm"
                  title="Supprimer le champ"
                  onClick={() =>
                    onSubmit(
                      customInstallationFields.filter((i) => i !== field).toJS()
                    )
                  }
                >
                  <MdClear />
                </Button>
              </ListGroup.Item>
            ))}
          </ListGroup>
        ) : (
          <Button
            type="button"
            variant="primary"
            onClick={() => {
              onSubmit(DEFAULT_HP_FIELDS);
            }}
          >
            Ajouter tous les champs &quot;HP&quot;
          </Button>
        )}
      </div>

      <div>
        <h3>Ajouter un champ</h3>
        <InstallationNewFieldForm
          key={customInstallationFields.size} // force re-render when number of fields did changed
          onSubmit={(values) => {
            onSubmit(customInstallationFields.push(values).toJS());
          }}
        />
      </div>
    </div>
  );
}

CustomInstallationFields.propTypes = {
  project: PropTypes.instanceOf(Project).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CustomInstallationFields;
