import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'react-loader';
import Required from './Required';
import {
  Button,
  ButtonToolbar,
  FormLabel,
  FormControl,
  FormGroup,
} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import history from '../../history';
import RouterPropTypes from '../../prop-types/router';
import logApi from '../../api/log';
import TaskLogList from '../log/TaskLogList';
import { transType } from '../log/utils';

class TaskLogCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      comment: '',
      type: 'phone_call',
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  handleSubmit(event) {
    const { comment, type } = this.state;
    const { taskId } = this.props;

    event.preventDefault();
    this.setState({ isSubmitting: true });
    logApi
      .create(
        {
          task: taskId,
          comment,
          type,
        },
        { fields: '@id' }
      )
      .then(() => {
        history.push('/appointment');
      });
  }

  render() {
    const { comment, type, isSubmitting } = this.state;
    const { taskId } = this.props;

    return (
      <Loader loaded={!isSubmitting}>
        <TaskLogList taskId={taskId} />
        <form onSubmit={this.handleSubmit}>
          <input type="hidden" name="taskId" value={taskId} />

          <FormGroup>
            <FormLabel>
              Type <Required />
            </FormLabel>
            <FormControl
              as="select"
              name="type"
              value={type}
              onChange={this.handleInputChange}
            >
              {['phone_call', 'email'].map((i) => (
                <option key={i} value={i}>
                  {transType(i)}
                </option>
              ))}
              ;
            </FormControl>
          </FormGroup>

          <FormGroup>
            <FormLabel>Commentaire</FormLabel>
            <FormControl
              as="textarea"
              name="comment"
              col="50"
              rows="3"
              value={comment}
              onChange={this.handleInputChange}
            />
          </FormGroup>

          <ButtonToolbar>
            <Button type="submit" variant="primary">
              Ajouter
            </Button>

            <LinkContainer to="/appointment/">
              <Button variant="link">Annuler</Button>
            </LinkContainer>
          </ButtonToolbar>
        </form>
      </Loader>
    );
  }
}

TaskLogCreate.propTypes = {
  taskId: PropTypes.string.isRequired,
};

const TaskLogWithRouteParams = ({
  match: {
    params: { taskId },
  },
  ...rest
}) => {
  return <TaskLogCreate taskId={`/v1/tasks/${taskId}`} {...rest} />;
};
TaskLogWithRouteParams.propTypes = {
  match: RouterPropTypes.params({ taskId: PropTypes.string.isRequired })
    .isRequired,
};

export default TaskLogWithRouteParams;
