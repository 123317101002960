import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { fromJS } from 'immutable';
import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';
import { Button, FormLabel } from 'react-bootstrap';
import PrettySelect from '../form/PrettySelect';
import { findAllTeams, filterTeamList } from '../../actions/team';
import PagedCollection from '../../entity/PagedCollection';

export class TeamFilter extends React.PureComponent {
  constructor(props) {
    super(props);

    this.getTeamListFilterOptions = this.getTeamListFilterOptions.bind(this);
  }

  componentDidMount() {
    this.props.findAllTeams();
  }

  getTeamListFilterOptions() {
    if (!this.props.teamList) {
      return [];
    }

    return this.props.teamList
      .getMembers()
      .map((team) => ({
        id: team.get('@id'),
        label: team.get('name'),
      }))
      .toArray();
  }

  render() {
    const { teamListInitialValue } = this.props;

    return (
      <Form
        initialValues={{
          teamListFilter:
            teamListInitialValue && teamListInitialValue.toArray(),
        }}
        onSubmit={this.props.filterTeamList}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FormLabel>Équipe</FormLabel>
            <Field name="teamListFilter">
              {({ input }) => (
                <PrettySelect
                  multiple
                  options={this.getTeamListFilterOptions()}
                  {...input}
                />
              )}
            </Field>

            <Button variant="primary" type="submit">
              Filtrer
            </Button>
          </form>
        )}
      </Form>
    );
  }
}

TeamFilter.defaultProps = {
  teamList: null,
  teamListInitialValue: null,
};
TeamFilter.propTypes = {
  findAllTeams: PropTypes.func.isRequired,
  filterTeamList: PropTypes.func.isRequired,
  teamList: PropTypes.instanceOf(PagedCollection),
  teamListInitialValue: ImmutablePropTypes.list,
};

function mapStateToProps(state) {
  // little hack because filter can be immutable (from localstorage) or an array (from form)
  const teamListInitialValue = fromJS(
    state.app.getIn(['savedFilters', 'teamList'])
  );

  return {
    teamList: state.app.get('teamList'),
    teamListInitialValue,
  };
}

export default connect(mapStateToProps, { findAllTeams, filterTeamList })(
  TeamFilter
);
