import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import TimeGrid from 'react-big-calendar/lib/TimeGrid';
moment.locale('fr');

class OgipCalendarView extends React.PureComponent {
  render() {
    let { date, ...props } = this.props;
    let range = OgipCalendarView.range(date, { culture: props.culture });

    return <TimeGrid {...props} range={range} />;
  }
}

OgipCalendarView.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
};

OgipCalendarView.defaultProps = TimeGrid.defaultProps;

// eslint-disable-next-line no-unused-vars
OgipCalendarView.range = (date, { culture }) => {
  const momentDate = moment(date);
  let currentTimestamp = momentDate.clone().startOf('week').valueOf();
  let end = momentDate.clone().add(1, 'weeks').endOf('week').valueOf();

  let days = [];

  while (currentTimestamp < end) {
    const d = new Date();
    d.setTime(currentTimestamp);
    currentTimestamp += 86400000; // 86_400_000

    days.push(d);
  }

  // exclude saturday and sunday
  return days.filter((d) => [6, 0].indexOf(d.getDay()) === -1);
};

OgipCalendarView.navigate = (date, action) => {
  switch (action) {
    case 'PREV':
      return moment(date).subtract(1, 'week').toDate();

    case 'NEXT':
      return moment(date).add(1, 'week').toDate();

    default:
      return date;
  }
};

// eslint-disable-next-line no-unused-vars
OgipCalendarView.title = (date, { formats, culture }) => {
  return ''; // as we use "toolbar=false", there is no need to return a title
};

export default OgipCalendarView;
