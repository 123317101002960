export function dispatchError(err, dispatch, dismissAfter = 5000) {
  const message = err.response
    ? JSON.parse(err.response.text)['hydra:description']
    : err.message || err;

  dispatch({
    type: 'ALERT',
    style: 'danger',
    message,
    dismissAfter,
  });
}

export function dispatchErrorAction(err, dismissAfter = 5000) {
  return (dispatch) => {
    dispatchError(err, dispatch, dismissAfter);
  };
}
