import PropTypes from 'prop-types';
import React from 'react';
import { is, List } from 'immutable';
import { connect } from 'react-redux';
import { Button, ButtonGroup, ButtonToolbar, Table } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import * as userActions from '../../actions/user';

class User extends React.PureComponent {
  constructor(props) {
    super(props);

    this.onMoveDown = this.onMoveDown.bind(this);
    this.onMoveUp = this.onMoveUp.bind(this);
  }

  componentDidMount() {
    this.props.findAllUsers();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userList && !is(prevProps.userList, this.props.userList)) {
      this.props.findAllUsers();
    }
  }

  onMoveDown(user) {
    this.props.moveUserDown(user);
  }

  onMoveUp(user) {
    this.props.moveUserUp(user);
  }

  getShortId(user) {
    return user.get('@id').replace('/v1/users/', '');
  }

  getUserList() {
    return this.props.userList
      ? this.props.userList.get('hydra:member')
      : List();
  }

  deactivateUser(user) {
    return this.props.deactivateUser(user);
  }

  activateUser(user) {
    return this.props.activateUser(user);
  }

  render() {
    return (
      <div>
        {this.props.children || (
          <div>
            <h1>Utilisateurs</h1>
            <Table striped hover>
              <thead>
                <tr>
                  <td>email</td>
                  <td>prénom</td>
                  <td>nom</td>
                  <td>équipe</td>
                  <td>actif</td>
                  <td>Actions</td>
                </tr>
              </thead>
              <tbody>
                {this.getUserList().map((user) => (
                  <tr key={user.get('@id')}>
                    <td>{user.get('email')}</td>
                    <td>{user.get('firstname')}</td>
                    <td>{user.get('lastname')}</td>
                    <td>{user.getIn(['team', 'name'])}</td>
                    <td>{user.get('isActive') ? 'oui' : 'non'}</td>
                    <td>
                      <ButtonToolbar>
                        <ButtonGroup>
                          <Button
                            variant="link"
                            onClick={() => this.onMoveUp(user)}
                          >
                            ↑
                          </Button>
                          <Button
                            variant="link"
                            onClick={() => this.onMoveDown(user)}
                          >
                            ↓
                          </Button>
                        </ButtonGroup>

                        <ButtonGroup>
                          <LinkContainer
                            to={`/planning/${this.getShortId(user)}`}
                          >
                            <Button variant="primary">planning</Button>
                          </LinkContainer>
                          <LinkContainer
                            to={`/users/${this.getShortId(user)}/edit`}
                          >
                            <Button variant="secondary">modifier</Button>
                          </LinkContainer>
                          {user.get('isActive') ? (
                            <Button
                              variant="warning"
                              onClick={() => this.deactivateUser(user)}
                            >
                              désactiver
                            </Button>
                          ) : (
                            <Button
                              variant="success"
                              onClick={() => this.activateUser(user)}
                            >
                              activer
                            </Button>
                          )}
                        </ButtonGroup>
                      </ButtonToolbar>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            <h2>Actions</h2>
            <ButtonGroup>
              <LinkContainer to="/users/create">
                <Button variant="primary">+ Ajouter un utilisateur</Button>
              </LinkContainer>
              <LinkContainer to="/teams/">
                <Button variant="secondary">Gérer les équipes</Button>
              </LinkContainer>
            </ButtonGroup>
            <LinkContainer to={'/'}>
              <Button variant="link">Retour</Button>
            </LinkContainer>
          </div>
        )}
      </div>
    );
  }
}

User.propTypes = {
  findAllUsers: PropTypes.func.isRequired,
  userList: PropTypes.object,
  deactivateUser: PropTypes.func.isRequired,
  activateUser: PropTypes.func.isRequired,
  children: PropTypes.node,
  moveUserUp: PropTypes.func.isRequired,
  moveUserDown: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    userList: state.app.get('userList'),
  };
}

export default connect(mapStateToProps, userActions)(User);
