import React from 'react';
import PropTypes from 'prop-types';
import { Button, Nav, NavItem } from 'react-bootstrap';
import Loader from 'react-loader';
import { useRouteMatch } from 'react-router-dom';
import history from '../../../history';
import RouterPropTypes from '../../../prop-types/router';
import Machine from '../../../entity/Machine';
import MachineDetail from './MachineDetail';
import MachineActions from './MachineActions';
import MachineLogList from '../../log/MachineLogList';
import { LinkContainer } from 'react-router-bootstrap';
import { entityShortId } from '../../../idTools';
import { MACHINE_DEFAULT_FIELDS } from '../../../entity/Machine';
import { MACHINE_PAGE_HISTORY_PATH, MACHINE_PAGE_PATH } from '../../../routes';
import { useMachineFetch } from '../../hooks/fetch';
import './index.css';

function MachineLayout({ machine }) {
  const shortId = entityShortId(machine);
  const machinePageMatch = useRouteMatch({
    path: MACHINE_PAGE_PATH,
    exact: true,
  });
  const historyMatch = useRouteMatch({
    path: MACHINE_PAGE_HISTORY_PATH,
    exact: true,
  });
  const activeMatch = machinePageMatch || historyMatch;

  return (
    <div>
      <Button variant="link" onClick={history.goBack}>
        Retour
      </Button>

      <Nav variant="tabs" activeKey={activeMatch && activeMatch.path}>
        <NavItem>
          <LinkContainer exact to={`/machines/${shortId}`}>
            <Nav.Link eventKey={MACHINE_PAGE_PATH}>Détail machine</Nav.Link>
          </LinkContainer>
        </NavItem>
        <NavItem>
          <LinkContainer exact to={`/machines/${shortId}/logs`}>
            <Nav.Link eventKey={MACHINE_PAGE_HISTORY_PATH}>Historique</Nav.Link>
          </LinkContainer>
        </NavItem>
      </Nav>

      {machinePageMatch && <MachineDetail machine={machine} />}
      {historyMatch && <MachineLogList machineId={machine.get('@id')} />}

      <MachineActions machine={machine} />
    </div>
  );
}

MachineLayout.propTypes = {
  machine: PropTypes.instanceOf(Machine).isRequired,
};

function MachinePage({
  match: {
    params: { machineId },
  },
  ...rest
}) {
  const [machine, isLoading] = useMachineFetch(
    machineId,
    `${MACHINE_DEFAULT_FIELDS},isPVAvailable,project{customInstallationFields}`
  );

  return (
    <Loader loaded={!isLoading} parentClassName="loader loader--inline">
      {machine && <MachineLayout machine={machine} {...rest} />}
    </Loader>
  );
}
MachinePage.propTypes = {
  match: RouterPropTypes.params({
    machineId: PropTypes.string.isRequired,
  }).isRequired,
};

export default MachinePage;
