import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import logApi from '../../api/log';
import LogList from './LogList';

export default function TaskLogList({ taskId }) {
  const [logList, setLogList] = useState(null);

  useEffect(() => {
    logApi
      .findBy({
        task: taskId,
        fields: '@id,createdAt,createdBy{@id,fullname},type,comment',
      })
      .then((result) => {
        setLogList(result.getMembers());
      });
  }, [taskId]);

  return <LogList logList={logList} />;
}
TaskLogList.propTypes = {
  taskId: PropTypes.string.isRequired,
};
