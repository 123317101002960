import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import localforage from 'localforage';
import { parse } from 'query-string';
import { Form, Field } from 'react-final-form';
import Loader from 'react-loader';
import {
  Alert,
  Button,
  FormGroup,
  FormControl,
  FormLabel,
} from 'react-bootstrap';
import config from '../../config';
import * as loginAction from '../../actions/login';
import { formControlFields } from '../form/utils';

const validate = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = 'Requis';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Adresse email invalide';
  }

  if (!values.password) {
    errors.password = 'Requis';
  }

  return errors;
};

class Login extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isEmailLoginShowned: false,
    };

    this.showEmailLogin = this.showEmailLogin.bind(this);
  }

  componentDidMount() {
    const { accessToken, doLoginWithToken, nextUrl } = this.props;
    if (accessToken) {
      doLoginWithToken(accessToken);
    }

    if (nextUrl) {
      localforage.setItem('nextUrl', nextUrl);
    }
  }

  showEmailLogin() {
    this.setState({ isEmailLoginShowned: true });
  }

  render() {
    const { loginAttempt, doLogin, loginFetchStatus, accessToken } = this.props;

    const isLoaded =
      loginFetchStatus.status !== 'IN_PROGRESS' ||
      (accessToken && !loginAttempt === 'azureFailed');

    return (
      <div>
        {loginAttempt === 'azureFailed' && (
          <Alert variant="danger">
            Erreur dans la tentative de connection avec Windows Azure. Êtes-vous
            bien présent dans la liste des utilisateurs connus ?
            <br />
            Veuillez contacter votre administrateur.
          </Alert>
        )}
        <a href={`${config.api.url}/connect/azure`}>
          <Button variant="primary">Se connecter avec Windows Azure</Button>
        </a>{' '}
        <Button variant="link" onClick={this.showEmailLogin}>
          Se connecter avec un email + mot de passe
        </Button>
        {this.state.isEmailLoginShowned && (
          <div>
            <hr />
            <Form onSubmit={doLogin} validate={validate}>
              {({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit}>
                  <Loader loaded={isLoaded}>
                    <FormGroup>
                      <FormLabel>Adresse email</FormLabel>
                      <Field name="email">
                        {(field) => (
                          <>
                            <FormControl
                              type="email"
                              autoComplete="email"
                              placeholder="utilisateur@axegroup.fr"
                              {...formControlFields(field.input)}
                            />
                            {field.meta.touched && field.meta.error && (
                              <Alert variant="danger">{field.meta.error}</Alert>
                            )}
                          </>
                        )}
                      </Field>
                    </FormGroup>
                    <FormGroup>
                      <FormLabel>Mot de passe</FormLabel>
                      <Field name="password">
                        {(field) => (
                          <>
                            <FormControl
                              type="password"
                              autoComplete="current-password"
                              placeholder="mot de passe"
                              {...formControlFields(field.input)}
                            />
                            {field.meta.touched && field.meta.error && (
                              <Alert variant="danger">{field.meta.error}</Alert>
                            )}
                          </>
                        )}
                      </Field>
                    </FormGroup>

                    {loginFetchStatus.status === 'ERROR' && (
                      <Alert variant="warning">{loginFetchStatus.error}</Alert>
                    )}

                    <Button
                      type="submit"
                      variant="primary"
                      disabled={submitting}
                    >
                      Se connecter
                    </Button>
                  </Loader>
                </form>
              )}
            </Form>
          </div>
        )}
      </div>
    );
  }
}

Login.defaultProps = {
  nextUrl: '/',
};

Login.propTypes = {
  doLogin: PropTypes.func.isRequired,
  doLoginWithToken: PropTypes.func.isRequired,
  accessToken: PropTypes.string,
  loginAttempt: PropTypes.string,
  loginFetchStatus: PropTypes.object.isRequired,
  nextUrl: PropTypes.string,
};

function mapStateToProps(state, ownProps) {
  return {
    accessToken: parse(ownProps.location.search).access_token,
    loginAttempt: parse(ownProps.location.search).loginAttempt,
    loginFetchStatus: state.app.get('login-fetch-status'),
    nextUrl: parse(window.location.search).next,
  };
}

export default connect(mapStateToProps, loginAction)(Login);
