import { dispatchError } from './error';

function baseFetchFolder(promise) {
  return (dispatch) => {
    dispatch({
      type: 'ONE_DRIVE_FETCH_DATA',
    });

    promise
      .then((response) => response.json())
      .then((currentData) => {
        dispatch({
          type: 'ONE_DRIVE_DATA_FETCHED',
          currentData,
        });
      })
      .catch((error) => {
        dispatchError(error, dispatch);
        dispatch({
          type: 'ONE_DRIVE_DATA_FETCH_ERROR',
        });
      });
  };
}

export function fetchFolderById(id) {
  return baseFetchFolder(window.oneDriveApi.fetchFolderById(id));
}

export function fetchFolderByPath(path) {
  return baseFetchFolder(window.oneDriveApi.fetchFolderByPath(path));
}

function createFolder(promise) {
  return (dispatch) => {
    dispatch({
      type: 'ONE_DRIVE_CREATING_FOLDER',
    });

    return promise.then((folder) => {
      dispatch({
        type: 'ONE_DRIVE_FOLDER_CREATED',
        folder,
      });
    });
  };
}

export function createFolderForParentId(name, parentId) {
  return createFolder(
    window.oneDriveApi.createFolderForParentId(name, parentId)
  );
}

export function initFolder(name) {
  return (dispatch) => {
    dispatch({
      type: 'ONE_DRIVE_CREATING_FOLDER',
    });

    window.oneDriveApi
      .createRootFolder(name)
      .then((response) => response.json())
      .then((folder) =>
        Promise.all([
          window.oneDriveApi.createFolderForParentId('Procédures', folder.id),
          window.oneDriveApi.createFolderForParentId('PVs', folder.id),
          window.oneDriveApi.createFolderForParentId(
            'Fichiers configurations',
            folder.id
          ),
        ]).then(() => folder)
      )
      .then((folder) => {
        dispatch({
          type: 'ONE_DRIVE_FOLDER_CREATED',
          folder,
        });
      });
  };
}
