import config from '../config';
import superagent from 'superagent';
import AbstractClient from './abstractClient';
import AuthIntercept from './AuthIntercept';
import { createEntity } from '../entity/factory';

class UserClient extends AbstractClient {
  getPathBase() {
    return `${config.api.version}/users`;
  }

  findMe() {
    return this.fetchUrl(
      `${config.api.version}/me?fields=@id,email,needPasswordChange,roles,fullname,azureToken`
    );
  }

  refreshToken() {
    return this._getAccessToken().then(
      (accessToken) =>
        new Promise((resolve, reject) => {
          superagent
            .post(`${config.api.url}${config.api.version}/refresh-token`)
            .set('Authorization', `Bearer ${accessToken}`)
            .use(AuthIntercept)
            .accept('json')
            .end((err, res) => {
              if (err) {
                reject(err);
              } else {
                resolve(createEntity(JSON.parse(res.text)));
              }
            });
        })
    );
  }

  changePassword(values) {
    return this._getAccessToken().then(
      (accessToken) =>
        new Promise((resolve, reject) => {
          superagent
            .put(`${config.api.url}/v1/me/change-password`)
            .set('Authorization', `Bearer ${accessToken}`)
            .use(AuthIntercept)
            .accept('json')
            .send(values)
            .end((err, res) => {
              if (err) {
                reject(err);
              } else {
                resolve(createEntity(JSON.parse(res.text)));
              }
            });
        })
    );
  }
}

export default new UserClient();
