import superagent from 'superagent';
import { fromJS } from 'immutable';
import config from '../config';
import AbstractClient from './abstractClient';

class SiteClient extends AbstractClient {
  getPathBase() {
    return `${config.api.version}/sites`;
  }

  findExisting(site) {
    return this._getAccessToken().then(
      (accessToken) =>
        new Promise((resolve, reject) => {
          superagent
            .get(`${config.api.url}/v1/sites`)
            .set('Authorization', `Bearer ${accessToken}`)
            .query(
              fromJS(site)
                .mapKeys((key) => `site[${key}]`)
                .toJS()
            )
            .accept('json')
            .end((err, res) => {
              if (err) {
                reject(err);
              } else {
                const body = JSON.parse(res.text);
                resolve(body);
              }
            });
        })
    );
  }
}

export default new SiteClient();
