import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { connect } from 'react-redux';
import { Button, ButtonGroup, ButtonToolbar, Table } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import PagedCollection from '../../entity/PagedCollection';
import { findAllTeams, deleteTeam } from '../../actions/team';

class Team extends React.PureComponent {
  constructor(props) {
    super(props);

    this.getTeamList = this.getTeamList.bind(this);
    this.deleteTeam = this.deleteTeam.bind(this);
  }

  componentDidMount() {
    this.props.findAllTeams();
  }

  getShortId(team) {
    return team.get('@id').replace('/v1/teams/', '');
  }

  getTeamList() {
    return this.props.teamList
      ? this.props.teamList.get('hydra:member')
      : List();
  }

  deleteTeam(team) {
    if (window.confirm(`Supprimer l'équipe ${team.get('name')}`)) {
      return this.props.deleteTeam(team);
    }
  }

  render() {
    return (
      <div>
        <div>
          <h1>Équipes</h1>
          <Table striped hover>
            <thead>
              <tr>
                <td>Id</td>
                <td>Nom</td>
                <td>Agence ?</td>
                <td>Actions</td>
              </tr>
            </thead>
            <tbody>
              {this.getTeamList().map((team) => (
                <tr key={team.get('@id')}>
                  <td>{this.getShortId(team)}</td>
                  <td>{team.get('name')}</td>
                  <td>{team.get('agency') ? 'Oui' : 'Non'}</td>
                  <td>
                    <ButtonGroup>
                      <LinkContainer
                        to={`/teams/${this.getShortId(team)}/edit`}
                      >
                        <Button variant="secondary">modifier</Button>
                      </LinkContainer>
                      <Button
                        variant="danger"
                        onClick={() => this.deleteTeam(team)}
                      >
                        supprimer
                      </Button>
                    </ButtonGroup>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <h2>Actions</h2>
          <ButtonToolbar>
            <LinkContainer to="/teams/create">
              <Button variant="primary">+ Ajouter une équipe</Button>
            </LinkContainer>
            <LinkContainer to={'/users/'}>
              <Button variant="link">Retour</Button>
            </LinkContainer>
          </ButtonToolbar>
        </div>
      </div>
    );
  }
}

Team.propTypes = {
  findAllTeams: PropTypes.func.isRequired,
  teamList: PropTypes.instanceOf(PagedCollection),
  deleteTeam: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    teamList: state.app.get('teamList'),
  };
}

export default connect(mapStateToProps, { findAllTeams, deleteTeam })(Team);
