import config from '../config';
import AbstractClient from './abstractClient';

class StockClientClient extends AbstractClient {
  getPathBase() {
    return `${config.api.version}/stock_clients`;
  }
}

export default new StockClientClient();
