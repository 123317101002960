import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import history from '../../history';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { List } from 'immutable';
import Loader from 'react-loader';
import { Button, Row, Col } from 'react-bootstrap';
import TeamFilter from '../filters/TeamFilter';
import ProjectFilter from '../filters/ProjectFilter';
import taskApi from '../../api/task';
import TaskTable from './TaskTable';
import { selectMachineList } from '../../actions/machine';

function FilterList() {
  return (
    <Fragment>
      <Row>
        <Col sm={6}>
          <TeamFilter />
        </Col>
        <Col sm={6}>
          <ProjectFilter />
        </Col>
      </Row>
    </Fragment>
  );
}

class Transfer extends PureComponent {
  constructor(props) {
    super(props);

    this.handleSelectedTaskListChanged = this.handleSelectedTaskListChanged.bind(
      this
    );

    this.state = {
      taskList: null,
      selectedTaskList: [],
    };
  }

  componentDidMount() {
    this.findTaskWithTransferStatus();
  }

  componentDidUpdate(prevProps) {
    const { projectListFilter, teamListFilter } = this.props;

    if (
      prevProps.projectListFilter !== projectListFilter ||
      prevProps.teamListFilter !== teamListFilter
    ) {
      this.findTaskWithTransferStatus({
        project: projectListFilter.toArray().join(','),
        team: teamListFilter.toArray().join(','),
      });
    }
  }

  handleSelectedTaskListChanged(selectedTaskList) {
    this.setState({ selectedTaskList });

    const machineList = this.state.taskList
      .filter((t) => List(selectedTaskList).contains(t.get('@id')))
      .map((task) => task.get('machineList'))
      .reduce((acc, machineList) => {
        return acc.concat(machineList);
      }, List());

    this.props.selectMachineList(machineList);
  }

  findTaskWithTransferStatus(extraParameters) {
    taskApi
      .findBy({
        'machine[transferStatus]': 'to_transfer',
        'order[startDate]': 'ASC',
        fields:
          '@id,startDate,mainSite{formattedAddress,siteId},machineList{transferStatus,machineModel,site{formattedAddress,siteId}},project{name},userList{firstname,lastname}',
        ...extraParameters,
      })
      .then((result) => {
        this.setState({
          taskList: result.getMembers(),
        });
      });
  }

  render() {
    const { taskList, selectedTaskList } = this.state;

    return (
      <div>
        <h1>Transfert de machines</h1>

        <FilterList />

        <Loader loaded={!!taskList}>
          <TaskTable
            taskList={taskList}
            onChange={this.handleSelectedTaskListChanged}
          />

          <Button
            disabled={selectedTaskList.length === 0}
            onClick={this.props.goToTaskCreation}
          >
            Créer une tâche de transfert
          </Button>
        </Loader>
      </div>
    );
  }
}

Transfer.propTypes = {
  projectListFilter: ImmutablePropTypes.listOf(PropTypes.string),
  teamListFilter: ImmutablePropTypes.listOf(PropTypes.string),
  selectMachineList: PropTypes.func.isRequired,
  goToTaskCreation: PropTypes.func.isRequired,
};
Transfer.defaultProps = {
  projectListFilter: List(),
  teamListFilter: List(),
};

const mapStateToProps = (state) => ({
  projectListFilter: state.app.getIn(['savedFilters', 'projectList']),
  teamListFilter: state.app.getIn(['savedFilters', 'teamList']),
});

const mapDispatchToProps = {
  selectMachineList,
  goToTaskCreation: function () {
    return () => {
      history.push('/tasks/create?taskType=transfer');
    };
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(Transfer);
