import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import Task from '../../entity/Task';
import { useSelector } from 'react-redux';
import { currentUserIsAdminSelector } from '../../selector';
import { entityShortId } from '../../idTools';
import { MdEdit, MdClear, MdToday, MdWarning } from 'react-icons/md';

function TaskDate({ task }) {
  const startDate = task.get('startDate');
  const endDate = task.get('endDate');
  const taskDate = startDate.isSame(endDate, 'day')
    ? `Le ${startDate.format('DD MMMM YYYY')}`
    : `Du ${startDate.format('DD MMM')} au ${endDate.format('DD MMM YYYY')}`;

  return (
    <h4>
      <MdToday /> {taskDate}
    </h4>
  );
}

TaskDate.propTypes = {
  task: PropTypes.instanceOf(Task).isRequired,
};

export default function TaskHeader({
  task,
  onShowTaskDeletionModal = null,
  hideUsers = false,
}) {
  const isAdmin = useSelector(currentUserIsAdminSelector);
  const siteIds = task.getSiteIds();

  return (
    <Container fluid>
      <Row>
        <Col xs={12} sm={8}>
          <h1>
            {task.get('status') === 'needs_appointment' && (
              <MdWarning title="RDV non validé" style={{ marginRight: 5 }} />
            )}
            {task.getIn(['project', 'name']) || 'Pas de projet'}
            {siteIds && ` – ${siteIds}`}{' '}
            <small>
              <LinkContainer to={`/tasks/${entityShortId(task)}/edit`}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a style={{ marginLeft: 5 }}>
                  <MdEdit />
                </a>
              </LinkContainer>

              {isAdmin && typeof onShowTaskDeletionModal === 'function' && (
                /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
                <a
                  href="#delete"
                  onClick={onShowTaskDeletionModal}
                  style={{ marginLeft: 5 }}
                >
                  <MdClear />
                </a>
              )}
            </small>
          </h1>
          {!hideUsers && (
            <h2>
              {task.userList
                .map((user) => `${user.firstname} ${user.lastname}`)
                .reduce((prev, curr) => `${prev}, ${curr}`)}
            </h2>
          )}
        </Col>
        <Col xs={12} sm={4}>
          <TaskDate task={task} />
        </Col>
      </Row>
    </Container>
  );
}

TaskHeader.propTypes = {
  task: PropTypes.instanceOf(Task).isRequired,
  onShowTaskDeletionModal: PropTypes.func,
  hideUsers: PropTypes.bool,
};
