import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import 'moment/locale/fr';
import momentLocalizer from 'react-widgets-moment';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import 'react-widgets/dist/css/react-widgets.css';

moment.locale('fr');
momentLocalizer();
// var FormField = require('./FormField');

class DateInput extends React.Component {
  // shouldComponentUpdate: FormField.shouldFormFieldUpdate,
  render() {
    const { field, inputProps, isEndDate } = this.props;

    return (
      <DateTimePicker
        {...inputProps}
        format="YYYY-MM-DD"
        name={field.name}
        onChange={(value) => { 
          if (isEndDate) {
            value.setHours(23, 59, 59);
          }

          field.onChange(value); 
        }}
        time={false}
        value={field.value || null}
      />
    );
  }
}

DateInput.defaultProps = {
  isEndDate: false,
}

  DateInput.propTypes = {
  field: PropTypes.object.isRequired,
  inputProps: PropTypes.object,
  isEndDate: PropTypes.bool,
};

export default DateInput;
