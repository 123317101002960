import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import moment from 'moment';
import { parse } from 'query-string';
import { Map, List } from 'immutable';
import cn from 'classnames';
import { Form, Field } from 'react-final-form';
import {
  Alert,
  FormLabel,
  FormControl,
  Button,
  ButtonToolbar,
  Col,
  Container,
  Modal,
  Row,
} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import RouterPropTypes from '../../prop-types/router';
import Task from '../../entity/Task';
import User from '../../entity/User';
import { shortId, entityShortId, entityId } from '../../idTools';
import PrettySelect from './PrettySelect';
import ProjectSelect from './ProjectSelect';
import {
  validateTaskForm,
  removeCurrentTask,
  createUpdateTask,
  updateMatResAllDay,
  findTask,
} from '../../actions/task';
import { findAllUsers } from '../../actions/user';
import { findAllMaterialResources } from '../../actions/materialResource';
import TeamFilter from '../filters/TeamFilter';
import DateInput from './DateInput';
import { formControlFields } from './utils';
import AllUserFilter from '../filters/AllUserFilter';
import ReduxFormGroup from './ReduxFormGroup';
import { transMachinePreparationStatus } from '../task/TaskMachinePreparationStatus';
import { transMachineTransferStatus } from '../task/TaskMachineTransferStatus';
import startDateEndDateDecorator from './startDateEndDateDecorator';

const isDatePast = (value) => value && moment(value) < moment();

const TASK_FIELDS = `
  @id,startDate,endDate,status,type,machinePreparationStatus,machineTransferStatus,
  comment,planningComment,
  project,
  machineList{@id},
  userList{@id,firstname,lastname},
  materialResourceList{@id,name,description}
`.replace(/\n\s*/g, '');

class TaskForm extends PureComponent {
  constructor(props) {
    super(props);

    this.fetchTask = this.fetchTask.bind(this);
    this.getSelectedMachinesSelect = this.getSelectedMachinesSelect.bind(this);
    this.createUpdateTask = this.createUpdateTask.bind(this);
    this.updateMatResAllDay = this.updateMatResAllDay.bind(this);
    this.closeModal = this.closeModal.bind(this);

    this.state = {
      isModalOpened: false,
    };
  }

  componentDidMount() {
    this.fetchTask();
    this.props.findAllUsers();
    this.props.findAllMaterialResources();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.taskId !== this.props.match.params.taskId) {
      this.fetchTask();
    }
  }

  componentWillUnmount() {
    this.props.removeCurrentTask();
  }

  getSelectedMachinesSelect() {
    return (
      this.props.selectedMachines &&
      this.props.selectedMachines
        .map((machine) => ({
          id: machine.get('@id'),
          label: machine.get('@id'),
        }))
        .toArray()
    );
  }

  fetchTask() {
    if (this.props.match.params.taskId) {
      this.props.findTask(this.props.match.params.taskId, TASK_FIELDS);
    }
  }

  createUpdateTask(values) {
    if (this.props.onlyMatRes && !this.state.isModalOpened) {
      return this.setState({ isModalOpened: true });
    }

    this.props.createUpdateTask(values, this.props.selectedMachines);
  }

  updateMatResAllDay(materialResourceList) {
    const { currentTask } = this.props;

    this.props.updateMatResAllDay(currentTask.get('@id'), materialResourceList);
  }

  closeModal() {
    this.setState({ isModalOpened: false });
  }

  render() {
    const {
      userList,
      materialResourceList,
      projectId,
      onlyMatRes,
      currentTask,
      isEdition,
      selectedMachines,
      initialValues,
    } = this.props;

    const currentMachinePreparationStatus =
      currentTask &&
      currentTask.machinePreparationStatus &&
      transMachinePreparationStatus(currentTask.machinePreparationStatus);
    const currentMachineTransferStatus =
      currentTask &&
      currentTask.machineTransferStatus &&
      transMachineTransferStatus(currentTask.machineTransferStatus);

    return (
      <div>
        <h1>
          {isEdition ? 'Édition' : 'Création'} de tâche{' '}
          {selectedMachines && (
            <small className="text-secondary">
              {selectedMachines.size} machine
              {selectedMachines.size > 1 ? 's' : ''}
            </small>
          )}
        </h1>

        <TeamFilter />

        <Form
          initialValues={initialValues}
          onSubmit={this.createUpdateTask}
          validate={validateTaskForm}
          decorators={[startDateEndDateDecorator]}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Modal show={this.state.isModalOpened} onHide={this.closeModal}>
                <Modal.Header>
                  Appliquer uniquement pour cette tâche ou bien pour toute la
                  journée ?
                </Modal.Header>
                <Modal.Body>
                  <ButtonToolbar>
                    <Button variant="secondary" onClick={handleSubmit}>
                      Uniquement cette tâche
                    </Button>
                    <Field
                      name="materialResourceList"
                      subscription={{ value: true }}
                    >
                      {({ input }) => (
                        <Button
                          onClick={() => this.updateMatResAllDay(input.value)}
                        >
                          Toute la journée
                        </Button>
                      )}
                    </Field>
                    <Button variant="link" onClick={this.closeModal}>
                      Annuler
                    </Button>
                  </ButtonToolbar>
                </Modal.Body>
              </Modal>

              <Field name="project">
                {({ input, meta }) => (
                  <>
                    <div>
                      <FormLabel>Projet</FormLabel>
                      <ProjectSelect {...formControlFields(input)} />
                    </div>

                    {meta.touched && meta.error && (
                      <Alert variant="danger">{meta.error}</Alert>
                    )}
                  </>
                )}
              </Field>

              <div className={cn(onlyMatRes && 'hidden')}>
                <Field>
                  {({ input }) => (
                    <div>
                      <FormLabel>Type</FormLabel>
                      <FormControl as="select" {...formControlFields(input)}>
                        <option value="installation">Installation</option>
                        <option value="transfer">Transfert</option>
                      </FormControl>
                    </div>
                  )}
                </Field>
              </div>

              <div className="hidden">
                <Field name="machineList">
                  {({ input }) => (
                    <PrettySelect
                      multiple
                      options={this.getSelectedMachinesSelect()}
                      {...input}
                    />
                  )}
                </Field>
              </div>

              <div className={cn(onlyMatRes && 'hidden')}>
                <Field name="userList">
                  {({ input, meta }) => (
                    <ReduxFormGroup meta={meta} label="Utilisateurs" isRequired>
                      <AllUserFilter doFetch={false} />
                      <PrettySelect
                        multiple
                        {...input}
                        options={userList
                          .map((user) => ({
                            id: user.get('@id'),
                            label: `${user.get('firstname')} ${user.get(
                              'lastname'
                            )}`,
                          }))
                          .toArray()}
                      />
                    </ReduxFormGroup>
                  )}
                </Field>

                <Container>
                  <Row>
                    <Col sm={6}>
                      <Field name="startDate">
                        {({ input, meta }) => (
                          <>
                            <ReduxFormGroup
                              meta={meta}
                              label="Date de début"
                              isRequired
                            >
                              <DateInput field={input} />
                            </ReduxFormGroup>

                            {isDatePast(input.value) && (
                              <Alert variant="warning">
                                Attention : date de départ passée
                              </Alert>
                            )}
                          </>
                        )}
                      </Field>
                    </Col>

                    <Col sm={6}>
                      <Field name="endDate">
                        {({ input }) => (
                          <>
                            <label htmlFor="endDate">Date de fin</label>
                            <DateInput id="endDate" isEndDate field={input} />
                          </>
                        )}
                      </Field>
                    </Col>
                  </Row>
                </Container>
              </div>

              <Field name="materialResourceList">
                {({ input }) => (
                  <div>
                    <FormLabel>Ressources matérielles</FormLabel>
                    <PrettySelect
                      multiple
                      {...input}
                      options={
                        materialResourceList &&
                        materialResourceList
                          .map((materialResource) => ({
                            id: materialResource.get('@id'),
                            label: `${materialResource.get('name')} ${
                              materialResource.get('description') &&
                              `(${materialResource.get('description')})`
                            }`,
                          }))
                          .toArray()
                      }
                    />
                  </div>
                )}
              </Field>

              <div className={cn(onlyMatRes && 'hidden')}>
                <Field name="status">
                  {({ input }) => (
                    <div>
                      <FormLabel>Statut</FormLabel>
                      <FormControl as="select" {...formControlFields(input)}>
                        <option value="needs_appointment">RDV à prendre</option>
                        <option value="appointment_taken">RDV pris</option>
                      </FormControl>
                    </div>
                  )}
                </Field>

                <div>
                  <Field name="machinePreparationStatus">
                    {({ input, meta }) => (
                      <ReduxFormGroup
                        meta={meta}
                        label="Préparation des machines"
                      >
                        <FormControl
                          as="select"
                          isValid={meta.touched && !meta.error}
                          isInvalid={meta.touched && meta.error}
                          {...formControlFields(input)}
                        >
                          <option value="">
                            {currentTask
                              ? `Status actuel (${currentMachinePreparationStatus})`
                              : ''}
                          </option>
                          <option value="waiting_info">
                            {"En attente d'informations"}
                          </option>
                          <option value="to_prepare">A préparer</option>
                          <option value="ready">Prête</option>
                          <option value="on_site">Sur site</option>
                          <option value="not_applicable">Non concernée</option>
                        </FormControl>

                        {meta.touched && input.value && (
                          <Alert variant="warning">
                            Attention, modifier le status de préparation des
                            machines impactera toutes les machines de cette
                            tâche
                          </Alert>
                        )}
                      </ReduxFormGroup>
                    )}
                  </Field>
                </div>

                <div>
                  <Field name="machineTransferStatus">
                    {({ input, meta }) => (
                      <ReduxFormGroup
                        meta={meta}
                        label="Transfert des machines"
                      >
                        <FormControl
                          as="select"
                          isValid={meta.touched && !meta.error}
                          isInvalid={meta.touched && meta.error}
                          {...formControlFields(input)}
                        >
                          <option value="">
                            {currentTask
                              ? `Status actuel (${currentMachineTransferStatus})`
                              : ''}
                          </option>
                          <option value="waiting_info">
                            {"En attente d'informations"}
                          </option>
                          <option value="to_transfer">A transférer</option>
                          <option value="transfered">Transférée</option>
                          <option value="not_applicable">Non concernée</option>
                        </FormControl>
                        {meta.touched && input.value && (
                          <Alert variant="warning">
                            Attention, modifier le status de transfert des
                            machines impactera toutes les machines de cette
                            tâche
                          </Alert>
                        )}
                      </ReduxFormGroup>
                    )}
                  </Field>
                </div>

                <Field name="comment">
                  {({ input }) => (
                    <div>
                      <FormLabel>Commentaire</FormLabel>
                      <FormControl
                        type="textarea"
                        {...formControlFields(input)}
                      />
                    </div>
                  )}
                </Field>

                <Field name="planningComment">
                  {({ input }) => (
                    <div>
                      <FormLabel>
                        Commentaire à afficher dans le planning
                      </FormLabel>
                      <FormControl
                        type="textarea"
                        {...formControlFields(input)}
                      />
                    </div>
                  )}
                </Field>
              </div>

              <ButtonToolbar>
                <Button type="submit" variant="primary">
                  {isEdition ? 'Modifier' : 'Créer'}
                </Button>
                {projectId ? (
                  <LinkContainer to={`/projects/${shortId(projectId)}/plan/`}>
                    <Button variant="link">Annuler</Button>
                  </LinkContainer>
                ) : (
                  <LinkContainer
                    to={
                      onlyMatRes
                        ? `/tasks/${currentTask && entityShortId(currentTask)}`
                        : '/planning/'
                    }
                  >
                    <Button variant="link">Annuler</Button>
                  </LinkContainer>
                )}
              </ButtonToolbar>
            </form>
          )}
        </Form>
      </div>
    );
  }
}
TaskForm.defaultProps = {
  selectedMachines: null,
  currentTask: null,
  projectId: null,
};

TaskForm.propTypes = {
  updateMatResAllDay: PropTypes.func.isRequired,
  createUpdateTask: PropTypes.func.isRequired,
  findTask: PropTypes.func.isRequired,
  findAllUsers: PropTypes.func.isRequired,
  findAllMaterialResources: PropTypes.func.isRequired,
  removeCurrentTask: PropTypes.func.isRequired,
  match: RouterPropTypes.params({ taskId: PropTypes.string.isRequired }),
  selectedMachines: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  userList: ImmutablePropTypes.listOf(PropTypes.instanceOf(User)),
  materialResourceList: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  onlyMatRes: PropTypes.bool.isRequired,
  projectId: PropTypes.string,
  currentTask: PropTypes.instanceOf(Task),
  isEdition: PropTypes.bool.isRequired,
  initialValues: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
  const currentTask = state.app.get('currentTask');

  const isEdition = !!currentTask;

  let initialValues = currentTask
    ? currentTask
        .set('machinePreparationStatus', '')
        .set('machineTransferStatus', '')
    : Map(); // clone

  if (!isEdition) {
    if (
      parse(ownProps.location.search).project &&
      parse(ownProps.location.search).project !== 'undefined'
    ) {
      initialValues = initialValues.set(
        'project',
        parse(ownProps.location.search).project
      );
    }
  } else {
    initialValues = initialValues.set(
      'project',
      currentTask.getIn(['project', '@id'])
    );
  }

  if (currentTask && currentTask.get('userList')) {
    initialValues = initialValues.set(
      'userList',
      currentTask.userList.map((u) => u.get('@id'))
    );
  }

  if (
    currentTask &&
    currentTask.get('materialResourceList') &&
    currentTask.get('materialResourceList').size > 0
  ) {
    initialValues = initialValues.set(
      'materialResourceList',
      currentTask.get('materialResourceList').map((mr) => mr.get('@id'))
    );
  } else {
    initialValues = initialValues.set('materialResourceList', List());
  }

  if (currentTask && currentTask.get('startDate')) {
    initialValues = initialValues.set(
      'startDate',
      currentTask.get('startDate').toDate()
    );
  }

  if (currentTask && currentTask.get('endDate')) {
    initialValues = initialValues.set(
      'endDate',
      currentTask.get('endDate').toDate()
    );
  }

  const taskType = parse(ownProps.location.search).taskType;
  if (taskType) {
    initialValues = initialValues.set('type', taskType);
  }

  // get user list
  const teamListFilter =
    state.app.getIn(['savedFilters', 'teamList']) || List();

  const userList = state.app.getIn(['userList', 'hydra:member']) || List();

  const displayAllUsers =
    state.app.getIn(['savedFilters', 'allUserFilter']) || false;

  const filteredUserList = userList
    .filter((user) => user.isActive)
    .filter(
      (user) => displayAllUsers === true || user.showInTaskList !== 'never'
    )
    .filter(
      (user) =>
        teamListFilter.size === 0 ||
        teamListFilter.includes(entityId(user.team))
    );

  const materialResourceList = state.app.getIn([
    'materialResourceList',
    'hydra:member',
  ]);
  const filteredMaterialResourceList =
    materialResourceList &&
    materialResourceList.filter(
      (matRes) =>
        teamListFilter.size === 0 ||
        teamListFilter.includes(matRes.getIn(['team', '@id']))
    );

  return {
    selectedMachines: currentTask
      ? currentTask.get('machineList')
      : state.app.get('selectedMachines'),

    projectId:
      parse(ownProps.location.search).project &&
      parse(ownProps.location.search).project !== 'undefined' &&
      parse(ownProps.location.search).project,

    onlyMatRes: !!parse(ownProps.location.search).onlyMatRes,

    userList: filteredUserList,
    materialResourceList: filteredMaterialResourceList,

    isEdition,

    initialValues: initialValues.toJS(),

    currentTask,
  };
}

export default connect(mapStateToProps, {
  findAllUsers,
  findAllMaterialResources,
  updateMatResAllDay,
  createUpdateTask,
  findTask,
  removeCurrentTask,
})(TaskForm);
