import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import {
  Alert,
  Button,
  ButtonGroup,
  Col,
  Container,
  Row,
} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { shortId, entityShortId } from '../../idTools';
import Task from '../../entity/Task';
import AttachedFiles from './AttachedFiles';
import TaskMachinePreparationStatus from './TaskMachinePreparationStatus';
import TaskMachineTransferStatus from './TaskMachineTransferStatus';
import TaskDeletionModal from './TaskDeletionModal';
import FinishTaskModal from './FinishTaskModal';
import TaskHeader from './TaskHeader';
import { MdCheck, MdEdit, MdPlace } from 'react-icons/md';

class TaskDetail extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      taskDeletionModalVisible: false,
      isFinishTaskModalVisible: false,
    };

    this.onMachineListFinish = this.onMachineListFinish.bind(this);
    this.onMachineListTransfered = this.onMachineListTransfered.bind(this);
    this.onDeleteCurrentTask = this.onDeleteCurrentTask.bind(this);
    this.hasMultipleContacts = this.hasMultipleContacts.bind(this);
    this.showTaskDeletionModal = this.showTaskDeletionModal.bind(this);
    this.hideTaskDeletionModal = this.hideTaskDeletionModal.bind(this);
    this.showFinishTaskModal = this.showFinishTaskModal.bind(this);
    this.hideFinishTaskModal = this.hideFinishTaskModal.bind(this);
  }

  showTaskDeletionModal() {
    this.setState({
      taskDeletionModalVisible: true,
    });
  }

  hideTaskDeletionModal() {
    this.setState({
      taskDeletionModalVisible: false,
    });
  }

  showFinishTaskModal() {
    this.setState({
      isFinishTaskModalVisible: true,
    });
  }

  hideFinishTaskModal() {
    this.setState({
      isFinishTaskModalVisible: false,
    });
  }

  onMachineListFinish(values) {
    // const machineList = this.props.currentTask
    //   .get('machineList')
    //   .filterNot((machine) => machine.get('status') === 'done');

    const machineList = Object.fromEntries(
      Object.entries(values).map(([id, value]) => [
        id,
        value ? 'done' : 'to_plan',
      ])
    );

    this.props.handleMachineListStatusChange(machineList);

    this.hideFinishTaskModal();
  }

  onMachineListTransfered(values) {
    // const machineList = this.props.currentTask
    //   .get('machineList')
    //   .filterNot((machine) => machine.get('transferStatus') === 'transfered');

    const machineList = Object.fromEntries(
      Object.entries(values).map(([id, value]) => [
        id,
        value ? 'transfered' : 'to_transfer',
      ])
    );

    this.props.handleMachineListTransferStatusChange(machineList);

    this.hideFinishTaskModal();
  }

  onDeleteCurrentTask() {
    this.props.deleteTask(this.props.currentTask);
  }

  hasMultipleContacts() {
    const task = this.props.currentTask;
    const machineList = task.get('machineList');

    const groupedMachines = machineList.groupBy((machine) =>
      List.of(
        machine.get('siteContactFirstname'),
        machine.get('siteContactName'),
        machine.get('siteContactPhone'),
        machine.get('siteContactEmail')
      )
    );

    return groupedMachines.size > 1;
  }

  render() {
    const {
      currentTask: task,
      isSigned,
      isServiceProvider,
      dispatchError,
    } = this.props;

    const { taskDeletionModalVisible, isFinishTaskModalVisible } = this.state;

    if (!task) {
      // should not happen
      return null;
    }

    const site = task.mainSite;

    const contactMachine = task.getIn(['machineList', 0]);

    return (
      <div>
        <TaskDeletionModal
          task={task}
          show={taskDeletionModalVisible}
          onHide={this.hideTaskDeletionModal}
          dispatchError={dispatchError}
          onSubmit={this.onDeleteCurrentTask}
        />

        <FinishTaskModal
          show={isFinishTaskModalVisible}
          onHide={this.hideFinishTaskModal}
          task={task}
          onSubmit={
            task.isTransfer()
              ? this.onMachineListTransfered
              : this.onMachineListFinish
          }
        />

        <TaskHeader
          task={task}
          onShowTaskDeletionModal={this.showTaskDeletionModal}
        />

        {site && (
          <h5>
            {site.get('formattedAddress')}

            <a
              href={`https://maps.google.fr/?q=${encodeURIComponent(
                site.get('formattedAddress')
              )}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <MdPlace />
            </a>
          </h5>
        )}

        {contactMachine && (
          <div>
            <h3>Contact site</h3>
            {this.hasMultipleContacts() && (
              <Alert variant="warning">
                Attention ! Les contacts sont différents selon les machines,
                seul le contact de la premiere machine est affiché
              </Alert>
            )}

            <div>
              <strong>
                {contactMachine.get('siteContactFirstname')}{' '}
                {contactMachine.get('siteContactName')}
              </strong>
            </div>
            <div>
              <a href={`tel: ${contactMachine.get('siteContactPhone')}`}>
                {contactMachine.get('siteContactPhone')}
              </a>
            </div>
            <div>{contactMachine.get('siteContactEmail')}</div>
          </div>
        )}

        {task.get('comment') && (
          <div>
            <h3>Commentaire</h3>
            <p>{task.get('comment')}</p>
          </div>
        )}

        {task.get('materialResourceList') && (
          <div style={{ paddingBottom: 10 }}>
            <h3>
              Ressources matérielles
              <LinkContainer
                to={`/tasks/${entityShortId(task)}/edit?onlyMatRes=1`}
              >
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a style={{ marginLeft: 5 }}>
                  <MdEdit />
                </a>
              </LinkContainer>
            </h3>
            {task.get('materialResourceList').map((matResource) => (
              <div
                key={matResource.get('@id')}
                style={{
                  backgroundColor: matResource.get('backgroundColor'),
                  color: matResource.get('foregroundColor'),
                }}
              >
                <div>
                  {matResource.get('name')}
                  {matResource.get('description') &&
                    `: ${matResource.get('description')}`}
                </div>
              </div>
            ))}
          </div>
        )}

        {task.get('machineList') && task.get('machineList').size > 0 ? (
          <div>
            <h3>Machines</h3>
            <p>
              Préparation des machines :{' '}
              <TaskMachinePreparationStatus task={task} />
            </p>
            <p>
              Transfert des machines : <TaskMachineTransferStatus task={task} />
            </p>
            <Container fluid>
              <Row>
                {task.get('machineList').map((machine) => (
                  <Col sm={3} key={machine}>
                    <LinkContainer
                      to={`/machines/${entityShortId(machine)}${
                        task.isTransfer() ? '?fromTransferTask=1' : ''
                      }`}
                    >
                      <Button
                        block
                        variant={
                          (task.isTransfer() &&
                            machine.transferStatus === 'transfered') ||
                          (!task.isTransfer() &&
                            machine.get('status') === 'done')
                            ? 'success'
                            : 'warning'
                        }
                        style={{ marginBottom: 10 }}
                      >
                        <div>
                          {machine.get('machineModel') ||
                            'Modele non renseigné'}
                        </div>
                        <div>{machine.get('machineSerialNumber')}</div>

                        <div className="small">
                          <div>Service: {machine.get('serviceName')}</div>
                          <div>Batiment: {machine.get('serviceBuilding')}</div>
                          <div>Bureau: {machine.get('serviceOffice')}</div>
                          <div>Étage: {machine.get('serviceFloor')}</div>
                        </div>
                      </Button>
                    </LinkContainer>
                  </Col>
                ))}
              </Row>
            </Container>
          </div>
        ) : (
          <Alert variant="warning">
            Aucune machine planifiée sur cette tâche
          </Alert>
        )}

        <div>
          {task.getIn(['project', '@id']) && !isServiceProvider && (
            <Fragment>
              <h3>Liens</h3>
              <LinkContainer
                to={`/projects/${shortId(task.getIn(['project', '@id']))}/`}
              >
                <Button variant="info">Contacts et procédures</Button>
              </LinkContainer>
            </Fragment>
          )}

          <h3>Fichiers attachés</h3>
          <AttachedFiles task={task} />

          <h3>Actions</h3>
          <ButtonGroup>
            <Button variant="primary" onClick={this.showFinishTaskModal}>
              {task.isTransfer()
                ? 'Passer toutes les machines à "transférées"'
                : 'Passer toutes les machines à "terminées"'}
            </Button>

            {isSigned && task.areAllMachinesWithInstallationFiles() && (
              <Button disabled variant="success">
                <MdCheck /> Intervention signée
              </Button>
            )}

            {isSigned && !task.areAllMachinesWithInstallationFiles() && (
              <LinkContainer
                to={`/tasks/${entityShortId(task)}/sign/per-machine`}
              >
                <Button variant="secondary">
                  Envoyer les fichiers de configuration des machines
                </Button>
              </LinkContainer>
            )}

            {!isSigned && (
              <LinkContainer
                to={`/tasks/${entityShortId(task)}/sign/installation`}
              >
                <Button variant="secondary">{"Signer l'intervention"}</Button>
              </LinkContainer>
            )}
          </ButtonGroup>
        </div>
      </div>
    );
  }
}

TaskDetail.defaultProps = {
  currentTask: null,
};

TaskDetail.propTypes = {
  dispatchError: PropTypes.func.isRequired,
  handleMachineListStatusChange: PropTypes.func.isRequired,
  handleMachineListTransferStatusChange: PropTypes.func.isRequired,
  deleteTask: PropTypes.func.isRequired,
  isServiceProvider: PropTypes.bool.isRequired,
  isSigned: PropTypes.bool.isRequired,
  currentTask: PropTypes.instanceOf(Task),
};

export default TaskDetail;
